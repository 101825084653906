const introData = [
  {
    image: "/feature_0.png",
    name: "Access to Taobao, Tmall, and 1688 with billions of products which duly meet your needs and result in higher profit.",
  },
  {
    image: "/feature_1.png",
    name: "Secure online transactions with alternative payment methods and on time delivery.",
  },
  {
    image: "/feature_2.png",
    name: "Drop-ship, wholesale bulk buy, white-label, or custom products, we will get all your sourcing needs covered.",
  },
  {
    image: "/feature_3.png",
    name: "Our professional service team can fill all your short and long-term wholesale merchandise needs.",
  },
];

export default introData;
