import { Container } from "components/container";
import { Heading } from "components/heading";
import { SharedImage } from "constants/image-constant";
import useLoader from 'hooks/useLoader';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Currency } from 'utilities';
import { additionalPayment, getOrderByNumber, sendReturnRequest } from 'utilities/order';
import Refund from '../refunds';
import './history.scss';
import ReturnsDialog from './returns_dialog';

//Firebase 
import { db } from 'firebase.js';
import { useSelector } from "react-redux";

const HeadingStyle = {
    textAlign: "left",
    paddingLeft: "0px",
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "57px",
    color: "#072A48",
    float:"left"
}

const { DownArrow } = SharedImage;

const ContainerStyle = {
    paddingBottom: "200px",
    width: '100%'
}

//Get QC Images
let qc_images_obj = {};
db.ref("warehouselist").once('value', async (snapshot) => {
    let vals = Object.values(snapshot.val() || {});
    for (let i = 0; i < vals.length; i++) {
        let val = vals[i];
        qc_images_obj[val["order_number"]] = val.images;
    }
});

function forceDownload(url, fileName){
    fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", '');
        a.setAttribute("href", url);
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });
    var a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const generateColumns = ({ onOpenReturnDialog }, rates) => [
    {
        name: 'Order Number',
        width: "230px",
        cell: (d) => {
            return <div style={{display:"flex", alignItems:"center"}}>
                <p className="order-number" style={{maxWidth: "120px", whiteSpace: "break-spaces"}}>{d.order_number}</p>
                <Link style={{textDecoration: "none"}} to={"/product-detail?store="+(d?.product.detail_url.includes("weidian") ? "weidian" : d.product?.tmall ? "tmall" : "taobao")+"&id="+d.product_number}><img src = {d.product.pic_url} alt = "Product"  style = {{width: "85px", height: "90px", padding: "5px 0px", marginLeft: "10px"}}/></Link>
            </div>
        }
    },
    {
        name: "Item Name",
        width: "300px",
        cell: (d) => {
            return <div>
                <Link style={{textDecoration: "none"}} to={"/product-detail?store="+(d?.product.detail_url.includes("weidian") ? "weidian" : d.product?.tmall ? "tmall" : "taobao")+"&id="+d.product_number}><p style={{whiteSpace: "break-spaces"}} className="product-title">{d.product.title}</p></Link>
            </div>
        },
    },
    {
        name: "Specifications",
        width: "180px",
        cell: (d) => {
            return <div className="specification-container">
                <span className="specification">
                {d.product.specifications &&
                    <div>
                        {
                            Object.keys(d.product.specifications).map((key, index) => {
                                let vals = Object.values(d.product.specifications)[index];
                                return (
                                    <p className="specification">
                                        <p style={{fontSize: "12px", whiteSpace: "nowrap", marginBottom: "-10px"}}>{key}:</p><span style={{fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap"}}>{vals[vals.length - 1]}</span>
                                        { vals.length > 3 &&
                                            <>
                                                <img src={vals[2].replace("url:", "")} style={{
                                                    height:"40px",
                                                    width:"40px",
                                                    border:"1px solid rgba(0,0,0,0.2)",
                                                    borderRadius: "8px",
                                                    display:"inline-block",
                                                    marginLeft:"10px",
                                                    verticalAlign: "middle"
                                                }}/>
                                            </> 
                                        }
                                    </p>
                                )
                            })
                        }
                    </div>
                }
                </span>
            </div>
        },
    },
    {
        name: 'Price',
        selector: row => row.product.price * +row.product.quantity,
        sortable: true,
        //sortFunction: sortingAlgorithms.priceSort,
        width: '190px',
        cell: (d) => {
            return <div className="item-price">{Currency.GetPrice(rates, d.product.price * +d.product.quantity)}</div>
        }
    },
    {
        name: 'Quantity',
        selector: row => row.product.quantity,
        sortable: true,
        //sortFunction: sortingAlgorithms.quantitySort,
        width: '100px',
        cell: (d) => {
            return <span className="quantity-count">{d.product.quantity}</span>
        }
    },
    {
        name: "Status",
        width: "170px",
        cell: (d) => {
            if (!d.product.additional_payment) {
                return (<div style={{padding: "8px 0", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <p className={"item-status status-"+d.product_status.replace("Payment", "")}>{d.product_status.replace("Payment", "")}</p>
                </div>)
            }
            return (<div className="additional-payment-container">
                <p className="additional-payment-warning">You have <span>{d.product.additional_payment}$</span> amount of additional payment: </p>
                <p className="additional-payment-loading">Loading...</p>
                <button onClick={(event) => {
                    $(".additional-payment-container > *").attr("style", "display: none");
                    $(".additional-payment-loading").show();
                    additionalPayment([d.order_number], [[d.product.num_iid]], d.user_id)
                    .then(() => {
                        toast.success("Addition has been payed successfully");
                        setTimeout(() => window.location.reload(), 2000);
                    })
                    .catch((e) => {
                        toast.error(e);

                        $(".additional-payment-container > *").removeAttr("style");
                        $(".additional-payment-loading").hide();
                    });
                }}>PAY NOW</button>
            </div>)
        }
    },
    {
        name: "Details",
        cell: (d) => {
            let order_number = d.order_number;
            let qc_images = qc_images_obj[order_number] || [];
            
            return <div>
                <a className="view-details" onClick={(event) => {
                    let parent_ = $(event.currentTarget).parent().parent().parent();
                    if (+parent_.css("height").slice(0,-2) != 210) {
                        parent_.animate({
                            height: "+210px"
                        });

                        if (qc_images?.length > 0) {
                            parent_.prepend(`
                                <div class="qc-images">
                                    <button class="qc-left">
                                        <img src="/caret-left-fill.svg">
                                    </button>
                                    <a download href="${qc_images[0]}" class="qc-download">
                                        <img src="/download.svg">
                                    </a>
                                    <button class="qc-right">
                                        <img src="/caret-right-fill.svg">
                                    </button>
                                </div>
                            `);

                            $(".qc-download").on("click", function(e) {
                                let images = $(e.currentTarget).parent().find("> img").toArray();
                                images.forEach(image => {
                                    forceDownload(image.src, "image.png");
                                })
                            });

                            let counter = 0;
                            qc_images.forEach(image => {
                                parent_.find(".qc-download").before(`
                                    <img src="${image}" class="img-num-${counter}">
                                `);
                                $(`.img-num-${counter}`).on("click", function() {
                                    setTimeout(() => {
                                        document.querySelector('.image-modal').style.display = 'flex';
                                        //document.querySelector('.modal-img').src = image;

                                        document.querySelector('.image-modal').style.display = 'flex';
                                        for (let i = 0; i < qc_images.length; i++) {
                                            let img_ = document.createElement('img');
                                            let img_modal = document.querySelector('.image-modal .img-container');
                                            img_.src = qc_images[i];
                                            img_modal.appendChild(img_);
                                        }
                                        $('.image-modal img').css("display", "none");
                                        $('.image-modal img:nth-child('+ (qc_images.indexOf(image) + 1) +')').css("display", "block");
                                    }, 1);
                                });
                                counter += 1;
                            });
                        } else {
                            parent_.prepend(`
                                <div class="qc-images">
                                    <p>QC Images don't exist for this product</p>
                                </div>
                            `);
                        }
                    } else {
                        parent_.animate({
                            height: "+131px"
                        }, function() {
                            parent_.find(".qc-images").remove();
                        });
                    }
                    }}>QC Images <FaChevronDown className="favoritePage__icon" />
                </a>
            </div>
        },
        width: "170px",
    },
    {
        name: "Date",
        id: "date",
        selector: d => new Date(d.order_time).getTime(),
        sortable: true,
        cell: (d) => {
            return <span className="item-date">{d.date}</span>
        },
        width: "160px",
    },
];

export default function HistoryPage() {
    const [datas, setData] = useState([]);
    const [dataBackup, setDataBackup] = useState([]);
    const [LoaderWrapper, dataLoader] = useLoader()
    const [openReturnsDialog, setOpenReturnsDialog] = useState(false)
    const [returnProduct, setReturnProduct] = useState(false)
    const [rates, setRates] = useState({});

    let user = useSelector(state => state.user);

    useEffect(async () => {
        if (user) {
            const fetchData = async () => {
                try {
                    user.orderHistoryRef().on("value", async (snapshot) => {
                        const fetched = snapshot.val() || [];
                        fetched.reverse();
                        let dummy = []
                        if (!fetched) return
                        let arr = [];
                        for (let orderNumber of fetched) {
                            let order = await getOrderByNumber(orderNumber)
                            if (order) {
                                if (!arr.includes(order.order_number)) {
                                    order.products[0]["additional_payment"] = order.additional_payment;
                                    dummy = dummy.concat(order.products)
                                    arr.push(order.order_number);
                                    setData(dummy);
                                }
                            }
                        }
                        setDataBackup(dummy);
                    });
                } catch(e) {
                    window.location = "/login";
                } 
            }
            dataLoader(fetchData);
        };
    }, [user]);

    useEffect(async () => {
        setRates(await Currency.GetRates())

        $(window).on("click", function(e) {
            let target = $(e.target);
            if (!target.closest(".image-modal .container__").length && $('.image-modal .container__').is(":visible")) {
                document.querySelector('.image-modal').style.display = 'none';
                document.querySelector('.modal-img').removeAttribute("src");
            }
        });
        
        $(".qc-download").on("click", function(e) {
            let images = $(e.currentTarget).parent().find("> img").toArray();
            images.forEach(image => {
                forceDownload(image.src, "image.png");
            })
        });
    }, []);

    function handleDialogOpen(product) {
        setOpenReturnsDialog(true)
        setReturnProduct(product)
    }

    async function handleReturns({ reason }) {
        if (user) {
            sendReturnRequest({
                reason,
                userInfo: user,
                product: returnProduct
            }).then(() => {
                toast.success("Return request has been received");
                setTimeout(() => { window.location.reload(); }, 2000);
            })
        }
    }

    function openDropdown(el) {
        if ($(el).is(":hidden")) setTimeout(() => document.querySelector(el).style.display = "flex", 1);
        else document.querySelector(el).style.display = "none";

        window.onclick = function() {
            document.querySelector(el).style.display = "none";
        }
    }

    const [currentFilter, setCurrentFilter] = useState("");
    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        let d = [...dataBackup]; //State changes within variable, this is how to prevent it
        for (let i = 0; i < dataBackup.length; i++) {
            let data_ = dataBackup[i];
            if (data_.product_status.toLowerCase() != currentFilter.toLowerCase()) {
                d.splice(d.indexOf(data_), 1);
            }
            setFilterData(d);
        }
    }, [currentFilter])

    function filterDataFunc(filter) {
        setCurrentFilter(filter);
    }

    const columns = generateColumns({ onOpenReturnDialog: handleDialogOpen}, rates);

    return (
        <div className="container_">
        <LoaderWrapper>
            <Container style={ContainerStyle} className="history-container">
                <Heading style={HeadingStyle}>
                    History
                </Heading>

                <div className="dropdowns-container">
                    <button className="dropdown-buttons" onClick={() => openDropdown(".sorting-dropdown")}>Sorting By <img src={DownArrow} /></button>
                        <div className="dropdown sorting-dropdown">
                        <span onClick={() => {filterDataFunc("Pending Payment")}} className="selected">Pending</span>
                        <span onClick={() => {filterDataFunc("Paid")}}>Paid</span>
                        <span onClick={() => {filterDataFunc("Stored in a Warehouse")}}>Stored in a Warehouse</span>
                        <span onClick={() => {filterDataFunc("Shipped Internationally")}}>Shipped Internationally</span>
                        <span onClick={() => {filterDataFunc("Return Pending")}}>Return Pending</span>
                        <span onClick={() => {filterDataFunc("Return Approved")}}>Return Approved</span>
                        <span>Refunded</span>
                    </div>

                    <button className="dropdown-buttons">Sort by <span className="active">{currentFilter}</span></button>
                </div>
                
                <DataTable
                title = "Order History"
                columns={columns}
                data={currentFilter ? filterData : datas}
                noHeader
                //defaultSortField="id"
                defaultSortFieldId="date"
                defaultSortAsc={false}
                pagination
                striped
                customStyles = {
                        {
                            table: {
                                style: {
                                    fontFamily: "Montserrat",
                                }
                            },
                            headCells: {
                                style: {
                                    paddingLeft: '8px',
                                    paddingRight: '16px',
                                    fontWeight: "bold",
                                    color: "#504D4D"
                                },
                                draggingStyle: {
                                    cursor: 'move',
                                },
                            },

                            headRow: {
                                style: {
                                    minHeight: '52px',
                                    borderBottomWidth: '1px',
                                    borderBottomStyle: 'solid',
                                    // backgroundColor: "#F0EFEF",
                                },
                                denseStyle: {
                                    minHeight: '32px',
                                },
                            },
                            rows: {
                                style: {
                                    border: 'solid 0px blue',
                                    backgroundColor: '#F4F4F5'

                                }
                            }
                        }
                    }
                />
                <Refund />
                <ReturnsDialog open = {openReturnsDialog} onClose={() => setOpenReturnsDialog(false)} onSubmit= {handleReturns}/>
            </Container>
        </LoaderWrapper>
        <div className='image-modal'>
            <div className='container__'>
                <button onClick={() => {
                    //Find the active image
                    let imgCount = $(".img-container img").length;
                    let done = false;
                    $(".img-container img").each((index, el) => {
                        if ($(el).css("display") == "block" && !done) {
                            $(el).css("display", "none");
                            if (index > 0) {
                                $(".img-container img:nth-child("+(index)+")").css("display", "block");
                            } else {
                                $(".img-container img:nth-child("+(imgCount)+")").css("display", "block");
                            }
                            done = true;
                        }
                    });
                }} ><FaChevronLeft /></button>
                <div className="img-container"></div>
                {/*<img class="modal-img" />*/}
                <button onClick={() => {
                    //Find the active image
                    let imgCount = $(".img-container img").length;
                    let done = false;
                    $(".img-container img").each((index, el) => {
                        if ($(el).css("display") == "block" && !done) {
                            $(el).css("display", "none");
                            if (index < imgCount - 1) {
                                $(".img-container img:nth-child("+(index + 2)+")").css("display", "block");
                            } else {
                                $(".img-container img:nth-child(1)").css("display", "block");
                            }
                            done = true;
                        }
                    });
                }} ><FaChevronRight /></button>
            </div>
        </div>
        
        </div>
    )
}
