import { signInWithPopup } from "@firebase/auth";
import TextField from '@mui/material/TextField';
import Spinner from "components/Spinner";
import CheckBox from 'components/checkbox';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import firebase from "firebase/compat/app";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from "styled-components";
import { auth, db } from "../firebase.js";
import Cookie from "helpers/cookie.js";

const cookie = new Cookie();

const rightPanels = [
  {
    icon: <img src="/image/cheap-price.svg" alt="cheap-price" />,
    title: "Cheap Prices",
    description:
      "With our trusted purchasing team we can purchase items from Taobao, Weidian, and Yupoo for cost effective prices",
  },
  {
    icon: <img src="/image/amazing-support.svg" alt="cheap-price" />,
    title: "Amazing Support",
    description:
      "We offer the best customer support, no longer will you be ignored or have long reply times, KgHype offers support through email and our discord server",
  },
  {
    icon: <img src="/image/clear-quality.svg" alt="cheap-price" />,
    title: "Clear Quality inspection",
    description:
      "We offer clear quality check pictures so you know exactly what products look like before shipping.",
  },
  {
    icon: <img src="/image/afford.svg" alt="cheap-price" />,
    title: "Affordable Shipping",
    description:
      "We offer amazing shipping prices to help you ship consolidate and ship parcels with ease",
  },
];
const SignPageLayout = ({ children, rightIcon }) => {

	const dispatch = useDispatch();

	function makeID(length) {
		let result           = '';
		let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let charactersLength = characters.length;
		for ( let i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	function getNameSurname(raw) {
		let a = raw;
		let b = "";

		let quoteLetter = false;
		a.split("").forEach(letter => {
			if (letter == '“' && !quoteLetter) {
				quoteLetter = true;
				b += " ";
			} else if (letter == '”' && quoteLetter) quoteLetter = false;
			else if (!quoteLetter && letter != " ") {
				b += letter;
			}
		});
		return b.split(" ");
	}

	const handleSignIn = () => {
		const provider = new firebase.auth.GoogleAuthProvider();
		provider.setCustomParameters({ prompt: "select_account" });
		provider.addScope('profile');
		provider.addScope('email');

		signInWithPopup(auth, provider).then(result => {
			setLoggingIn(true);
			let user = result.user;
			const usersRef = db.ref('users');

			usersRef.once("value", async snapshot => {
				let user_vals = snapshot.val();
				let vals = Object.values(user_vals);
				for (let i = 0; i < vals.length; i++) {
					let userDatas = vals[i];
					if (userDatas.mail == user.email) {
						//User found, signin
						let key = Object.keys(user_vals)[i];
						await db.ref("secrets/"+key).once("value", async d => {
							d = d.val();
							if (!d) {
								//First login
								let obj = {};
								let secret = makeID(8);
								obj[key] = secret;
								cookie.setCookie("s", btoa(secret), 90);
								await db.ref("secrets").update(obj);
							} else cookie.setCookie("s", btoa(d), 90);
							window.location = '/profile';
						});
					} else if (i == vals.length - 1) {
						//User not found, signup
						let nameSurname = getNameSurname(user.displayName);
						if (nameSurname.length === 1) nameSurname = [""];

						usersRef.push()
						.set({
							_profilePicture: user.photoURL,
							mail: user.email,
							fName: nameSurname[0],
							lName: nameSurname[nameSurname.length - 1],
							phoneNum: "",
							country: "",
						})
						.then(async() => {
							window.location.href = "/profile";
						})
					}
				}
			});
		}).catch(() => setLoggingIn(false));
  	};

	const [loggingIn, setLoggingIn] = useState(false);
	const [registering, setRegistering] = useState(false);
	
	function discordSignup() {
		setDiscordFound(false);
		setDiscordLoading(false);
		let password = makeID(12);
		const usersRef = db.ref('users');

		if (document.getElementById("fName").value != "" &&
			document.getElementById("lName").value &&
			document.getElementById("phoneNum").value &&
			document.getElementById("agreement").checked &&
			!loggingIn
			) {
				setRegistering(true);
				setLoggingIn(true);
				usersRef.push({
					_profilePicture: `https://cdn.discordapp.com/avatars/${discordID}/${dcAvatar}.jpg`,
					mail: dcEmail,
					password: password,
					fName: document.getElementById("fName").value,
					lName: document.getElementById("lName").value,
					phoneNum: document.getElementById("phoneNum").value,
					country: dcLocale,
					discord_id: discordID
				}).then(async () => {
					toast.success("Account created");
					await createUserWithEmailAndPassword(auth, dcEmail, password)
					window.location = '/profile';
				});
		} else {
			toast.error("Please fill all the areas");
		}
	}

	const [isDiscordLogin, setDiscordLogin] = useState(false);
	const [discordFound, setDiscordFound] = useState(false);
	const [discordLoading, setDiscordLoading] = useState(false);
	
	const [discordID, setDiscordID] = useState("");
	const [dcEmail, setDcEmail] = useState("");
	const [dcLocale, setDcLocale] = useState("");
	const [dcAvatar, setDcAvatar] = useState("");

	useEffect(() => {
		const fragment = new URLSearchParams(window.location.hash.slice(1));
		const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];

		if (accessToken && !loggingIn) {
			fetch('https://discord.com/api/users/@me', {
				headers: {
					authorization: `${tokenType} ${accessToken}`,
				},
			})
			.then(res => {
				return res.json();
			})
			.then(res => {
				setDiscordLogin(false);
				setDiscordLoading(true);

				//Check if Email exists in database
				let email = res.email;
				let id_ = res.id;

				setDcEmail(email);
				setDiscordID(id_);
				setDcLocale(res.locale);
				setDcAvatar(res.avatar);
				const usersRef = db.ref('users');
				let dcFound = false;

				usersRef.once("value", async snapshot => {
					let user_vals = snapshot.val();
					for (let i = 0; i < Object.keys(user_vals).length; i++) {
						if (Object.values(user_vals)[i].mail == email && Object.values(user_vals)[i].discord_id == id_ && !registering) {
							dcFound = true;
							setLoggingIn(true);
							toast.success("You've successfully logged in. Redirecting...");
							setDiscordFound(true);
							let password = Object.values(user_vals)[i].password;
							
							await signInWithEmailAndPassword(
								auth,
								email,
								password
							); //dispatch(setLogin(user));

							let key = Object.keys(user_vals)[i];
							await db.ref("secrets/"+key).once("value", async d => {
								d = d.val();
								if (!d) {
									//First login
									let obj = {};
									let secret = makeID(8);
									obj[key] = secret;
									cookie.setCookie("s", btoa(secret), 90);
									await db.ref("secrets").update(obj);
								} else cookie.setCookie("s", btoa(d), 90);
								window.location = '/profile';
							});
							break;
						}
						if (i == Object.keys(user_vals).length - 1 && !dcFound) { setDiscordLogin(true); setDiscordLoading(false) }
					}
				});
			})
			.catch(err => console.log(err));
		}
	}, []);

  	return (
		<ContentWrapper>
		<FormWrapper>
			{ (!isDiscordLogin && !loggingIn && !discordLoading) &&
				<div>
					{children}
					<div className="hr-line-separator">
					<h2>
						<span>or</span>
					</h2>
					</div>
					<div className="oauth-login-container" style={{display: "flex", justifyContent: "space-between"}}>
						<button className="google-button" onClick={handleSignIn}>
							<div className="google-icon-wrapper">
								<img
								className="google-icon"
								src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
								alt="google"
								/>
							</div>
							<p className="btn-text">
								<b>Sign in with google</b>
							</p>
						</button>
						<a href="https://discord.com/api/oauth2/authorize?client_id=1083900144656855141&redirect_uri=https%3A%2F%2Fkghype.com%2Flogin&response_type=token&scope=email%20identify" 
							className="google-button discord-button"
						>
							<div className="google-icon-wrapper">
								<img
								className="google-icon"
								src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/62595384e89d1d54d704ece7_3437c10597c1526c3dbd98c737c2bcae.svg"
								alt="google"
								/>
							</div>
							<p className="btn-text">
								<b>Sign in with Discord</b>
							</p>
						</a>
					</div>
				</div>
			}
			{
				(isDiscordLogin && !discordFound && !loggingIn) &&
					<div className="discord-container">
						<h1>Register with Discord</h1>
						<TextField
							label="First Name"
							name="firstname"
							id="fName"
							required
						/>
						<TextField
							label="Last Name"
							name="lastname"
							id="lName"
							required
						/>
						<TextField
							label="Phone"
							name="phoneNum"
							id="phoneNum"
							placeholder="+0 000 000 0000"
							required
						/>
						<CheckBox
							name="agreement"
							label="By register you agree on our Terms of Service and Privacy Policy"
							id="agreement"
							required
						/>
						<button className="submit-button" onClick={discordSignup}>
							REGISTER
						</button>
					</div>
			}
			{
				(loggingIn || discordLoading) &&
					<div style={{display: "flex", justifyContent:"center"}}>
						<Spinner bg="dark" />
					</div>
			}
		</FormWrapper>
		<Wrapper>
			<div className="circle-1"></div>
			<div className="circle-2"></div>
			<div className="circle-3"></div>
			<div className="circle-4"></div>
			{rightIcon ? (
			<div className="right-icon">
				<span>NEW MEMBER COUPON</span>
				<img src={rightIcon} alt="icon" />
			</div>
			) : (
			<div className="service-list">
				{rightPanels.map((item, index) => (
				<div className="service-item" key={`service-item-${index}`}>
					<div>{item.icon}</div>
					<div>
					<span>{item.title}</span>
					<p>{item.description}</p>
					</div>
				</div>
				))}
			</div>
			)}
		</Wrapper>
		</ContentWrapper>
  	);
};

export default SignPageLayout;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;

  height: 900px;
  overflow: hidden;
  background: linear-gradient(180deg, #072a48 0%, #093963 100%);
  padding: 50px;

  * {
    box-sizing: border-box;
  }

  .hr-line-separator {
    h2 {
      border-bottom: 1px solid #e1e3eb;
      line-height: 0.1em;
      text-align: center;
    }
    span {
      background: #fff;
      padding: 0 19px;
      font-size: 16px;
      font-weight: 400;
      color: #546285;
    }
  }

  .discord-container {
	display: flex;
    flex-direction: column;
	justify-content: center;

	h1 {
		text-align: center;
		font-family:"Inter", sans-serif;
		margin-bottom:50px;
		font-weight:700;
		text-transform:uppercase;
		font-size:40px;
	}

	> div {
		margin-bottom:10px;
	}

	button {
		padding:10px;
		background-color: #5865F2;
		color:white;
		font-size:14px;
		text-align:center;
		border-radius:4px;
	}
  }

  .oauth-login-container {
	margin-top:10px;
	@media screen and (max-width: 620px) {
		flex-direction: column;
		margin-top:30px;

		button, a {
			margin-top:5px;
		}
	}
  }

  .google-button {
    margin: 0px auto 0 auto;
    width: 184px;
    height: 42px;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #4285f4;
    position: relative;

    font-size: 13px;
    font-weight: 600;
    color: #072a48;

    border: none;
    outline: none;
    cursor: pointer;

	text-decoration: none;
    display: flex;
    align-items: center;

    .google-icon-wrapper {
      position: absolute;
      left: 1px;
      top: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: white;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .google-icon {
      width: 18px;
      height: 18px;
    }

    .btn-text {
      margin:0;
      margin-left: 50px;
      color: white;
    }

    &:hover {
      box-shadow: 0 0 6px #4285f4;
    }
    &:active {
      background: #1669f2;
    }
  }

  .discord-button {
	background-color: #5865F2;

	.btn-text {
		color:white;
	}
  }

  h1 {
    color: black;
    margin: 0;
    font-size: 32px;
  }

  h5 {
    color: #4b5563;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    line-height: 28px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .submit-button {
      padding: 25px;
      border-radius: 6px;
      background-color: #072a48;

      color: white;
      text-align: center;
      font-size: 16px;
      font-weight: bold;

      border: none;
      outline: none;
      cursor: pointer;

      &:hover {
        opacity: 0.96;
      }
    }

    .supporter {
      text-align: center;
      color: #4b5563;
      font-size: 14px;

      a {
        color: #4b5563;
        text-decoration: none;
        font-weight: bold;
        padding-left: 4px;
      }
    }
  }
`;

const FormWrapper = styled.div`
  width: 50%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 90px 60px;
  background: white;
  border-radius: 15px;

  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .space-1 {
    height: 35px;
  }
  .space-2 {
    height: 25px;
  }

  @media screen and (max-width: 1390px) {
    padding: 60px 40px;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  flex: 1;

  padding: 30px 80px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    display: none;
  }

  .right-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: auto auto;

    width: 100%;
    // height: 350px;

    font-size: 64px;
    text-align: center;
    font-weight: 800;
    color: white;

    background: linear-gradient(
      180deg,
      rgba(7, 42, 72, 0.4) 0%,
      rgba(79, 70, 229, 0) 100%
    );
    border-radius: 16px;

    img {
      margin-top: 50px;
      width: 100%;
    }
  }

  .service-list {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  .service-item {
    display: flex;
    align-items: center;
    gap: 30px;

    color: white;
    span {
      font-weight: 700;
      font-size: 36px;
    }

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 14px;
      margin: 0;
    }
  }

  .label {
    position: absolute;
    bottom: 150px;
    left: 100px;

    color: white;

    p {
      margin: 0;
      opacity: 0.6;
    }

    h2 {
      font-size: 28px;
      font-weight: 400;
      margin: 10px 0 0 0;
    }
  }

  .circle-1 {
    position: absolute;
    width: 1090px;
    height: 1090px;
    left: -249px;
    top: -73px;
    border-radius: 50%;

    background: linear-gradient(
      140.4deg,
      rgba(255, 255, 255, 0.03) 16.29%,
      rgba(255, 255, 255, 0) 63.17%
    );
  }

  .circle-2 {
    position: absolute;
    width: 935px;
    height: 935px;
    left: -134px;
    top: 66px;
    border-radius: 50%;

    background: linear-gradient(
      140.4deg,
      rgba(255, 255, 255, 0.02) 16.29%,
      rgba(255, 255, 255, 0) 63.17%
    );
  }

  .circle-3 {
    position: absolute;
    width: 935px;
    height: 935px;
    left: 74px;
    top: 250px;
    border-radius: 50%;

    background: linear-gradient(
      163.92deg,
      rgba(255, 255, 255, 0.012) 16.01%,
      rgba(255, 255, 255, 0) 57.64%
    );
  }

  .circle-4 {
    position: absolute;
    width: 700px;
    height: 700px;
    left: 282px;
    top: 434px;
    border-radius: 50%;

    background: linear-gradient(
      140.4deg,
      rgba(255, 255, 255, 0.01) 16.29%,
      rgba(255, 255, 255, 0) 63.17%
    );
  }
`;
