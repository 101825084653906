const getAttributes = async (item) => {
	if (!item?.prop_imgs) return {};
	const { prop_imgs, props_list } = item;

	const attributes = {};
	const translatedAttrs = [];

	const objEntries = Object.entries(props_list);
	for (let i = 0; i < objEntries.length; i++) {
		let val = objEntries[i];
		// val = ['33434:333','size:S']
		//get attribute name
		let attrName = val[1].split(":")[0];

		//Translate attribute value
		let english = /^[A-Za-z0-9]*$/;
		if (!translatedAttrs.includes(attrName) && !english.test(attrName.replaceAll(" ", ""))) {
			await fetch("https://api.kghype.com:5000/api/translate", {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					words: {
						attr: attrName
					}
				}),
			})
			.then(data => {return data.json()})
			.then(data => {
				//console.log(data);
				translatedAttrs.push(data.attr);
				attrName = data.attr;
			});
		}

		if (!attributes[attrName]) attributes[attrName] = [];
		if (prop_imgs?.prop_img?.length) {
			const propId = val[0];
			const findImg = prop_imgs.prop_img.find(
				(img) => img.properties === propId
			);
			if (findImg) {
				val.push(`url:${findImg.url}`);
			}
		}
		let attrVal = val[1].split(":")[1];

		val.push(attrVal); //Attr value
		attributes[attrName].push(val);
	}

	return attributes;
};

export default getAttributes;