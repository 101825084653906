import {
    AFFILIATE_PREFIX,
    AFILLIATE_PREFIX,
    CALCULATOR_PREFIX,
    FORWARDING_PREFIX,
    PUBLIC_PREFIX,
} from 'configs/app-config';
import { auth } from 'firebase.js';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export default function Menu() {

    const [currentUser, setCurrentUser] = useState(0);
    useEffect(() => {
        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) setCurrentUser(1);
        });
    }, []);

    return (
        <Wrapper>
            <NavLink to={PUBLIC_PREFIX} exact activeClassName="is-active">
                <li>homepage</li>
            </NavLink>
            <NavLink
                to={PUBLIC_PREFIX + CALCULATOR_PREFIX}
                activeClassName="is-active"
            >
                <li>Calculator</li>
            </NavLink>
            <NavLink
                to={PUBLIC_PREFIX + FORWARDING_PREFIX}
                activeClassName="is-active"
            >
                <li>forwarding</li>
            </NavLink>

            <NavLink
                to={currentUser ? PUBLIC_PREFIX + AFFILIATE_PREFIX : PUBLIC_PREFIX + AFILLIATE_PREFIX}
                activeClassName="is-active"
            >
                <li>affiliates</li>
            </NavLink>
        </Wrapper>
    )
}

const Wrapper = styled.ul`
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    gap: 30px;
    list-style: none;
    flex-flow: wrap;
    transform: translateX(20px);
    font-family: 'Montserrat';
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-size:16px;
    @media screen and (max-width: 1320px) {
        display: none;
    }
    a {
        text-decoration: none;
        color: #000;
        transition:200ms;
    }
    a:hover:not(.is-active) {
        color:gray;
    }
    .is-active {
        color: #0072f5;
        font-weight: 600;
    }
    .is-active:hover {
        color:#010d81;
    }
`