import { getSeller } from 'actions/product'
import { PRODUCT_DETAIL_PREFIX, PUBLIC_PREFIX } from 'configs/app-config'
import { db } from 'firebase.js'
import { useEffect, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import reactStringReplace from 'react-string-replace'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Currency } from 'utilities'

const FavoriteProductCard = ({ product, setProducts, searchInput, user }) => {
    const { push } = useHistory()
    const [rates, setRates] = useState({})

    useEffect(() => {
        async function getRates() {
            setRates(await Currency.GetRates())
        }
        getRates()
    }, []);
    const deleteHandler = async (e) => {
        e.stopPropagation();
        return new Promise((res, rej) => {
            if (window.confirm('Are you sure, you want to delete this product?')) {
                db.ref("favorite-products/"+user.key).once("value", snap => {
                    let userFavProducts = Object.values(snap.val() || {});
                    const newFavProductsAfterDelete = userFavProducts.filter((p) => p.num_iid.toString() !== product.num_iid.toString())
                    let obj = {};
                    obj[user.key] = newFavProductsAfterDelete.length == 0 ? null : Object.assign({}, newFavProductsAfterDelete);
                    db.ref("favorite-products/").update(obj)
                    .then(() => {
                        setProducts(newFavProductsAfterDelete);
                        return res();
                    })
                })
            }
        })
    }

    const goDetailsPage = () => {
        push({
            pathname: PUBLIC_PREFIX + PRODUCT_DETAIL_PREFIX,
            search: `?store=${getSeller(product.detail_url)}&id=${
                product.num_iid
            }`,
        })
    }

    const title = searchInput
        ? reactStringReplace(
              product.title.slice(0, 60),
              searchInput,
              (match, i) => {
                  return <mark key={i}>{match}</mark>
              }
          )
        : product?.title?.length > 60
        ? product?.title?.slice(0, 60) + ' ...'
        : product?.title

    return (
        <ProductCard onClick={goDetailsPage} className="favoritePage__product">
            <ProductImageWrapper>
                <img src={product?.item_imgs[0].url} alt="product" />
            </ProductImageWrapper>
            <div className="favoritePage__productBody">
                <p className="favoritePage__description">{title}</p>
                <CardInfo className="favoritePage__prodFooter">
                    <span className="favoritePage__prodPrice">
                        {Currency.GetPrice(rates, product?.price)}
                    </span>
                    <span
                        style={{ color: 'red' }}
                        onClick={(e) => {deleteHandler(e).then(() => toast.success("Product has been deleted"))}}
                        className="favoritePage__prodDelete"
                    >
                        <FaTrashAlt />
                    </span>
                </CardInfo>
            </div>
        </ProductCard>
    )
}

const ProductCard = styled.div`
    box-shadow: 0px 41px 27px -27px rgba(116, 155, 169, 0.59);
    max-width: 320px;
    padding: 20px;
    border: 1px solid #d6d5d5;
    cursor: pointer;
`
const ProductImageWrapper = styled.div`
    img {
        width: 100%;
    }
`

const CardInfo = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
`

export default FavoriteProductCard
