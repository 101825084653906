import TextField from "@mui/material/TextField";
import Button from "components/button";
import { Heading } from "components/heading";
import Spinner from 'components/Spinner';
import { IconImage } from "constants/image-constant";
import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import "reactjs-popup/dist/index.css";
import styled from "styled-components";
import { Currency } from 'utilities';
import AvailableCoupon from '../wallet/AvailableCoupon';
//Firebase
import { Container } from '@mui/material';
import axios from 'axios';
import { Row } from 'components/ui-components';
import { db } from 'firebase.js';
import { useLocation } from 'react-router-dom';
import { createOrder } from 'utilities/order';
import { addShippingOrder } from 'utilities/shipping';
import { topUp } from 'utilities/wallet';
import { coinBaseCheckout, paypalCheckout, stripeCheckout } from "./checkout_service";
import Cookie from '../../../helpers/cookie';
import { useSelector } from "react-redux";
import './checkout.scss';

const {
    Bitcoin,
    Paypal,
    VenmoQR,
    CashAppQR,
    CreditCard,
    Wallet,
} = IconImage

const HeadingStyle = {
    textAlign: 'left',
}

const cookie = new Cookie();

function makeID(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default function CheckoutPage() {
    const location = useLocation();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [checkoutData, setCheckoutData] = useState(false);
    const [checkoutAmount, setChecheckoutAmount] = useState(0)
    const [rates, setRates] = useState({})
    const [curCart, setCart] = useState([]);
    const [couponUsed, setCouponUsed] = useState(false);
    const [couponName, setCouponName] = useState(null);
    const [couponAmount, setCouponAmount] = useState(0);
    const [loadingModal, setLoadingToModal] = useState(false);

    let user = useSelector(state => state.user);

    useEffect(async () => {
        window.scrollTo(0, 0);
        cookie.setCookie("sid", makeID(8));
        setRates(await Currency.GetRates());
    }, []);

    function productCheckout(userInfo, desc) {
        return new Promise((resolve, reject) => {
            if (!userInfo) return reject(new Error('User Info Object required'))

            let selectedCartRef = userInfo.selectedCartRef();

            selectedCartRef.once('value', async (cartSnapshot) => {
                let cart = cartSnapshot.val()
                setCart(cart);

                if (!cart) return resolve()

                let orderHistory = userInfo.dbUser.orderhistory;
                let usercart = userInfo.dbUser.cart || [];
                if (orderHistory === undefined) orderHistory = [];
                let newArr = [];

                let ids = [];
                for (let j = 0; j < Object.values(cart).length; j++) {
                    ids.push(Object.values(cart)[j].cid);
                }

                for (let i = 0; i < usercart.length; i++) {
                    if (!ids.includes(usercart[i].cid)) newArr.push(usercart[i]);
                }

                let order = await createOrder([...cart], userInfo.key, desc);
                orderHistory.push(order.order_number)

                await userInfo.ref().update({
                    orderhistory: orderHistory,
                    selectedCart: [],
                    cart: newArr,
                });

                resolve({ amount: order.total_price, name: `${userInfo.dbUser.fName} ${userInfo.dbUser.lName}`, description: "Checkout Products" });
            })
        })
    }

    function shippingCheckout(userInfo, checkoutInfo, desc) {
        return new Promise(async (resolve, reject) => {
            await addShippingOrder({ //ADDING SHIPPING ORDER
                items: checkoutInfo.items,
                shippingInfo: checkoutInfo.shippingInfo,
                user_id: userInfo.key,
                user: {
                    name: `${userInfo.dbUser.fName} ${userInfo.dbUser.lName}`,
                    email: userInfo.dbUser.mail,
                },
                shippingFee: checkoutInfo.shippingFee,
                description: desc,
                couponUsed: couponUsed,
                couponName: couponName
            })
                .then(() => resolve())
                .catch(e => reject());
        })
    }

    async function topUpCheckout(checkoutInfo, desc = "") {
        if (user) {
            setLoadingToModal(true);
            return topUp({ amount: checkoutInfo.amount, desc: desc, userInfo: user }).then(() => {
                toast.success("TOPUP Request has been received");
            })
        }
    }

    function checkout(checkoutInfo, desc = "") {
        return new Promise((resolve, reject) => {
            if (user) {
                (async () => {
                    let checkoutData;
                    try {
                        if (checkoutInfo && checkoutInfo.type === 'SHIPPING') {
                            checkoutData = await shippingCheckout(user, checkoutInfo, desc)
                        } else if (checkoutInfo && checkoutInfo.type === 'TOPUP') {
                            checkoutData = await topUpCheckout(checkoutInfo, desc)
                        } else {
                            checkoutData = await productCheckout(user, desc)
                        }
                        resolve(checkoutData);
                    } catch (error) {
                        reject(error)
                    }
                })()
            } else window.location.href = "/login"
        })
    }

    async function convertCurrency(amount, USD_CNY = false) {
        return axios
            .get('https://open.er-api.com/v6/latest/CNY')
            .then((res) => {
                if (USD_CNY) return amount / res.data.rates["USD"];
                return res.data.rates["USD"] * amount;
            })
    }

    const [handlingFee, setHandlingFee] = useState(0);
    async function getCheckoutAmount(checkoutData) {
        return new Promise(async (resolve, reject) => {
            if (user) {
                const userInfo = user;
                if (!checkoutData) return resolve(0)
                if (!userInfo) return resolve(0)

                if (checkoutData.type === "SHIPPING") {
                    let amount_;
                    if (couponUsed) amount_ = await convertCurrency(checkoutData.shippingFee - couponAmount)
                    else amount_ = await convertCurrency(checkoutData.shippingFee);
                    setChecheckoutAmount(amount_);
                    resolve(amount_);
                } else if (checkoutData.type === "TOPUP") {
                    setChecheckoutAmount(checkoutData.amount)
                    resolve(checkoutData.amount)
                } else {
                    var cartRef = userInfo.selectedCartRef();

                    cartRef.once('value', async (cartSnapshot) => {
                        let cart = cartSnapshot.val();
                        let totalAmount = 0;
                        let handle3Fee = ["zelle", "cashapp", "venmo"].includes(paymentMethod);

                        if (!cart) return resolve(totalAmount)

                        for (let cartItem of cart) {
                            let shippingPrice = parseFloat(
                                cartItem?.post_fee ||
                                0 + cartItem?.express_fee ||
                                0 + cartItem?.ems_fee ||
                                0
                            ) || 0;
                            totalAmount += (shippingPrice + cartItem["price"]) * cartItem["quantity"];
                        }
                        totalAmount = totalAmount + (totalAmount * (handle3Fee ? 3 : 10) / 100); //Handling Fee

                        setHandlingFee(+(await convertCurrency(totalAmount * (handle3Fee ? 3 : 10) / 100)).toFixed(2));
                        totalAmount = await convertCurrency(totalAmount);
                        setChecheckoutAmount(+totalAmount.toFixed(2))
                        resolve(totalAmount)
                    })
                }
            }
        })
    }

    const [canLoad, setCanLoad] = useState(false);

    useEffect(async () => {
        if (location.state) {
            setCheckoutData(location.state);
            getCheckoutAmount(location.state).then((a) => {
                setCanLoad(true);
            })
        } else {
            if (user && user?.selectedCartRef && (checkoutData.type !== "TOPUP" && checkoutData.type !== "SHIPPING")) {
                let userInfo = user;
                let cartRef = userInfo.selectedCartRef();
                cartRef.once('value', async (cartSnapshot) => {
                    let handle3Fee = ["zelle", "cashapp", "venmo"].includes(paymentMethod);

                    let cart = cartSnapshot.val()
                    if (!cart) return;
                    else {
                        setCanLoad(true);
                        let totalPrice = 0;
                        let cartLen = cart.length;
                        for (let i = 0; i < cartLen; i++) {
                            let curItem = cart[i];
                            let shippingPrice = parseFloat(
                                curItem?.post_fee ||
                                0 + curItem?.express_fee ||
                                0 + curItem?.ems_fee ||
                                0
                            ) || 0;
                            totalPrice += (curItem["price"] + shippingPrice) * curItem["quantity"];
                        }
                        totalPrice = totalPrice + (totalPrice * (handle3Fee ? 3 : 10) / 100); //Handling Fee

                        setHandlingFee(+(await convertCurrency(totalPrice * (handle3Fee ? 3 : 10) / 100)).toFixed(2));
                        totalPrice = await convertCurrency(totalPrice);
                        setChecheckoutAmount(+totalPrice.toFixed(2));
                        setCheckoutData({...checkoutData, amount: +totalPrice});
                    }
                })
            }
        }
    }, [user, paymentMethod, couponUsed])

    function PaymentListItem({ logoImage, title, id, children }) {
        let radioBtn = useRef()

        useEffect(() => {
            radioBtn.current.checked = id === paymentMethod
        }, [])

        return (
            <Row
                onClick={() => {
                    if (id) {
                        setPaymentMethod(id)
                    }
                }}
                mainAxisAlignment="space-between"
                style={{
                    fontFamily: 'Montserrat',
                    color: '#504D4D',
                    padding: '25px 15px',
                    border: 'solid 1px #E2E2E3',
                    fontSize: '12px',
                    textAlign: 'right',
                    margin: '0px',
                    cursor: 'pointer',
                }}
            >
                <Row
                    style={{ flex: '40%' }}
                    crossAxisAlignment="center"
                    mainAxisAlignment={'start'}
                >
                    <div style={{ padding: '1% 0px', display: "flex", alignItems: "center" }}>
                        <input
                            type="radio"
                            name="paylistOption"
                            ref={radioBtn}
                        />
                    </div>
                    <div
                        style={{
                            width: '40px',
                            height: '40px',
                            margin: '0px 10px',
                        }}
                    >
                        <LogoWrapper
                            src={logoImage}
                            alt="transfer"
                        />
                    </div>
                    <div style={{ padding: '3% 0px' }}> {title} </div>
                </Row>

                <Row
                    style={{ flex: '60%' }}
                    mainAxisAlignment={'end'}
                    crossAxisAlignment="center"
                >
                    {children}
                </Row>
            </Row>
        )
    }

    const [loading, setLoading] = useState(false);

    function coupon() {
        if (!couponUsed) {
            var ref = db.ref(`coupon_list/`);
            ref.once("value").then(async (snapshot) => {
                const fetched = snapshot.val();
                var coupn = document.getElementById("couponthing").value;
                for (let i = 0; i < Object.keys(fetched).length; i++) {
                    let a = Object.keys(fetched)[i];
                    if (coupn === a && await convertCurrency(checkoutAmount, true) >= (fetched[coupn]["minimum_price"] || 0)) {
                        let couponAmount_ = await convertCurrency(parseFloat(fetched[coupn]["amount"]));
                        setCouponAmount(couponAmount_);
                        setChecheckoutAmount(checkoutAmount - couponAmount_);
                        setCouponName(coupn);
                        toast.success("Coupon has been redeemed");
                        setCouponUsed(true);
                        setCheckoutData({
                            ...checkoutData,
                            amount: checkoutAmount - couponAmount_,
                            couponUsed: true,
                            couponName: coupn
                        });
                        break;
                    } else if (coupn === a && await convertCurrency(checkoutAmount, true) < fetched[coupn]["minimum_price"]) {
                        toast.error("Minimum price is not enough to use this coupon code");
                        break;
                    } else if (i == Object.keys(fetched).length - 1) {
                        toast.error("Wrong coupon code");
                    }
                }
            });
        } else toast.error("A coupon has already used");
    }

    function payWithBalance(data) {
        if (user.dbUser.balance) {
            if (checkoutAmount <= user?.dbUser?.balance) {
                checkout(data, "Paid with Balance").then(async () => {
                    toast.loading("Please wait...");
                    await user.ref().update({
                        balance: user?.dbUser?.balance - checkoutAmount
                    })
                        .then(() => {
                            let type_;
                            switch (checkoutData?.type?.toLowerCase()) {
                                case "shipping":
                                    type_ = "shipping";
                                    break;
                                default:
                                    type_ = "product";
                                    break;
                            }
                            db.ref("stats/order/" + type_).once("value", async snapshot => {
                                let vals = snapshot.val();
                                let curDate = new Date().toLocaleString('tr-TR').split(" ")[0].split(".");
                                curDate[1] = new Date().toLocaleString('en-EN', { month: 'long' });
                                curDate = curDate.join(" ");

                                if (!vals) vals = {};
                                if (!vals[curDate]) vals[curDate] = 1;
                                else vals[curDate] += 1;

                                await db.ref("stats/order/" + type_).update(vals);
                                window.location.href = "payment-success/?n=1";
                            });
                        })
                })
            } else {
                toast.dismiss();
                toast.error("Insufficient balance.")
            }
        }
    }

    function showPaymentPopup(method) {
        $(".payment-modal").show();
        $(".payment-modal .background > div").hide();
        $("." + method).css("display", "flex");
        $(".payment-modal .close").on("click", function () {
            setLoading(false);
            $(".payment-modal .background > div").hide();
            $(".payment-modal").hide();
        });
    }

    function confirmPayment(m) {
        let desc;
        switch (m) {
            case "zelle":
                if ($(".zelle input").val()) desc = "Zelle Name: " + $(".zelle input").val();
                break;
            case "cashapp":
                if ($(".cashapp input").val()) desc = "Cashapp $ashtag: " + $(".cashapp input").val();
                break;
            case "venmo":
                if ($(".venmo input").val()) desc = "Venmo Name: " + $(".venmo input").val();
                break;
        }

        if (desc) {
            toast.loading("Please wait...");
            checkout(checkoutData, desc).then(() => {
                $(".payment-modal").hide();
                let type_;
                switch (checkoutData?.type?.toLowerCase()) {
                    case "topup":
                        type_ = "topup";
                        break;
                    case "shipping":
                        type_ = "shipping";
                        break;
                    default:
                        type_ = "product";
                        break;
                }
                db.ref("stats/order/" + type_).once("value", async snapshot => {
                    let vals = snapshot.val();
                    let curDate = new Date().toLocaleString('tr-TR').split(" ")[0].split(".");
                    curDate[1] = new Date().toLocaleString('en-EN', { month: 'long' });
                    curDate = curDate.join(" ");

                    if (type_ != "topup") {
                        if (!vals) vals = {};
                        if (!vals[curDate]) vals[curDate] = 1;
                        else vals[curDate] += 1;
                    } else {
                        if (!vals) vals = { "count": {}, "balance": {} };
                        if (!vals?.["count"]?.[curDate]) {
                            vals["count"][curDate] = 1;
                            vals["balance"][curDate] = parseFloat(checkoutData?.amount);
                        }
                        else {
                            vals["count"][curDate] += 1;
                            vals["balance"][curDate] += parseFloat(checkoutData?.amount);
                        }
                    }

                    await db.ref("stats/order/" + type_).update(vals);

                    db.ref("transaction").once("value", async snap => {
                        let transactions = snap.val();
                        transactions.push({
                            method: m,
                            type: type_,
                            amount: checkoutData?.amount,
                            date: new Date().getTime()
                        });
                        await db.ref("transaction").update(transactions);
                        window.location.href = "payment-success/?n=1";
                    });
                });
            })
            .catch(e => toast.dismiss());
        } else {
            toast.dismiss();
            toast.error("Please enter your payment information.")
        }
    }

    function paymentButton() {
        return loading ? <Spinner /> : <Button text="Confirm Payment" onClick={() => {
            if (paymentMethod) {
                setLoading(true);
                if (paymentMethod != "cashapp" && paymentMethod != "venmo" && paymentMethod != "zelle" && paymentMethod != "balance") {
                    let type__ = "product";
                    switch(checkoutData?.type?.toLowerCase()) {
                        case "topup":
                            type__ = "topup";
                            break;
                        case "shipping":
                            type__ = "shipping";
                            break;
                    }
                    let paymentInfo = {
                        amount: checkoutAmount,
                        productName: curCart.length === 1 ? curCart[0].title : curCart.length + " Products",
                        name: `${user.dbUser.fName} ${user.dbUser.lName}`,
                        description: "Paid with " + paymentMethod,
                        type: type__,
                        item_data: checkoutData,
                        user_id: user.key,
                        sid: cookie.getCookie("sid")
                    }
                    
                    if (couponUsed) {
                        paymentInfo["couponUsed"] = couponUsed;
                        paymentInfo["couponName"] = couponName;
                    }
                    switch (paymentMethod) {
                        case 'bitcoin':
                            coinBaseCheckout(paymentInfo).then((redirectUrl) => {
                                window.location.href = redirectUrl;
                            })
                            break;
                        case 'paypal':
                            paypalCheckout(paymentInfo).then((redirectUrl) => {
                                window.location.href = redirectUrl;
                            })
                            break;
                        case 'stripe':
                            stripeCheckout(paymentInfo).then((redirectUrl) => {
                                window.location.href = redirectUrl;
                            })
                            break;
                    }
                } else if (paymentMethod == "balance") {
                    payWithBalance(checkoutData)
                } else {
                    showPaymentPopup(paymentMethod)
                }
            } else toast.error("Please select a payment method")
        }}></Button>
    }

    if (!canLoad) return <Spinner />
    else if (!user) return <Spinner />
    return (
        <Container maxWidth="lg">
            <Heading style={HeadingStyle}>CheckOut Cart</Heading>
            <br /><br />

            {checkoutData?.type != "TOPUP" && <PaymentListItem id='balance' title='Pay with Balance' logoImage={Wallet}>
                <p style={{ overflowWrap: 'break-word' }}>
                    Pay with your Top-up Balance
                </p>
            </PaymentListItem>}

            <PaymentListItem id='stripe' title='Debit / Credit Card' logoImage={CreditCard}>
                <p style={{ overflowWrap: 'break-word' }}>
                    Payment with debit or credit card
                </p>
            </PaymentListItem>

            <PaymentListItem logoImage={Paypal} title='Paypal' id='paypal'>
                <p style={{ overflowWrap: 'break-word' }}>
                    Cover 202 countries or regions | 25 currencies are supported, and
                    currency exchang...
                </p>
            </PaymentListItem>

            <PaymentListItem logoImage={Bitcoin} title='Bitcoin' id='bitcoin'>
                <p >
                    Pay with Wallet. If the amount is less than the order amount due to
                    exchange rate fluctuations, the payment will fall and it will be
                    topped up to your KGHYPE account balance. No withdrawal if paying
                    with this. For any other questions, feel free to contact Customer
                    Service.{" "}
                </p>
            </PaymentListItem>

            <PaymentListItem id='zelle' logoImage={"zelle-logo-3.png"} title='Zelle'>
                <p style={{ overflowWrap: 'break-word' }}>
                    Plase send the money to the zelle account (dewhite444@gmail.com)
                </p>
            </PaymentListItem>

            <PaymentListItem id='venmo' logoImage={"https://ludwigshorseshow.com/wp-content/uploads/2021/03/venmo-logo.png"} title='Venmo'>
                <p style={{ overflowWrap: 'break-word' }}>
                    Please send the money to @KgHypeLast, 4 digits of phone number is 0302
                </p>
            </PaymentListItem>

            <PaymentListItem id='cashapp' title='CashApp' logoImage={"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png"} >
                <p style={{ overflowWrap: 'break-word' }}>
                    Cover 202 countries or regions | 25 currencies are supported, and
                    currency exchang...
                </p>
            </PaymentListItem>

            <Row mainAxisAlignment={'space-between'} style={{ marginTop: '20px' }}>
                <Row>
                    {checkoutData?.type == "SHIPPING" &&
                        <div style={{ display: "flex" }}>
                            <TextField id="couponthing" placeholder="Coupon" style={{ marginRight: '5px' }} />
                            <Button text="Add" onClick={() => {
                                if (checkoutData?.type == "SHIPPING") { coupon() }
                            }} />
                        </div>
                    }
                </Row>

                <Row>
                    <div style={{ padding: '15px', fontSize: "16px" }}>
                        {(checkoutData?.type == "TOPUP" && user) ? <></> : <span>Balance : <span style={{ 'color': 'green' }}>{Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", user?.dbUser?.balance, false))}</span></span>}
                    </div>

                    <div style={{ padding: '15px', fontSize: "16px" }}>
                        Total : <span style={{ 'color': 'green' }}>{Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", checkoutAmount, false))}</span>
                        {(checkoutData?.type != "TOPUP" && checkoutData?.type != "SHIPPING") &&
                            <div style={{ fontSize: "11px", color: "gray", width: "120px" }}>
                                {handlingFee}$ handling fee included
                            </div>
                        }
                    </div>

                    {paymentButton()}

                </Row>
            </Row>

            <br /><br /><br />

            {checkoutData?.type == "SHIPPING" &&
                <div>
                    <h3>Available Coupons</h3>
                    <div className="checkout-coupon-container">
                        <AvailableCoupon currentUser={user} checkout={true} />
                    </div>
                </div>
            }

            <br /><br /><br />

            <div className="payment-modal">
                {
                    !loadingModal &&
                    <div className="background">
                        <div className="zelle">
                            <span className="close">X</span>
                            <img src="zelle-logo-3.png" style={{ width: "30%", marginBottom: "30px" }} />
                            <p>
                                Pay {Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", checkoutAmount, false))} to <span>dewhite444@gmail.com</span>
                                <br />Name on Account is <span>Cameron</span>
                            </p>
                            <input placeholder="Your name on Zelle" required />
                            <button onClick={() => { confirmPayment("zelle") }}>Confirm</button>
                        </div>

                        <div className="cashapp">
                            <span className="close">X</span>
                            <p>
                                Pay {Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", checkoutAmount, false))} to <span>$hypekg</span>
                            </p>
                            <p className="divider">Or...</p>
                            <img src={CashAppQR} />
                            <input placeholder="Your $ashTag" required />
                            <button onClick={() => { confirmPayment("cashapp") }}>Confirm</button>
                        </div>

                        <div className="venmo">
                            <span className="close">X</span>
                            <p>
                                Pay {Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", checkoutAmount, false))} to <span>@KgHype</span>
                                Last 4 digits of phone number is <span>0302</span>
                            </p>
                            <p className="divider">Or...</p>
                            <img src={VenmoQR} />
                            <input placeholder="Your name on Venmo" required />
                            <button onClick={() => { confirmPayment("venmo") }}>Confirm</button>
                        </div>
                    </div>
                }
                {
                    loadingModal &&
                    <div className="background">
                        <div style={{ display: "flex" }}>
                            <Spinner />
                        </div>
                    </div>
                }
            </div>
        </Container>
    );
}

const LogoWrapper = styled.img`
    max-width: 100%;
    height: 100%;
    max-width: 278px;
    max-height: 278px;
`