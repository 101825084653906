import Button from "components/button";
import { Heading } from "components/heading";
import ImageWrapper from "components/image-wrapper";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Currency } from "utilities";
import { shippingCalculator } from "utilities/shipping";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const ResultHeadingStyle = {
	color: "#72727E",
	fontSize: "16px",
};

const ResultBox = styled.div`
display: flex;
width: 100%;
max-width: 1000px;
background-color: white;
padding: 32px 56px;
border: 1px solid rgb(218, 217, 217);
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
margin-top: 32px;

div {
  flex-grow: 1;
  margin-right: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
`;


function ShippingMethodItem({ shippingInfo, category, totalWeight = 0, onClick, isSelected = false, showSelectButton = true, showAll = false, country }) {
	const { height, width } = useWindowDimensions();
	const [shippingFee, setShippingFee] = useState(0)
	const [rates, setRates] = useState({})
	const [countryCheck, setCountryCheck] = useState(true);
	const [reRender, setRender] = useState(true);
	const [countryDatas, setCountryDatas] = useState(null);

	useEffect(() => {
		setRender(!reRender);
		setCountryCheck(true);
		if (shippingInfo.country_prices) {
			for (let i = 0; i < shippingInfo.country_prices.length; i++) {
				//if (shippingInfo?.country_prices[i]?.country.includes(country)) { setCountryCheck(false); break; }
				let found = false;
				for (let j = 0; j < shippingInfo?.country_prices[i]?.country?.length; j++) {
					if (shippingInfo?.country_prices[i]?.country[j] == country) {
						setCountryCheck(false);
						setCountryDatas(shippingInfo?.country_prices[i]);
						found = true;
						break;
					}
				}

				if (found) break;
			}
		}
	}, [country]);

	useEffect(async () => {
		let rates_ = await Currency.GetRates()
		setRates(rates_);

		let continuedFee = 0, initialFee = 0;

		/*if (shippingInfo.country_prices) {
			if (shippingInfo?.country_prices[0]?.feePerGram) continuedFee = shippingInfo?.country_prices[0]?.feePerGram;
			else continuedFee = 50;
			if (shippingInfo?.country_prices[0]?.initialWeightFee) initialFee = shippingInfo?.country_prices[0]?.initialWeightFee;
			else initialFee = 500;
		}*/

		if (countryDatas) {
			continuedFee = countryDatas.feePerGram;
			initialFee = countryDatas.initialWeightFee;

			let shippingFee = shippingCalculator({
				weight: totalWeight,
				feePerGram: parseFloat(continuedFee),
				initialWeight: parseFloat(shippingInfo.initialWeight) || 500, //??????
				initialWeightFee: parseFloat(initialFee)
			});

			setShippingFee(shippingFee)
		}
		

		/**
		 * shippingCalculator({
			  weight: cartItems.totalWeight, 
			  feePerGram: parseFloat(continuedFee), 
			  initialWeight: parseFloat(shippingInfo.initialWeight ? shippingInfo.initialWeight : 500),
			  initialWeightFee: parseFloat(initialFee)
			});
		 */

	}, [totalWeight, shippingInfo, country, countryDatas]);

	if ((!shippingInfo?.category?.includes(category) && !showAll) || countryCheck) return <></>;
	return <ResultBox style={{ flexDirection: width <= 800 ? "column" : "row", border: isSelected ? "2px solid green" : null }}>
		<div>
			<Heading style={ResultHeadingStyle}>{shippingInfo.name}</Heading>
			<ImageWrapper
				style={{ width: "72px", marginTop: "12px", height: "75px", borderRadius: '50%' }}
				src={shippingInfo.logo}
				alt={shippingInfo.name}
			/>
		</div>
		<div>
			<Heading style={ResultHeadingStyle}>Shipping Fee</Heading>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<Heading style={{ color: "#072A48" }}>{Currency.GetPrice(rates, shippingFee)}&nbsp;</Heading>
				<Heading
					style={{
						color: "#72727E",
						textDecoration: "line-through",
						margin: 0,
					}}
				>
					{Currency.GetPrice(rates, +shippingFee + (+shippingFee / 10))}
				</Heading>
			</div>
			<Button
				style={{
					textTransform: "uppercase",
					fontSize: "16px",
					fontWeight: 700,
					padding: "10px",
					cursor: "auto",
				}}
				text={"10% Off"}
			/>
		</div>
		<div>
			<Heading style={ResultHeadingStyle}>Transit Time</Heading>
			<Heading style={{ color: "#072A48" }}>{shippingInfo.transitTime}</Heading>
		</div>
		<div>
			<Heading style={ResultHeadingStyle}>About Carrier</Heading>
			<p style={{ color: "#072A48" }}>{shippingInfo.description}</p>
			{
				showSelectButton && <Button
					onClick={() => {
						// setSelected(shippingInfo.name)
						if (onClick) {
							onClick(shippingInfo)
						}
					}}
					style={{
						textTransform: "uppercase",
						fontSize: "16px",
						fontWeight: 700,
						padding: "10px",
					}}
					text={isSelected ? "Selected" : "Select"}
				/>}
		</div>
	</ResultBox>
}

export {
	ShippingMethodItem
};
