import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Currency } from 'utilities'

export default function PriceConverter({ itemPrice, oldPrice, rates }) {
    const [ogPrice, setOgPrice] = useState(0);
    const selectedCurrency = localStorage.getItem('currency');

    useEffect(async () => {
        // if (selectedCurrency === "CNY") {
        //     itemPrice = itemPrice + itemPrice * (20 / 100);
        // } else {
        //     setTransformedPrice(itemPrice + itemPrice * (20 / 100));
        // }

        //Instead of raising the price of other currencies, cut the price of CNY currency
        //console.log(oldPrice, itemPrice);
        if (selectedCurrency !== "CNY") setOgPrice(oldPrice);
        else setOgPrice(itemPrice);
    }, [itemPrice, oldPrice, selectedCurrency])

    return (
        <>
            <Wrapper>
                <h1 className="productInfo__price">
                    CNY ¥ {parseFloat(ogPrice).toFixed(1)}{' '}
                    <span style={{ color: '#21c35e' }}>≈</span>{' '}
                    {Currency.GetPrice(rates, itemPrice)}
                </h1>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`