import CouponCard from "components/coupon-card";
import { db } from "firebase.js";
import {
  onValue,
  ref
} from "firebase/database";
import { useEffect, useState } from "react";

const CouponUsed = ({currentUser}) => {
  const [couponUsed, setCouponUsed] = useState([]);
  useEffect(async () => {
    if (currentUser) {
      const used_coupons = currentUser.dbUser.used_coupons || [];
      const usedCouponArr = [];
      onValue(ref(db, "coupon_list"), (snapshots) => {
        snapshots.forEach((snapshot) => {
          if (used_coupons.includes(snapshot.val().code))
            usedCouponArr.push(snapshot.val());
        });
      });
      setCouponUsed(usedCouponArr);
    }
  }, []);
  return (
    <div className="availableCoupon">
      {couponUsed.map((coupon, i) => (
        <CouponCard key={i} coupon={coupon} />
      ))}
    </div>
  );
};

export default CouponUsed;
