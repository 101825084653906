import styled from "styled-components";
import { Link } from "react-router-dom";
import { PUBLIC_PREFIX } from "configs/app-config";

export default function ImageWrapper({
  src,
  style,
  alt,
  id,
  className,
  onClick,
}) {
  return (
    <Wrapper
        id={id}
        src={src}
        style={style}
        alt={`KGHYPE-${alt}`}
        className={className}
        onClick={onClick}
      />
  );
}

const Wrapper = styled.img`
  max-width: 100%;
  height: 100%;
  /* max-width: 278px; */
  /* max-height: 278px; */
`;
