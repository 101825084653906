import { Heading } from 'components/heading'
import ProfileSidebar from 'components/profile-sidebar'
import {
    ADDRESS_PREFIX, AFFILIATE_PREFIX, FAVORITE_PREFIX, FORWARDED_PREFIX, HISTORYCART_PREFIX,
    HISTORY_PREFIX, PARCEL_PREFIX, PUBLIC_PREFIX,
    WALLET_PREFIX, WAREHOUSE_PREFIX
} from 'configs/app-config'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Address from 'views/public/address'
import FavoritePage from 'views/public/favorite'
import HistoryPage from 'views/public/history'
import ShippingHistory from 'views/public/historycart'
import ParcelPage from 'views/public/parcel'
import ProfilePage from 'views/public/profile'
import Wallet from 'views/public/wallet'
import WareHousePage from 'views/public/warehouse'
//import ChatPage from 'views/public/chat/chat'
import AffiliatePage from 'views/public/affiliate'
import ForwardedPage from 'views/public/forwarding/forwarded_list'

const HeadingStyle = {
    textAlign: 'left',
    margin: '0px',
}

export default function ProfileLayout() {
    
    let user = useSelector(state => state.user);

    return (
        <ProfileContainer>
            <SideBar>
                <Heading style={HeadingStyle}>Options</Heading>
                <ProfileSidebar />
            </SideBar>
            <Content>
                <Switch>
                    <Route
                        path="/profile"
                        render={() => (
                            <ProfilePage user={user} />
                        )}
                    />
                    <Route
                        path={PUBLIC_PREFIX + HISTORYCART_PREFIX}
                        render={() => (
                            <ShippingHistory name={`${user?.fName} ${user?.lName}`} email={user?.mail} />
                        )}
                    />
                    <Route
                        path={PUBLIC_PREFIX + WALLET_PREFIX}
                        component={Wallet}
                    />
                    <Route
                        path={PUBLIC_PREFIX + HISTORY_PREFIX}
                        component={HistoryPage}
                    />
                    <Route
                        path={PUBLIC_PREFIX + PARCEL_PREFIX}
                        component={ParcelPage}
                    />
                    <Route
                        path={PUBLIC_PREFIX + FORWARDED_PREFIX}
                        component={ForwardedPage}
                    />
                    <Route
                        path={PUBLIC_PREFIX + FAVORITE_PREFIX}
                        component={FavoritePage}
                    />
                    <Route
                        path={PUBLIC_PREFIX + ADDRESS_PREFIX}
                        component={Address}
                    />
                    <Route
                        path={PUBLIC_PREFIX + WAREHOUSE_PREFIX}
                        component={WareHousePage}
                    />
                    <Route
                        path={PUBLIC_PREFIX + AFFILIATE_PREFIX}
                        component={AffiliatePage}
                    />
                </Switch>
            </Content>
        </ProfileContainer>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width: 800px) {
        margin: 2rem 0;
    }
`

const Content = styled.section`
    padding-left: 20px;
    max-width: 100%;
    flex: 1;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
    }
`

const ProfileContainer = styled.div`
    padding: 50px 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`
