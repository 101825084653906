import { ChakraProvider } from '@chakra-ui/react'
import { Container } from '../../../components/business/Container'
import IntroSection from '../../../components/business/intro/IntroSection'
import IntroTabs from '../../../components/business/intro/IntroTabs'
import theme from '../../../theme'

const Index = () => {
    return (
        <ChakraProvider theme={theme}>
            <Container minH="100vh">
                <IntroSection />
                <IntroTabs />
            </Container>
        </ChakraProvider>
    )
}

export default Index
