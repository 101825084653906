const apiData: { image: string, name: string }[] = [
    {
        image: "/future-1.png",
        name: "Membership",
    },
    {
        image: "/future-2.png",
        name: "Product",
    },
    {
        image: "/future-3.png",
        name: "Order",
    },
    {
        image: "/future-4.png",
        name: "payment",
    },
    {
        image: "/future-5.png",
        name: "LOGISTICS",
    },
    {
        image: "/future-6.png",
        name: "Order status",
    },
    {
        image: "/future-7.png",
        name: "Warehousing",
    },
]

export default apiData