import {
    Box,
    Image,
    Center,
    Flex,
    Heading,
    IconButton,
    Grid,
} from '@chakra-ui/react'
import { IoArrowBack, IoArrowForward } from 'react-icons/io5'
import Carousel from 'nuka-carousel'
import { sbsData } from './SBSData'

function SBSOffers() {
    return (
        <Box
            style={{
                backgroundImage: "url('/plan_bg.png')",
                backgroundSize: 'cover',
            }}
            position="relative"
            w="full"
            my={10}
        >
            <Center>
                <Flex
                    direction="column"
                    alignItems="center"
                    w="full"
                    bgColor="#000000b5"
                >
                    <Heading
                        position="relative"
                        color="white"
                        textAlign="center"
                        fontSize="3xl"
                        mt={['20']}
                        mb="10"
                        zIndex={6}
                    >
                        SBS offers
                    </Heading>

                    <Center style={{ padding: '4rem' }}>
                        <Box maxW={'70rem'} zIndex={2}>
                            <Carousel
                                autoplay={false}
                                cellAlign="center"
                                renderCenterLeftControls={({
                                    previousSlide,
                                }) => (
                                    <IconButton
                                        aria-label="Previous slide"
                                        icon={<IoArrowBack size={30} />}
                                        size="lg"
                                        onClick={previousSlide}
                                        rounded="full"
                                        bgColor="white"
                                        color="gray"
                                        _hover={{
                                            bgColor: 'white',
                                            color: 'gray',
                                        }}
                                        colorScheme="skyblue"
                                        sx={{
                                            transform: [
                                                'none',
                                                'none',
                                                'translate(-70%, 0)',
                                                'translate(-70%, 0)',
                                            ],
                                        }}
                                    />
                                )}
                                renderCenterRightControls={({ nextSlide }) => (
                                    <IconButton
                                        aria-label="Next slide"
                                        icon={<IoArrowForward size={30} />}
                                        onClick={nextSlide}
                                        size="lg"
                                        rounded="full"
                                        bgColor="white"
                                        color="gray"
                                        _hover={{
                                            bgColor: 'white',
                                            color: 'gray',
                                        }}
                                        colorScheme="skyblue"
                                        sx={{
                                            transform: [
                                                'none',
                                                'none',
                                                'translate(70%, 0)',
                                                'translate(70%, 0)',
                                            ],
                                        }}
                                    />
                                )}
                                wrapAround
                            >
                                {sbsData.map(
                                    ({
                                        image,
                                        heading1,
                                        heading2,
                                        heading3,
                                        data1,
                                        data2,
                                    }) => (
                                        <Center>
                                            <Flex
                                                direction={[
                                                    'column',
                                                    'column',
                                                    'column',
                                                    'row',
                                                ]}
                                                key={image}
                                                h={[
                                                    '50rem',
                                                    '50rem',
                                                    '50rem',
                                                    'full',
                                                    'full',
                                                ]}
                                                p={['5']}
                                                pt={['5', '5', '5', '14', '14']}
                                                pb={['3', '3', '3', '12', '12']}
                                                w={['100%', '100%']}
                                                alignItems={'center'}
                                            >
                                                <Image
                                                    w="30rem"
                                                    h={[
                                                        '50rem',
                                                        '50rem',
                                                        '50rem',
                                                        '25rem',
                                                    ]}
                                                    src={image}
                                                    alt=""
                                                />
                                                <Flex
                                                    direction="column"
                                                    pl={[
                                                        '0',
                                                        '0',
                                                        '0',
                                                        '5',
                                                        '5',
                                                    ]}
                                                    py={[
                                                        '0',
                                                        '0',
                                                        '0',
                                                        '4',
                                                        '4',
                                                    ]}
                                                    w="50rem"
                                                    h={[
                                                        '50rem',
                                                        '50rem',
                                                        '50rem',
                                                        '25rem',
                                                    ]}
                                                    bg="white"
                                                >
                                                    <Heading
                                                        fontSize="xl"
                                                        mb={[
                                                            '0',
                                                            '0',
                                                            '0',
                                                            '5',
                                                            '5',
                                                        ]}
                                                    >
                                                        {heading1}
                                                    </Heading>
                                                    <Heading
                                                        fontSize="lg"
                                                        mb={[
                                                            '0',
                                                            '0',
                                                            '0',
                                                            '2',
                                                            '2',
                                                        ]}
                                                    >
                                                        {heading2}
                                                    </Heading>
                                                    <Box
                                                        mb={[
                                                            '0',
                                                            '0',
                                                            '0',
                                                            '2',
                                                            '2',
                                                        ]}
                                                    >
                                                        {data1}
                                                    </Box>
                                                    <Heading
                                                        fontSize="lg"
                                                        mb={[
                                                            '0',
                                                            '0',
                                                            '0',
                                                            '2',
                                                            '2',
                                                        ]}
                                                    >
                                                        {heading3}
                                                    </Heading>
                                                    <Box>{data2}</Box>
                                                </Flex>
                                            </Flex>
                                        </Center>
                                    )
                                )}
                            </Carousel>
                        </Box>
                    </Center>
                </Flex>
            </Center>
        </Box>
    )
}

export default SBSOffers
