import axios from "axios";
let baseURL = "https://api.kghype.com:5000/api/";

/**
    amount: checkoutAmount,
    productName: curCart.length === 1 ? curCart[0].title : curCart.length + " Products",
    name: `${user.dbUser.fName} ${user.dbUser.lName}`,
    description: "Paid with " + paymentMethod,
    type: type__,
    item_data: checkoutData,
    user_id: user.key,
    sid: cookie.getCookie("sid")
 */

function coinBaseCheckout(datas) {
    return new Promise(async (resolve, reject) => {
        const checkoutData = {
            name: datas.name,
            description: datas.description,
            type: datas.type,
            item_data: datas.item_data,
            sid: datas.sid,
            user_id: datas.user_id,
            pricing_type: "fixed_price",
            local_price: {
                amount: parseFloat(datas.amount.toFixed(2)),
                currency: "USD"
            },
            requested_info: ["name", "email"],
            productName: datas.productName,
        }

        let resp = await axios.post(baseURL+'coinbase-checkout', checkoutData);
        let data = resp.data;
        if (data.hosted_url) resolve(data.hosted_url);
        else reject(new Error("Unable to proceed payment"))
    })
}


function stripeCheckout({
    name,
    description,
    amount,
    productName,
    item_data,
    sid,
    user_id,
    type
}) {
    return new Promise(async (resolve, reject) => {

        let resp = await axios.post(baseURL+'stripe-checkout-session', {
            productName, description, amount: parseFloat(amount.toFixed(2)), type: type, sid, user_id
        });
        let data = resp.data;
        if(data.hosted_url) {
            localStorage.setItem("stripe_item_data", btoa(JSON.stringify(item_data)));
            resolve(data.hosted_url)
        } else {
            reject(new Error("Unable to proceed with payment"))
        }
    })
}

function paypalCheckout({
    name,
    description,
    amount,
    productName,
    item_data,
    sid,
    user_id,
    type
}) {
    return new Promise(async (resolve, reject) => {
        console.log(amount)
        let resp = await axios.post(baseURL+'paypal-checkout', {
            name, description, amount: parseFloat(amount.toFixed(2)), type: type, sid, user_id, item_data: item_data
        });
        let data = resp.data;
        if (data.hosted_url) {
            resolve(data.hosted_url)
        } else {
            reject(new Error("Unable to proceed with payment"))
        }
    })
}

export {
    coinBaseCheckout,
    stripeCheckout,
    paypalCheckout
}