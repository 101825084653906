// CSS NOTE

// the 'main Axis alignment' property is achieved via the 'justify-content' css property. Possible values are:
// 'start', 'center', 'space-between', 'space-around', 'space-evenly', ... etc. look up the 'justify-content' property

// the 'cross Axis alignment' propery is achieved via the 'align-content' property. Possible values are:
// 'start', 'center', 'space-between', 'space-around', 'space-evenly', ... etc. look up the 'align-content' property

// Note: for cross-axis or main-axis properties to work, you need to set 'flex-wrap' css property to 'wrap'

function Row({
    mainAxisAlignment,
    crossAxisAlignment,
    children,
    onClick,
    style = {}
}) {
    
    const customStyle = {
        display: "flex",
        flexDirection: 'row',
        flexFlow: "wrap",
        alignContent: crossAxisAlignment,
        justifyContent: mainAxisAlignment,
        ...style

    }

    return (
        <div style = {customStyle} onClick = {onClick}>
            {children}
        </div>
    );
}


function Column({
    mainAxisAlignment = 'start',
    crossAxisAlignment = 'center',
    children,
    onClick,
    style
}) {

    const customStyle = {
        display: "flex",
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: crossAxisAlignment,
        justifyContent: mainAxisAlignment,
        ...style

    }

    return (
        <div style = {customStyle} onClick = {onClick}>
            {children}
        </div>
    );
}

const layout = {
    Row,
    Column
}

export default layout
