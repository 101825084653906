import styled from 'styled-components'
// import { ClientTable, ClientTd, ClientTh, ClientTr } from "components/client-table";
import axios from 'axios'
import Button from 'components/button'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import { CONFIRM_PREFIX, PUBLIC_PREFIX } from 'configs/app-config'
import { SharedImage } from 'constants/image-constant'
import useLoader from 'hooks/useLoader'
import $ from 'jquery'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Currency } from 'utilities'
import countryList from '../signup/countryList'

//Firebase
import TablePriceCell from 'components/table-price-cell'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { removeFromCartButton } from 'utilities/cart'

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material'

import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import './cart.scss'

const HeadingStyle = {
    textAlign: 'left',
    paddingLeft: '20px',
}

const { Bell, TrashCan } = SharedImage

const ContainerStyle = {
    padding: "20px 20px 200px 20px"
}

const MetaStyle = {
    paddingRight: "10px"
}

const columnGenerate = (confirm, user, quantities, setQuantities, setCart) => {
    let arr = [
        {
            name: 'Item Name',
            cell: (d) => (
                <div>
                    { d?.link ? (
                        <a href={d.link}>
                            <img
                                src={d.pic_url}
                                alt="Product"
                                style={{
                                    width: '85px',
                                    height: '90px',
                                    padding: '5px 0px',
                                    borderRadius:"15px"
                                }}
                            />
                        </a>
                    ) : (
                        <Link style={{textDecoration: "none"}} to={"/product-detail?store=taobao&id="+(d.product_number || d.num_iid)}>
                            <img
                                src={d.pic_url}
                                alt="Product"
                                style={{
                                    width: '85px',
                                    height: '90px',
                                    padding: '5px 0px',
                                    borderRadius:"15px"
                                }}
                            />
                        </Link>
                    )}
                </div>
            ),
            width:"140px"
        },
        {
            name: <div className="cart-notification">
                <img src={Bell} />
                <span>International Shipping charged separately</span>
            </div>,
            width: '315px',
            cell: (d) => (
                <div>
                    {d?.link ? (
                        <a href={d.link}>
                            <strong className="item-title">{d.title ?? ''}</strong>
                        </a>
                    ) : (
                        <Link style={{textDecoration: "none"}} to={"/product-detail?store=taobao&id="+(d.product_number || d.num_iid)}>
                            <strong className="item-title">{d.title ?? ''}</strong>
                        </Link>
                    )}
                    
                </div>
            ),
        },
        {
            name: 'Specifications',
            cell: (d) => (
                <div>
                    {d.specifications &&
                        Object.keys(d.specifications).map((key, index) => {
                            let vals = Object.values(d.specifications)[index];
                            return (
                                <p className="specification">
                                    <span>{key}:</span> <span>{vals[vals.length - 1]}</span>
                                </p>
                            )
                        })
                    }
                </div>
            ),
            width:"200px"
        },
        {
            name: 'Price',
            width: "190px",
            cell: (d) => {
                return <div className="item-price"><TablePriceCell itemPrice={d.quantity ? +d.price * +d.quantity : d.price} /></div>
            },
        },
        {
            name: 'Shipping Cost',
            width: "120px",
            cell: (d) => {
                let shippingPrice = parseFloat(
                    d?.post_fee ||
                        0 + d?.express_fee ||
                        0 + d?.ems_fee ||
                        0
                ) || 0;
                return <div className="shipping-fee"><TablePriceCell itemPrice={shippingPrice * +d.quantity} /></div>
            },
        },
        {
            name: 'Quantity',
            cell: (d) => (
                <div className="quantity-container">
                    <span className="quantity-title">Quantity</span>
                    {confirm ? (
                        <div><span>{quantities[d.num_iid] ? quantities[d.num_iid] : d.quantity}</span></div>
                    ) : (
                        <div>
                            <button onClick={() => {
                                let quantities_ = {...quantities};
                                if (!quantities[d.num_iid]) {
                                    if (d.quantity > 1) quantities_[d.num_iid] = parseInt(d.quantity) - 1;
                                } else {
                                    if (quantities[d.num_iid] > 1) quantities_[d.num_iid] = quantities[d.num_iid] - 1;
                                }
                                setQuantities(quantities_);
                            }}>-</button>
                            <span>{quantities[d.num_iid] ? quantities[d.num_iid] : d.quantity}</span>
                            <button onClick={() => {
                                let quantities_ = {...quantities};
                                if (!quantities[d.num_iid]) {
                                    quantities_[d.num_iid] = parseInt(d.quantity) + 1;
                                } else {
                                    quantities_[d.num_iid] = quantities[d.num_iid] + 1;
                                }
                                setQuantities(quantities_);
                            }}>+</button>
                        </div>
                    )}
                </div>
            ),
        },
        {
            name: 'Remark',
            cell: (d) => (
                <>
                    {
                        !confirm 
                        ? <textarea className="remark-textarea" type='text' placeholder='Leave a message to agent here,&#10;Your buyer will contact seller (optional)'>{d.remark}</textarea>
                        : <p>{d.remark}</p>
                    }
                </>
            )
        },
    ]

    if (!confirm) {
        arr.push({
            name: 'Remove',
            cell: (d) => {
                return (
                    <button className="item-remove-button" onClick={async (e) => {
                        await removeFromCartButton(d, user)
                        .then(data => {
                            setCart(data);
                        })
                        .catch(() => toast.error("Item cannot be found"))
                    }}>
                        <img src={TrashCan} />
                    </button>
                )
            },
            width: '50px',
        });
    }
    else {
        arr[1]["width"] = "400px";
        arr[2]["width"] = "230px";
    }

    return arr;
}

export default function ShippingCart({ confirm = false, onConfirm }) {
    const [selected, setSelected] = useState([])
    const history = useHistory();
    const location = useLocation();
    const [country, setCountry] = useState(localStorage.getItem("confirm-selected-country") || "")
    const [LoaderWrapper, dataLoader] = useLoader();
    const [columns, setColumns] = useState({});
    const [quantities, setQuantities] = useState({});

    let user = useSelector(state => state.user);

    const [rates, setRates] = useState({})
    useEffect(() => {
        (async () => {
            setRates(await Currency.GetRates())
        })()
    }, [])

    const [state, setState] = useState({
        ip: '',
        countryName: '',
        countryCode: '',
        city: '',
        timezone: '',
    })

    const getGeoInfo = () => {
        axios
            .get('https://ipapi.co/json/')
            .then((response) => {
                let data = response.data
                setState({
                    ...state,
                    ip: data.ip,
                    countryName: data.country_name,
                    countryCode: data.country_calling_code,
                    city: data.city,
                    timezone: data.timezone,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [datas, setData] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [selectedItemsFromDB, setSelectedItemsFromDB] = useState([]);
    const [ref, setRef] = useState(0);
    const [selectedRef, setSelectedRef] = useState(0);

    let selectedReseted = false;

    useEffect(() => {
        window.scrollTo(0, 0);
        
        let fetchData;
        if (user) {
            fetchData = async () => {
                getGeoInfo()
                
                let ref_ = await user.cartRef();
                setRef(ref_);
                
                let selectedRef_ = await user.selectedCartRef();
                if (!confirm) {
                    selectedReseted = true;
                    user.selectedCartRef().update({
                        selectedCart: [],
                    })
                }
                
                setSelectedRef(selectedRef_);
                setSelectedItemsFromDB(location?.state?.confirm_cart_data || []);
                setColumns(columnGenerate(confirm, user, quantities, setQuantities, setData));
    
                if (ref_ && !confirm)
                    ref_.on('value', (snapshot) => {
                        console.log(snapshot.val())
                        try {
                            const fetched = snapshot.val();
                            if (!fetched) return;
                            let dummy = [...fetched];
                            setData(dummy);
                            setTotalAmount(0);
                        } catch(e) {
                            setData([]);
                            setTotalAmount(0);
                        }
                    })
            }

            dataLoader(fetchData)
        }
    }, [user]);

    useEffect(() => {
        if (user && Object.values(quantities)?.length > 0) 
            setColumns(columnGenerate(confirm, user, quantities, setQuantities, setData));
    }, [quantities]);

    const [quantityChanged, setQChanged] = useState(false);
    $("span[id-quantity]").on("change", function() {
        setQChanged(!quantityChanged);
    })

    useEffect(async () => {
        let selected_;
        if (selected) selected_ = selected;

        if (user) {
            if (!confirm) {
                if (selectedReseted) {
                    if (user.dbUser.selectedCart?.length) selected_ = user.dbUser.selectedCart;
                    await user.selectedCartRef().update({ selectedCart: selected_ || [] })
                }
            } else if (location.state.confirm_cart_data) {
                //if (user.dbUser.selectedCart?.length) selected_ = user.dbUser.selectedCart;
                selected_ = location.state.confirm_cart_data;
            } else {
                window.location.href = "/cart";
            }
        }

        if (selected_?.length) {
            let fetched;
            if (selected?.length) fetched = selected;
            else fetched = selected_;

            let totalA = 0
            for (let product of fetched) {

                let q = product.quantity;

                if (quantities[product.num_iid]) q = quantities[product.num_iid];

                let shippingPrice = parseFloat(
                    product?.post_fee ||
                        0 + product?.express_fee ||
                        0 + product?.ems_fee ||
                        0
                ) || 0;

                totalA += (parseFloat(product.price) + parseFloat(shippingPrice)) * parseFloat(q);
            }

            setTotalAmount(totalA);
        } else { setTotalAmount(0); }
    }, [selected, user, quantities]);

    const tableData = {
        columns: columns,
        data: confirm ? selectedItemsFromDB : datas,
    }

    const handleChange = ({ selectedRows }) => {
        setSelected([...selectedRows])
    }

    return (
        <LoaderWrapper>
            <Container className="cart-main" style={ContainerStyle}>
                {confirm ? (
                    <span></span>
                ) : (
                    <Heading style={HeadingStyle}> Cart </Heading>
                )}

                <DataTable
                    title="Cart"
                    columns={columns}
                    data={tableData.data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    selectableRows={!confirm}
                    onSelectedRowsChange={handleChange}
                    selectableRowSelected={row => row}
                    className="table-main-container"
                    customStyles={{
                        table: {
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        },
                        headCells: {
                            style: {
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                fontWeight: 'bold',
                                color: '#504D4D',
                            },
                            draggingStyle: {
                                cursor: 'move',
                            },
                        },

                        headRow: {
                            style: {
                                minHeight: '52px',
                                borderBottomWidth: '1px',
                                borderBottomStyle: 'solid',
                                backgroundColor: 'transparent',
                            },
                            denseStyle: {
                                minHeight: '32px',
                            },
                        },
                    }}
                />

                {tableData.data.length > 0 ? (
                    <div className="cart-checkout-container">
                        <MetaWrapper>
                            <Wrapper>
                                Selected: 
                                {confirm
                                        ? <Heading style={MetaStyle}>{tableData.data.length}</Heading>
                                        : <Heading style={MetaStyle}>
                                        {selected.length}
                                    </Heading>}
                            </Wrapper>
                            <Wrapper>
                                Total: {' '}
                                <Heading id="total-price-container" style={MetaStyle}>
                                    &nbsp;{Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "CNY", totalAmount.toFixed(2), false))}
                                </Heading>
                            </Wrapper>
                        </MetaWrapper>
                        {confirm &&
                            <FormControl className="cart-form-control" style={{width: "260px", marginTop: "20px"}}>
                                <InputLabel id="country-select">Country</InputLabel>
                                <Select
                                    labelId="country-select"
                                    id="country"
                                    defaultValue={country}
                                    label="country"
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                        localStorage.setItem("confirm-selected-country", e.target.value);
                                    }}
                                >
                                    {countryList.map((ct) => (
                                        <MenuItem key={ct} value={ct}>
                                            {ct}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                        <Button
                            style={{
                                background: "#072A48",
                                boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.1)",
                                borderRadius: "10px",
                                width:"230px",
                                marginTop:"10px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "16px",
                                lineHeight: "23px",
                                textAlign: "center"
                            }}
                            text={(confirm) ? "Confirm Order" : "Checkout"}
                            disabled={((!country && confirm) || (!selected.length && !confirm)) ? true : false}
                            onClick={() => {
                                (async() => {
                                    if (ref && !confirm && !((!country && confirm) || (!selected.length && !confirm))) {
                                        let arr_ = [];
                                        for (let i = 0; i < selected.length; i++) {
                                            arr_.push({
                                                ...selected[i],
                                                remark: $("#row-"+i.toString()).find(".remark-textarea").val(), //Get remark
                                                quantity: +$("#row-"+i.toString()).find(".quantity-container > div > span").text(), //Get quantity
                                            });
                                        }
                                        history.push({
                                            pathname: PUBLIC_PREFIX + CONFIRM_PREFIX,
                                            state: {
                                                confirm_cart_data: arr_
                                            }
                                        });
                                    }
                                    else if (confirm && country) {
                                        let fetched = selected;
                                        if (fetched) {
                                            let arr = [];
                                            for (let i = 0; i < location.state.confirm_cart_data.length; i++) {
                                                let data_ = location.state.confirm_cart_data[i];
                                                data_["country"] = country;
                                                arr.push(data_);
                                            }
                                            await selectedRef.update(arr);
                                            history.replace();
                                            onConfirm();
                                        }
                                    }
                                    else if (confirm && !country) toast.error("Please select your country.");
                                })();
                            }}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </Container>
        </LoaderWrapper>
    )
}

// style input

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 2rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
`

const MetaWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-left: 10px;
`