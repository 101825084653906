import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import { onWithdrawalRequestChanged } from 'utilities/wallet';
import useLoader from 'hooks/useLoader';

const HeadingStyle = {
    textAlign: "left",
    marginTop: "20px",
    marginBottom: "-20px"
}

const ContainerStyle = {
    paddingBottom: "10px",
    width: '100%',
    marginLeft: "20px"
}

const columns = [
    {
        name: 'User Name',
        width: "250px",
        cell: (d) => {
            let style = {overflowWrap: 'break-word', padding: "10px 0px", color: "#625E5E", fontWeight: "normal" }
            return <p style={style}>{d.user.name}</p>
        }
    },
    {
        name: "Amount",
        width: "120px",
        cell: (d) => {
            return <div style = {{margin: '20px 0px'}}>
                {d.amount}
                </div>
        },
      },

      {
        name: 'information',
        width: '200px',
        cell: (d) => {
            let style = {overflowWrap: 'break-word', padding: "10px 0px", color: "#625E5E", fontWeight: "normal" }
            return <div>
                <p style = {style}>{d.description}</p>
            </div>
        }
      },
      {
        name: "Date",
        sortable: true,
        width: "200px",

        cell: (d) => <><span>{new Date(d.date).toDateString()}</span></>
      },
      {
        name: "Status",
        sortable: true,
        width: "200px",

        cell: (d) => <><span>{d.status || "Pending"}</span></>
      },
]

export default function WithdrawalRequestPage({userKey}) {
    const [datas, setData] = useState([]);
    const [currentUser, setUser] = useState('')
    const [LoaderWrapper, dataLoader] = useLoader()
    const [selectedRows, setSelectedRows] = useState([])

    function handleRowSelect(select_data) {
        setSelectedRows((prev) => {
            prev.splice(0, prev.length, ...select_data.selectedRows)
            return prev
        })
    }
    

    useEffect(() => {
        if (userKey) {
            const fetchData = async () => {
                try {
                    let list_ = []
                    onWithdrawalRequestChanged((orderList) => {
                        for (let i = 0; i < orderList.length; i++) {
                            let order = orderList[i];
                            if (order.user.userId == userKey) list_.push(order);
                        }

                        setData(list_);
                    })
                } catch(e) {
                    console.error(e)
                } 
            };
            dataLoader(fetchData);
        }
    }, [userKey])

    const tableData = {
        columns,
        data: datas
      };

    return (
        <div>
            <LoaderWrapper>
                <div style={ContainerStyle}>
                    <div style={HeadingStyle}>
                        <strong>Withdrawal Requests</strong>
                    </div>
                    <br />
                    <DataTable
                    title = "Withdrawal Requests"
                    columns={columns}
                    data={tableData.data}
                    noHeader
                    pagination
                    highlightOnHover
                    striped
                    selectableRows
                    onSelectedRowsChange={handleRowSelect}

                    customStyles = {
                            {
                                table: {
                                    style: {
                                        fontFamily: "Montserrat",
                                    }
                                },
                                headCells: {
                                    style: {
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        fontWeight: "bold",
                                        color: "#504D4D"
                                    },
                                    draggingStyle: {
                                        cursor: 'move',
                                    },
                                },

                                headRow: {
                                    style: {
                                        minHeight: '52px',
                                        borderBottomWidth: '1px',
                                        borderBottomStyle: 'solid',
                                        // backgroundColor: "#F0EFEF",
                                    },
                                    denseStyle: {
                                        minHeight: '32px',
                                    },
                                },
                                rows: {
                                    style: {
                                        border: 'solid 0px blue',
                                        backgroundColor: '#F4F4F5'

                                    }
                                }
                            }
                        }
                    />
                </div>

            </LoaderWrapper>
        </div>
    )
}