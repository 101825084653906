import Button from 'components/button'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import { PUBLIC_PREFIX, SHIPPING_PREFIX } from 'configs/app-config'
import useLoader from 'hooks/useLoader'
import $ from 'jquery'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
    FaAngleLeft, FaAngleRight
} from "react-icons/fa"
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { getOrderByNumber, sendReturnRequest } from 'utilities/order'
import { getWarehouseProductByUser } from 'utilities/warehous'
import ReturnsDialog from '../history/returns_dialog'
import './warehouse.scss'

const HeadingStyle = {
    textAlign: 'left',
    paddingLeft: '20px',
}

const ContainerStyle = {
    paddingBottom: '10px',
    width: '100%',
    // margin: "0px auto"
}

const MetaStyle = {
    paddingLeft: '10px',
}

export default function WareHousePage() {

    let user = useSelector(state => state.user);

    const [LoaderWrapper, setDataLoader] = useLoader()
    const [currentUser, setUser] = useState('')
    const [listOfData, setListOfData] = useState([])
    const history = useHistory()
    const [selectedRows, setSelectedRows] = useState(false)
    const [openReturnsDialog, setOpenReturnsDialog] = useState(false)
    const [returnProduct, setReturnProduct] = useState(false)
    const [selectedRowSummary, setSelectedRowsSummary] = useState({
        totalSelected: 0,
        totalWeight: 0,
    })
    const [qcImages, setQcImages] = useState([])
    const [qcIndex, setQcIndex] = useState(0);

    function returnSentStyle() {
        if (document.querySelector(".return-sent")) {
            document.querySelector(".return-sent").closest(".rdt_TableRow").style.background = "#b1b1b1";
        }
    }

    const columns = [
        {
            name: 'Product Image',
            width: '200px',
            cell: (d) => {
                return (
                    <div style={{display: "flex"}}>
                        <div>
                            <img
                                src={d.product_image}
                                alt="Product"
                                style={{
                                    height: '130px',
                                    border: "1px solid rgb(28 28 28 / 18%)"
                                }}
                            />
                        </div>
                    </div>
                )
            },
        },
        {
            name: 'QC Images',
            width: '150px',
            cell: (d) => {
                return (
                    <div className="qc-container">
                        <span onClick={() => 
                            setTimeout(() => {
                                setQcImages(d);
                                setQcIndex(0);
                            }, 1)
                        }>See QC Images</span>
                    </div>
                )
            }
        },
        {
            name: 'Valid/Invalid',
            width: '200px',
            cell: (d) => {
                return (
                    <span style={d?.valid == "invalid" ? {color: "red"} : {color: "green"}}>{d?.valid == "invalid" ? "Invalid" : "Valid"}</span>
                )
            }
        },
        {
            name: 'Product No',
            width: '100px',
            cell: (d) => {
                let style = {
                    overflowWrap: 'break-word',
                    padding: '10px 0px',
                    color: '#625E5E',
                    fontWeight: 'normal',
                }
                return <p style={style}>{d.product_number}</p>
            },
        },
        {
            name: 'Description',
            width: '220px',
            cell: (d) => {
                return (
                    <div style={{ margin: '20px 0px' }}>
                        <p> {d.description} </p>
                    </div>
                )
            },
        },
    
        {
            name: 'Weight',
            width: '90px',
            cell: (d) => {
                let style = {
                    overflowWrap: 'break-word',
                    padding: '10px 0px',
                    color: '#625E5E',
                    fontWeight: 'normal',
                }
                return (
                    <div>
                        <p style={style}>{d.weight}</p>
                    </div>
                )
            },
        },
    
        {
            name: 'Amount',
            width: '170px',
            cell: (d) => {
                let style = {
                    overflowWrap: 'break-word',
                    padding: '10px 0px',
                    color: '#625E5E',
                    fontWeight: 'normal',
                }
                return (
                    <div>
                        <p style={style}>{parseFloat(d?.amount || 0)?.toFixed(2) || '0'}¥</p>
                    </div>
                )
            },
        },
    
        {
            name: "Return",
            width: '200px',
            cell: (d) => {
                if (d?.returned) {
                    setTimeout(() => returnSentStyle(), 10);
                    return <span className="return-sent"><b>Return request sent</b></span>
                }

                const date1 = new Date();
                const date2 = new Date(d.date);
                const diffTime = Math.abs(date2 - date1);
                const diffMins = Math.ceil(diffTime / (1000 * 60)); //Minute
                
                let mins = 5 * 24 * 60 - diffMins;
                let hours = Math.floor(mins / 60);
                mins = mins - (hours * 60);
                let days = Math.floor(hours / 24);
                hours = hours - (days * 24);
    
                const diffDate = <span><b>{`${days.toString().padStart(2, "0")}:${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`}</b> left to return</span>;
                
                if (+days < 5 && +days >= 0)
                    return (
                        <div style={{
                            "display": "flex",
                            "alignItems": "center",
                            "height": "100%"
                        }}>
                            <div>
                                <div className="button-container">
                                    <button className="return-button" onClick={() => {handleDialogOpen(d.order_number)}}>Return</button>
                                    <button className="return-button" onClick={async () => {
                                        toast.info("Please wait...");
                                        sendReturnRequest({
                                            reason: "Exchange Request",
                                            userInfo: currentUser,
                                            product: await getOrderByNumber(d.order_number)
                                        }).then(() => {
                                            setTimeout(() => { window.location.reload(); }, 2000);
                                            toast.success("Exchange Request successfully sent!");
                                        });
                                    }}>Exchange</button>
                                </div>
                                {diffDate}
                            </div>
                        </div>
                    )

                return (
                    <div style={{
                        "display": "flex",
                        "alignItems": "center",
                        "height": "100%"
                    }}>
                        <span style={{
                            "overflowWrap": "break-word",
                            "whiteSpace": "break-spaces",
                            "padding": "10px 0px",
                            "color": "rgb(98, 94, 94)",
                            "fontWeight": "normal"
                        }}>Return date is over. If you think there's a problem please contact us.</span>
                    </div>
                )
            }
        }
    ]

    async function handleDialogOpen(product_num) {
        setReturnProduct(await getOrderByNumber(product_num))
        setOpenReturnsDialog(true)
    }

    function handleReturns({ reason }) {
        toast.info("Please wait...");
        sendReturnRequest({
            reason,
            userInfo: currentUser,
            product: returnProduct
        }).then(() => {
            toast.success("Return request has been received.");
            setTimeout(() => { window.location.reload(); }, 2000);
        })
    }

    function handleRowSelect({ selectedRows }) {
        let totalWeight = 0
        let totalSelected = selectedRows.length

        for (let selected of selectedRows) {
            totalWeight += parseFloat(selected.weight) || 0
        }

        setSelectedRows(selectedRows)
        setSelectedRowsSummary((prev) => {
            return {
                totalWeight,
                totalSelected,
            }
        })
    }

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                try {
                    setUser(user)
    
                    let list = await getWarehouseProductByUser(user.key)
                    setListOfData(list)
                } catch (e) {
                    console.error(e)
                }
            }
    
            setDataLoader(fetchData)
        }
    }, [user]);

    useEffect(() => {
        $(window).on("click", function(e) {
            let target = $(e.target);
            if (!target.closest(".image-modal .container__").length && $('.image-modal .container__').is(":visible") && !target.closest(".qc-buttons").length) {
                setQcImages([]);
            }
        });
    }, [])

    const tableData = {
        columns,
        data: listOfData,
    }

    return (
        <LoaderWrapper>
            <Container style={ContainerStyle}>
                <Heading style={HeadingStyle}>My Warehouse</Heading>

                <DataTable
                    title="Warehouse"
                    columns={columns}
                    data={tableData.data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    // striped
                    selectableRows
                    onSelectedRowsChange={handleRowSelect}
                    // selectableRowSelected = {selectionCriteria}

                    customStyles={{
                        table: {
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        },
                        headCells: {
                            style: {
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                fontWeight: 'bold',
                                color: '#504D4D',
                            },
                            draggingStyle: {
                                cursor: 'move',
                            },
                        },

                        headRow: {
                            style: {
                                minHeight: '52px',
                                borderBottomWidth: '1px',
                                borderBottomStyle: 'solid',
                                // backgroundColor: "#F0EFEF",
                            },
                            denseStyle: {
                                minHeight: '32px',
                            },
                        },
                        rows: {
                            style: {
                                border: 'solid 0px blue',
                                // backgroundColor: '#F4F4F5'
                                backgroundColor: '#EEF2F5',
                            },
                        },
                    }}
                />
            </Container>

            <Wrapper style={{ display: listOfData.length ? 'block' : 'none' }}>
                <Button
                    text="Submit"
                    onClick={() => {
                        if (!selectedRows) {
                            alert('Please select items to proceed')
                            return
                        }
                        history.push(PUBLIC_PREFIX + SHIPPING_PREFIX, {
                            selectedRows,
                            totalWeight: selectedRowSummary.totalWeight,
                        })
                    }}
                />
                <MetaWrapper>
                    <Wrapper>
                        Selected :
                        <Heading style={MetaStyle}>
                            {selectedRowSummary.totalSelected}
                        </Heading>
                    </Wrapper>
                    <Wrapper>
                        Total :{' '}
                        <Heading style={MetaStyle}>
                            {selectedRowSummary.totalWeight}
                        </Heading>
                    </Wrapper>
                </MetaWrapper>
            </Wrapper>
            <ReturnsDialog open={openReturnsDialog} onClose={() => setOpenReturnsDialog(false)} onSubmit= {handleReturns}/>

            {(qcImages?.images) &&
                (qcImages.images.length > 0 && qcIndex >= 0) &&
                    <div className="image-modal" style={{display: "flex"}}>
                        <div className="container__">
                            <div style={{backgroundColor: "white", padding: "10px", borderRadius: "14px"}}>
                                {qcIndex > 0 && <button className="qc-buttons" onClick={() => setQcIndex(qcIndex - 1)}><FaAngleLeft /></button>}
                                <img src={qcImages?.images[qcIndex]} style={{maxWidth:"600px", height: "auto", margin: "auto", display: "block"}} />
                                <br />
                                <p style={{marginTop: "10px"}}><b>Product Weight: </b>{qcImages.weight}</p>
                                <p style={{marginTop: "10px"}}><b>Description: </b>{qcImages.description}</p>
                                {qcIndex < qcImages?.images.length - 1 && <button className="qc-buttons" onClick={() => setQcIndex(qcIndex + 1)}><FaAngleRight /></button>}
                            </div>
                        </div>
                    </div>
            }
            {
                (!qcImages?.images && qcImages?.amount) &&
                <div className="image-modal" style={{display: "flex"}}>
                    <div className="container__">
                        <div style={{backgroundColor: "white", padding: "10px", borderRadius: "14px"}}>
                            <div style={{maxWidth:"600px", height: "300px", margin: "auto", display: "flex", justifyContent: "center", alignItems: "center"}}><p>QC Images don't exist</p></div>
                            <br />
                            <p style={{marginTop: "10px"}}><b>Product Weight: </b>{qcImages.weight}</p>
                            <p style={{marginTop: "10px"}}><b>Description: </b>{qcImages.description}</p>
                        </div>
                    </div>
                </div>
            }
        </LoaderWrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
`

const MetaWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding-left: 20px;

    @media (max-width: 480px) {
        margin-top: 2rem;
    }
`