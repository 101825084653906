import { Box, Image, Center, Flex, Heading } from "@chakra-ui/react"
import likeData from "./LikeToWorkData"
import { range } from "lodash";

function LikeToWorkSection() {
  return (
    <Box position="relative" w="full" my={10}>
        <Image src="/like-to-work-bg-1.png" position="absolute" zIndex={1} w="full" mt="100" minH={["full"]} alt="" />
        <Center>
            <Flex direction="column" alignItems="center" mt="10" zIndex={3} w="full">
                <Heading position="relative" color="white" textAlign="center" fontSize="3xl" mt={["110", "110", "110", "140", "140"]} mb="10" zIndex={2}>We would like to work with you for</Heading>
                <Flex gap={5} direction={["column", "column", "column", "row", "row"]} wrap="wrap">
                  {likeData.slice(0, 3).map(({image, name}) => (
                    <Flex
                        direction="row"
                        alignItems="center"
                        w={["25rem"]}
                        p="6"
                        gap={5}
                        bg="white"
                        borderRadius="md"
                        key={name}
                    >
                        <Image
                        src={image}
                        cursor="pointer"
                        />
                        <Box cursor="pointer" fontSize="lg">
                        {name}
                        </Box>
                    </Flex>
                  ))}
                </Flex>
                <Flex gap={5} mt="5" direction={["column", "column", "column", "row", "row"]} wrap="wrap">
                  {likeData.slice(3, 7).map(({image, name}) => (
                    <Flex
                        direction="row"
                        alignItems="center"
                        w={["25rem"]}
                        gap={5}
                        borderRadius="md"
                        p="7"
                        bg="white"
                        key={name}
                    >
                        <Image
                        src={image}
                        cursor="pointer"
                        />
                        <Box cursor="pointer" fontSize="lg">
                        {name}
                        </Box>
                    </Flex>
                  ))}
                </Flex>
            </Flex>
        </Center>
    </Box>
  )
}

export default LikeToWorkSection