import React from "react";

const Spinner = ({ bg, style }) => {
  return (
    <div className="lds-ring" style={style}>
      <div className={bg}></div>
      <div className={bg}></div>
      <div className={bg}></div>
      <div className={bg}></div>
    </div>
  );
};

export default Spinner;
