import {
    FORGOT_PASSWORD_PREFIX, PUBLIC_PREFIX,
    SIGNUP_PREFIX
} from 'configs/app-config'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setLogin } from 'store/reducers/authSlice/authSlice'
import styled from 'styled-components'
import { isValidEmail } from 'utilities/validator'
import { TextField } from '@mui/material'
import CheckBox from 'components/checkbox'
import { auth } from 'firebase.js'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import SignPageLayout from 'layouts/sign-page-layout'
import Cookie from 'helpers/cookie'
import { toast } from 'react-toastify'

export default function LoginPage(props) {
    const dispatch = useDispatch();
    const [ref, setRef] = useState(undefined)

    useEffect(() => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let ref_ = url.searchParams.get("ref");
        setRef(ref_);
        
        let cookie = new Cookie();
        cookie.eraseCookie("s");

        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) window.location.href = "/profile";
        });
    }, []);

    const [data, setData] = useState({
        mail: '',
        password: '',
        remember: false,
    })

    const [passwordError, setPasswordError] = useState({
        error: false,
        errorText: '',
    })

    const validationEmail = useMemo(() => {
        return data.mail === '' || isValidEmail(data.mail)
    }, [data.mail])

    //Values

    const handleSubmit = (e) => {
        e.preventDefault()
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData)
    }

    const login = async (e) => {
        e.preventDefault()
        try {
            if (validationEmail) {
                signInWithEmailAndPassword(
                    auth,
                    data.mail,
                    data.password
                )
                .then(() => {
                    window.location = '/profile';
                })
                .catch(e => {
                    toast.error("Login credentials are wrong")
                })
            }
        } catch (error) {
            setPasswordError({
                error: true,
                errorText: 'The password is invalid or the user does not have a password',
            })
        }
    }

    return (
        <SignPageLayout
            placeholderText="Login to KgHype"
            label="Login"
            // rightIcon={true}
        >
            <FormWrapper>
                <h1>Enter your Credentials</h1>
                <h5>Login to our service</h5>
                <div className="space-2"></div>
                <div className="form">
                    <TextField
                        label="Email"
                        name="email"
                        value={data.mail}
                        id="mail"
                        error={!validationEmail}
                        onChange={handleSubmit}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        name="password"
                        value={data.password}
                        id="password"
                        append={{
                            to: PUBLIC_PREFIX + FORGOT_PASSWORD_PREFIX,
                            text: 'Forgot your password?',
                        }}
                        onChange={handleSubmit}
                        error={passwordError.error}
                        helperText={passwordError.errorText}
                    />

                    <CheckBox
                        name="remember"
                        label="Remember Me"
                        value={data.remember}
                        onChange={handleSubmit}
                        id="remember"
                    />

                    <button className="submit-button" onClick={login}>
                        SUBMIT
                    </button>
                    <div className="supporter" style={{ marginTop: 25, marginBottom: 20, fontSize: "23px" }}>
                        Don't have an account?
                        <Link to={(ref) ? PUBLIC_PREFIX + SIGNUP_PREFIX + "?ref=" + ref : PUBLIC_PREFIX + SIGNUP_PREFIX}>
                            Register here.
                        </Link>
                    </div>
                </div>
            </FormWrapper>
        </SignPageLayout>
    )
}

const FormWrapper = styled.div`
    font-size: 50px;

    input,
    label {
        font-size: 2.2rem;
    }
`