import { toast } from 'react-toastify'
import axios from 'axios'
import UserCurrencyContext from 'contexts/userCurrencyContext'
import { useContext } from 'react'

export const NotifySuccess = (str) => {
    if (typeof str === 'string') {
        toast.success(str)
    }
    console.error('This is not String')
}
export const NotifyFail = (str) => {
    if (typeof str === 'string') {
        toast.error(str)
    }
    console.error('This is not String')
}

export const UpperCase = (str) => {
    if (typeof str === 'string') {
        return str.toUpperCase()
    }
    console.error('This is not String')
}

export const DetectLink = (str) => {
    if (typeof str === 'string') {
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
        return str.match(urlRegex)
    }
    console.error('This is not String')
}

export const OnlyNumber = (str) => {
    return str.match(/^[0-9!@#\$%\^\&*\)\(+=._-]+$/g)
}

export const checkURL = (str) => {
    return str.match(/(\d +)(?!.*\d)/)
}

export const ChooseURL = (str) => {
    const urlRegex_1688 = str.indexOf('1688')
    const urlRegex_Weidian = str.indexOf('weidian')
    const urlRegex_taobao = str.indexOf('taobao')
    const urlRegex_jd = str.indexOf('jd')
    const urlRegex_tmall = str.indexOf('tmall')
    if (urlRegex_taobao > -1 || urlRegex_tmall > -1) {
        return 'taobao'
    }
    else if (urlRegex_1688 > -1) {
        return '1688'
    }
    else if (urlRegex_Weidian > -1) {
        return 'micro'
    }
    else if (urlRegex_jd > -1) {
        return 'jd'
    }

    return undefined;
}

export const getId = (str) => {
    let url = new URL(str);
    
    if (new URLSearchParams(url.search).get('id')) {
        return new URLSearchParams(url.search).get('id');
    }
    else if (new URLSearchParams(url.search).get('itemID')) {
        return new URLSearchParams(url.search).get('itemID');
    }
    // else if (url.search.includes("id=")) {
    //     console.log("c")
    //     let params = url.search.split("&");
    //     console.log(params);
    //     for (let i = 0; i < params.length; i++) {
    //         if (params[i].includes("id=") && !params[i].includes("pvid=")) {
    //             return params[i].split("id=")[1];
    //         }
    //     }
    // }
    else {
        if (str.includes("a.m.taobao.com")) return url.pathname.split(".")[0].split("/")[1].split("i")[1]
        //else if (str.includes("detail.tmall.com")) return url.search.split("&")[2].replace("id=", "")
        else return url.href.split("/").pop().split(".")[0]
    }
}

export const getFileExt = (filename) => {
    var parts = filename.split('.')
    return parts.length > 1 ? parts.pop() : ''
}
export const Currency = {
    GetRates: async () => {
        return axios
            .get('https://open.er-api.com/v6/latest/CNY')
            .then((res) => {
                return res.data.rates
            })
    },

    ConvertToCNY: (rates, from, amount, format=true) => {
        if (amount != 0) {
            let money = amount * (1 / rates[from]);
            if (format)
                return Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: "CNY",
                }).format(money);
            return money || 0;
        } else return 0;
    },

    GetPrice: (rates, cnyPrice) => {
        const selectedCurrency = useContext(UserCurrencyContext)

        const currency = selectedCurrency || 'USD'
        const convertedPrice =
            currency === 'CNY' ? cnyPrice : rates[currency] * cnyPrice

        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        }).format(convertedPrice || 0)
    },

    FormatCurrency: (amount) => {
        const selectedCurrency = useContext(UserCurrencyContext)
        const currency = selectedCurrency || 'USD'
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        }).format(amount)
    },
}

export const generateOrderId = () => {
    var randomNumber =
        Math.floor(Math.random() * (10000000 - 10000 + 1)) + 10000
    return randomNumber.toString() + new Date().getTime().toString()
}

export const mapObjectToArray = (obj) => {
    if (obj) {
      let keys = Object.keys(obj);
      let list = keys.map((k) => obj[k]);
      return list;
    }
    return [];
  }
