import { auth, db } from "firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import Cookie from "helpers/cookie";

const cookie = new Cookie();

function makeID(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function getCurrentLoggedInUser() {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                let key = btoa(currentUser.email);
                db.ref("users/"+key).once('value', async (snapshot) => {
                    resolve({
                        currentUser,
                        dbUser: snapshot.val(),
                        key,
                        ref: () => {
                            return db.ref(`users/${key}`);
                        },
                        cartRef: () => {
                            return db.ref(`carts/${key}`);
                        },
                        selectedCartRef: () => {
                            return db.ref(`users/${key}/selectedCart`);
                        },
                        cartHistoryRef: () => {
                            return db.ref(`cart-histories/${key}`);
                        },
                        orderHistoryRef: () => {
                            return db.ref(`order-histories/${key}`);
                        },
                        addressBookRef: () => {
                            return db.ref(`users/${key}/addressbook`);
                        },
                        browseHistoryRef: () => {
                            return db.ref(`browse-histories/${key}`);
                        },
                        searchHistoryRef: () => {
                            return db.ref(`search-histories/${key}`);
                        },
                        favoriteProductsRef: () => {
                            return db.ref(`favorite-products/${key}`);
                        },
                        addAddress: (address) => {
                            return new Promise((resolve, reject) => {
                                try {
                                    db.ref(`users/${key}/addressbook`).once('value', async (snapshot) => {
                                        let addressbook = snapshot.val()
                                        if(!addressbook) addressbook = []

                                        addressbook.push(address)
                                        await db.ref(`users/${key}/addressbook`).update(addressbook)
                                        resolve()

                                    })
                                } catch(e) {
                                    reject(e)
                                }
                            })
                        }
                    })
                })
            } else reject()
        })
    })
}

export {
    getCurrentLoggedInUser
};
