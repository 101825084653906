import { ADMIN_PREFIX, ERROR_PREFIX } from 'configs/app-config';
import AppLayout from 'layouts/app-layout';
import PublicLayout from 'layouts/public-layout';
import { Redirect, Route, Switch } from 'react-router-dom';

/*export default function Views() {
    return(
        <Switch>
            <Route exact path="/">
                <Redirect to={PUBLIC_PREFIX} />
            </Route>
            <Route path={PUBLIC_PREFIX}>
                <PublicLayout/>
            </Route>
            <Route path={ADMIN_PREFIX}>
                <AppLayout/>
            </Route>
            <Route path={ERROR_PREFIX}>
                <Redirect to={PUBLIC_PREFIX} />
            </Route>
        </Switch>
    )
}*/

export default function Views() {
    return(
        <Switch>
            <Route path="/">
                <PublicLayout/>
            </Route>
            <Route path={ADMIN_PREFIX}>
                <AppLayout/>
            </Route>
            <Route path={ERROR_PREFIX}>
                <Redirect to={"/"} />
            </Route>
        </Switch>
    )
}