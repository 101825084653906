import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { FaExclamationCircle, FaLink, FaMinus, FaPlus } from 'react-icons/fa';
import { ImArrowUpRight2 } from 'react-icons/im';
import { addToBrowseHistory, addToCartButton } from 'utilities/cart';
import getAttributes from './getAttributes';
import ShippingInfo from './ShippingInfo';
import Spinner from "components/Spinner";
import { CART_PREFIX, PUBLIC_PREFIX } from 'configs/app-config';
import { useHistory } from 'react-router-dom';
import { Popover, Typography } from '@mui/material';
import PriceConverter from 'components/price-converter';
import { NotifyFail, NotifySuccess } from 'utilities';
import { Currency } from 'utilities';
import { db } from 'firebase.js';
import './ProductInfo.scss';

const ProductInfo = ({ item, shopLogo, setCurrImage, currentUser = null }) => {
    const [quantity, setQuantity] = useState(1)
    const [invetory, setInvetory] = useState(0)
    const [price, setPrice] = useState(item.price || 0)
    const [attributes, setAttributes] = useState({});
    const [selectedAttr, setSelectedAttr] = useState({}) //{color:"",size:''}
    const history = useHistory()
    const [totalShipping, setTotalShipping] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [reRender, setRender] = useState(false);
    const [rates, setRates] = useState({});
    const [oldPrice, setOldPrice] = useState(0);
    const [markup, setMarkup] = useState(20);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)

    useEffect(async () => {
        db.ref("config").once("value", async snap => {
            item.price = parseFloat(item.price);
            setOldPrice(item.price);
            setMarkup(snap.val()?.config || 20);
            item.price = item.price + item.price * (snap.val()?.config || 20)/100;
            setPrice(item.price);
            let shippingPrice = parseFloat(
                item?.post_fee ||
                    0 + item?.express_fee ||
                    0 + item?.ems_fee ||
                    0
            ) || 0;
            setTotalShipping(shippingPrice);
            item.total_price = parseFloat(item.price) + shippingPrice;
            setAttributes(await getAttributes(item));
        })

        window.scrollTo(0, 0)

        //Translate
        await fetch("https://api.kghype.com:5000/api/translate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                words: {
                    title: item.title
                }
            }),
        })
        .then(data => {return data.json()})
        .then(data => {
            item.title = data.title;
            setRender(!reRender);
        });

        setRates(await Currency.GetRates());
    }, [])

    useEffect(() => {
        if (currentUser) addToBrowseHistory(item, currentUser);
    }, [currentUser]);

    const swap = (arr) => {
        const temp = arr[0]
        arr[0] = arr[1]
        arr[1] = temp
        return arr
    }

    const [buyNowRequest, setBuyNowRequest] = useState(false);
    const [addCartRequest, setAddCartRequest] = useState(false);
    useEffect(async () => {
        if (currentUser && buyNowRequest) {
            await addToCartButton(
                {
                    ...item, 
                    specifications: selectedAttr, 
                    remark: $("#remark-text").val(),
                    quantity: $(".productInfo__qtyInput").val() >= 1 ? $(".productInfo__qtyInput").val() : 1,
                }, 
                false, 
                currentUser
            );
            history.push(PUBLIC_PREFIX + CART_PREFIX);
        } else if (currentUser === 0 && buyNowRequest) {
            $(".productInfo__actions").show();
            $(".productInfo__actions__loading").hide();
            NotifyFail('Please login to buy this product.')
            setTimeout(() => {window.location.href = "/login"}, 3000);
        }
    }, [buyNowRequest, currentUser]);

    useEffect(async () => {
        if (currentUser && addCartRequest) {
            await addToCartButton({...item, specifications: selectedAttr, remark: $("#remark-text").val(), quantity: $(".productInfo__qtyInput").val() >= 1 ? $(".productInfo__qtyInput").val() : 1}, false, currentUser)
            .then(() => {
                NotifySuccess('Product added to cart successfully')
                $(".productInfo__actions").show();
                $(".productInfo__actions__loading").hide();
                setAddCartRequest(false);
            });
        } else if (currentUser === 0 && addCartRequest) {
            NotifyFail('Please login to add this product to cart');
            setTimeout(() => {window.location.href = "/login"}, 3000);
        }
    }, [addCartRequest, currentUser]);

    const [translatedWords, setTranslatedWords] = useState({});
    const changeSelectAttribute = async (attr) => {
        const newSelectedAttr = { ...selectedAttr, ...attr }

        Object.keys(newSelectedAttr).forEach(async key => {
            let raw_word = newSelectedAttr[key]?.at(-1);
            let translatedWords_ = {...translatedWords};
            
            //Translate and show current attr
            if (!Object.keys(translatedWords_).includes(raw_word)) {
                await fetch("https://api.kghype.com:5000/api/translate", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        words: {
                            attrVal: raw_word
                        }
                    }),
                })
                .then(data => {return data.json()})
                .then(data => {
                    translatedWords_[raw_word] = data.attrVal;
                    newSelectedAttr[key][newSelectedAttr[key].length - 1] = data.attrVal;
                    setTranslatedWords(translatedWords_);
                })
                .catch(e => {
                    console.error(e);
                });
            }
        });

        setSelectedAttr(newSelectedAttr);
        const propKeyArr = Object.keys(newSelectedAttr).map(
            (key) => newSelectedAttr[key][0]
        )
        const findSku = item.skus.sku.find(
            (p) =>
                p.properties.split(';').sort().join(';') === propKeyArr.sort().join(';') ||
                p.properties === propKeyArr.join(';') ||
                p.properties === swap(propKeyArr).join(';') ||
                p.properties === propKeyArr.join('')
        )
        if (findSku && findSku?.price) {
            let priceToUpdate = parseFloat(findSku?.price) + parseFloat(findSku?.price) * (markup/100);
            if (!priceToUpdate) priceToUpdate = item.price;
            item.price = priceToUpdate;
            item.total_price = priceToUpdate + totalShipping;

            setPrice(item.price);
            setOldPrice(parseFloat(findSku?.price));

            setInvetory((state) => findSku?.quantity || state);
            item.selected_attribute = newSelectedAttr;
        }
    }

    return (
        <div style={{ width: '100%' }} className="productInfo">
            {item?.seller_info?.shop_name && (
                <header className="productInfo__header">
                    <Typography
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <h6 className="productInfo__shopName">
                            <img
                                className="productInfo__shopLogo"
                                alt="logo"
                                src={shopLogo}
                            />
                            {item?.seller_info?.shop_name}
                        </h6>
                    </Typography>
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <ul
                            style={{
                                padding: '1rem 2rem',
                                backgroundColor: 'black',
                                color: 'white',
                            }}
                        >
                            <li
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>Overall Score</span>
                                <span style={{ margin: '0 1rem' }}>
                                    {item?.seller_info?.score_p}
                                </span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>Delivery Score</span>
                                <span style={{ margin: '0 1rem' }}>
                                    {item?.seller_info?.delivery_score}
                                </span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>Item Score</span>
                                <span style={{ margin: '0 1rem' }}>
                                    {item?.seller_info?.item_score}
                                </span>
                            </li>
                        </ul>
                    </Popover>

                    <a
                        href={item?.seller_info?.zhuy}
                        className="btn btn__primary btn__primary--sm"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            backgroundColor: "#1c1c1c",
                            color: "white",
                            borderRadius: "30px",
                            padding: "4px 10px",
                            marginTop: "0px"
                        }}
                    >
                        Enter shop <ImArrowUpRight2 />
                    </a>
                </header>
            )}

            <h4 className="productInfo__title">
                {item?.title}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={item?.detail_url}
                    className="productInfo__pLink"
                >
                    <FaLink />
                    &nbsp; Product Link
                </a>
            </h4>
            <PriceConverter rates={rates} itemPrice={price} oldPrice={oldPrice} />
            <p className="productInfo__purchaseTime">
                After payment, we will purchase for you from 09:00-18:00
                (UTC+8).
            </p>
            <ShippingInfo shipping={totalShipping} />
            {/* <Sizes item={item} setCurrSizeKey={setCurrSizeKey} /> */}
            {/* <ProductColors item={item} currSizeKey={currSizeKey} /> */}
            {Object.keys(attributes).map((attr, i) => {
                return (
                    <div key={i} className="productInfo__attribute">
                        <h5 className="productInfo__attributeName">{attr}:&nbsp;
                            <span style={{color: "rgb(31,196,93)"}}>
                                {selectedAttr ? 
                                    Object.values(selectedAttr).length != 0 ? 
                                        Object.keys(translatedWords).includes(selectedAttr[attr]?.at(-1)) ? 
                                            translatedWords[selectedAttr[attr]?.at(-1)] : 
                                            selectedAttr[attr]?.at(-1)
                                        : "" 
                                    : ""
                                }
                            </span>
                        </h5>
                        <ul className="productInfo__attributeList">
                            {attributes[attr].map((arr, j) => {
                                if (arr.length === 4) {
                                    const imgUrl = arr[2].includes('https:')
                                        ? arr[2].replace('url:', '')
                                        : arr[2].replace('url:', '')
                                    return (
                                        <li
                                            onClick={() => {
                                                changeSelectAttribute({
                                                    [attr]: arr,
                                                })
                                                setCurrImage(imgUrl)
                                            }}
                                            key={j}
                                            attribute-value={arr[arr.length - 1]}
                                            className={`productInfo__attributeImgBox ${
                                                (selectedAttr[attr]
                                                    ? selectedAttr[attr][0]
                                                    : null) === arr[0]
                                                    ? 'productInfo__attributeImgBox--active'
                                                    : ''
                                            }`}
                                        >
                                            <img
                                                src={imgUrl}
                                                width={50}
                                                height={50}
                                                alt={arr[1]}
                                            />
                                        </li>
                                    )
                                }
                                return (
                                    <li
                                        onClick={() => {
                                            changeSelectAttribute({
                                                [attr]: arr,
                                            })
                                        }}
                                        className={`productInfo__attributeItem ${
                                            (selectedAttr[attr]
                                                ? selectedAttr[attr][0]
                                                : null) === arr[0]
                                                ? 'productInfo__attributeItem--active'
                                                : ''
                                        }`}
                                        key={j}
                                    >
                                        {arr[1].split(':')[1]}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )
            })}
            <div className="remark-container" style={{display: "flex", flexDirection:"column"}}>
                <textarea id="remark-text" style={{resize:"none", padding:"10px", fontFamily: "Raleway", fontSize:"14px"}} placeholder="Remark..."></textarea>
                {/*<button style={{backgroundColor: "rgb(32, 197, 93)", color:"white", width:"fit-content", padding: "4px 10px", marginTop:"10px", borderRadius:"7px"}}>Save</button>*/}
            </div>
            <div className="productInfo__quantity">
                <h3 className="productInfo__qty">Qty:</h3>
                <div className="productInfo__qtyBox">
                    <button
                        disabled={quantity === 0}
                        onClick={() => {
                            if (quantity !== 0) {
                                setQuantity(+quantity - 1)
                            }
                        }}
                        className="productInfo__qtyBtn"
                    >
                        <FaMinus style={{color: "rgb(32,197,93)"}} />
                    </button>
                    <input
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="productInfo__qtyInput"
                        style={{ width: '50px', textAlign:"center" }}
                        type="text"
                    />
                    <button
                        onClick={() => setQuantity(+quantity + 1)}
                        className="productInfo__qtyBtn"
                    >
                        <FaPlus style={{color: "rgb(32,197,93)"}} />
                    </button>
                </div>
                <h5 className="productInfo__inventory">
                    Inventory: {invetory}
                </h5>
            </div>
            <div className="productInfo__actions">
                <button
                    className="btn btn__primary btn__primary--lg product-bottom-button buy-now-button"
                    onClick={async () => {
                        if (currentUser == 0 || currentUser?.payload == 0) {
                            $(".productInfo__actions").show();
                            $(".productInfo__actions__loading").hide();
                            NotifyFail('Please login to buy this product.');
                            setTimeout(() => {window.location.href = "/login"}, 3000);
                        } else {
                            $(".productInfo__actions").hide();
                            $(".productInfo__actions__loading").show();
                            if (
                                Object.keys(selectedAttr).length !==
                                Object.keys(attributes).length
                            ) {
                                NotifyFail('Select an option to proceed')
                                $(".productInfo__actions").show();
                                $(".productInfo__actions__loading").hide();
                                return;
                            }
                            if (currentUser === null) {
                                setBuyNowRequest(true);
                            } else {
                                await addToCartButton(
                                    {
                                        ...item, 
                                        specifications: selectedAttr, 
                                        remark: $("#remark-text").val(), 
                                        quantity: $(".productInfo__qtyInput").val() >= 1 ? $(".productInfo__qtyInput").val() : 1,
                                    }, 
                                    false, 
                                    currentUser
                                ).catch(e => {
                                    $(".productInfo__actions").show();
                                    $(".productInfo__actions__loading").hide();
                                    NotifyFail('Please login to buy this product.')
                                    //setTimeout(() => {window.location.href = "/login"}, 3000);
                                });
                                history.push(PUBLIC_PREFIX + CART_PREFIX);
                            }
                        }
                    }}
                >
                    Buy now
                </button>
                <button
                    className="btn btn__ghost btn__ghost--lg product-bottom-button"
                    onClick={async () => {
                        if (currentUser == 0 || currentUser?.payload == 0) {
                            NotifyFail('Please login to add this product to cart');
                            setTimeout(() => {window.location.href = "/login"}, 3000);
                        } else {
                            $(".productInfo__actions").hide();
                            $(".productInfo__actions__loading").show();
                            if (
                                Object.keys(selectedAttr).length !==
                                Object.keys(attributes).length
                            ) {
                                NotifyFail('Select an option to proceed')
                                $(".productInfo__actions").show();
                                $(".productInfo__actions__loading").hide();
                                return
                            }
                            if (currentUser === null) {
                                setAddCartRequest(true);
                            } else {
                                await addToCartButton({...item, specifications: selectedAttr, remark: $("#remark-text").val(), quantity: $(".productInfo__qtyInput").val() >= 1 ? $(".productInfo__qtyInput").val() : 1}, false, currentUser)
                                .then(() => {
                                    NotifySuccess('Product added to cart successfully')
                                    $(".productInfo__actions").show();
                                    $(".productInfo__actions__loading").hide();
                                })
                                .catch(e => {
                                    NotifyFail('Please login to add this product to cart');
                                    setTimeout(() => {window.location.href = "/login"}, 3000);
                                });
                            }
                        }
                    }}
                >
                    Add to cart
                </button>
            </div>
            <div className="productInfo__actions__loading">
                <Spinner bg="dark" />
            </div>
            <h5 className="productInfo__disclaimer">
                <FaExclamationCircle /> Disclaimer
            </h5>
            <p className="productInfo__disclaimerTxt">
                Displayed products are from third-party platforms. KGHYPE
                provides information searching, purchasing, and overseas
                shipping services. Before purchasing, please note that KGHYPE
                cannot distinguish the quality and/or authenticity.
            </p>
        </div>
    )
}

export default ProductInfo
