import {
    CART_PREFIX,
    PROFILE_PREFIX,
    PUBLIC_PREFIX,
    SEARCH_PREFIX
} from 'configs/app-config'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SharedImage } from '../constants/image-constant'
import ImageWrapper from './image-wrapper'
import SelectCurrency from './select-currency'

const { UserIcon, CartIcon, SearchIcon, Profile } = SharedImage

const MenuStyle = {
    width: '350px',
    paddingTop: '90px',
}

function ToolBar({ updateUserCurrency }) {
    const [state, setState] = useState(0)
    const [ref, setRef] = useState(undefined)

    useEffect(() => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let ref_ = url.searchParams.get("ref");
        setRef(ref_)
    }, []);

    return (
        <Wrapper>
            <SelectCurrency updateUserCurrency={updateUserCurrency} />
            <ToolWrapper>
                <li>
                    <Link to={PUBLIC_PREFIX + SEARCH_PREFIX}>
                        <ImageWrapper
                            style={{ width: '24px' }}
                            src={SearchIcon}
                            alt="search-icon"
                        />
                    </Link>
                </li>
                <li>
                    <Link to={(ref) ? PUBLIC_PREFIX + PROFILE_PREFIX + "?ref=" + ref : PUBLIC_PREFIX + PROFILE_PREFIX}>
                        <ImageWrapper
                            style={{ width: '24px' }}
                            src={UserIcon}
                            alt="login-icon"
                        />
                    </Link>
                </li>
                <li>
                    <Link to={PUBLIC_PREFIX + CART_PREFIX}>
                        <ImageWrapper
                            style={{ width: '24px' }}
                            src={CartIcon}
                            alt="cart-icon"
                        />
                    </Link>
                </li>
            </ToolWrapper>
        </Wrapper>
    )
}

export default ToolBar

const ToolWrapper = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    gap: 10px;
    font-size: 20px;
    margin-left:10px;

    li {
        &:hover { background: #eaeaea; }
        a { height:24px; }
        transition: 200ms;
        height:40px;
        width:40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #f1f3f5;
        padding: 0.6rem 0.8rem;
        border-radius: 100%;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
`
const ProfileWrapper = styled.div`
    max-width: 30px;
    height: auto;
`
