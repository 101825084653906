import Button from '@mui/material/Button';
import Spinner from "components/Spinner";
import { CHECKOUT_PREFIX, PUBLIC_PREFIX } from 'configs/app-config';
import { useEffect, useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Currency } from 'utilities';
import { additionalPayment } from 'utilities/order';
import { sendWithdrawalRequest } from 'utilities/wallet';
import TopUpDialog from './topUpDialog';
import WithdrawalDialog from './withdrawal_dialog';

import { useSelector } from 'react-redux';
import RefundRequestPage from './refund_list';
import TopupRequestPage from './topup_list';
import WithdrawalRequestPage from './withdrawal_list';

export default function BalancePage() {
    const [balance, setBalance] = useState(false)
    const [underpayBalance, setUnderPayBalance] = useState(0)
    const [openTopUp, setOpenTopUpDialog] = useState(false)
    const [openWithdraw, setOpenWithdrawDialog] = useState(false)
    const history = useHistory()
    const [rates, setRates] = useState({});

    let user = useSelector(state => state.user)

    async function setDatas() {
        setBalance(user.dbUser.balance) //USD
        setUnderPayBalance(user.dbUser.underpaid || 0);
    }

    const [userKey, setUserKey] = useState("");
    useEffect(() => {
        (async () => {
            setRates(await Currency.GetRates())
        })()
    }, [])

    useEffect(() => {
        if (user) {
            setUserKey(user.key);
            setDatas();
        }
    }, [user])

    function handleWithdrawal({ amount, description }) {
        sendWithdrawalRequest({ amount, description }).then(() => {
            setOpenWithdrawDialog(false);
            toast.success("Withdraw request successfully sent");
        })
    }

    function handleTopUp({ amount }) {
        history.push(PUBLIC_PREFIX + CHECKOUT_PREFIX, { amount, type: 'TOPUP' })
    }

    const [underpayLoading, setUnderpayLoading] = useState(false);

    return (
        <div className="balancePage">
            <header className="balancePage__header">
                <div className="balancePage__header--top">
                    <div>
                        <h3 className="balancePage__balanceTitle">
                            <span className="balancePage__balanceTitle--title">
                                Balance
                            </span>
                            { balance !== false &&
                                <span className="balancePage__balanceTitle--balance">
                                    {Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", (balance || 0), false))}
                                </span>   
                            }
                            { balance === false &&
                                <Spinner bg="white" />
                            }
                        </h3>
                    </div>
                    <div>
                        <button
                            className="btn balancePage__topUp"
                            style={{color: "white"}}
                            onClick={() => setOpenTopUpDialog(true)}
                        >
                            Top up
                        </button>
                        <span className="balancePage__infoIcon">
                            <FaRegQuestionCircle />
                            <p className="balancePage__infoTxt">
                                Topup means that you can recharge some CNY to
                                your KGHYPE account wallet. Directly using the
                                balance to pay can save you time.
                            </p>
                        </span>
                    </div>
                </div>
                <div className="balancePage__header--bottom" style={{position: "relative"}}>
                    <div>
                        <span className="balancePage__underpaidTitle">
                            Underpaid:
                        </span>
                        <span className="balancePage__underpaid">
                        {
                            Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", (underpayBalance || 0), false))
                        }
                        </span>
                        {(underpayBalance > 0 && !underpayLoading) &&
                            <button onClick={() => {
                                setUnderpayLoading(true);
                                additionalPayment(false, false, userKey) //All of additional payments
                                .then(() => {
                                    setUnderpayLoading(false);
                                    toast.success("Addition has been payed successfully");
                                })
                                .catch((e) => {
                                    setUnderpayLoading(false);
                                    toast.error(e);
                                })
                            }} className="btn btn__ghost btn__ghost--sm">
                                Pay now
                            </button>
                        }
                        { underpayLoading && <Spinner bg="dark" /> }
                    </div>
                    <Button
                        className="balancePage__viewRecord"
                        onClick={() => setOpenWithdrawDialog(true)}
                        style={{fontSize: "13px"}}
                    >
                        Request Withdrawal
                    </Button>
                </div>
            </header>
            <br /><br />
            
            <h1 style={{marginBottom:"10px"}}>Transactions</h1>
            <WithdrawalRequestPage userKey={userKey} />
            <RefundRequestPage userKey={userKey} />
            <TopupRequestPage userKey={userKey} />

            <TopUpDialog
                open={openTopUp}
                onClose={() => setOpenTopUpDialog(false)}
                onSubmit={handleTopUp}
            />
            <WithdrawalDialog
                open={openWithdraw}
                onClose={() => setOpenWithdrawDialog(false)}
                onSubmit={handleWithdrawal}
                balance={balance}
            />
        </div>
    )
}
