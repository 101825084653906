import { Heading } from 'components/heading'
import ImageWrapper from 'components/image-wrapper'
import { SharedImage } from 'constants/image-constant'
import {
    deleteObject, getDownloadURL, ref, uploadBytesResumable
} from 'firebase/storage'
import useLoader from 'hooks/useLoader'
import { useEffect, useRef, useState } from 'react'
import { Trash } from 'react-bootstrap-icons'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { getFileExt } from 'utilities'
import {
    Button, Column, Row
} from '../../../components/ui-components'
import { auth, db, storage } from '../../../firebase'
import countryList from '../signup/countryList'

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import Spinner from 'components/Spinner'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import './profile.scss'

const HeadingStyle = {
    textAlign: 'left',
    margin: '1rem 0',
}

const ButtonStyle = {
    marginTop: '20px',
    width: '60%',
}

const subText = {
    color: '#8E8D8F',
    fontSize: '14px',
}

const { Profile, AccountImage } = SharedImage

function ProfilePicture({ user }) {
    const hiddenFileInput = useRef(null) // a reference to the hidden file input element
    const [imgUrl, setImgUrl] = useState(null)
    const [progresspercent, setProgresspercent] = useState(0)
    const [LoaderWrapper, dataLoader] = useLoader();

    useEffect(() => {
        if (user) {
            let ppf = user.dbUser?.["_profilePicture"];
            if (ppf) {
                if (!ppf.includes("discordapp") && !ppf.includes("googleusercontent")) {
                    getDownloadURL(ref(storage, `files/profile-pics/${ppf}`)).then(url => {
                        dataLoader(() => setImgUrl(url));
                    });
                } else dataLoader(() => setImgUrl(ppf));
            } else dataLoader(() => setImgUrl(Profile));
        }
    }, [user])

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        hiddenFileInput.current.click()
    }

    const handleFileUpload = async (file) => {
        if (!file || !user) return

        // first remove prev picture from both firebase and storage
        const prevProfilePic = user.dbUser._profilePicture
        if (prevProfilePic) {
            const userRef = db.ref(`/users/${user.key}`)
            const fileRef = ref(storage, `files/profile-pics/${prevProfilePic}`)
            await deleteObject(fileRef)
                .then(() => {
                    userRef.update({
                        profilePicture: '',
                        _profilePicture: '',
                    })
                })
                .then((e) => {
                    console.error(e)
                })
        }

        const fileExt = getFileExt(file.name)
        const fileName = user.key + (fileExt ? `.${fileExt}` : '')
        const storageRef = ref(storage, `files/profile-pics/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )
                //add loader gif
                setProgresspercent(progress)
            },
            (error) => {
                alert(error)
            },
            async () => {
                await getDownloadURL(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                        setImgUrl(downloadURL)
                        // update user profile to include the newly uploaded photo
                        const userRef = db.ref(`/users/${user.key}`)
                        let u = { ...user.dbUser }
                        u.profilePicture = downloadURL
                        u._profilePicture = fileName
                        userRef.update(u);
                        toast.success("Profile picture has been changed.");
                    }
                )
            }
        )
    }

    // to handle the user-selected file
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0]

        handleFileUpload(fileUploaded)
    }

    const removeImage = () => {
        let prevProfilePic = user.dbUser._profilePicture
        if (prevProfilePic) {
            const userRef = db.ref(`/users/${user.key}`)
            const fileRef = ref(storage, `files/profile-pics/${prevProfilePic}`)
            deleteObject(fileRef)
                .then(() => {
                    userRef.update({
                        profilePicture: '',
                        _profilePicture: '',
                    })
                    setImgUrl(null)
                })
                .then((e) => {
                    console.error(e)
                })
        }
    }

    return (
        <LoaderWrapper>
            <Row>
                <ImageWrapper
                    src={imgUrl || Profile}
                    style={{
                        justifyContent: 'center',
                        width: '110px',
                        height: '110px',
                        borderRadius: '50%',
                        marginRight: '20px',
                    }}
                    alt="profile"
                />

                <Column mainAxisAlignment="center">
                    <Button
                        text="Change profile Picture"
                        style={{ backgroundColor: '#6804CC' }}
                        onClick={handleClick}
                    />
                    <Button
                        text="Remove"
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: 'solid 1px #C2C1C1',
                            marginTop: '5px',
                        }}
                        Icon={
                            <Trash
                                color="red"
                                style={{
                                    marginRight: '5px',
                                    fontSize: '10px',
                                }}
                            ></Trash>
                        }
                        onClick={removeImage}
                    />
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={hiddenFileInput}
                        onChange={handleChange}
                    />
                </Column>
            </Row>
        </LoaderWrapper>
    )
}

export default function ProfilePage({ user }) {
    let [phoneNum, setPhoneNum] = useState('');
    let [gender, setGender] = useState('');
    let [country, setCountry] = useState('');
    let [dob, setDob] = useState('');
    let [newPassword, setNewPassword] = useState('');
    let [oldPassword, setOldPassword] = useState("");
    let [loading, setLoading] = useState(false);

    const validationPassword = (pass) => {
        return pass.trim() !== '' && pass.length > 6
    };

    useEffect(() => {
        setLoading(true)

        let url_string = window.location.href;
        let url = new URL(url_string);
        let ref = url.searchParams.get("ref");

        console.log(user);

        if (user === 0 || user?.payload === 0) {
            console.log("LOL");
            window.location = ref ? '/login?ref=' + ref : '/login';
        }
        else if (user) {
            const fetchData = async () => {
                try {
                    setPhoneNum(user.dbUser?.phoneNum || '');
                    setGender(user.dbUser?.gender || '');
                    setCountry(user.dbUser?.country || '');
                    setDob(user.dbUser?.dateOfBirth || '');
                } catch (e) {
                    console.log(e);
                }
                setLoading(false);
            }

            fetchData();
        }
    }, [user])

    async function handleSubmit() {
        setLoading(true);

        onAuthStateChanged(auth, async (cur) => {
            if (cur) {
                try {
                    if (validationPassword(newPassword)) {
                        auth.currentUser.updatePassword(newPassword)
                        .then(() => {
                            setLoading(false);
                            toast.success("Saved successfully");
                        })
                        .catch(e => {
                            console.log(e);
                            setLoading(false);
                            toast.error("An error occured");
                        });
                    } else if (newPassword != "") {
                        setLoading(false);
                        toast.error("The password should be longer than 6 characters");
                    }
                    
                    let obj = {};

                    if (phoneNum) obj["phoneNum"] = phoneNum;
                    if (gender) obj["gender"] = gender;
                    if (country) obj["country"] = country;
                    if (dob) obj["dateOfBirth"] = dob;

                    if (Object.values(obj).length > 0) {
                        user.ref().update(obj).then(() => toast.success("Saved successfully"));
                        setLoading(false);
                    }

                } catch (e) {
                    console.error(e);
                    toast.error("Password is wrong");
                    setLoading(false);
                }
            }
        })
    }

    return (
        <InputFormWrapper>
            <Heading style={HeadingStyle}>Profile Setting</Heading>
            {loading ? <Spinner bg="dark" /> : null}

            <div className="profile-edit-section">
                <Row style={{ display: loading ? 'none' : 'flex' }}>
                    <Column style={{ flex: '50%' }} crossAxisAlignment="stretch">
                        <UserInfoText>Edit Profile Info</UserInfoText>
                        <ProfilePicture user={user}></ProfilePicture>
                        <br />
                        <p style={subText}>
                            Recommended Photo size should be 256 x 256px
                        </p>
                        <br />
                        <br />
                        <TextField
                            type="tel"
                            label="Phone number"
                            value={phoneNum}
                            onChange={(e) => setPhoneNum(e.target.value)}
                            sx={{
                                width: {
                                    md: '60%',
                                    xs: '100%',
                                },
                            }}
                        />
                        <br />
                        <FormControl>
                            <InputLabel id="gender-select">Gender</InputLabel>
                            <Select
                                onChange={(e) => setGender(e.target.value)}
                                value={gender}
                                id="gender-selection"
                                labelId="gender-select"
                                label="Gender"
                                sx={{
                                    width: {
                                        md: '60%',
                                        xs: '100%',
                                    },
                                }}
                            >
                                <MenuItem style={{ fontSize: "19px" }} value="">Rather not specify</MenuItem>
                                <MenuItem style={{ fontSize: "19px" }} value="male">Male</MenuItem>
                                <MenuItem style={{ fontSize: "19px" }} value="female">Female</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <FormControl fullWidth>
                            <InputLabel id="country-select">Country</InputLabel>
                            <Select
                                sx={{
                                    width: {
                                        md: '60%',
                                        xs: '100%',
                                    },
                                }}
                                fontSize="17px"
                                labelId="country-select"
                                id="country"
                                value={country}
                                label="country"
                                onChange={(e) => setCountry(e.target.value)}
                            >
                                {countryList.map((ct) => (
                                    <MenuItem style={{ fontSize: "19px" }} key={ct} value={ct}>
                                        {ct}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <br />
                        <TextField
                            sx={{
                                width: {
                                    md: '60%',
                                    xs: '100%',
                                },
                            }}
                            InputLabelProps={{ shrink: true }}
                            label="Date of birth"
                            type="date"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                            placeholder=""
                        />
                        <br />
                        <TextField
                            type='password'
                            label="Old Password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            sx={{
                                width: {
                                    md: '60%',
                                    xs: '100%',
                                },
                            }}
                        />/
                        <br />
                        <TextField
                            type='password'
                            label="New password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            error={!validationPassword}
                            sx={{
                                width: {
                                    md: '60%',
                                    xs: '100%',
                                },
                            }}
                        />
                        <br />
                        <Button
                            text="Save"
                            style={ButtonStyle}
                            onClick={async (e) => {
                                handleSubmit()
                            }}
                        />
                        <br />
                    </Column>

                    <ImageContainer style={{ flex: '50%' }}>
                        <img
                            src={AccountImage}
                            style={{
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                marginRight: '20px',
                            }}
                            alt="account"
                        />
                    </ImageContainer>
                </Row>
            </div>
        </InputFormWrapper>
    )
}
const ImageContainer = styled.div`
  @media (max-width:800px) {
    display none;
  }
`
const UserInfoText = styled.h5`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding: 0px;
    margin-top: 35px;
    margin-bottom: 5px;
    padding: 0px;
    @media screen and (max-width: 800px) {
        text-align: center;
    }
`
const InputFormWrapper = styled.div`
    input {
        box-shadow: unset;
        max-width: 100%;
    }
`
