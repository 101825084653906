const likeData: { image: string, name: string }[] = [
    {
        image: "/cooperations-1.png",
        name: "Product (order) interactive cooperation",
    },
    {
        image: "/cooperations-2.png",
        name: "Payment (order) interactive cooperation",
    },
    {
        image: "/cooperations-3.png",
        name: "After-sales (order) interactive cooperation",
    },
    {
        image: "/cooperations-4.png",
        name: "Warehousing (order) interactive cooperation",
    },
    {
        image: "/cooperations-5.png",
        name: "Logistics (order) interactive cooperation",
    },
    {
        image: "/cooperations-6.png",
        name: "BuckyDrop plugin",
    },
]

export default likeData