import {
    ADDRESS_PREFIX, AFFILIATE_PREFIX, FAVORITE_PREFIX, FORWARDED_PREFIX, HISTORYCART_PREFIX,
    HISTORY_PREFIX, PARCEL_PREFIX, PROFILE_PREFIX, PUBLIC_PREFIX,
    WALLET_PREFIX, WAREHOUSE_PREFIX
} from 'configs/app-config';
import { auth } from 'firebase.js';
import $ from 'jquery';
import { FaChevronDown } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import Cookie from 'helpers/cookie';

const cookie = new Cookie();

const ProfileSidebar = () => {
    const { pathname } = useLocation()
    const isActive = (path) => pathname.endsWith(path)

    return (
        <ul className="profileSidebar">
            <li className="profileSidebar__item">
                <Link
                    className={`profileSidebar__link ${
                        isActive('profile')
                            ? 'profileSidebar__link--active'
                            : ''
                    }`}
                    to={PUBLIC_PREFIX + PROFILE_PREFIX}
                >
                    My Account
                </Link>
            </li>

            <li className="profileSidebar__item profileSidebar__link " onClick={() => {
                if ($(".order-dropdown").is(":hidden")) {
                    $(".order-dropdown").show();
                    $(".order-dropdown").animate({
                        height: "+100px"
                    });
                } else {
                    $(".order-dropdown").animate({
                        height: "-100px"
                    }, function() {
                        $(".order-dropdown").hide();
                    });
                }
                }}>
                My Orders <FaChevronDown className="favoritePage__icon" />
                <ul className="order-dropdown" style={{height: "0px", display: "none", padding: "10px 0 0 0"}}>
                    <li className="profileSidebar__item">
                        <Link
                            className={`profileSidebar__link ${
                                isActive(HISTORY_PREFIX)
                                    ? 'profileSidebar__link--active'
                                    : ''
                            }`}
                            to={PUBLIC_PREFIX + HISTORY_PREFIX}
                        >
                            Shopping Orders
                        </Link>
                    </li>
                    <li className="profileSidebar__item">
                        <Link
                            className={`profileSidebar__link ${
                                isActive(FORWARDED_PREFIX)
                                    ? 'profileSidebar__link--active'
                                    : ''
                            }`}
                            to={PUBLIC_PREFIX + FORWARDED_PREFIX}
                        >
                            Forwarding Orders
                        </Link>
                    </li>
                </ul>
            </li>

            <li className="profileSidebar__item profileSidebar__link " onClick={() => {
                    if ($(".shipping-dropdown_").is(":hidden")) {
                        $(".shipping-dropdown_").show();
                        $(".shipping-dropdown_").animate({
                            height: "+150px"
                        });
                    } else {
                        $(".shipping-dropdown_").animate({
                            height: "-150px"
                        }, function() {
                            $(".shipping-dropdown_").hide();
                        });
                    }
                }}>
                Shipping <FaChevronDown className="favoritePage__icon" />
                <ul className="shipping-dropdown_" style={{height: "0px", display: "none", padding: "10px 0 0 0"}}>
                    <li className="profileSidebar__item">
                        <Link
                            className={`profileSidebar__link ${
                                isActive(WAREHOUSE_PREFIX)
                                    ? 'profileSidebar__link--active'
                                    : ''
                            }`}
                            to={PUBLIC_PREFIX + WAREHOUSE_PREFIX}
                        >
                            Warehouse
                        </Link>
                    </li>
                    <li className="profileSidebar__item">
                        <Link
                            className={`profileSidebar__link ${
                                isActive(PARCEL_PREFIX)
                                    ? 'profileSidebar__link--active'
                                    : ''
                            }`}
                            to={PUBLIC_PREFIX + PARCEL_PREFIX}
                        >
                            My Parcels
                        </Link>
                    </li>
                    <li className="profileSidebar__item">
                        <Link
                            className={`profileSidebar__link ${
                                isActive(ADDRESS_PREFIX)
                                    ? 'profileSidebar__link--active'
                                    : ''
                            }`}
                            to={PUBLIC_PREFIX + ADDRESS_PREFIX}
                        >
                            Address Book
                        </Link>
                    </li>
                </ul>
            </li>

            {/*<li className="profileSidebar__item">
                <Link
                    className={`profileSidebar__link ${
                        isActive(CHAT_PREFIX)
                            ? 'profileSidebar__link--active'
                            : ''
                    }`}
                    to={PUBLIC_PREFIX + CHAT_PREFIX}
                >
                    Chats
                </Link>
            </li>*/}

            <li className="profileSidebar__item">
                <Link
                    className={`profileSidebar__link ${
                        isActive(FAVORITE_PREFIX)
                            ? 'profileSidebar__link--active'
                            : ''
                    }`}
                    to={PUBLIC_PREFIX + FAVORITE_PREFIX}
                >
                    Favorites
                </Link>
            </li>

            <li className="profileSidebar__item">
                <Link
                    className={`profileSidebar__link ${
                        isActive(HISTORYCART_PREFIX)
                            ? 'profileSidebar__link--active'
                            : ''
                    }`}
                    to={PUBLIC_PREFIX + HISTORYCART_PREFIX}
                >
                    Browse History
                </Link>
            </li>

            <li className="profileSidebar__item profileSidebar__link" onClick={() => {
                    if ($(".wallet-dropdown").is(":hidden")) {
                        $(".wallet-dropdown").show();
                        $(".wallet-dropdown").animate({
                            height: "+100px"
                        });
                    } else {
                        $(".wallet-dropdown").animate({
                            height: "-100px"
                        }, function() {
                            $(".wallet-dropdown").hide();
                        });
                    }
                }}>
                Wallet <FaChevronDown className="favoritePage__icon" />
                <ul className="wallet-dropdown" style={{height: "0px", display: "none", padding: "10px 0 0 0"}}>
                    <li className="profileSidebar__item">
                        <Link
                            className={`profileSidebar__link ${
                                isActive(WALLET_PREFIX)
                                    ? 'profileSidebar__link--active'
                                    : ''
                            }`}
                            to={PUBLIC_PREFIX + WALLET_PREFIX}
                        >
                            Account Balance
                        </Link>
                    </li>
                    <li className="profileSidebar__item">
                        <Link
                            className="profileSidebar__link"
                            to={PUBLIC_PREFIX + WALLET_PREFIX + "?coupon"}
                        >
                            Coupons
                        </Link>
                    </li>
                </ul>
            </li>

            <li className="profileSidebar__item">
                <Link
                    className={`profileSidebar__link ${
                        isActive(AFFILIATE_PREFIX)
                            ? 'profileSidebar__link--active'
                            : ''
                    }`}
                    to={PUBLIC_PREFIX + AFFILIATE_PREFIX}
                >
                    Affiliates
                </Link>
            </li>

            <li>
                <button
                    style={{
                        backgroundColor: '#fff',
                        border: 'none',
                        padding: '1rem 3rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'black',
                        fontSize: '1.8rem',
                        position: 'relative',
                        //'&:hover': { cursor: 'pointer' },
                        cursor: 'pointer'
                    }}
                    onClick={async () => {
                        await auth.signOut();
                        cookie.eraseCookie("s");
                        window.location.reload();
                    }}
                >
                    Logout
                </button>
            </li>
        </ul>
    )
}

export default ProfileSidebar
