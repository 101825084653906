import { Container } from 'components/container';
import { Heading } from 'components/heading';
import AffiliateChart from 'components/affiliate-chart';
import ChartStats from 'components/chart-stats';
import Modal from 'components/modal';
import Spinner from "components/Spinner";
import { SharedImage } from 'constants/image-constant';
import $ from 'jquery';
import QRCode from "qrcode.react";
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FaChevronDown, FaChevronUp, FaDownload } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { Currency } from 'utilities';
import { sendWithdrawalRequest } from 'utilities/wallet';
import qrcode from '../../../assets/icons/qrcode 1.png';
import textcopy from '../../../assets/icons/text-copy 1.png';
import { db } from 'firebase.js';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'store/reducers/userReducer/userReducer';
import './affiliate.scss';
const { Logo } = SharedImage;

const HeadingStyle = {
    textAlign: 'left',
    paddingLeft: '20px',
    marginBottom: "20px"
}

const circleIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <circle cx="10" cy="10" r="9" stroke="#C5C7CD" strokeWidth="2" />
    </svg>
)

const checked = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <g clipPath="url(#clip0_4_11154)">
            <circle cx="10" cy="10" r="10" fill="#3751FF" />
            <path
                d="M5 11L7.92929 13.9293C7.96834 13.9683 8.03166 13.9683 8.07071 13.9293L15 7"
                stroke="white"
                strokeWidth="1.7"
                strokeLinecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_4_11154">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default function AffiliatePage() {

    const dispatch = useDispatch();

    let user = useSelector(state => state.user);

    let affiliatesRef = db.ref('affiliates');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [chartDuration, setChartDuration] = useState("30-days");
    const [curMonth, setMonth] = useState(0);
    const [affiliateDatas, setAffiliateDatas] = useState({
        user_id: "",
        id: "",
        clicks: 0,
        signups: 0,
        sales: 0,
        comissionPercent: 10,
        balance: 0,
        date: getDate(),
        earnings: {
            total: 0,
            pending: 0,
            last_payment: 0
        },
        history: {
            clicks: "", //Object
            signups: "", //Object
            earnings: "", //Object
            payments: "", //Object
            sales: "" //Object
        },
        users: "" //Object
    })
    const [rates, setRates] = useState({})

    useEffect(() => {
        (async () => {
            setRates(await Currency.GetRates())
        })();

        var currentDate = new Date();
        setMonth(parseInt(currentDate.getMonth() + 1));
    }, [])

    function getDate() {
        var currentDate = new Date();
        var month = currentDate.getMonth() + 1;
        if (month < 10) month = "0" + month;
        var dateOfMonth = currentDate.getDate();
        if (dateOfMonth < 10) dateOfMonth = "0" + dateOfMonth;
        var year = currentDate.getFullYear();
        var formattedDate = dateOfMonth + "/" + month + "/" + year;
        return formattedDate;
    }

    const dataFromFirebase = async () => {
        let userKey = user.key;
        let afKey = generateLink();
        let afData = {
            user_id: userKey,
            id: afKey,
            clicks: 0,
            signups: 0,
            sales: 0,
            comissionPercent: 5,
            balance: 0,
            date: getDate(),
            earnings: {
                total: 0,
                pending: 0,
                last_payment: 0
            },
            history: {
                clicks: "", //Object
                signups: "", //Object
                earnings: "", //Object
                payments: "", //Object
                sales: "" //Object
            },
            users: "", //Object
            commissions: []
        };

        db.ref('affiliates').once("value", async snapshot => {
            let affiliateList = snapshot.val();
            if (!affiliateList) {
                let newAffiliateList = {};
                newAffiliateList[afKey] = afData;
                setLink(afKey)
                await affiliatesRef.update(newAffiliateList);
            } else {
                let affValues = Object.values(affiliateList);
                let affFound = false;
                for (let i = 0; i < affValues.length; i++) {
                    if (affValues[i]["user_id"] === userKey) {
                        affFound = true;
                        setAffiliateDatas(affValues[i]);
                        setLink(Object.keys(affiliateList)[i]);
                        setDataLoaded(true);
                        break;
                    }

                    if (i == affValues.length - 1 && !affFound) {
                        affiliateList[afKey] = afData;
                        setLink(afKey)
                        affiliatesRef.update(affiliateList);
                        setDataLoaded(true);
                    }
                }
            }
        });
    }

    const [percentages, setPercentages] = useState({
        "signups": "0.00%",
        "sales": "0.00%",
    });

    useEffect(() => {
        if (user) {
            (async() => {
                await dataFromFirebase();
            })();

            //Percentage calculation
            let p = percentages;

            //Convert all history data to month hash table
            let signups = monthHashTable(affiliateDatas["history"]["signups"]),
                sales = monthHashTable(affiliateDatas["history"]["sales"]),
                clicks = monthHashTable(affiliateDatas["history"]["clicks"]);

            let prevMonth = curMonth === 1 ? 12 : curMonth - 1;

            if (signups[curMonth] > 0 && signups[prevMonth] == 0) p["signups"] = "100.00%";
            else if (signups[prevMonth] > 0 && signups[curMonth] == 0) p["signups"] = "-100.00%";
            else if (signups[prevMonth] == 0 && signups[curMonth] == 0) p["signups"] = "0.00%";
            else if (signups[prevMonth] && signups[curMonth]) p["signups"] = (signups[curMonth] - signups[prevMonth]) / Math.abs(signups[prevMonth]) * 100 + "%";
            else p["signups"] = "0.00%";

            if (sales[curMonth] > 0 && sales[prevMonth] == 0) p["sales"] = "100.00%";
            else if (sales[prevMonth] > 0 && sales[curMonth] == 0) p["sales"] = "-100.00%";
            else if (sales[prevMonth] == 0 && sales[curMonth] == 0) p["sales"] = "0.00%";
            else if (sales[prevMonth] && sales[curMonth]) p["sales"] = (sales[curMonth] - sales[prevMonth]) / Math.abs(sales[prevMonth]) * 100 + "%";
            else p["sales"] = "0.00%";

            if (clicks[curMonth] > 0 && clicks[prevMonth] == 0) p["clicks"] = "100.00%";
            else if (clicks[prevMonth] > 0 && clicks[curMonth] == 0) p["clicks"] = "-100.00%";
            else if (clicks[prevMonth] == 0 && clicks[curMonth] == 0) p["clicks"] = "0.00%";
            else if (clicks[prevMonth] && clicks[curMonth]) p["clicks"] = (clicks[curMonth] - clicks[prevMonth]) / Math.abs(clicks[prevMonth]) * 100 + "%";
            else p["clicks"] = "0.00%";

            setPercentages(p);
        }
    }, [user])

    function monthHashTable(datasoup) {
        let obj = {};
        for (let i = 0; i < datasoup.length; i++) {
            let month = parseInt(new Date(datasoup[i]).toLocaleDateString("tr-TR").split(".")[1]);
            if (obj[month]) {
                obj[month] += 1;
            } else {
                obj[month] = 1;
            }
        }
        if (!obj[curMonth]) obj[curMonth] = 0;
        return obj;
    }


    const [affiliateLink, setAffiliateLink] = useState("");
    const [id, setID] = useState("");
    const [newID, setNewID] = useState("");

    function generateLink() {
        const length = 12;
        let result = '';
        let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    function setLink(id) {
        //console.log(window.location);
        let link = window.location.origin+"/signup?ref="+id;
        setID(id);
        setAffiliateLink(link);
        getPayments(id);
    }

    function copyAffiliateLink() {
        navigator.clipboard.writeText(affiliateLink);
        toast.success("Link has been copied successfully!");
    }

    const [qrModal, isQrOpened] = useState(false);
    function generateQR() { isQrOpened(true); }

    const [formModal, setFormModal] = useState(false);

    $(".modal").on("click", function(e) {
        let target = $(e.target);
        if (!target.closest(".modal-content").length) {
            isQrOpened(false);
            setFormModal(false);
            showCommissionModal(false);
        }
    });

    const [paymentInfo, setPaymentInfo] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState("paypal");
    function paymentDropdown(t) {
        setPaymentMethod(t);
        switch(t) {
            case "paypal":
                setPaymentInfo(<input id="paypal-method-entry" prefix="paypal-email" style={withdrawInput} type="email" placeholder="Your Paypal E-Mail" />);
                break;
            case "cashapp":
                setPaymentInfo(<input id="cashapp-method-entry" prefix="cashtag" style={withdrawInput} type="text" placeholder="Your $Cashtag" />);
                break;
            case "bitcoin":
                setPaymentInfo(<input id="bitcoin-method-entry" prefix="btc-address" style={withdrawInput} type="text" placeholder="Your Bitcoin Address" />);
                break;
            case "venmo":
                setPaymentInfo(<>
                    <input id="venmo-method-entry" prefix="venmo-username" style={withdrawInput} type="text" placeholder="Venmo Username" />
                    <input id="venmo-method-phone-entry" prefix="last-4-digit-of-phone-number" style={withdrawInput} type="text" placeholder="Last 4 digits of your phone number" />
                </>);
                break;
        }
    }

    const [withdrawErrorMessage, setWithdrawErrorMessage] = useState("");
    //const [amount, setAmount] = useState(0);
    function withdraw() {
        let desc = "";
        let amount = $("#amount-to-withdraw").val();
        switch(paymentMethod) {
            case "paypal":
                desc = $("#paypal-method-entry").attr("prefix") + ": " + $("#paypal-method-entry").val();
                break;
            case "cashapp":
                desc = $("#cashapp-method-entry").attr("prefix") + ": " + $("#cashapp-method-entry").val();
                break;
            case "bitcoin":
                desc = $("#bitcoin-method-entry").attr("prefix") + ": " + $("#bitcoin-method-entry").val();
                break;
            case "venmo":
                desc = $("#venmo-method-entry").attr("prefix") + ": " + $("#venmo-method-entry").val() + ", " 
                     + $("#venmo-method-phone-entry").attr("prefix") + ": " + $("#venmo-method-phone-entry").val();
                break;
        }
        if (+amount <= affiliateDatas["balance"]) {
            sendWithdrawalRequest({ amount: parseFloat(amount), description: desc, affiliate: {id: id}, userInfo: user })
            .then(() => {
                setFormModal(false);
                affiliatesRef.once("value", snapshot => {
                    let datas = snapshot.val();
                    datas[affiliateDatas["id"]]["earnings"]["pending"] += +amount;
                    affiliatesRef.update(datas);
                    toast.success("Withdraw request successfully sent");
                });
            })
            .catch(e => {
                setWithdrawErrorMessage(e);
            });
        } else if (+amount < 10) setWithdrawErrorMessage("Minimum withdraw amount is 10$");
        else toast.error("Insufficient balance");
    }

    const [latestPayments, setLatestPayments] = useState(null);
    function getPayments(id) {
        db.ref("affiliate-withdraw-requests").once("value", async snapshot => {
            let vals = snapshot.val();
            let arr = [];
            for (let i = 0; i < Object.keys(vals).length; i++) {
                let val = Object.keys(vals)[i];
                let data_ = vals[val];
                if (val.split("-")[1] === id) {
                    arr.push({
                        status: data_.status,
                        date: data_.date,
                        amount: data_.amount,
                        id: data_.id
                    });
                }
            }

            for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < arr.length; j++) {
                    if (new Date(arr[i]["date"]).getTime() > new Date(arr[j]["date"]).getTime()) {
                        let clone = {...arr[i]};
                        arr[i] = arr[j];
                        arr[j] = clone;
                    }
                }
            }

            setLatestPayments(arr);
        });
    }
    
    function setChartDataTypeFunc(e, t) {
        setChartDataType(t);

        $(".affiliate-box").removeClass("active-box");
        $(e.currentTarget).addClass("active-box");
    }

    const download = () => {
        const canvas = document.getElementById("qr-canvas");
        const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    function censorName(name) {
        let arr = name.split(" ");
        let name_ = arr[0][0] , surname_ = arr[1][0];

        for (let i = 1; i < arr[0].length; i++) {
            name_ += "*";
        }
        for (let i = 1; i < arr[1].length; i++) {
            surname_ += "*";
        }

        return name_ + " " + surname_;
    }

    const [newAffiliateLink, setNewAffiliateLink] = useState(null);
    function affiliateLinkChanged(e) {
        let id = e.target.value;
        let newLink = window.location.origin+"/signup?ref=" + id;
        setNewAffiliateLink(newLink);
        setNewID(id);
    }

    function saveNewLink() {
        let allowedChars = /^[a-zA-Z ]*$/;
        if (allowedChars.test(newID)) {
            db.ref("affiliates").once("value", snapshot => {
                let affiliates = snapshot.val();
                if (!Object.keys(affiliates).includes(newID)) {
                    affiliates[id]["id"] = newID;
                    affiliates[newID] = affiliates[id];
                    affiliates[id] = null;
                    db.ref("affiliates").update(affiliates).then(() => {
                        dispatch(setUser({...user, affiliate: affiliates[newID]}));
                        toast.success("Affiliate link successfully updated");
                    });
                } else toast.error("Affiliate link already exists");
            });
        } else toast.error("Only text is allowed");
    }

    const [commissionModal, showCommissionModal] = useState(false);

    const [chartDataType, setChartDataType] = useState([false, false, false, false, false]);

    if (!dataLoaded) return <Spinner bg="dark" />;
    return (
        <>
            <Modal display={{display: qrModal ? "flex" : "none"}}>
                <QRCode
                    id="qr-canvas"
                    size={510}
                    value={affiliateLink}
                />
                <FaDownload onClick={() => download(document.querySelector(".qr-canvas"))} style={{position:"fixed", left: "10px", bottom: "10px", cursor:"pointer"}} fontSize="80px" />
            </Modal>

            <Modal display={{display: formModal ? "flex" : "none"}}>
                <img style={withdrawLogo} src={Logo} alt="Logo" />
                <h1 style={withdrawText}>Withdraw</h1>
                <div style={{display:"flex", height:"100%", padding: "0 90px", alignItems:"center", justifyContent:"center", flexDirection: "column"}}>
                    <p style={withdrawAvailableBalance}>Your available balance: ${(affiliateDatas["balance"] - affiliateDatas["earnings"]["pending"]).toFixed(2)}</p>
                    <input id="amount-to-withdraw" style={withdrawInput} type="number" placeholder="Amount to Withdraw ($)" />
                    <small style={{fontSize: "13px"}}>Minimum withdraw amount is <span style={{color: "blue"}}>10$</span></small>
                    <select style={withdrawPaymentMethods} onChange={(event) => {paymentDropdown(event.target.value)}}>
                        <option value="cashapp">CashApp</option>
                        <option selected value="paypal">Paypal</option>
                        <option value="bitcoin">Bitcoin</option>
                        <option value="venmo">Venmo</option>
                    </select>
                    {paymentInfo ? paymentInfo : <input id="paypal-method-entry" prefix="paypal-email" style={withdrawInput} type="email" placeholder="Your Paypal E-Mail" />}
                    <p style={{color: "red", fontSize: "15px", margin: "10px 0 -10px 0"}}>{withdrawErrorMessage}</p>
                    <button style={withdrawButton} onClick={withdraw}>Withdraw</button>
                </div>
            </Modal>

            <Modal display={{display: commissionModal ? "flex" : "none"}} style={{width: "80%", height: "90vh"}}>
                <DataTable
                    title = "Commissions"
                    columns={[
                        {
                            name: "Date",
                            id: "date",
                            sortable: true,
                            selector: (d) => d.date,
                            cell: (d) => <div>{new Date(d.date).toLocaleString("en-EN")}</div>
                        },
                        {
                            name: "Name",
                            cell: (d) => <div>{censorName(d.name + " " + d.surname)}</div>
                        },
                        {
                            name: "Commission",
                            cell: (d) => <div>{parseFloat(d.fee).toFixed(2)}$</div>
                        }
                    ]}
                    data={affiliateDatas?.commissions}
                    noHeader
                    defaultSortFieldId="date"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    striped
                    selectableRows
                />
            </Modal>

            <Container>
                <Heading style={HeadingStyle}>Affiliate Program</Heading>
                <div>
                    <iframe width="675" height="390" src="https://www.youtube.com/embed/h85KI8NRIJE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <PromotionLinkWrapper>
                    <h2>My Promotion Link</h2>
                    <PromotionLink>
                        {affiliateLink &&
                            <div className="input-wrapper">
                                <span style={{fontSize: "20px"}}>{window.location.origin+"/signup?ref="}</span>
                                <input
                                    style={{marginRight: "10px", width: '100%', maxWidth: '950px', padding: "5px 10px", outline: "none", fontSize: "20px" }} 
                                    type="text" 
                                    defaultValue={id}
                                    onChange={(event) => affiliateLinkChanged(event)}
                                />
                                {(newAffiliateLink !== affiliateLink && newAffiliateLink !== null) &&
                                    <input
                                        style={{padding: "5px 10px", backgroundColor: "#072a48", border:"none", color: "white", fontSize: "20px", cursor: "pointer"}}
                                        type="button"
                                        defaultValue="Save"
                                        onClick={saveNewLink}
                                    />
                                }
                            </div>
                        }
                        <div>
                            <img onClick={copyAffiliateLink} src={textcopy} alt="" />
                            <img onClick={generateQR} src={qrcode} alt="" />
                        </div>
                    </PromotionLink>
                </PromotionLinkWrapper>
                <p style={{fontSize: "17px", margin: "10px 0 -10px 0"}}>Join our <a href="https://discord.gg/Y2ArZ2haR6" target="_blank" style={{color: "#7000e5"}}>Discord</a> which is only for the affiliate users</p>
                <RowSort>
                    <select onChange={(event) => {setChartDuration(event.target.value)}} style={{
                        background:"#ffffff",
                        border:"1px solid #858585",
                        borderRadius:"5px",
                        padding:"1rem"
                    }}>
                        <option value="1-year">
                            This year
                        </option>
                        <option value="6-months">
                            Last 6 months
                        </option>
                        <option value="3-months">
                            Last 3 months
                        </option>
                        <option selected value="30-days">
                            Last 30 days
                        </option>

                    </select>
                    {/*<p>Stats interval: 27th Aug 2023 - 1st Sep 2023 </p>*/}
                </RowSort>
                <AffiliateStats>
                    <Stat className="affiliate-box" onClick={(event) => {setChartDataTypeFunc(event, [false, true, true, true, true])}}>
                        <h2>CLICKS</h2> {/* Clicks on ref link */}
                        <h3>{affiliateDatas["clicks"]}</h3>
                        <div className="is-down">
                            {
                                (percentages["clicks"][0] == "-")
                                    ? <span>{percentages["clicks"]}<FaChevronDown /></span>
                                    : <span style={{color: "green"}}>{percentages["clicks"]}<FaChevronUp /></span>
                            }
                        </div>
                    </Stat>
                    <Stat className="affiliate-box" onClick={(event) => {setChartDataTypeFunc(event, [true, false, true, true, true])}}>
                        <h2>USER SIGNUPS</h2> {/* Count of users that signed up with Ref link */}
                        <h3>{affiliateDatas["signups"]}</h3>
                        <div className="is-down">
                            {
                                (percentages["signups"][0] == "-")
                                    ? <span>{percentages["signups"]}<FaChevronDown /></span>
                                    : <span style={{color: "green"}}>{percentages["signups"]}<FaChevronUp /></span>
                            }
                        </div>
                    </Stat>
                    <Stat className="affiliate-box" onClick={(event) => {setChartDataTypeFunc(event, [true, true, false, true, true])}}>
                        <h2>SALES AMOUNT</h2> {/* Amount of products affiliates bought */}
                        <h3>{affiliateDatas["sales"]}</h3>
                        <div className="is-down">
                            {
                                (percentages["sales"][0] == "-")
                                    ? <span>{percentages["sales"]}<FaChevronDown /></span>
                                    : <span style={{color: "green"}}>{percentages["sales"]}<FaChevronUp /></span>
                            }
                        </div>
                    </Stat>
                    <Stat className="affiliate-box" onClick={(event) => {setChartDataTypeFunc(event, [true, true, true, false, true])}}>
                        <h2>PAYABLE AMOUNT</h2> {/* Total money - Pending money */}
                        <h3>{Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", affiliateDatas["balance"] - affiliateDatas["earnings"]["pending"], false))}</h3>
                        <div className="is-nuetral">
                            {/*<span>5 Commissions</span> Total affiliate count */}
                        </div>
                    </Stat>
                    <Stat>
                        <h2>PENDING AMOUNT</h2> {/* Pending money */}
                        <h3>{Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", affiliateDatas["earnings"]["pending"], false))}</h3>
                        <div className="is-nuetral">
                            {/*<span>5 Commissions</span> Total affiliate count */}
                        </div>
                    </Stat>
                    <Stat>
                        <h2>AMOUNT LAST 30 DAYS</h2> {/* Money earned last 30 days */}
                        <h3>{Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", 0, false))}</h3>
                        <div className="is-nuetral">
                            {/*<span>0 Commissions</span>  Affiliate count last 30 days */}
                        </div>
                    </Stat>
                    <Stat className="affiliate-box" onClick={(event) => {setChartDataTypeFunc(event, [true, true, true, true, false])}}>
                        <h2>LAST PAYMENT</h2> {/* Last payment */}
                        <h3>{Currency.GetPrice(rates, Currency.ConvertToCNY(rates, "USD", affiliateDatas["earnings"]["last_payment"], false))}</h3>
                    </Stat>
                </AffiliateStats>
                {dataLoaded && <ChartContainer>
                    <div className="flex-contain">
                        <div className="chart">
                            <h2>Commissions</h2>
                            <AffiliateChart chartDataType={chartDataType} duration={chartDuration} history={affiliateDatas["history"]} />
                        </div>
                        <ChartStats signups={affiliateDatas["signups"]} revenue={affiliateDatas["earnings"]["total"]} clicks={affiliateDatas["clicks"]} />
                    </div>
                </ChartContainer>}
                <LatestContainer>
                    <Card>
                        <div className="top-bar">
                            <div>
                                <h3>Latest commissions</h3>
                            </div>
                            <a style={{cursor: "pointer", color:"#0d6efd"}} onClick={() => { showCommissionModal(true) }}>View details</a>
                        </div>
                        <div className="card-content commissions-container">
                            { affiliateDatas?.commissions?.map((data, index) => {
                                return (
                                    <div key={index} className={index < 4 ? "card-row" : "card-row hide-details"}>
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ marginLeft: '0.6rem' }}>
                                                {data.message}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </Card>
                    <Card>
                        <div className="top-bar">
                            <div>
                                <h3>Latest payments</h3>
                            </div>
                            <a style={{
                                color:"#0d6efd",
                                cursor:"pointer"
                            }} onClick={() => {setFormModal(true)}}>Request withdrawal</a>
                        </div>
                        <div className="card-content latest-payments-container">
                            {latestPayments === null &&
                                <Spinner bg="dark" />
                            }
                            {latestPayments && latestPayments?.map((e, i) => {
                                return (
                                    <div className={i < 4 ? "card-row" : "card-row hide-details"} key={e["id"]}>
                                        <div style={{ display: 'flex' }}>
                                            {e["status"].toLowerCase() == "pending" && circleIcon}
                                            {e["status"].toLowerCase() == "completed" && checked}
                                            <span style={{ marginLeft: '0.6rem' }}>
                                                {e["amount"]}$ on {new Date(e["date"]).toLocaleString("en-EN")}
                                            </span>
                                        </div>
                                        <StatusPill>
                                            <a className={"status-"+e["status"]} href="#">{e["status"]}</a>
                                        </StatusPill>
                                    </div>
                                )
                            })}
                            {(latestPayments && latestPayments?.length === 0) &&
                                <p style={{textAlign:"center"}}>There are no previous payments</p>
                            }
                            <a style={{cursor: "pointer", color:"#0d6efd", textAlign:"right", margin: "10px 20px"}} onClick={() => {$(".latest-payments-container .hide-details").removeClass("hide-details")}}>View more</a>
                        </div>
                    </Card>
                </LatestContainer>
            </Container>
            <ToastContainer />
        </>
    )
}

const qrModalStyle = {
    content: {
        width:"512px",
        height:"512px",
        left: "0",
        right: "0",
        margin: "auto",
        padding: "0"
    }
}

const PromotionLinkWrapper = styled.div`
    margin-top: 4rem;
    padding: 1rem;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: #858585 1px 1px 4px 1px;

    .input-wrapper {
        flex: 1 0 70%;
    }

    img {
        cursor: pointer;
    }
`

const PromotionLink = styled.div`
    display: flex;
`

const RowSort = styled.div`
    display: flex;
    margin-top: 4rem;
    justify-content: space-between;
    align-items: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        /* identical to box height */

        letter-spacing: 0.4px;

        color: #000000;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: start;
    }
`
const SortByDays = styled.select`
    background: #ffffff;
    border: 1px solid #858585;
    border-radius: 5px;
    padding: 1rem;
`

const AffiliateStats = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4rem;

    @media (max-width: 460px) {
        justify-content: center;
    }
`

const Stat = styled.div`
    background: #ffffff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    box-shadow: #858585 1px 1px 4px 1px;
    width: 200px;
    height: 160px;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor:pointer;

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 40px;
    }

    div {
        display: flex;
        align-items: center;
    }

    .is-down {
        color: #ff0000;
    }

    .is-nuetral {
        color: #858585;
    }
`

const ChartContainer = styled.div`
    margin-top: 4rem;
    border-radius: 8px;

    box-shadow: #858585 1px 1px 4px 1px;

    background-color: #ffffff;

    .flex-contain {
        display: flex;
        @media (max-width: 800px) {
            flex-direction: column;
        }
    }

    .chart {
        flex: 1 0 70%;
        width: 100%;

        h2,
        p {
            margin-top: 1rem;
            margin-left: 2rem;
        }

        p {
            font-size: 12px;
            line-height: 16px;

            letter-spacing: 0.1px;

            color: #9fa2b4;
        }
    }
`

const LatestContainer = styled.div`
    margin-top: 4rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;

    @media (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
const Card = styled.div`
    border-radius: 8px;

    box-shadow: #858585 1px 1px 4px 1px;
    background-color: #ffffff;

    h3 {
        margin-bottom: 1rem;
    }
    span {
        color: #252733;
    }
    p {
        color: #9fa2b4;
    }

    a {
        text-decoration: none;
    }

    .top-bar {
        display: flex;
        justify-content: space-between;
        padding: 1rem 2rem;
        margin: 1rem;
    }

    .card-content {
        display: grid;

        .card-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2rem;
            border-bottom: 1px solid #dfe0eb;
        }
    }
`

const StatusPill = styled.div`
    text-align: center;
    text-transform: uppercase;

    .status-success {
        background: #29cc97;
        border-radius: 8px;
        color: #ffffff;
        padding: 0.4rem 1rem;
    }

    .status-pending {
        background: #fec400;
        border-radius: 8px;
        color: #ffffff;
        padding: 0.4rem 1rem;
    }
`

const withdrawButton = {
    backgroundColor: "#40bd00",
    color: "white",
    fontSize: "25px",
    fontFamily: "Raleway",
    padding:"5px 20px",
    border:"none",
    marginTop:"30px",
    borderRadius: "10px"
};

const withdrawInput = {
    fontSize:"18px",
    padding:"10px 20px",
    marginTop:"10px",
    border:"1px solid #e7e7e7",
    borderRadius:"9px",
    boxShadow:"0px 1px 11px 2px #f3f3f3",
    outline:"none",
    width: "330px"
};

const withdrawAvailableBalance = {
    fontSize: "20px",
    margin:"20px",
    textAlign:"center",
};

const withdrawText = {
    fontSize:"20px",
    position:"absolute",
    right:"20px",
    top:"20px"
};

const withdrawLogo = {
    height:"40px",
    position:"absolute",
    left:"10px",
    top:"10px"
};

const withdrawPaymentMethods = {
    marginTop:"35px",
    fontFamily: "Raleway",
    fontSize: "18px",
    padding: "3px 10px",
    borderRadius: "8px",
    border: "1px solid #cbcbcb61",
    boxShadow: "rgb(90 133 43 / 10%) 0px 0px 8px 3px",
    outline: "none",
    marginRight:"auto"
}