import { db } from 'firebase.js'
import {
    onValue,
    ref
} from 'firebase/database'
import { generateOrderId } from './index'

function mapObjectToArray(obj) {
    if (obj) {
        let keys = Object.keys(obj)
        let list = keys.map((k) => obj[k])
        return list
    }
    return []
}

function getShippingMethodList() {
    return new Promise((resolve, reject) => {
        try {
            onValue(ref(db, 'shipping-method'), (snapshot) => {
                let obj = snapshot.val()
                return resolve(mapObjectToArray(obj))
            })
        } catch (e) {
            reject(e)
        }
    })
}

function getShippingOrderList(userId) {
    return new Promise((resolve, reject) => {
        try {
            var orderListRef = db
                .ref('shipping_orderlist')
                .orderByChild('user_id')
                .equalTo(userId)
            orderListRef.once('value', (snapshot) => {
                let orderList = snapshot.val()
                resolve(mapObjectToArray(orderList))
            })
        } catch (e) {
            reject(e)
        }
    })
}

function addShippingOrder({ items, shippingInfo, user_id, user, shippingFee, description, couponUsed=false, couponName }) {
    return new Promise(async (resolve, reject) => {
        try {
            let orderNumber = generateOrderId()
            let orderKey = `${orderNumber}__${user_id}`
            var orderListRef = db.ref('shipping_orderlist')

            let order = {
                order_number: orderNumber,
                items,
                shippingInfo,
                user_id,
                user,
                shippingFee,
                description: description,
                date: new Date().getTime()
            }

            orderListRef.once('value', async (snapshot) => {
                let orderlist = snapshot.val()

                if (!orderlist) {
                    let newOrderList = {}
                    newOrderList[orderKey] = order
                    await orderListRef.update(newOrderList)
                    resolve()
                } else {
                    orderlist[orderKey] = order
                    await orderListRef.update(orderlist)
                    resolve()
                }
            })

            //Make product invisible in warehouse
            await db.ref('warehouselist').once('value', snapshot => {
                let warehouseList = snapshot.val();
                let obj = {...warehouseList};
                let keys = Object.keys(warehouseList);
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    for (let j = 0; j < items.length; j++) {
                        if (key == items[j].product_number.toString()+"__"+items[j].user_id) {
                            obj[key]["invisibility"] = true;
                            db.ref('warehouselist').update(obj).then(() => {
                                if (!couponUsed) resolve({ amount: shippingFee, name: user.name, email: user.email, description: "Checkout Shipment"})
                                else {
                                    //Set coupon as used after shipping
                                    db.ref("users/" + user_id).once("value", userSnapshot => {
                                        let userVals = userSnapshot.val();
                                        if (userVals?.used_coupons) userVals.used_coupons.push(couponName);
                                        else userVals.used_coupons = [couponName];
                                        userVals.available_coupons = userVals?.available_coupons?.splice(userVals?.available_coupons?.indexOf(couponName), 1);
                                        db.ref("users/" + user_id).update(userVals).then(() => {
                                            //Update coupon usage stat
                                            db.ref("stats/coupons/").once("value", async couponSnapshot => {
                                                let vals = couponSnapshot.val();
                                                let curDate = new Date().toLocaleString('tr-TR').split(" ")[0].split(".");
                                                curDate[1] = new Date().toLocaleString('en-EN', { month: 'long' });
                                                curDate = curDate.join(" ");
                                                if (!vals) vals = {};
                                                if (!vals[curDate]) vals[curDate] = 1;
                                                else vals[curDate] += 1;
                                                await db.ref("stats/coupons/").update(vals);
                                                resolve({ amount: shippingFee, name: user.name, email: user.email, description: "Checkout Shipment"});
                                            });
                                        })
                                    })
                                }
                            });
                        }
                    }
                }
            })
        } catch (e) {
            reject(e)
        }
    })
}

function shippingCalculator({
    weight,
    feePerGram = 50,
    initialWeight = 500,
    initialWeightFee = 200,
}) {
    let w = weight
    if (w <= initialWeight) {
        return initialWeightFee
    }

    //let fee = initialWeightFee + ((w - initialWeight) / initialWeight) * feePerGram
    let fee = initialWeightFee + ((w - initialWeight) / initialWeight) * feePerGram;
    //return Math.round(fee)

    return fee;
}


 async function onAddressBookChanged(handler, userInfo) {
  try {
      userInfo.addressBookRef().on('value', (snapshot) => {
        handler(snapshot.val() || [])
      })
  } catch(e) {
      console.error(e)
  }
}

export {
    getShippingMethodList,
    shippingCalculator,
    addShippingOrder,
    getShippingOrderList,
    onAddressBookChanged
}

