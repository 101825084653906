import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { UpperCase } from 'utilities'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { motion, MotionConfig, useAnimationControls } from 'framer-motion'
import {
    ABOUT_PREFIX,
    AFILLIATE_PREFIX,
    CART_PREFIX,
    FORWARDING_PREFIX,
    LOGIN_PREFIX,
    ORDER_PREFIX,
    PUBLIC_PREFIX,
    SEARCH_PREFIX,
    CALCULATOR_PREFIX
} from 'configs/app-config'
import { Link } from 'react-router-dom'
import { SharedImage } from '../constants/image-constant'

const { CartIcon } = SharedImage

const links = [
    {
        label: 'homepage',
        to: PUBLIC_PREFIX,
    },
    {
        label: 'Shopping Agent',
        to: PUBLIC_PREFIX + SEARCH_PREFIX,
    },
    {
        label: 'forwarding',
        to: PUBLIC_PREFIX + FORWARDING_PREFIX,
    },
    {
        label: 'affiliate',
        to: PUBLIC_PREFIX + AFILLIATE_PREFIX,
    },
    {
        label: 'calculator',
        to: PUBLIC_PREFIX + CALCULATOR_PREFIX,
    },
    {
        label: 'orders',
        to: PUBLIC_PREFIX + ORDER_PREFIX,
    },
    {
        label: 'cart',
        to: PUBLIC_PREFIX + CART_PREFIX,
    },
    {
        label: 'login',
        to: PUBLIC_PREFIX + LOGIN_PREFIX,
    },
    {
        label: 'about',
        to: PUBLIC_PREFIX + ABOUT_PREFIX,
    },
]

export default function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false)
    const controls = useAnimationControls()
    const menuItemsVariant = {
        initial: {
            translateY: '100%',
            opacity: 0,
        },
        animate: (i) => ({
            translateY: '0%',
            opacity: 1,
            transition: {
                delay: 0.3,
                duration: 0.2 * i,
            },
        }),
    }

    const btnVariant = {
        moveUp: {
            translateY: '-200%',
        },
        center: {
            translateY: '-50%',
        },
        moveDown: {
            translateY: '200%',
        },
    }

    useEffect(() => {
        if (isOpen) {
            controls.start('animate')
        }
    }, [isOpen])

    return (
        <MotionConfig transition={{ ease: [0.16, 1, 0.3, 1], duration: 1 }}>
            <Container>
                <IconWrapper onClick={() => setIsOpen(!isOpen)}>
                    <motion.div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            translateX: '-50%',
                        }}
                        variants={btnVariant}
                        initial={false}
                        animate={isOpen ? 'center' : 'moveUp'}
                    >
                        <RiCloseLine style={{ width: 30, height: 30 }} />
                    </motion.div>
                    <motion.div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            translateX: '-50%',
                        }}
                        variants={btnVariant}
                        initial={false}
                        animate={isOpen ? 'moveDown' : 'center'}
                    >
                        <RiMenu3Line style={{ width: 30, height: 30 }} />
                    </motion.div>
                </IconWrapper>

                <Wrapper
                    isOpen={isOpen}
                    initial={{ translateX: '200%' }}
                    animate={{
                        translateX: isOpen ? '0%' : '200%',
                    }}
                >
                    {links.map((link, i) => (
                        <li
                            key={i}
                            style={{ overflow: 'hidden' }}
                            onClick={() => setIsOpen(false)}
                        >
                            <Link
                                to={link.to}
                                style={{ textDecoration: 'none' }}
                            >
                                <LinkBox
                                    variants={menuItemsVariant}
                                    initial="initial"
                                    animate={isOpen ? 'animate' : 'initial'}
                                    custom={i + 1}
                                >
                                    {UpperCase(link.label)}
                                </LinkBox>
                            </Link>
                        </li>
                    ))}
                </Wrapper>
            </Container>
        </MotionConfig>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const LinkBox = styled(motion.div)`
    font-size: 2rem;
    color: black;
    &:hover {
        color: #00000080;
    }
`

const Wrapper = styled(motion.ul)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    height: 100vh;
    z-index: 10;
    transform: ${(props) =>
        props.isOpen ? 'translateY(0%)' : 'translateY(-100%)'};

    @media (max-width: 600px) {
        width: 60%;
    }
    @media (max-width: 420px) {
        width: 80%;
    }
`

const IconWrapper = styled.button`
    cursor: pointer;
    position: relative;
    margin-right: 20px;
    overflow: hidden;
    z-index: 100;
    width: 40px;
    height: 40px;
    @media screen and (min-width: 1320px) {
        display: none;
    }
`
