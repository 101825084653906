import FavoriteProductCard from 'components/favorite-product-card'
import Spinner from 'components/Spinner'
import { db } from 'firebase.js'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaRegTimesCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const FavoritePage = () => {

    const user = useSelector(state => state.user);

    const [products, setProducts] = useState([])
    const [showShops, setShowShops] = useState(false)
    const [tempProducts, setTempProducts] = useState([])
    const [filteredShopName, setFilteredShopName] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchInput, setSearchInput] = useState('')

    useEffect(() => {
        setLoading(true)
        if (user) {
            db.ref("favorite-products/" + user.key).once("value", snap => {
                let products = Object.values(snap.val() || {}) || [];
                setProducts(products);
                setTempProducts(products);
                setLoading(false);
            })
        }
    }, [user])

    const filterByShop = (shopName, e) => {
        e.stopPropagation()
        setShowShops(false)
        if (!shopName) {
            setTempProducts(products)
            setFilteredShopName('')
            return
        }
        setTempProducts(products.filter((p) => p.detail_url.includes(shopName)))
        setFilteredShopName(shopName)
    }

    const searchByProdName = (str) => {
        setSearchInput(str)
        if (!str) {
            setTempProducts(products)
            return
        }
        setTempProducts(
            products.filter((p) =>
                p.title.toLowerCase().includes(str.toLowerCase())
            )
        )
    }

    if (loading) return <Spinner bg="dark" />

    return (
        <div className="favoritePage">
            <h1 className="favoritePage__title">All your favorite products</h1>
            <div className="favoritePage__filterBox">
                <ShopFilter
                    onClick={() => setShowShops((state) => !state)}
                    className="favoritePage__filterItem favoritePage__filterShop"
                >
                    <Wrapper>
                        {filteredShopName ? (
                            <p className="favoritePage__label">
                                {filteredShopName}
                                <FaRegTimesCircle
                                    onClick={filterByShop.bind(null, null)}
                                    className="favoritePage__icon"
                                />
                            </p>
                        ) : (
                            <p style={{ cursor: 'pointer' }} className="favoritePage__label favoritePage__label--muted">
                                Select platform
                                <FaChevronDown className="favoritePage__icon" />
                            </p>
                        )}
                    </Wrapper>

                    <ul
                        className={`favoritePage__shopList ${
                            showShops ? 'favoritePage__shopList--active' : ''
                        }`}
                        style={
                            showShops
                                ? { maxHeight: '500px', transition: "all 700ms ease-in-out" }
                                : { maxHeight: '0', transition: "all 700ms cubic-bezier(0, 1.07, 0.58, 1)" }
                        }
                    >
                        <ListItem
                            onClick={filterByShop.bind(null, 'taobao')}
                            className="favoritePage__shopName"
                        >
                            Taobao
                        </ListItem>
                        <ListItem
                            onClick={filterByShop.bind(null, 'tmall')}
                            className="favoritePage__shopName"
                        >
                            Tmall
                        </ListItem>
                        <ListItem
                            onClick={filterByShop.bind(null, 'weidian')}
                            className="favoritePage__shopName"
                        >
                            Weidian
                        </ListItem>
                        <ListItem
                            onClick={filterByShop.bind(null, 'jd')}
                            className="favoritePage__shopName"
                        >
                            Jd
                        </ListItem>
                        <ListItem
                            onClick={filterByShop.bind(null, '1688')}
                            className="favoritePage__shopName"
                        >
                            1688
                        </ListItem>
                    </ul>
                </ShopFilter>
                <div className="favoritePage__filterItem favoritePage__filterTitle">
                    <input
                        className="favoritePage__filterInput"
                        type="text"
                        placeholder="Search by product name"
                        onChange={(e) => searchByProdName(e.target.value)}
                        value={searchInput}
                    />
                </div>
            </div>
            <CardWrapper className="favoritePage__products">
                {tempProducts.map((product) => (
                    <FavoriteProductCard
                        setProducts={setTempProducts}
                        product={product}
                        key={product.num_iid}
                        searchInput={searchInput}
                        user={user}
                    />
                ))}
            </CardWrapper>
        </div>
    )
}

const Wrapper = styled.div`
    width: 200px;
`

const CardWrapper = styled.ul`
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;

    @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 920px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 680px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const ListItem = styled.li`
    text-align: center;
    padding: 1rem 2rem;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: rgb(239, 239, 239);
    }
`

const ShopFilter = styled.div``

export default FavoritePage
