import {
    BANK_PREFIX,
    CONTACT_PREFIX,
    FAQ_PREFIX,
    FEE_PREFIX, PACKAGING_PREFIX,
    PAYPAL_PREFIX,
    PRIVACY_PREFIX,
    PUBLIC_PREFIX,
    REFUND_PREFIX,
    REVIEW_PREFIX,
    STATUS_PREFIX,
    STORAGE_PREFIX,
    TERMS_PREFIX,
    ABOUT_PREFIX
} from 'configs/app-config'
import {
    Discord, Facebook, Instagram, Reddit,
    Youtube
} from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
const { default: styled } = require('styled-components')

export default function Footer() {
    return (
        <Wrapper>
            <FooterMenu>
                <MenuColumn>
                    <h3>Customer Service</h3>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + ABOUT_PREFIX}>About Us</Link>
                    </p>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + FAQ_PREFIX}>FAQ</Link>
                    </p>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + CONTACT_PREFIX}>
                            Contact Us
                        </Link>
                    </p>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + REVIEW_PREFIX}>
                            Review Rewards
                        </Link>
                    </p>
                </MenuColumn>
                <MenuColumn>
                    <h3>Shopping Agent</h3>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + FEE_PREFIX}>
                            Services and Fees
                        </Link>
                    </p>

                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + STATUS_PREFIX}>
                            Order Status
                        </Link>
                    </p>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + REFUND_PREFIX}>
                            Returns and Refunds
                        </Link>
                    </p>
                </MenuColumn>
                <MenuColumn>
                    <h3>Payment</h3>

                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + BANK_PREFIX}>
                            Deposit Funds
                        </Link>
                    </p>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + PAYPAL_PREFIX}>Paypal</Link>
                    </p>
                </MenuColumn>
                <MenuColumn>
                    <h3>Delivery</h3>
                    <p>
                        {' '}
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + FEE_PREFIX}>Charge</Link>
                    </p>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + PACKAGING_PREFIX}>
                            Packaging
                        </Link>
                    </p>
                </MenuColumn>
                <MenuColumn>
                    <h3>After Sales Services</h3>
                    <p>
                        <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + STORAGE_PREFIX}>Storage</Link>
                    </p>
                </MenuColumn>
            </FooterMenu>
            <DividerBorder></DividerBorder>
            <FooterBottom>
                <p>
                    <Link target="_blank" onClick={() => window.scrollTo(0, 0)} to={PUBLIC_PREFIX + TERMS_PREFIX}>Terms</Link>
                    <Link
                        style={{ marginLeft: '1rem' }}
                        to={PUBLIC_PREFIX + PRIVACY_PREFIX}
                        onClick={() => window.scrollTo(0, 0)}
                        target="_blank"
                    >
                        Privacy
                    </Link>
                </p>
                <p>Copyright © 2023 KGHYPE All rights reserved.</p>
                <ul>
                    <li>
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=100086946365691">
                            <Facebook />
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.youtube.com/@cameronscott1294">
                            <Youtube />
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.reddit.com/r/KgHype/">
                            <Reddit />
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.instagram.com/kghypeagent/">
                            <Instagram />
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://discord.gg/NCDsBcRPyv">
                            <Discord />
                        </a>
                    </li>
                </ul>
            </FooterBottom>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background: linear-gradient(
        to bottom,
        rgba(217, 217, 217, 0) 9%,
        #d9d9d5 50%
    );

    a {
        text-decoration: none;
    }
`
const FooterMenu = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 4rem;

    @media (max-width: 700px) {
        flex-direction: column;
        text-align: center;
    }
`
const MenuColumn = styled.div`
    h3 {
        font-weight: 600;
        line-height: 29px;
        color: #11181c;
    }
    p {
        padding: 1rem 0;
        font-weight: 500;
        color: #666666;

        a {
            font-weight: 500;
            color: #666666;
        }
    }
`
const DividerBorder = styled.div`
    width: 80%;
    border: 1px solid #666666;
    margin: 2rem auto;
`

const FooterBottom = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 2rem 0;

    text-align: center;

    flex-wrap: wrap;

    a {
        font-weight: 500;
        color: #666666;
    }

    ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        margin: 1rem;

        li {
            margin: 0 1rem;
            width: 50px;
            height: 50px;

            background: white;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 30px;
                height: 35px;

                color: #666666;
            }
        }
    }
`
