import styled from 'styled-components';
import * as React from 'react';
import { Container } from "components/container";
import { Heading } from "components/heading";
import { SharedImage } from 'constants/image-constant';
import ImageWrapper from 'components/image-wrapper';
import Input from 'components/input';
import Button from 'components/button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import { useState } from 'react';
//Firebase 

import { auth } from 'firebase.js';
import { db } from 'firebase.js';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"

const HeadingStyle = {
    textAlign: "left",
    margin: "0px"
}

const HeadingStyle2 = {
    fontSize: "18px",
    fontWeight: "400"
}

const ContainerStyle = {
    padding: "50px 20px",
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap"
}

const ButtonStyle = {
    marginTop: "50px"
}

  
const { Profile } = SharedImage;

export default function Promo() {

    const [age, setAge] = React.useState('');
    const [promo, setPromo] = React.useState();
    const handleChange = (event) => {
      setAge(event.target.value);
    };
    function genetrateCode(){
        let code = (Math.random() + 1).toString(36).substring(3);
        //console.log("random", code);
        setPromo(code);
        return  code;
        
    }
    const [nameText, setNameText] = useState([]);
    const [mailText, setMailText] = useState([]);

    onAuthStateChanged(auth, async (currentUser) => {
        if (!currentUser) {
            window.location = "/login";
        }
        else {
            const usersRef = await db.ref("users");
            usersRef.once('value', function (snapshot) {
                snapshot.forEach(function (usersSnapshot) {
                    var usersData = usersSnapshot.val();


                    if (usersData.mail == currentUser.email) {

                        setNameText(`${usersData.fName} ${usersData.lName} `);
                        setMailText(usersData.mail);
                    }

                });
            });
        }
    });
    function push_firebase(){
        var postData = {
            price: document.getElementById("discount").value,
            type: "off",

          };
          var name = document.getElementById("code").value
          const usersRef =  db.ref("coupon_list/"+ name ).update(postData);
    }
    return (
        <Container style={ContainerStyle}>

            <Content>
                <Heading style={HeadingStyle}>Generate promo codes</Heading>
                <div>
                    <Input placeholder='Set discount' id="discount" />
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={age}
                            onChange={handleChange}
                            autoWidth
                            label="Age"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"percentage"}>Percentage</MenuItem>
                            <MenuItem value={"discount"}>Discount</MenuItem>
                        </Select>

                    </FormControl>

                </div>
                <Input placeholder='Set the number of uses' id="uses"  />
                <Input placeholder='Set promo code' id="code"  />

                <Button text="Save promo" onClick={push_firebase} style={ButtonStyle} />
            </Content>
        </Container>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width:800px){
        display:none;
    }
`
const SideMenu = styled.ul`
    list-style:none;
    display:flex;
    flex-direction:column;
    gap:20px;
    font-family:Open Sans;
    padding:0px;
    margin:0px;
    padding-top:50px;
`

const Content = styled.section`
    padding-left:100px;
    max-width:100%;
    flex:1;
    @media screen and (max-width:800px){
        padding-left:0px;
    }
`

const ProfileWrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-flow:wrap;
    padding:50px 0px;
    align-items:center;
    @media screen and (max-width:800px) {
        flex-direction:column;
        align-items:center; 
    }
`

const TextWrapper = styled.div`
    padding-left:50px;
    display:flex;
    flex-direction:column;
    gap:20px;
    @media screen and (max-width:800px){
        padding-left:0px;
        padding-top:30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding:0px;
    margin:0px;
    padding:0px;
    @media screen and (max-width:800px){
        text-align:center;
    }
`