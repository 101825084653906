import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FormGroup, FormControl, Input, InputLabel, FormHelperText } from '@mui/material';
import { Row } from 'components/ui-components';
// import ImagesGallery from '../../components/image_gallery';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));


const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};


function ProductDetail({ product }) {
	const headingStyle = {
		fontWeight: 600,
		marginRight: '10px'
	}
	return <div>
		<hr />
		{/* <img src = {product.images[0] || " "} alt = {product.product_number}/> */}
		{/* <ImagesGallery photos={product.images}/> */}
		<Row mainAxisAlignment={"space-between"}>
			<div>
				<p><span style={headingStyle}>Order Number: </span>{product.order_number}</p>
				{/* <p><span style={headingStyle}>Product Number: </span>{product.product_number}</p> */}
				<p><span style={headingStyle}>Amount:</span> USD {product.amount}</p>
				<p><span style={headingStyle}>Weight:</span>{product.weight}</p>
			</div>
			<div>
				<img src={product.images[0] || " "} alt={product.product_number} style={{ width: "150px", height: "150px" }} />
			</div>
		</Row>
	</div>
}


export default function ShippingDetailModal({ open, onClose, info }) {
	const [isOpen, setOpen] = React.useState(open);
	const [totalW, setTotal] = React.useState(0);

	React.useEffect(() => {
		setOpen(open);
		console.log(info);

		let t = 0;
		if (info.items) {
			info.items.forEach(item => {
				t += parseFloat(item.weight);
			})
		}
		setTotal(t);
	}, [open])

	const handleClose = (e, reason) => {
		if (reason !== 'backdropClick')
			setOpen(false);
		if (onClose && reason !== 'backdropClick') {
			onClose()
		}
	};

	const headingStyle = {
		fontWeight: 600,
		marginRight: '10px'
	}

	const textStyle = {
		fontWeight: 400
	}

	const pStyle = {
		margin: "2px 0px"
	}


	return (
		<div>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={isOpen}
				disableEscapeKeyDown={true}
			>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
					<h3>Parcel Detail</h3>
				</BootstrapDialogTitle>
				<DialogContent dividers>
					{/* <ImagesGallery photos={info.images}/> */}

					<Typography gutterBottom>
						<FormGroup style={{ margin: '20px', width: '500px' }}>
							<p style={pStyle}><span style={headingStyle}>Order Date: </span><span style={textStyle}>{info?.items ? new Date(info?.items[0]?.date).toLocaleString("en-EN") : "-"}</span></p>
							<p style={pStyle}><span style={headingStyle}>Parcel Number:</span><span style={textStyle}>{info.order_number}</span></p>
							<p style={pStyle}><span style={headingStyle}>Tracking Number:</span><span style={textStyle}>{info.trackingNumber || " "}</span></p>
							<p style={pStyle}><span style={headingStyle}>Order Status:</span><span style={textStyle}>{info.status || "Pending"}</span></p>
							<p style={pStyle}><span style={headingStyle}>Total Weight:</span><span style={textStyle}>{totalW}g</span></p>
							<br />
							<p style={pStyle}><span style={headingStyle}>Products:</span></p>
							{
								info && info.items ? info.items.map((i) => <ProductDetail product={i} />) : <></>
							}
						</FormGroup>
					</Typography>
				</DialogContent>
			</BootstrapDialog>
		</div>
	);
}