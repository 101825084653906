import { getSeller, searchProduct } from 'actions/product'
import { PRODUCT_DETAIL_PREFIX, PUBLIC_PREFIX } from 'configs/app-config'
import { useHistory } from 'react-router-dom'

export default function GotoProduct({ item }) {
    const history = useHistory()

    const searchItem = async (item) => {
        history.push({
            pathname: PUBLIC_PREFIX + PRODUCT_DETAIL_PREFIX,
            search: `?store=${getSeller(item.detail_url)}&id=${item.num_iid}`,
        })
    }
    return (
        <div>
            <button
                onClick={() => {
                    searchItem(item)
                }}
                type="button"
                style={btnStyle}
            >
                Go To Product
            </button>
        </div>
    )
}

const btnStyle = {
    padding: '6px 9px',
    margin: '2px 0px',
    fontSize: '12px',
    backgroundColor: 'rgb(7, 42, 72)',
    border: '0',
    color: "white",
    borderRadius: "3px",
    fontFamily: "Inter",
}
