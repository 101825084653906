import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Center } from '@chakra-ui/react'
import BusinessIntro from '../business-tab/intro/BusinessIntro'
import ChannelCooperation from '../channel-cooperation/ChannelCooperation'

function IntroTabs() {
  return (
    <Box mt="10" bgColor="rgba(255, 255, 255, .9)" w="full">
        <Tabs variant='enclosed'>
            <Center>
                <TabList color="black">
                    <Tab _selected={{ bg: "white" }} px="16" pt="4" borderTopRadius="xl">BUSINESS</Tab>
                    <Tab _selected={{ bg: "white" }} px="6" pt="4" borderTopRadius="xl">Channel Cooperation</Tab>
                </TabList>
            </Center>

            <TabPanels bg="white" color="black" minH="200vh">
                <TabPanel p="none">
                <Box position="absolute" bg="blue" left={["25%", "25%", "25%", "44%", "44.5%"]} w="5" h=".25rem"></Box>
                <BusinessIntro />
                </TabPanel>
                <TabPanel p="none">
                <Box position="absolute" bg="blue" left={["75%", "75%", "75%", "55%", "55%"]} w="5" h=".25rem"></Box>
                <ChannelCooperation />
                </TabPanel>
            </TabPanels>
        </Tabs>
    </Box>
  )
}

export default IntroTabs