import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Currency } from 'utilities'

/*export default function TablePriceCell({ itemPrice }) {
    const [rates, setRates] = useState({})

    useEffect(async () => {
        setRates(await Currency.GetRates())
    }, [])

    return (
        <>
            <div style={{ color: 'red' }}>
                {Currency.GetPrice(rates, itemPrice)}
            </div>
        </>
    )
}*/

export default function TablePriceCell({ itemPrice }) {
    const [price, setPrice] = useState(itemPrice)
    let [rates, setRates] = useState({})

    useEffect(async () => {
        setRates(await Currency.GetRates())
    }, [])

    return (
        <>
            <span style={{ color: 'red' }}>
                {Currency.GetPrice(rates, price)}
            </span>
        </>
    )
}