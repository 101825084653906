import React from "react";

const TabSpecification = ({ item }) => {
  return (
    <ul className="TabSpecification">
      {item?.props &&
        Object.values(item.props).map((prop, i) => (
          <li key={i} className="TabSpecification__item">
            <p className="TabSpecification__label">{prop["name"]}</p>
            <p className="TabSpecification__value">{prop["value"]}</p>
          </li>
        ))}
    </ul>
  );
};

export default TabSpecification;
