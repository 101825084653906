import styled from 'styled-components';
export default function Button ({style,text,onClick, Icon}) {
    return(
        <ButtonWrapper style={style} onClick={onClick} >
            {
                Icon
            }
            {
                text
            }
        </ButtonWrapper>
    )
}

const ButtonWrapper = styled.button`
    background-color:#072A48;
    border-radius: 5px;
    border:none;
    color:white;
    padding:10px;
    font-weight: 600;
    font-family:Open Sans;
    font-size: 12px;
    // width:150px;
    cursor:pointer;
`