export const sbsData = [
    {
        image: "/tab_0.png",
        heading1: "Start your global e-commerce business easily with a plugin that connects to Shopify automatically",
        heading2: "One-Click Product Upload:",
        data1: "With this function, you can access products from e-commerce platforms like Taobao, Tmall, 1688, and JD fast. You can also change the description, pictures, and categories of the products based on your needs.",
        heading3: "One-Click Order Fulfillment:",
        data2: "To complete the shipping procedure of an order, you just need to confirm the purchase and forwarding request of the client. You have nothing to worry about as the system will handle everything for you automatically.", 
    },
    {
        image: "/tab_1.png",
        heading1: "For clients who already have Amazon stores or clients who want to open their stores on Amazon, we can customize the packaging of your products and ship the products to the selected FBA warehouse",
        heading2: "Professional Product Sourcing",
        data1: "We will assign an account manager to each client. You will be reaching out the initial supplier as wished. We will source your products from platforms like 1688, Taobao, and Tmall. We will also negotiate with the suppliers and try to get the lowest price for you. There will be no communication barriers and you do not need to worry about the complicated procedures at all.",
        heading3: "Warehouse Services",
        data2: "Our warehouse staff can inspect your products when they are stocked in or stocked out. We can remove or change the product labels for you. We can attach the FBA label onto your products or the packaging. We also offer product photo or video services. If you need something else, you can try our customization services.", 
    },
    {
        image: "/tab_2.png",
        heading1: "With over 5 years of cross-border e-commerce experience, we offer reliable international logistics services",
        heading2: "Customized Logistics Services",
        data1: "Ship by sea, by air, or by land as you need, at exclusive discounts.",
        heading3: "Express Shipping",
        data2: "Optimized forwarding procedure and faster warehousing operations. Global dropshipping with one click.", 
    },
    {
        image: "/tab_3.png",
        heading2: "1 to 1 Exclusive Account Manager",
        data1: "We will assign an account manager to each client. The account manager will provide full support from product sourcing to technical services. Without complicated communication procedures, you can save a lot of time.",
        heading3: "Selected Quality Products for Export",
        data2: "We offer exclusive customized products like OEM packaging materials and gifts to business clients (MOQ:100).", 
    },
]