import React, { useEffect, useState } from "react";
import { db } from "firebase.js";
import { ref, onValue } from "firebase/database";
import CouponCard from "components/coupon-card";

const CouponExpired = ({currentUser}) => {
  const [couponExpired, setCouponExpired] = useState([]);
  useEffect(() => {
    if (currentUser) {
      const expiredCouponArr = [];
      onValue(ref(db, "coupon_list"), (snapshots) => {
        snapshots.forEach((snapshot) => {
          //if (snapshot.val()?.expired && currentUser.dbUser?.available_coupons?.includes(snapshot.val().code)) {
          if (snapshot.val()?.expired) {
            expiredCouponArr.push(snapshot.val());
          }
        });
      });
      setCouponExpired(expiredCouponArr);
    }
  }, [currentUser]);

  return (
    <div className="availableCoupon">
      {couponExpired.map((coupon, i) => (
        <CouponCard key={i} coupon={coupon} />
      ))}
    </div>
  );
};

export default CouponExpired;
