import { NotifyFail, NotifySuccess } from 'utilities'
import { v4 as uuid } from 'uuid'

function addToCart(item, user) {
    return new Promise(async (resolve, reject) => {
        if (!user || !user?.ref()) reject();
        user.cartRef().once("value", async u => {
            let currentCart = Object.values(u.val() || {});
            let arr = [...currentCart];

            if (arr.length == 0) {
                arr = [item]
            } else {
                arr.push(item)
            }

            try {
                user.cartRef().set(arr)
                .then(() => {
                    resolve()
                })
            } catch (e) {
                console.err(e);
                reject(e)
            }
        });
    })
}

function removeFromCart(item, user) {
    return new Promise(async (resolve, reject) => {
        user.cartRef().once("value", async u => {
            let currentCart = Object.values(u.val() || {});
            let index;

            if (currentCart) {
                for (let i = 0; i < currentCart.length; i++) {
                    if (currentCart[i].num_iid == item.num_iid) {
                        index = i;
                        break;
                    } else if (i == currentCart.length - 1) reject();
                }
                currentCart.splice(index, 1);
            }

            try {
                await user.cartRef().set(currentCart || []);
                resolve(currentCart)
            } catch (e) {
                reject(e)
            }

        });
    })
}
function removeFromHistory(item, user) {
    return new Promise((resolve, reject) => {
        if (!user || !user?.ref()) reject();
        user.browseHistoryRef().once("value", async snap => {
            let currentHistory;
            let vals = snap.val();
            if (vals) currentHistory = Object.values(snap.val());
            else return reject();

            let len = currentHistory.length;
            let item_ = item.item;

            for (let i = 0; i < len; i++) {
                if (currentHistory[i]?.item?.num_iid == item_.num_iid) {
                    currentHistory.splice(i, 1);
                    break;
                }
            }

            if (len == currentHistory.length + 1) {
                try {
                    await user.browseHistoryRef().update(currentHistory.length == 0 ? { 0: null } : Object.assign({}, currentHistory));
                    return resolve()
                } catch (e) {
                    return reject(e)
                }
            }
            else return reject();
        });
    })
}

function addToHistory(item, user) {
    return new Promise(async (resolve, reject) => {
        if (!user || !user?.ref()) reject();
        user.browseHistoryRef().once("value", async snap => {
            let currentHistory = Object.values(snap.val() || {}) || [];

            let index = currentHistory.find(function (obj) {
                return obj.num_iid == item.num_iid
            });

            if (index) {
                resolve()
                return
            }

            currentHistory.push(item);

            try {
                await user.browseHistoryRef().update(Object.assign({}, currentHistory));
                resolve()
            } catch (e) {
                reject(e)
            }
        });
    })
}

function addToBrowseHistory(item, userInfo) {
    return new Promise(async (resolve, reject) => {
        userInfo.browseHistoryRef().once("value", async snap => {
            let currentHistory = Object.values(snap.val() || {}) || [];
            let obj = [...currentHistory];

            let index = null;
            for (let i = 0; i < currentHistory?.length; i++) {
                if (currentHistory[i].item.num_iid == item.num_iid) {
                    index = i;
                    break;
                }
            }

            if (index !== null) {
                obj = [{ item, history_date: new Date().getTime() }];
            } else {
                obj.push({ item, history_date: new Date().getTime() });
            }

            try {
                await userInfo.browseHistoryRef().update(Object.assign({}, obj));
                resolve()
            } catch (e) {
                console.error(e);
                reject(e)
            }
        });
    })
}

const generateCustomId = () => {
    const unique_id = uuid()
    return unique_id
}

const addToCartButton = async (item, custom, user) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (custom) {
                let num_iid = generateCustomId()
                item.num_iid = num_iid
            }

            addToCart(item, user).then(() => {
                resolve();
            })
                .catch(() => reject())

        } catch (error) {
            reject(error)
        }
    })
}

const removeFromCartButton = async (item, user) => {
    return new Promise(async (resolve, reject) => {
        try {
            await removeFromCart(item, user).then(d => {
                NotifySuccess('Product Removed Succesfully!')
                resolve(d);
            });
        } catch (error) {
            reject(error)
        }
    })
}

const removeFromHistoryButton = async (item, user) => {
    return new Promise(async (resolve, reject) => {
        try {
            await removeFromHistory(item, user)
            NotifySuccess('Product Removed Succesfully!')
            // window.location = "/p/shipping";
            resolve()
        } catch (error) {
            NotifyFail('Product was not removed')
            reject(error)
        }
    })
}
export {
    addToCartButton,
    removeFromCartButton,
    addToHistory,
    removeFromHistoryButton,
    addToBrowseHistory
}
