import { useEffect, useState } from "react";

import BalancePage from "./Balance";
import CouponPage from "./Coupon";

const Wallet = () => {
  const [activeTab, setActiveTab] = useState("balance");
  let content = activeTab === "balance" ? <BalancePage /> : <CouponPage />;

  useEffect(() => {
    if (window.location.href.includes("?coupon")) setActiveTab("coupon");
  }, []);

  return (
    <div className="walletPage">
      <ul className="walletPage__tab">
        <li
          onClick={() => setActiveTab("balance")}
          className={`walletPage__item ${
            activeTab === "balance" ? "walletPage__item--active" : ""
          }`}
        >
          Balance
        </li>
        <li
          onClick={() => setActiveTab("coupon")}
          className={`walletPage__item ${
            activeTab === "coupon" ? "walletPage__item--active" : ""
          }`}
        >
          Coupon
        </li>
      </ul>
      {content}
    </div>
  );
};

export default Wallet;
