import { db } from "firebase.js";
import { generateOrderId } from "utilities";

function orderSummary({productsList, orderNumber, userId, status}) {
    let products = {}
    let products_list = [];
    for(let product of productsList) {
        if(products[product.num_iid]) {
            products[product.num_iid].qty += 1;
            products_list[products[product.num_iid].index].quantity = products[product.num_iid].qty
        } else {
            products[product.num_iid] = {
                index: products_list.length,
                qty: 1
            }
            products_list.push(
              {
                product: product,
                order_number: orderNumber,
                product_number: product.num_iid || generateOrderId(),
                user_id: userId,
                product_status: status,
                quantity: product.quantity,
                remark: product.remark,
                date: new Date().toDateString()
              }
            )
        }
    }
    return products_list
  }
  
  function addNewOrder(order) {
    return new Promise((resolve, reject) => {
      try {
        let orderKey = `${order.order_number}__${order.user_id}`
        var orderListRef = db.ref('orderlist');

        orderListRef.once('value', async (snapshot) => {
          let orderlist = snapshot.val()
          
          if(!orderlist) {
            let newOrderList = {}
            newOrderList[orderKey] = order
            await orderListRef.update(newOrderList)
            resolve()
          } else {
            orderlist[orderKey] = order
            await orderListRef.update(orderlist)
            resolve()
          }
        })
      } catch(e) {
        reject(e)
      }
  
    })
  }

  function sendReturnRequest({ reason, userInfo, product }) {
    return new Promise((resolve, reject) => {
      try {
          let id = generateOrderId()
          let returnKey = `${id}__${userInfo.key}`

          let returnRef = db.ref('returns')

          returnRef.once('value', async (snapshot) => {
            let returns = snapshot.val()
            let newReturn = {
              reason,
              product,
              user: {
                user_id: userInfo.key,
                name: `${userInfo.dbUser.fName} ${userInfo.dbUser.lName}`,
                email: userInfo.dbUser.mail
              },
              date: new Date().toISOString()
            }

            if(returns) {
              returns[returnKey] = newReturn;
              await returnRef.update(returns);
            } else {
              returns = {}
              returns[returnKey] = newReturn
              await returnRef.update(returns)
            }

            let orderNum = product.order_number;
            //Remove from warehouselist
            /// Let's not do that for now and mark the item as "returned"
            /*
            db.ref("warehouselist").once("value", wrSnapshot => {
              let warehouses = wrSnapshot.val();
              let vals = Object.values(warehouses);
              for (let i = 0; i < vals.length; i++) {
                if (vals[i].order_number == orderNum) {
                  warehouses[Object.keys(warehouses)[i]] = null;
                  db.ref("warehouselist").update(warehouses).then(() => {
                    resolve();
                  })
                  break;
                }
              }
            })*/

            //Mark item as returned in warehouselist
            db.ref("warehouselist").once("value", wrSnapshot => {
              let warehouses = wrSnapshot.val();
              let vals = Object.values(warehouses);
              for (let i = 0; i < vals.length; i++) {
                if (vals[i].order_number == orderNum) {
                  let target = {...warehouses[Object.keys(warehouses)[i]]};
                  target["returned"] = true;
                  warehouses[Object.keys(warehouses)[i]] = target;
                  db.ref("warehouselist").update(warehouses).then(() => {
                    resolve();
                  })
                  break;
                }
              }
            })
          })

      } catch(e) {
        reject(e)
      }
    })
  }

  function getOrderByNumber(order_number) {
    return new Promise((resolve, reject) => {
        var orderListRef = db.ref('orderlist').orderByKey().startAt(order_number);
        orderListRef.once('value', (snapshot) => {
            let orderList = snapshot.val()
            if(orderList) {
                let key = (Object.keys(orderList))[0]
                return resolve(orderList[key])
            }
            resolve()
        })
    })
  }

  function getOrderByUserId(userId) {
    return new Promise((resolve, reject) => {
        try {
            let userOrderRef = db.ref('orderlist').orderByKey().endAt(userId);
            userOrderRef.once('value', (snapshot) => {
                let order = snapshot.val()
                resolve(order)
            })
        } catch(e) {
            reject(e)
        }
    })
}

  async function createOrder(productList, userId, desc="") {
    // GENERATE ORDER ID
    var orderId = generateOrderId();

    var today = new Date();
    var date_order = today.toLocaleString("en-GB");


    let totalPrice = 0;
    let products = []
    for(let product of productList) {
        totalPrice += parseFloat(product.price) || 0
        products.push(product)
    }

    let status = (desc.includes("Zelle") || desc.includes("Cashapp") || desc.includes("Venmo")) ? "Pending Payment" : "Paid";
    
    // also store this order in the admin end
    let newOrder = {
      user_id: userId,
      order_time: date_order,
      order_number: orderId,
      total_price: totalPrice,
      order_status: status,
      order_description: desc,
      products: orderSummary({ productsList: products, orderNumber: orderId, userId, status: status })
  }
    await addNewOrder(newOrder)

    return newOrder
  }

  	function additionalPayment(order_nums, prod_nums, user_id) {
		return new Promise(async (resolve, reject) => {
			/**
			 * 1) Get all products from order numbers ✅
			 * 2) Sum up all the additional payments from products ✅
			 * 3) User Balance = User Balance - Sum ✅
			 * 4) Remove additional payments from products ✅
			 */

			let additionalSum = 0;
			let userRef = db.ref('users/'+user_id);
			let len = order_nums ? order_nums.length : 0;

			if (order_nums && prod_nums) {
				for (let i = 0; i < len; i++) {
					let order_num = order_nums[i];
					let production = await getOrderByNumber(order_num);
					
					for (let n = 0; n < prod_nums[i].length; n++) {
						for (let m = 0; m < production.products.length; m++) {
							if (prod_nums[i][n] == production.products[m]["product_number"]) {
								additionalSum += production.products[m].product.additional_payment;
								break;
							}
						}
					}
	
					if (i == len - 1) {
						userRef.once("value", snapshot => {
							let user = snapshot.val();
							let obj = {...user};
	
							if (user.balance >= additionalSum) {
								let userAdditional = user.underpaid - additionalSum;
	
								obj["underpaid"] = Math.max(0, userAdditional);
								obj["balance"] = user.balance - additionalSum;
	
								userRef.update(obj).then(() => {
									db.ref('orderlist').once("value", async snapshot_ => {
									let prods = snapshot_.val();
	
									for (let j = 0; j < len; j++) {
										for (let k = 0; k < Object.keys(prods).length; k++) {
											if (Object.keys(prods)[k].startsWith(order_nums[j].toString())) {
												let obj_ = {...Object.values(prods)[k]};
	
												for (let n = 0; n < prod_nums[i].length; n++) {
													for (let m = 0; m < production.products.length; m++) {
														if (prod_nums[i][n] == production.products[m]["product_number"]) {
															obj_.products[m].product.additional_payment = null;
                              obj_.products[m]["product_status"] = "Paid";
                              obj_["order_status"] = "Paid";
															break;
														}
													}
												}
												
												await db.ref('orderlist/'+Object.keys(prods)[k]).update(obj_).then(() => {
												  if (j == len - 1) resolve();
												});
											}
										}
									}
									});
								})
							} else reject("Insufficient balance");
						});
					}
				}
			}

			//Pay ALL of additional payments
			else if (order_nums === false && prod_nums === false) {
				//Get user's orders
				let adProds = [];
				db.ref('orderlist')
				.orderByChild("user_id")
				.equalTo(user_id)
				.once("value", async snapshot_ => {
					let vals = Object.values(snapshot_.val());
					for (let i = 0; i < vals.length; i++) {
						for (let j = 0; j < vals[i].products.length; j++) {
							if (vals[i].products[j].product["additional_payment"]) {
								adProds.push([vals[i].order_number, vals[i].products[j].product_number]);
								additionalSum += vals[i].products[j].product["additional_payment"];
							}
						}
					}

					userRef.once("value", u_s => {
						let user = u_s.val();
						if (user.balance >= additionalSum) {
							let obj = {...user};
							obj["underpaid"] = null;
							obj["balance"] -= additionalSum;
							userRef.update(obj).then(() => {

								db.ref("orderlist").once("value", async snapshot_ => {
									let originalData = snapshot_.val();
									let obj_ = {...originalData};

									//Welcome to the hell
									for (let i = 0; i < adProds.length; i++) {
										for (let j = 0; j < Object.keys(originalData).length; j++) {
											if (Object.keys(originalData)[j].startsWith(adProds[i][0])) {
												for (let k = 0; k < Object.values(originalData)[j].products.length; k++) {
													if (Object.values(originalData)[j].products[k].product["additional_payment"]) {
														obj_[Object.keys(originalData)[j]].products[k].product["additional_payment"] = null;
                            obj_[Object.keys(originalData)[j]].products[k]["product_status"] = "Paid";
                            obj_[Object.keys(originalData)[j]]["order_status"] = "Paid";
													}
												}
											}
										}
									}

									db.ref("orderlist").update(obj_).then(() => {
										resolve();
									});
								});
							});
						}
					});
				});
			}
		});
  	}

  export {
  additionalPayment, createOrder,
  getOrderByNumber,
  getOrderByUserId,
  sendReturnRequest
};
