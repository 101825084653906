import {
    Box,
    Center,
    Flex,
    Heading,
    Image,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react'

function SBSPartner() {
    return (
        <Box w="full" mt="100">
            <Center>
                <Flex direction="column" alignItems="center" maxW="80rem">
                    <Heading
                        color="black"
                        fontSize="3xl"
                        py="16"
                        textAlign="center"
                    >
                        SBS would like to partner with
                    </Heading>
                    <Flex
                        gap={20}
                        direction={['column', 'column', 'column', 'row', 'row']}
                    >
                        <Box
                            style={{ backgroundImage: "url('/case_0.png')" }}
                            position="relative"
                            w="full"
                        >
                            <Box style={{ margin: '2rem' }} px="12" py="8">
                                <Heading
                                    position="relative"
                                    color="white"
                                    fontSize="lg"
                                    fontWeight="normal"
                                    mt={['8']}
                                    zIndex={2}
                                >
                                    E-commerce sellers
                                </Heading>
                                <UnorderedList
                                    mt="5"
                                    position="relative"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="normal"
                                    zIndex={2}
                                >
                                    <ListItem>
                                        Third-party sellers on platforms like
                                        Amazon and eBay
                                    </ListItem>
                                    <ListItem>
                                        Sellers who have their own online shops
                                        on platforms like Shopify
                                    </ListItem>
                                    <ListItem>
                                        Offline retailers who want to sell
                                        online
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </Box>
                        <Box
                            style={{ backgroundImage: "url('/case_1.png')" }}
                            position="relative"
                            w="full"
                        >
                            <Box style={{ margin: '2rem' }} px="12" py="14">
                                <Heading
                                    position="relative"
                                    color="white"
                                    fontSize="lg"
                                    fontWeight="normal"
                                    mt={['8']}
                                    zIndex={2}
                                >
                                    Cross-border logistics
                                </Heading>
                                <UnorderedList
                                    mt="5"
                                    position="relative"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="normal"
                                    zIndex={2}
                                >
                                    <ListItem>
                                        Users who need batch packing and
                                        shipping
                                    </ListItem>
                                    <ListItem>
                                        Users who want to split orders and ship
                                        parcels to different users seperately
                                    </ListItem>
                                    <ListItem>
                                        Users who need custom logistics or
                                        parcel shipping services
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </Box>
                        <Box
                            style={{ backgroundImage: "url('/case_2.png')" }}
                            position="relative"
                            w="full"
                        >
                            <Box style={{ margin: '2rem' }} px="12" py="8">
                                <Heading
                                    position="relative"
                                    color="white"
                                    fontSize="lg"
                                    fontWeight="normal"
                                    mt={['8']}
                                    zIndex={2}
                                >
                                    B2B
                                </Heading>
                                <UnorderedList
                                    mt="5"
                                    position="relative"
                                    color="white"
                                    fontSize="sm"
                                    fontWeight="normal"
                                    zIndex={2}
                                >
                                    <ListItem>
                                        Bulk purchasing projects
                                    </ListItem>
                                    <ListItem>
                                        Product customization needs
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                        </Box>
                    </Flex>
                </Flex>
            </Center>
        </Box>
    )
}

export default SBSPartner
