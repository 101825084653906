import { Box, Center, Flex, Heading } from "@chakra-ui/react";
import { B2BServices } from "../../b2b-services/B2BServices";
import SBSPartner from "../business-partner/SBSPartner";
import GetFromSBS from "../get-from-sbs/GetFromSBS";
import MostPopular from "../most-popular/MostPopular";
import PartnerPlatforms from "../partner-platforms/PartnerPlatforms";
import SBSOffers from "../sbs-offers/SBSOffers";
import { IntroCard } from "./IntroCard";
import introData from "./IntroData";

function BusinessIntro() {
  return (
    <Box w="full">
      <Center>
        <Flex direction="column" alignItems="center" maxW="80rem">
          <Heading color="black" fontSize="3xl" py="16" textAlign="center">
            What is Superbuy Business Service (SBS)?
          </Heading>
          <Box
            textAlign="center"
            fontSize="lg"
            maxW="50rem"
            pb="16"
            color="gray.600"
          >
            SBS is specially designed to help small and medium size clients. Our
            goal is to help online and offline resellers, retailers, or shop
            owners become more competitive by making it easier to source quality
            products directly from China.
          </Box>
          <Flex
            gap={20}
            direction={["column", "column", "column", "row", "row"]}
            wrap="wrap"
          >
            {introData.map(({ image, name }) => (
              <IntroCard key={name} image={image} name={name} />
            ))}
          </Flex>
        </Flex>
      </Center>
      <GetFromSBS />
      <SBSOffers />
      <SBSPartner />
      <B2BServices />
      <PartnerPlatforms />
      <Heading
        bg="rgba(0, 0, 0, .05)"
        textAlign="center"
        color="black"
        mt="100"
        p={["70", "70", "70", "90", "90"]}
        fontSize="3xl"
      >
        Products Recommendation
      </Heading>
      <MostPopular />
    </Box>
  );
}

export default BusinessIntro;
