import { Box, Image, Center, Flex, Heading } from "@chakra-ui/react"
import apiData from "./AvailableApiData"

function AvailableApi() {
  return (
    <Box position="relative" w="full">
        <Image src="/bg-2.png" position="absolute" zIndex={1} w="full" minH={["full"]} alt="" />
        <Center>
            <Flex direction="column" alignItems="center" mt="10" mb="20" zIndex={3} w="full">
                <Heading position="relative" color="white" textAlign="center" fontSize="3xl" mt={["10"]} mb="10" zIndex={2}>Available APIs in near soon</Heading>
                <Flex gap={5} direction={["column", "column", "column", "row", "row"]} wrap="wrap">
                  {apiData.map(({image, name}) => (
                    <Flex
                        direction="column"
                        alignItems="center"
                        p="6"
                        justify="space-between"
                        w="9.5rem"
                        h="10rem"
                        gap={5}
                        bg="white"
                        borderRadius="md"
                        key={name}
                    >
                        <Image
                        src={image}
                        cursor="pointer"
                        />
                        <Box cursor="pointer" fontSize="lg">
                        {name}
                        </Box>
                    </Flex>
                  ))}
                </Flex>
            </Flex>
        </Center>
    </Box>
  )
}

export default AvailableApi