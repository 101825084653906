import { List, ListItem, ListIcon, Box, Heading } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";

export default function CustomBulletList({
  listData,
  bulletColor,
  box,
  title,
}) {
  return (
    <Box>
      <Heading fontSize="3xl" color="black">
        {title}
      </Heading>
      {box && <Box bgColor={bulletColor} w="30px" mt="5" h="7px"></Box>}
      <List fontSize="sm" spacing={1} color="black" py="5">
        {listData.map((item) => (
          <ListItem key={item}>
            <ListIcon as={FaCircle} size={1} color={bulletColor} />
            {item}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
