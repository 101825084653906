import styled from "styled-components";
import { UpperCase } from "utilities";
import { Heading } from "./heading";

const fontStyle = {
  fontSize: "14px",
  color: "#72727E",
  textAlign: "left",
};

export default function Input({
  label = null,
  style,
  placeholder = "",
  onChange,
  name,
  type = "text",
  value,
  id,
  ref,
  focus,
  disabled=false,
  readOnly=false
}) {
  return (
    <div>
      <label>
        <Heading style={fontStyle}>{UpperCase(label)}</Heading>
      </label>
      <InputWrapper
        style={style}
        id={id}
        placeholder={placeholder}
        type={type}
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
        onFocus={focus}
        disabled={disabled}
        readOnly={readOnly}
      />
    </div>
  );
}


export function InputSelection({
  children,
  label = null,
  style,
  placeholder = "",
  onChange,
  name,
  value,
  id,
  ref,
}) {
  return (
    <div>
      <label>
        <Heading style={fontStyle}>{UpperCase(label)}</Heading>
      </label>
      <InputSelectionWrapper
        style={style}
        id={id}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
      >{children}</InputSelectionWrapper>
    </div>
  );
}

const InputWrapper = styled.input`
  border: none;
  background-color: #eef2f5;
  height: 50px;
  width: 100%;
  outline: none;
  text-indent: 10px;
  font-family: Open Sans;
  &:focus {
    outline: #072a48;
    box-shadow: 0px 0px 2px #072a48;
  }
  ${(props) => props.style}
`;

const InputSelectionWrapper = styled.select`
  border: none;
  background-color: #eef2f5;
  height: 50px;
  width: 100%;
  outline: none;
  text-indent: 10px;
  font-family: Open Sans;
  &:focus {
    outline: #072a48;
    box-shadow: 0px 0px 2px #072a48;
  }
  ${(props) => props.style}
`;
