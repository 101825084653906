import useLoader from 'hooks/useLoader';
import { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { useSelector } from 'react-redux';
import ShippingDetailModal from './shipping_detail_modal';
import { getShippingOrderList } from 'utilities/shipping';

const ContainerStyle = {
    paddingBottom: "200px",
    width: '100%'
}

function generateColumns({ openDialog }) {
    return [
        {
            name: 'Parcel No',
            width: "230px",
            cell: (d) => {
                let style = { overflowWrap: 'break-word', padding: "10px 0px", color: "#625E5E", fontWeight: "normal" }
                return <p style={style}>{d.order_number}</p>
            }
        },
        {
            name: "Shipping Method",
            width: "100px",
            cell: (d) => {
                return <div style={{ margin: '20px 0px' }}>
                    <p> {d.shippingInfo.name} </p>
                </div>
            },
        },

        {
            name: "Price",
            width: "100px",
            cell: (d) => {
                return <div style={{ margin: '20px 0px' }}>
                    <p> ¥{d.shippingFee} </p>
                </div>
            },
        },

        {
            name: 'Date',
            width: '200px',
            cell: (d) => {
                let style = { overflowWrap: 'break-word', padding: "10px 0px", color: "#625E5E", fontWeight: "normal" }
                return <div>
                    <p style={style}>{new Date(d.items[0].date).toLocaleString("en-EN")}</p>
                </div>
            }
        },

        {
            name: 'Tracking No',
            width: '200px',
            cell: (d) => {
                let style = { overflowWrap: 'break-word', padding: "10px 0px", color: "#625E5E", fontWeight: "normal" }
                return <div>
                    <p style={style}>{d.trackingNumber ?? "-"}</p>
                </div>
            }
        },

        {
            name: 'Status',
            // width: '200px',
            cell: (d) => {
                return <div>
                    {/* <img src = {d.images.length ? d.images[0] : ''} alt = "Product"  style = {{width: "205px", height: "110px", padding: "5px 0px"}}/> */}
                    {d?.status?.toUpperCase() || "PENDING"}
                </div>
            }
        },

        {
            name: "Operate",
            // selector: "id",
            sortable: true,
            cell: (d) => {
                let btnStyle = { padding: "3px 4px", margin: "2px 0px", fontSize: "11px", backgroundColor: "#F7F7F7", border: "solid 1px" };
                //let btnStyle2 = { padding: "3px 4px", margin: "2px 0px", fontSize: "11px", backgroundColor: "#e74c3c", border: "solid 1px #e74c3c", color: 'white' };
                return <div>
                    <button type="button" onClick={() => openDialog(d)} style={btnStyle}> View Details</button>
                    {/*<button type="button" onClick={() => { }} style={btnStyle2}> X Delete </button>*/}
                </div>
            },
            width: "130px",
        },
    ]
}

export default function ShippingListPage() {
    const [datas, setData] = useState([]);
    const [LoaderWrapper, dataLoader] = useLoader()
    //const [selectedRows, setSelectedRows] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [dialogData, setDialogData] = useState({})

    let user = useSelector(state => state.user);

    /*function handleRowSelect(select_data) {
        setSelectedRows((prev) => {
            prev.splice(0, prev.length, ...select_data.selectedRows)
            return prev
        })
    }*/

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                try {
                    setData(await getShippingOrderList(user.key));
                } catch (e) {
                    console.error(e)
                }
            };
            dataLoader(fetchData);
        }
    }, [user])

    const columns = generateColumns({
        openDialog: (data) => {
            setDialogData(data)
            setOpenDialog(true)
        }
    });

    const tableData = {
        columns,
        data: datas
    };

    return (
        <LoaderWrapper>
            <div style={ContainerStyle}>
                <DataTable
                    title="My Parcels"
                    columns={columns}
                    data={tableData.data}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    selectableRows
                    customStyles={
                        {
                            table: {
                                style: {
                                    fontFamily: "Montserrat",
                                }
                            },
                            headCells: {
                                style: {
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    fontWeight: "bold",
                                    color: "#504D4D"
                                },
                                draggingStyle: {
                                    cursor: 'move',
                                },
                            },

                            headRow: {
                                style: {
                                    minHeight: '52px',
                                    borderBottomWidth: '1px',
                                    borderBottomStyle: 'solid',
                                    // backgroundColor: "#F0EFEF",
                                },
                                denseStyle: {
                                    minHeight: '32px',
                                },
                            },
                            rows: {
                                style: {
                                    border: 'solid 0px blue',
                                    backgroundColor: '#F4F4F5'

                                }
                            }
                        }
                    }
                />
            </div>

            <ShippingDetailModal open={openDialog} onClose={() => setOpenDialog(false)} info={dialogData} />
        </LoaderWrapper>
    )
}