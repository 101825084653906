import { getSeller, searchProduct } from 'actions/product'
import {
    CALCULATOR_PREFIX,
    MANUAL_PREFIX,
    PRODUCT_DETAIL_PREFIX,
    PUBLIC_PREFIX,
    SEARCH_PREFIX
} from 'configs/app-config'
import { CategoryImage } from 'constants/image-constant'
import { useState } from 'react'
import { RiSearch2Line } from 'react-icons/ri'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { NotifyFail } from 'utilities'

import { BsFillArrowDownCircleFill } from 'react-icons/bs'
import { CgArrowLongRight } from 'react-icons/cg'

import { CircularProgress } from '@mui/material'
import BadgeIcon from 'assets/icons/badge-icon.png'
import CartIcon from 'assets/icons/cart-icon.png'
import DiscordIcon from 'assets/icons/discord.svg'
import InernationalIcon from 'assets/icons/international-icon.png'
import ShippingIcon from 'assets/icons/shipping-icon.png'
import WarehouseIcon from 'assets/icons/warehouse-icon.png'
import {
    Link as ScrollLink
} from 'react-scroll'

const {
    Clothing,
    Footwear,
    Handbag,
    Accessories,
    Jewelry,
    HomeCat,
    Kids,
    FoodDrinks,
    Computers,
    Electronics,
} = CategoryImage

const categories = [
    {
        image: Clothing,
        label: 'clothing',
        link: 'clothing',
    },
    {
        image: Footwear,
        label: 'footwear',
        link: 'shoes',
    },
    {
        image: Handbag,
        label: 'handbags',
        link: 'handbag',
    },
    {
        image: Accessories,
        label: 'accessories',
        link: 'headphones',
    },
    {
        image: Jewelry,
        label: 'jewelry',
        link: 'jewelry',
    },
    {
        image: Kids,
        label: 'Baby & Kids',
        link: 'kids',
    },
    {
        image: Electronics,
        label: 'Electronics',
        link: 'phones',
    },
    {
        image: Computers,
        label: 'Computers',
        link: 'computer',
    },
    {
        image: HomeCat,
        label: 'Home',
        link: 'home decor',
    },
    {
        image: FoodDrinks,
        label: 'Food & Drinks',
        link: 'food',
    },
]

const processes = [
    {
        image: CartIcon,
        title: 'Purchase Items',
        description:
            'The Easiest Way Of Finding The Items You Want Is With KGHYPE. A Simple And Safe Browsing',
    },
    {
        image: WarehouseIcon,
        title: 'Ship to Warehouse',
        description:
            'With Your Shopping Cart Full, Check Out With A Variety Of Safe And Well Known Payment Methods.Once The Payment Is Processed Your Items Will Be On Their Way To Our Warehouse For Quality Checking.',
    },
    {
        image: BadgeIcon,
        title: 'Quality Inspection',
        description:
            'Once The Items Are In Our Warehouse, You Will Receive Pictures To Ensure The Quality Of Your Ordered Item.',
    },
    {
        image: InernationalIcon,
        title: 'International Shipping',
        description:
            'Nobody Likes Waiting For Their Stuff, I Get it. But The Good News Is That It Will Soon Be At Your Doorstep. And With Your Tracking Number, You Can Get Detailed Info On How Close Your Items Are!',
    },
    {
        image: ShippingIcon,
        title: 'It’s Here',
        description:
            'Your Package Is Finally Here! If You Have Any Questions Or Are Not Satisfied With Something, Contact Support And Get It fixed ASAP. Thank You For Shopping With KGHYPE!',
    },
]

const reasonsToChooseUs = [
    {
        title: 'Easy to use',
        description:
            'Find anything on Taobao, Weidian or 1688 with our very easy to use interface. Paste Links and Keywords',
        class: 'gradient-light-blue',
    },
    {
        title: 'Safe and Reliable',
        description:
            'The safest platform to buy anything you ever wanted. Find exactly what you want from china in a couple of seconds',
        class: 'gradient-green',
    },
    {
        title: 'Amazing Support',
        description:
            'Get any question answered asap. Returns, issues and suggestions are Priority number #1',
        class: 'gradient-orange',
    },
]

export default function LandingPage() {
    const history = useHistory()
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({})
    const toSearchProduct = async (e, searchKey) => {
        if (!searchKey.search) {
            NotifyFail('Search field cant be empty')
            return
        }
        setLoading(true)
        e.preventDefault()
        const response = await searchProduct(searchKey)
        if (response) {
            setLoading(false)
            if (response.flag === 0) {
                history.push({
                    pathname: PUBLIC_PREFIX + MANUAL_PREFIX,
                })
                return
            }
            if (response.flag === 2) {
                history.push({
                    pathname: PUBLIC_PREFIX + SEARCH_PREFIX,
                    search: `page=1&search=${searchKey.search}`,
                    state: {
                        item: response.data,
                    },
                })
            } else {
                history.push({
                    pathname: PUBLIC_PREFIX + PRODUCT_DETAIL_PREFIX,
                    search: `?store=${getSeller(
                        response.data.item.detail_url
                    )}&id=${response.data.item.num_iid}`,
                })
            }
        } else {
            NotifyFail('Error Ocurred')
        }
    }

    return (
        <>
            <Wrapper>
                <HeroWrapper>
                    <div className="container">
                        <HeroHeading>
                            <h1>#1 Most Affordable</h1>
                            <h2>Taobao Agent</h2>
                        </HeroHeading>
                        <SearchWrapper
                            onSubmit={(e) => {
                                e.preventDefault()
                                toSearchProduct(e, formData)
                            }}
                        >
                            <input
                                name="search"
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        [e.target.name]: e.target.value,
                                    })
                                }}
                                type="text"
                                placeholder="Type Keyword or Place Product Link"
                                style={{
                                    height: "40px",
                                    border: "none",
                                    paddingLeft: "10px",
                                    outline: "none"
                                }}
                            />
                            {loading ? (
                                <CircularProgress size={40} color="inherit" />
                            ) : (
                                <IconWrapper
                                    type="submit"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <RiSearch2Line />
                                </IconWrapper>
                            )}
                        </SearchWrapper>
                        <StoresWrapper
                            style={{ display: 'flex', gap: 20, marginTop: 20 }}
                        >
                            <StepCard>
                                <a
                                    href="https://world.taobao.com/"
                                    style={{
                                        color: '#072a48',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Taobao
                                </a>
                            </StepCard>
                            <StepCard>
                                <a
                                    href="https://www.tmall.com/"
                                    style={{
                                        color: '#072a48',
                                        textDecoration: 'none',
                                    }}
                                >
                                    TMail
                                </a>
                            </StepCard>
                            <StepCard>
                                <a
                                    href="https://www.1688.com/"
                                    style={{
                                        color: '#072a48',
                                        textDecoration: 'none',
                                    }}
                                >
                                    1688
                                </a>
                            </StepCard>
                            <StepCard>
                                <a
                                    href="https://m.weidian.com/"
                                    style={{
                                        color: '#072a48',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Weidian
                                </a>
                            </StepCard>
                            <StepCard>
                                <a
                                    href="https://www.brandyupoo.com/"
                                    style={{
                                        color: '#072a48',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Yupoo
                                </a>
                            </StepCard>

                            <ScrollLink
                                to="features"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                style={{ cursor: 'pointer' }}
                            >
                                <BsFillArrowDownCircleFill
                                    style={{ fontSize: '4rem' }}
                                />
                            </ScrollLink>
                        </StoresWrapper>
                    </div>
                </HeroWrapper>

                <PageWrapper className="container">
                    <CategoriesWrapper>
                        <SectionHeading>
                            <h2>
                                Find{' '}
                                <span className="gradient-light-blue">
                                    Anything
                                </span>{' '}
                                You Want.
                            </h2>

                            <p className="heading-subtext">
                                The Biggest Assortment Of Goods From Taobao,
                                Weidian And 1688.
                            </p>
                        </SectionHeading>
                        <CategoryGrid>
                            {categories.map((category) => {
                                return (
                                    <CategoryCard
                                        style={{
                                            backgroundImage: `url(${category.image})`,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            history.push({
                                                pathname:
                                                    PUBLIC_PREFIX +
                                                    SEARCH_PREFIX,
                                                search: `page=1&search=${category.link}`,
                                            })
                                        }}
                                    >
                                        <h3>{category.label}</h3>
                                        <div>
                                            <p>
                                                Browse 100,000+ {category.label}{' '}
                                                designs
                                            </p>
                                            <button
                                                onClick={() => {
                                                    history.push({
                                                        pathname:
                                                            PUBLIC_PREFIX +
                                                            SEARCH_PREFIX,
                                                        search: `page=1&search=${category.link}`,
                                                    })
                                                }}
                                            >
                                                BROWSE
                                            </button>
                                        </div>
                                    </CategoryCard>
                                )
                            })}
                        </CategoryGrid>
                        <SectionCTA
                            onClick={() => {
                                history.push({
                                    pathname: PUBLIC_PREFIX + SEARCH_PREFIX,
                                    search: `page=1&search=All`,
                                })
                            }}
                        >
                            Browse All Items <CgArrowLongRight />
                        </SectionCTA>
                    </CategoriesWrapper>

                    <ProcessWrapper>
                        <SectionHeading>
                            <h2>
                                Our Purchasing{' '}
                                <span className="gradient-green">Process.</span>{' '}
                            </h2>
                        </SectionHeading>
                        <ProcessGrid>
                            {processes.map((process) => {
                                return (
                                    <ProcessCard>
                                        <div className="card-header">
                                            <div className="logo-wrapper">
                                                <img src={process.image} />
                                            </div>
                                            <h3>{process.title}</h3>
                                        </div>
                                        <p>{process.description}</p>
                                    </ProcessCard>
                                )
                            })}
                        </ProcessGrid>
                    </ProcessWrapper>
                </PageWrapper>

                <ShippingWrapper className="container">
                    <SectionHeading>
                        <h2>
                            <span className="gradient-light-blue">
                                Affordables
                            </span>{' '}
                            and{' '}
                            <span className="gradient-orange">Efficient</span>{' '}
                            <br />
                            Shipping and Logisitics.
                        </h2>
                    </SectionHeading>
                    <ShippingRow>
                        <div>
                            <p className="shipping-text">
                                Calculate your shipping costs quickly and easily
                                through our shipping cost calculator
                            </p>
                            <Link to={PUBLIC_PREFIX + CALCULATOR_PREFIX}>
                                <SectionCTA>Shipping Calculator</SectionCTA>
                            </Link>
                        </div>
                        <ShippingTable>
                            <div className="table-header">
                                <p>DESTINATION</p>
                                <p>WEIGHT</p>
                                <p>PRICE</p>
                                <p>DELIVERY TIME</p>
                                <p>METHOD</p>
                            </div>
                            <div className="table-body">
                                <div className="table-row">
                                    <p>USA</p>
                                    <p>1KG</p>
                                    <p>$22</p>
                                    <p>13</p>
                                    <p>Air</p>
                                </div>
                                <div className="table-row">
                                    <p>USA</p>
                                    <p>3KG</p>
                                    <p>$56</p>
                                    <p>7</p>
                                    <p>Air</p>
                                </div>
                                <div className="table-row">
                                    <p>Canada</p>
                                    <p>2KG</p>
                                    <p>$45</p>
                                    <p>12</p>
                                    <p>Air</p>
                                </div>
                                <div className="table-row">
                                    <p>UK</p>
                                    <p>1KG</p>
                                    <p>$18</p>
                                    <p>15</p>
                                    <p>Air</p>
                                </div>
                            </div>
                        </ShippingTable>
                    </ShippingRow>
                </ShippingWrapper>

                <FeaturesWrapper id="features">
                    <h3>
                        Why <span className="gradient-purple"> Choose Us.</span>
                    </h3>
                    <FeaturesCard>
                        {reasonsToChooseUs.map((reason, index) => {
                            return (
                                <div className="feature-row">
                                    <div className="feature-number">
                                        <p className={reason.class}>
                                            {index + 1}
                                        </p>
                                    </div>
                                    <div className="feature-text">
                                        <h3>{reason.title}</h3>
                                        <p>{reason.description}</p>{' '}
                                    </div>
                                </div>
                            )
                        })}
                    </FeaturesCard>
                    <SocialCard
                        href="https://discord.gg/NCDsBcRPyv"
                        target="_blank"
                    >
                        <div>
                            <img src={DiscordIcon} />
                            <span>Join Our Discord - KGHYPE</span>
                        </div>
                        <p>Join Our Discord For World-Class Customer Support</p>
                    </SocialCard>
                </FeaturesWrapper>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    width: 100%;
    a {
        text-decoration: none;
    }
    .container {
        margin-left: auto;
        margin-right: auto;
    }
`

const PageWrapper = styled.div`
    margin: 4rem auto;
`

const HeroWrapper = styled.div`
    background-image: url('/landing-page.jpg');
    background-size: cover;
    background-position-y: center;
    width: 100%;
    padding: 6rem 0;
    margin: 4rem 0;
    @media (max-width: 520px) {
        padding: 6rem 2rem;
    }
`
const InnerWrapper = styled.div``

const HeroHeading = styled.div`
    font-weight: 700;
    font-size: 48px;
    line-height: 90px;
    h1 {
        background: linear-gradient(180deg, #8e4ec6 0%, #d3b4ed 100%),
            linear-gradient(180deg, #ff1cf7 0%, #b249f8 100%),
            linear-gradient(180deg, #00b7fa 22.41%, #01cfea 89.65%),
            linear-gradient(182.38deg, #f36534 1.99%, #f69f27 50%), #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    @media (max-width: 520px) {
        font-size: 38px;
        line-height: 60px;
    }
    @media (max-width: 460px) {
        font-size: 30px;
    }
`

const SearchWrapper = styled.form`
    display: flex;
    align-items: center;
    margin: 4rem 0;
    background: #f1f3f5;
    box-shadow: 0px 4px 18px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: fit-content;
    input {
        background: #f1f3f5;
        box-shadow: 0px 4px 18px 2px rgba(0, 0, 0, 0.25);
        border-radius: 15px 0 0 15px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #11181c;
        margin: 0;
        width: 400px;
        @media (max-width: 460px) {
            width: 200px;
        }
    }
`
const SectionHeading = styled.div`
    font-weight: 700;
    font-size: 48px;
    @media (max-width: 520px) {
        font-size: 38px;
        line-height: 60px;
    }
    @media (max-width: 460px) {
        font-size: 30px;
    }
    .heading-subtext {
        margin: 4rem 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #7e868c;
    }
    .gradient-light-blue {
        background: linear-gradient(180deg, #00b7fa 22.41%, #01cfea 89.65%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .gradient-green {
        background: linear-gradient(180deg, #6fee8d 0%, #17c964 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .gradient-orange {
        background: linear-gradient(182.38deg, #f36534 1.99%, #f69f27 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`

const IconWrapper = styled.button`
    height: 40px;
    color: #ffffff;
    background: #11181c;
    border-radius: 0px 15px 15px 0px;
    & svg {
        padding: 0.6rem;
        width: 100%;
        height: 100%;
    }
`
const StepCard = styled.div`
    font-size: 1.5rem;
    color: #072a48;
    cursor: pointer;
    &:hover {
        color: #072a48;
    }
`

const StoresWrapper = styled.div`
    display: flex;
    gap: 20;
    margin-top: 20;
    align-items: center;
    @media (max-width: 460px) {
        flex-wrap: wrap;
    }
`

const CategoriesWrapper = styled.section``

const CategoryGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    margin: 2rem;
    @media (max-width: 1440px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 860px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 620px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const CategoryCard = styled.div`
    background-size: cover;
    width: 250px;
    height: 300px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem;
    border-radius: 12px;
    @media (max-width: 1440px) {
        width: 200px;
        height: 250px;
    }
    @media (max-width: 860px) {
        width: 250px;
        height: 300px;
    }
    h3 {
        text-transform: capitalize;
        padding: 2rem;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
    }
    button {
        background: rgba(217, 217, 217, 0.61);
        border-radius: 15px;
        padding: 0.1rem 0.2rem;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        margin-left: 0.4rem;
        cursor: pointer;
    }
    div {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #d1d1d1;
        background: rgba(15, 17, 20, 0.4);
        border-top: 1px solid #687076;
        border-radius: 0px 0px 15px 15px;
        padding: 1rem;
    }
`

const SectionCTA = styled.button`
    display: flex;
    align-items: center;
    background: #11181c;
    border-radius: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding: 2rem 1rem;
    margin: 3rem;
    cursor: pointer;
    svg {
        margin-left: 1rem;
    }
`

const ProcessWrapper = styled.section`
    margin-top: 7rem;
`
const ProcessGrid = styled.div`
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 420px);
    gap: 2rem;
    @media (max-width: 1440px) {
        grid-template-columns: repeat(2, 420px);
    }
    @media (max-width: 920px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 580px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
const ProcessCard = styled.div`
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(7px);
    border-radius: 15px;
    .card-header {
        display: flex;
        align-items: center;
        padding: 2rem 1rem;
    }
    .logo-wrapper {
        background: #e8fcf1;
        border-radius: 999px;
        width: fit-content;
        padding: 0.4rem 0.6rem;
        margin: 0 1rem;
    }
    h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #11181c;
    }
    p {
        padding: 1rem;
    }
`

const ShippingWrapper = styled.section`
    margin: 7rem 4rem;
`
const ShippingRow = styled.div`
    margin: 6rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    @media (max-width: 980px) {
        grid-template-columns: repeat(1, 1fr);
    }
    .shipping-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #7e868c;
        margin-right: 4rem;
    }
`
const ShippingTable = styled.div`
    .table-header {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #7e868c;
        background: #f1f3f5;
        border-radius: 15px;
        padding: 1rem 1.6rem;
        @media (max-width: 560px) {
            padding: 1rem 0;
        }
        p {
            padding: 0 1rem;
s        }
    }
    .table-body {
        .table-row {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            padding: 1rem 1.6rem;
            @media (max-width: 560px) {
                grid-template-columns: repeat(5, 80px);
            }
            p {
                padding: 1rem;
                @media (max-width: 560px) {
                    padding: 0;
                }
            }
        }
    }
`
const FeaturesWrapper = styled.section`
    background-image: url('/bg-frame.png');
    background-position-y: bottom;
    background-size: cover;
    margin-top: 12rem;
    padding-bottom: 12rem;
    h3 {
        text-align: center;
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        color: #11181c;
    }
    .gradient-purple {
        background: linear-gradient(180deg, #8e4ec6 0%, #d3b4ed 100%),
            linear-gradient(180deg, #ff1cf7 0%, #b249f8 100%),
            linear-gradient(180deg, #00b7fa 22.41%, #01cfea 89.65%),
            linear-gradient(182.38deg, #f36534 1.99%, #f69f27 50%), #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`

const FeaturesCard = styled.div`
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(7px);
    border-radius: 15px;
    width: 550px;
    height: 650px;
    margin: 12rem auto;
    margin-top: 4rem;
    padding: 2rem;
    display: grid;
    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
    }
    .feature-number {
        padding-top: 1rem;
        width: fit-content;
        @media (max-width: 500px) {
            margin: auto;
        }
        p {
            padding: 0.4rem 2rem;
            border-radius: 100%;
            font-weight: 700;
            font-size: 48px;
            line-height: 58px;
            color: #ffffff;
            margin-right: 4rem;
        }
        .gradient-light-blue {
            background: linear-gradient(180deg, #00b7fa 22.41%, #01cfea 89.65%);
        }
        .gradient-green {
            background: linear-gradient(180deg, #6fee8d 0%, #17c964 100%);
        }
        .gradient-orange {
            background: linear-gradient(182.38deg, #f36534 1.99%, #f69f27 50%);
        }
    }
    .feature-row {
        display: flex;
        padding: 1rem 2rem;
        @media (max-width: 500px) {
            flex-direction: column;
        }
        h3 {
            text-align: start;
            padding-bottom: 1rem;
        }
        .feature-text {
        }
    }
`

const SocialCard = styled.a`
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(7px);
    border-radius: 15px;
    width: 548px;
    height: 151px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: 600px) {
        width: 100%;
    }
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    span {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #11181c;
        margin-left: 2rem;
    }
    p {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #7e868c;
    }
`