import axios from "axios";
import { Container } from "components/container";
import { Heading } from "components/heading";
import { SharedImage } from "constants/image-constant";
import styled from 'styled-components';

import { useEffect, useState } from 'react';

//Firebase 
import { CHECKOUT_PREFIX, PUBLIC_PREFIX } from 'configs/app-config';
import { useHistory } from 'react-router-dom';
import ShippingCart from '../cart';

const HeadingStyle = {
    textAlign: "left",
    paddingLeft: "20px"
}

const { Product } = SharedImage;

const ContainerStyle = {
    paddingBottom: "200px",
    margin: "20px 30px"
}

const MetaStyle = {
    paddingLeft: "10px"
}


export default function ConfirmCart() {
    const [datas, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [state, setState] = useState({
        ip: "",
        countryName: "",
        countryCode: "",
        city: "",
        timezone: ""
    });

    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0);
        //getGeoInfo();
    }, []);

    var total_price = 0;
    var total_amount = 0;

    function onChange(event, item) {
        if (event.target.checked) {
            setSelected([...selected, item]);
        } else {
            setSelected((prev) =>
                prev.filter((currItem) => currItem.value !== item.value)
            );
        }
    }

    const getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                setState({
                    ...state,
                    ip: data.ip,
                    countryName: data.country_name,
                    countryCode: data.country_calling_code,
                    city: data.city,
                    timezone: data.timezone
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <Container style={ContainerStyle}>
            <Heading style={HeadingStyle}>Confirmation</Heading>
        
            <br />
            <br />
            <ShippingCart confirm={true} onConfirm = {() => { history.push(PUBLIC_PREFIX + CHECKOUT_PREFIX) }} />
           
        </Container>
    )
}


// style input
const inputStyle = {
    width: "100%",
    height: "40px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    padding: "0 10px",
    fontSize: "14px",
    outline: "none",
    marginBottom: "10px"
}

const Inputs = styled.input`
    color: #000;

`
const ProductWrapper = styled.div`
    max-width:150px;
    padding-right:20px;
`

const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
`

const MetaWrapper = styled.div`
    display:flex;
    flex-direction:row;
    gap:30px;
    padding-left:20px;
`