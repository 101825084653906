import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./reducers/cartSlice/cartSlice";
import authReducer from "./reducers/authSlice/authSlice";
import userReducer from "./reducers/userReducer/userReducer";

export default configureStore({
  reducer: {
    cart: cartReducer,
    auth: authReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
    }),
});
