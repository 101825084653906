import styled from 'styled-components'

export default function ChartStats(props) {
    return (
        <StatContainer>
            <ChartStat>
                <p>Clicks</p>
                <h3>{props.clicks}</h3>
            </ChartStat>
            <ChartStat>
                <p>Conversions</p>
                <h3>0</h3>
            </ChartStat>
            <ChartStat>
                <p>Revenue</p>
                <h3>{props.revenue}</h3>
            </ChartStat>
            <ChartStat>
                <p>{/*Voids*/}Signups</p>
                <h3>{props.signups}</h3>
            </ChartStat>
            <ChartStat>
                <p>Earns per sale {/*Click*/}</p>
                <h3>{(props.signups != 0) ? props.revenue / props.signups : 0}</h3>
            </ChartStat>
        </StatContainer>
    )
}

const StatContainer = styled.div`
    flex: 0 1 30%;
    border: 1px solid #dfe0eb;

    display: grid;
    justify-items: center;
`

const ChartStat = styled.div`
    text-align: center;
    border-bottom: 1px solid #dfe0eb;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        text-align: center;
        letter-spacing: 0.3px;
        color: #9fa2b4;
    }
`
