const channelData: { image: string, name: string }[] = [
    {
        image: "/customer-1.png",
        name: "A B2C e-commerce platform",
    },
    {
        image: "/customer-2.png",
        name: "A self-owned online store",
    },
    {
        image: "/customer-3.png",
        name: "A sourcing service provider",
    },
    {
        image: "/customer-4.png",
        name: "A logistics service provider",
    },
    {
        image: "/customer-5.png",
        name: "A warehousing service provider",
    },
    {
        image: "/customer-6.png",
        name: "Other e-commerce players",
    },
]

export default channelData