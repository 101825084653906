// import DashboardLayout from '../../components/layout'
// import useLoader from '../../hooks/useLoader';
import useLoader from 'hooks/useLoader';
import DataTable from "react-data-table-component";
import { useState, useEffect } from 'react';
import { onForwardingChanged } from 'utilities/warehous';
import { auth } from 'firebase.js'
import Spinner from "components/Spinner";


function dataColumns() {
    return [
          {
            name: 'Product Name',
            width: "250px",
            cell: (d) => {
                let style = {overflowWrap: 'break-word', padding: "10px 0px", color: "#625E5E", fontWeight: "normal" }
                return <div>
                    <p style = {style}>{d.product_name}</p>
                </div>
            }
          },
          {
            name: "Image",
            width: "220px",
            cell: (d) => {
                return <div style = {{margin: '20px 0px'}}>
                        <img src = {d.image} alt = {d.product_name}  style = {{width: "180px", height: "200px", padding: "5px 0px",}}/>
                    </div>
            },
          },
          {
            name: "Tracking Number",
            sortable: true,
            width: "200px",
            cell: (d) => <><span>{d.tracking_number}</span></>
          },
          
          {
            name: "User Name",
            sortable: true,
    
            cell: (d) => <><span>{d.user.name}</span></>
          },
          {
            name: "Date",
            id: "date",
            sortable: true,
            selector: d => parseInt(d.date),
            cell: (d) => <><span>{new Date(d.date).toLocaleString("en-EN")}</span></>
          },
    ];
}

export default function ForwardedPage() {
    const [LoaderWrapper, dataLoader] = useLoader()
    const [tableData, setData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = () => {
            try {
                onForwardingChanged((list) => {
                    let list_ = [];
                    for (let i = 0; i < list.length; i++) {
                        if (list[i].user.email == auth?.currentUser.email) list_.push(list[i]);
                    }

                    //Sort array by date
                    for (let i = 0; i < list_.length; i++) {
                        for (let j = 0; j < list_.length; j++) {
                            if (new Date(list_[i]["date"]).getTime() > new Date(list_[j]["date"]).getTime()) {
                                let clone = {...list_[i]};
                                list_[i] = list_[j];
                                list_[j] = clone;
                            }
                        }
                    }

                    setData(list_);
                    setLoading(false)
                })
            } catch(e) {}
        };
        
        dataLoader(fetchData);
    }, [])

    
    const columns = dataColumns()

    if (loading) return <Spinner />
    return (
        <div>
            <a href="/forwarding" style={{
                position: "absolute",
                right: "10px",
                zIndex: 4,
                marginTop: "10px",
                right: "80px",
                backgroundColor: "#072A48",
                borderRadius: "5px",
                border: "none",
                color: "white",
                padding: "10px 30px",
                fontWeight: "600",
                fontFamily: "Open Sans",
                fontSize: "12px",
                cursor: "pointer",
            }}>Add New</a>
            <LoaderWrapper>
                <DataTable
                    title = "Forwarded Items"
                    columns={columns}
                    data={tableData}
                    // noHeader
                    defaultSortFieldId="date"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    // striped

                    customStyles = {
                            {
                                table: {
                                    style: {
                                        fontFamily: "Montserrat",
                                    }
                                },
                                headCells: {
                                    style: {
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        fontWeight: "bold",
                                        color: "#504D4D"
                                    },
                                    draggingStyle: {
                                        cursor: 'move',
                                    },
                                },

                                headRow: {
                                    style: {
                                        minHeight: '52px',
                                        borderBottomWidth: '1px',
                                        borderBottomStyle: 'solid',
                                        // backgroundColor: "#F0EFEF",
                                    },
                                    denseStyle: {
                                        minHeight: '32px',
                                    },
                                },
                                rows: {
                                    style: {
                                        border: 'solid 0px blue',
                                        backgroundColor: '#F4F4F5'

                                    }
                                }
                            }
                        }
                    />
            </LoaderWrapper>
            <a></a>
        </div>
    );
}