import React from "react";
import { Box, Image as ChakraImage, Flex, Heading } from "@chakra-ui/react";

export function IntroCard({ image, name }) {
  return (
    <Flex direction="column" alignItems="center" maxW="12rem">
      <ChakraImage src={image} cursor="pointer" />
      <Box fontSize="sm" mt="10" cursor="pointer">
        {name}
      </Box>
    </Flex>
  );
}
