import { Container } from 'components/container'
import GotoProduct from 'components/goto-product'
import { Heading } from 'components/heading'
import TablePriceCell from 'components/table-price-cell'
import useLoader from 'hooks/useLoader'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
    removeFromHistoryButton
} from '../../../utilities/cart'

import { useSelector } from 'react-redux'
import './carthistory.scss'
import Spinner from 'components/Spinner'

//Firebase

const HeadingStyle = {
    textAlign: 'left',
    paddingLeft: '20px',
    margin: '20px 0',
}

const ContainerStyle = {
    // paddingBottom:"200px",
    width: '100%',
}

const createColumns = (user) => [
    {
        name: 'Image',
        // width: "60px",
        cell: (d) => (
            <div>
                <img
                    src={d.item.pic_url}
                    alt="Product"
                    style={{
                        width: '85px',
                        height: '90px',
                        padding: '5px 0px',
                    }}
                />
            </div>
        ),
    },
    {
        name: 'Item Name',
        width: '600px',
        cell: (d) => (
            <span
                style={{
                    overflowWrap: 'break-word',
                    padding: '10px 0px',
                    color: '#625E5E',
                    fontWeight: 'normal',
                }}
            >
                {d.item.title ?? ''}
            </span>
        ),
    },
    {
        name: 'Price',
        sortable: true,
        width: '100px',

        cell: (d) => {
            return <TablePriceCell itemPrice={d.item.price} />
        },
    },
    {
        name: 'Time',
        sortable: true,
        width: '180px',
        cell: (d) => (
            <span style={{ color: '#625E5E' }}>
                {new Date(d.history_date).toLocaleString("tr-TR")}
            </span>
        ),
    },
    {
        name: 'Details',
        // selector: "id",
        sortable: true,
        cell: (d) => {
            return <GotoProduct item={d.item}></GotoProduct>
        },
        width: '200px',
    },
    {
        name: 'Operating',
        // selector: "id",
        sortable: true,
        cell: (d) => {
            let btnStyle = {
                padding: '6px 9px',
                margin: '2px 0px',
                fontSize: '12px',
                borderRadius: "3px",
                backgroundColor: 'red',
                color: "white",
                fontFamily: "Inter",
                border: '0',
            }
            return (
                <div>
                    <button
                        type="button"
                        onClick={() => removeFromHistoryButton(d, user)}
                        style={btnStyle}
                    >
                        {' '}
                        <i className="cart">x</i> Delete
                    </button>
                </div>
            )
        },
        width: '100px',
    },
]

export default function ShippingHistory() {
    const [datas, setData] = useState([])
    const [LoaderWrapper, dataLoader] = useLoader()
    const [loading, setLoading] = useState(true);

    let user = useSelector(state => state.user);

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                try {
                    await user.browseHistoryRef().once('value', (snapshot) => {
                        const fetched = (snapshot.val() || [])?.reverse();
                        if (fetched) {
                            let dummy = []
                            for (let i = 0; i < fetched.length; i++) {
                                for (let j = 0; j < fetched.length; j++) {
                                    if (fetched[i]?.history_date > fetched[j]?.history_date) {
                                        let clone = {...fetched[i]};
                                        fetched[i] = fetched[j];
                                        fetched[j] = clone;
                                    }
                                }
                            }
                            setData(fetched || dummy)
                            setLoading(false);
                        } else setData([]);
                    })
                } catch (e) {
                    window.location = '/login'
                }
            }
            dataLoader(fetchData)
        }
        
    }, [user])

    const tableData = {
        columns: createColumns(user),
        data: datas,
    }

    if (loading) return <Spinner bg="dark" />;
    return (
        <LoaderWrapper>
            <Container className="browse-history-container" style={ContainerStyle}>
                <Heading style={HeadingStyle}> Browse history </Heading>

                <DataTable
                    title="Cart"
                    columns={tableData.columns}
                    data={tableData.data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    selectableRows
                    customStyles={{
                        table: {
                            style: {
                                fontFamily: 'Montserrat',
                            },
                        },
                        headCells: {
                            style: {
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                fontWeight: 'bold',
                                color: '#504D4D',
                            },
                            draggingStyle: {
                                cursor: 'move',
                            },
                        },

                        headRow: {
                            style: {
                                minHeight: '52px',
                                borderBottomWidth: '1px',
                                borderBottomStyle: 'solid',
                                backgroundColor: '#F0EFEF',
                                // overflowWrap: 'break-word'
                            },
                            denseStyle: {
                                minHeight: '32px',
                            },
                        },
                    }}
                />
            </Container>
        </LoaderWrapper>
    )
}
