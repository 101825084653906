import styled from "styled-components";

const fontStyle = {
  fontSize: "12px",
  fontWeight: "600",
  color: "#8E8D8F",
  textAlign: "left",
};

function Input({
  label = null,
  style,
  placeholder = "",
  onChange,
  name,
  type = "text",
  value,
  id,
  ref,
}) {
  return (
    <div>
      <label style={fontStyle}>
        {/* <Heading style={fontStyle}>{UpperCase(label)}</Heading> */}
        {label}
      </label>
      <InputWrapper
        style={style}
        id={id}
        placeholder={placeholder}
        type={type}
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
      />
    </div>
  );
}


function InputSelection({
  children,
  label = null,
  style,
  placeholder = "",
  onChange,
  name,
  value,
  id,
  ref,
}) {
  return (
    <div>
      <label style={fontStyle}> {label}</label>
      <InputSelectionWrapper
        style={style}
        id={id}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
      >{children}</InputSelectionWrapper>
    </div>
  );
}

const InputWrapper = styled.input`
  border: solid 1px #C2C1C1;

// background-color: #eef2f5;
  height: 37px;
  width: 100%;
  outline: none;
  text-indent: 10px;
  font-family: Open Sans;
  &:focus {
    outline: #072a48;
    box-shadow: 0px 0px 2px #072a48;
  }
  ${(props) => props.style}
`;

const InputSelectionWrapper = styled.select`
  border: solid 1px #C2C1C1;
  // background-color: #eef2f5;
  height: 37px;
  width: 89.5%;
  display: block;
  outline: none;
  text-indent: 10px;
  font-family: Open Sans;
  &:focus {
    outline: #072a48;
    box-shadow: 0px 0px 2px #072a48;
  }
  ${(props) => props.style}
`;

export {
  Input,
  InputSelection
}
