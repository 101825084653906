import styled from 'styled-components';
import { motion } from "framer-motion";

let buttonStyle = {
  backgroundColor:"#072A48",
  border:"none",
  color:"white",
  padding:"15px",
  fontFamily:"Open Sans",
  width:"150px",
  cursor:"pointer",
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
}

let disabledButton = {
  cursor: "default",
  backgroundColor: "rgb(62 96 112 / 62%)"
}

export default function Button({ style, text, onClick, disabled=false, Icon }) {

  return (
    <button style={!disabled ? {...style, ...buttonStyle} : {...style, ...buttonStyle, ...disabledButton}} onClick={onClick}
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.98 }}
      disabled={disabled}
    >
      {
        text
      }
    </button>
  )
}