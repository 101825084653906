import { Box, Center, Flex, Heading, Image } from "@chakra-ui/react"

function SuccessfulCases() {
  return (
    <Box w="full" bg="rgba(0, 0, 0, .025)">
        <Center>
            <Flex direction="column" alignItems="center" maxW="80rem" pt="20" pb="20">
                <Heading color="black" fontSize="3xl" textAlign="center" mb="20">Successful Cases</Heading>
                <Flex gap={12} direction={["column", "column", "column", "row", "row"]} maxW="70rem">
                    <Flex direction="column" alignItems="center" bg="white" borderRadius="xl" p="7" boxShadow="lg" flex="1">
                        <Image src="/avatar.png" position="absolute" mt="-16" alt="" />
                        <Flex mb="8" mt="8" alignItems="center">
                            <Box color="black" mr="1" fontSize="lg">Ruby Sligo</Box>
                            <Box color="rgba(0, 0, 0, .4)" fontSize="sm">(Ruby Sligo)</Box>
                        </Flex>
                        <Box lineHeight={2}>I usually spend a lot of time communicating with vendors which took a lot of my time. With Superbuy SBS, I never had to speak to a vendor again and they can handle all after-sales follow up for me. You wouldn't believe it until you have tried it.</Box>
                    </Flex>
                    <Flex direction="column" alignItems="center" bg="white" borderRadius="xl" p="7" boxShadow="lg" flex="1">
                        <Image src="/avatar.png" position="absolute" mt="-16" alt="" />
                        <Flex mb="8" mt="8" alignItems="center">
                            <Box color="black" mr="1" fontSize="lg">Corey M. Rand</Box>
                            <Box color="rgba(0, 0, 0, .4)" fontSize="sm">(United States)</Box>
                        </Flex>
                        <Box lineHeight={2}>You can literally buy everything from China! My favorite are shops in Taobao and Tmall. They also provide complimentary quality inspection service order automation that made scaling my business so easy.</Box>
                    </Flex>
                </Flex>
            </Flex>
        </Center>
    </Box>
  )
}

export default SuccessfulCases