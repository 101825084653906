import styled from 'styled-components'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import { SharedImage } from 'constants/image-constant'
import ImageWrapper from 'components/image-wrapper'
import Input from 'components/input'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, div, CardActions } from '@mui/material'
import * as React from 'react'
//Firebase

import { auth } from 'firebase.js'
import { db } from 'firebase.js'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'

const HeadingStyle = {
    textAlign: 'left',
    margin: '0px',
}

const HeadingStyle2 = {
    fontSize: '18px',
    fontWeight: '400',
}

const ContainerStyle = {
    padding: '50px 20px',
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
}

const ButtonStyle = {
    marginTop: '50px',
}

const { Profile } = SharedImage

export default function Status() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Container style={ContainerStyle}>
                <Card sx={{ maxWidth: 1000 }}>
                    <div>
                        <h1
                            style={{
                                margin: '1rem 0',
                                fontSize: '3.4rem',
                                padding: '16px',
                            }}
                        >
                            Order Statuses
                        </h1>
                        <CardContent style={{ paddingTop: '0' }}>
                            <p>
                                Have you just ordered from KgHype and are
                                wondering what is the difference or how to check
                                the order statuses well this guide will explain
                                that in detail.
                            </p>
                            <PragraphText>
                                <h2>Unpaid</h2>
                                Unpaid means that you have not yet paid for your
                                order and you can click on the Pay now button to
                                do so. You have a 72-hour timer from initiating
                                the order this is because conversion rates
                                change frequently and prices for goods may
                                change within that time so it is important to
                                pay on time.
                            </PragraphText>
                            <PragraphText>
                                <h2>Processing</h2>
                                This means that you have chosen a manual payment
                                method such as Cashapp, Venmo, Zelle, or even
                                Crypto since it takes a while for crypto to
                                process up to 1 hour. In these cases, your order
                                will be marked as processing. For manual payment
                                methods, it will be processed within 24 hours
                                but most of the time very quickly. If you need
                                it processed even quicker feel free to open up a
                                ticket in our discord ad we would be happy to
                                help you.
                            </PragraphText>
                            <PragraphText>
                                <h2>Paid</h2>
                                This means that your order has been confirmed to
                                be paid and is awaiting purchasing. The
                                purchasing process usually takes 48 hours but
                                most of the time the same day. We aim to
                                purchase items as fast as possible so you can
                                get the items you enjoy shipped out quicker.
                            </PragraphText>
                            <PragraphText>
                                <h2>On The way</h2>
                                This means that your items are purchased from
                                the seller and are awaiting to arrive at our
                                local warehouse in china. This process can take
                                about 3-5 days but sometimes more depending on
                                the seller. Feel free to open a ticket in the
                                discord and we wold be happy to assist you with
                                support.
                            </PragraphText>
                            <PragraphText>
                                <h2>Stored in Warehouse</h2>
                                When your item is stored in the warehouse you
                                will be able to inspect the item to see if you
                                want to keep or return it. Returns are handled
                                on a case-by-case basis so please be careful but
                                most of the time you can return for a refund or
                                exchange{' '}
                            </PragraphText>
                            <PragraphText>
                                <h2>Unpaid Shipping Fee</h2>
                                This means that you have started an
                                international shipping order but did not pay for
                                it just like the unpaid status you can click pay
                                now to pay for the order and it will be shipped
                                out
                            </PragraphText>
                            <PragraphText>
                                <h2>Shipped Internationally </h2> This is when
                                your items have been consolidated into one
                                package and shipped to your address.{' '}
                            </PragraphText>
                            <PragraphText>
                                <h2>Canceled</h2>
                                This means that the order has been canceled by
                                you or the patent has failed. This can happen if
                                manual payments are not confirmed to be paid or
                                if the order cannot be fulfilled you will be
                                refunded to your KgHype balance.
                            </PragraphText>
                            <PragraphText>
                                <h2>Refunded</h2>
                                This can happen upon your request or if the
                                seller has not shipped we will automatically
                                give you a refund or if the item is unavailable
                                we will refund you to your KgHype balance and
                                you can withdraw to what payment method works
                                best for you.{' '}
                            </PragraphText>
                        </CardContent>
                    </div>
                    <CardActions></CardActions>
                </Card>
            </Container>
        </div>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const SideMenu = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: Open Sans;
    padding: 0px;
    margin: 0px;
    padding-top: 50px;
`

const Content = styled.section`
    padding-left: 100px;
    max-width: 100%;
    flex: 1;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
    }
`

const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 50px 0px;
    align-items: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

const TextWrapper = styled.div`
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
        padding-top: 30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding: 0px;
    margin: 0px;
    padding: 0px;
    @media screen and (max-width: 800px) {
        text-align: center;
    }
`
const PragraphText = styled.p`
    padding: 1rem 0;

    h2 {
        padding: 0.4rem 0;
    }
`
