import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { setUser } from "store/reducers/userReducer/userReducer";
import { getCurrentLoggedInUser } from "utilities/session";
import Views from "./views";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-utilities.min.css";
import "react-tabs/style/react-tabs.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import "./styles/main.scss";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		(async() => {
			//Get user if exists
			let user = await getCurrentLoggedInUser().catch(() => dispatch(setUser(0)));
			if (user) dispatch(setUser(user));
			else dispatch(setUser(0));
		})();
	}, []);
	
	return (
		<Router>
			<Switch>
				<Route>
					<Views />
					<TawkMessengerReact
						propertyId="631de1df54f06e12d893fdc9"
						widgetId="1gcmbkkqn"
					/>
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
