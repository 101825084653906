import { Box, Button, Center, Flex, Heading, Image } from "@chakra-ui/react";

export function B2BServices() {
  return (
    <Box position="relative" w="full" my={10}>
      <Image
        src="/middle_banner.png"
        zIndex={1}
        top={0}
        w="full"
        mt="100"
        minH={["full"]}
        alt=""
      />

      <Flex direction="column" alignItems="center" mt="10">
        <Heading
          position="relative"
          color="white"
          textAlign="center"
          fontSize="3xl"
          mt={["110", "110", "110", "140", "140"]}
          mb="10"
          zIndex={2}
        >
          One-click auto access of e-commerce business, to achieve complete B2B
          service solution
        </Heading>
        <Button
          rounded="full"
          bg="blue"
          color="white"
          zIndex={2}
          w="min-content"
          px={16}
          py={8}
          fontWeight="normal"
          fontSize="lg"
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  );
}
