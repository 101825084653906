import styled from 'styled-components'
import { CircularProgress } from '@mui/material'
import { RiSearch2Line } from 'react-icons/ri'
import { motion } from 'framer-motion'

export default function SearchBox({
    onChange,
    onClick,
    name = 'search',
    setLoading,
    loading,
}) {
    return (
        <InputWrapper
            style={{border: '1px solid #EFEFEF'}}
            onSubmit={(e) => {
                e.preventDefault()
                setLoading(true)
                onClick(e)
            }}
        >
            <IconWrapper>
                <RiSearch2Line />
            </IconWrapper>
            <SearchInput
                onChange={onChange}
                name={name}
                type="text"
                placeholder="Buy Anything From Weidian,Taobao And 1688"
                style={{fontSize: "15px", paddingLeft:"10px"}}
                autocomplete="off"
            />
            <SearchBtn
                type="submit"
                initial={{ scale: 1 }}
                whileHover={{ scale: loading ? 1 : 1.05 }}
                whileTap={{ scale: loading ? 1 : 0.9 }}
            >
                {loading ? (
                    <CircularProgress size={25} color="inherit" />
                ) : (
                    'Search'
                )}
            </SearchBtn>
        </InputWrapper>
    )
}

const InputWrapper = styled.form`
    background-color: white;
    height: 60px;
    width: 70%;
    margin: 0px 4px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    @media (max-width: 400px) {
        padding: 1px;
    }
`

const IconWrapper = styled.div`
    width: 25px;
    height: 25px;
    color: #404040;
    margin-left: 10px;

    & svg {
        width: 100%;
        height: 100%;
    }
`

const SearchInput = styled.input`
    all: unset;
    flex: 1;
    height: 100%;
    border: none;
    &:placeholder {
        color: #191919;
        font-size: 10px;
    }
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0) !important;
`

const SearchBtn = styled(motion.button)`
    all: unset;
    /* background-color: #fa7a50; */
    background-color: #02bee4;
    color: white;
    height: 100%;
    width: 120px;
    border: none;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`
