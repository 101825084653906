import React from "react";
//images
import cigarateImg from "../../../assets/image/cigarate.jpg";
import skinCream from "../../../assets/image/skin_cream.jpg";
import butcher from "../../../assets/image/butcher.png";
import medicine from "../../../assets/image/medicine.png";
import liquide from "../../../assets/image/liquide.png";
import lipstick from "../../../assets/image/lip_stick.png";

const TabAgent = () => {
  return (
    <div className="TabAgent">
      <div className="TabAgent__item">
        <h3 className="TabAgent__title">Prohibited Items</h3>
        <span className="TabAgent__sub">
          ·Tobacco, alcohol, and contraband items are not accepted.
        </span>
        <p className="TabAgent__pera">
          Restricted items include but are not limited to the following items:
          Swiss army knife, kitchen knives set, kitchen knife, the paper /letter
          knives, gardening knife/scissors/saw, seam ripper, pure
          fruit/vegetable juice, lighters, wine, putty knife,
          cheese/western-style cheese, crabs, bamboo/wooden sword knife, knife
          CARDS/universal knife CARDS, ice skates, other outdoor cutting tools,
          magnetic components, Swiss army knife, endmill, burin, smoke cigarette
          label/card, vajra bell/vajra/ye-tomahawk, knife, seeds,etc.
        </p>
        <div className="TabAgent__images">
          <img className="TabAgent__img" src={cigarateImg} alt="butcher" />
          <img className="TabAgent__img" src={skinCream} alt="butcher" />
          <img className="TabAgent__img" src={butcher} alt="butcher" />
        </div>
      </div>
      <div className="TabAgent__item">
        <h3 className="TabAgent__title">Limited Items</h3>
        <span className="TabAgent__sub">
          ·Medicine, Liquids, creams, powder, and cosmetics items are limited in
          shipping by Chinese exporting goods. Contact{" "}
          <a href="#!">【Customer support】</a> for details before purchasing.
        </span>

        <div className="TabAgent__images">
          <img className="TabAgent__img" src={medicine} alt="butcher" />
          <img className="TabAgent__img" src={liquide} alt="butcher" />
          <img className="TabAgent__img" src={lipstick} alt="butcher" />
          <img className="TabAgent__img" src={skinCream} alt="butcher" />
        </div>
      </div>
      <div className="TabAgent__item">
        <h3 className="TabAgent__title">Kindly Remind</h3>
        <span className="TabAgent__sub">
          [About Customs] due to the differences in countries' customs policies,
          some items are sensitive. So that prohibited items, limited items,
          limited logistics, and limited weight and volume exist.
        </span>
      </div>
    </div>
  );
};

export default TabAgent;
