import { Box, Center, Flex, Heading, Image as ChakraImage } from "@chakra-ui/react"
import channelData from "./ChannelIntroData"

function ChannelCooperationIntro() {
  return (
    <Box>
        <Center>
            <Flex direction="column" alignItems="center">
                <Heading color="black" fontSize="3xl" pt="20" pb="12" textAlign="center">If you are</Heading>
                <Flex gap={5} direction={["column", "column", "column", "row", "row"]} wrap="wrap">
                  {channelData.map(({image, name}) => (
                    <Flex
                        direction="column"
                        alignItems="center"
                        maxW="12rem"
                        key={name}
                    >
                        <ChakraImage
                        src={image}
                        cursor="pointer"
                        />
                        <Box textAlign="center" mt="5" cursor="pointer">
                        {name}
                        </Box>
                    </Flex>
                  ))}
                </Flex>
            </Flex>
        </Center>
    </Box>
  )
}

export default ChannelCooperationIntro