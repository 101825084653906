import { NotifyFail, NotifySuccess } from 'utilities'

function addToFavorites(item, user) {
    return new Promise(async (resolve, reject) => {
        let userFavorites = [];

        user.favoriteProductsRef().once("value", async snap => {
            userFavorites = [...Object.values(snap.val() || {})];

            for (let prod of userFavorites) {
                if (prod.num_iid == item.num_iid) {
                    NotifyFail('Product already favorited')
                    return;
                }
            }

            userFavorites.push(item);

            try {
                await user.favoriteProductsRef().update(Object.assign({}, userFavorites));
                return resolve();
            } catch (e) {
                return reject(e);
            }
        })
    })
}

const addToFavoriteButton = async (item, userInfo) => {
    return new Promise(async (resolve, reject) => {
        try {
            await addToFavorites(item, userInfo);
            NotifySuccess('Product favorited successfully');
            return resolve();
        } catch (error) {
            console.log(error)
            return reject(error)
        }
    })
}

export { addToFavoriteButton }
