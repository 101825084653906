import React from 'react'
import styled from 'styled-components'
import { FaClipboardCheck } from 'react-icons/fa'

const RewardCardCmp = ({ children }) => {
    return (
        <RewardCard>
            <CardBody>
                <RewardHeader>
                    <RewardHeaderText>
                        <h5>Reddit Reviews</h5>
                        <p>
                            Make a review showcasing usage of our service at
                            Reddit subs (fashionreps/ Repsneakers/ DesignerReps)
                            and you can get rewards according to upvotes and
                            impressions.
                        </p>
                    </RewardHeaderText>
                    <RewardHeaderPrice>
                        <h3>
                            <span>$20-$50</span>
                            <span>Per Review</span>
                        </h3>
                    </RewardHeaderPrice>
                </RewardHeader>
                <CardContent>
                    <CheckList>
                        <li>
                            <span>
                                <FaClipboardCheck />
                            </span>
                            10-50 Upvotes:$5
                        </li>
                        <li>
                            <span>
                                <FaClipboardCheck />
                            </span>
                            51-100 Upvotes:$15
                        </li>
                        <li>
                            <span>
                                <FaClipboardCheck />
                            </span>
                            101-500 Upvotes:$25-Up To Diamond
                        </li>
                        <li>
                            <span>
                                <FaClipboardCheck />
                            </span>
                            Over 500 Upvotes:$50-Up To Vip
                        </li>
                    </CheckList>
                    <Button
                        href="https://forms.gle/zKrijQAVuxPa1i3j7"
                        target="_blank"
                    >
                        Review Now
                    </Button>
                </CardContent>
            </CardBody>
            {children}
        </RewardCard>
    )
}

const Button = styled.a`
    border: none;
    border-radius: 10px;
    padding: 20px 30px;
    outline: none;
    font-size: 20px;
    color: #072a48;
    background: transparent;
    cursor: pointer;
    border: 1px solid #072a48;
    text-decoration: none;
`

const RewardCard = styled.div`
    border-radius: 20px;
    overflow: hidden;
    flex: 48%;
    box-shadow: 0px 20px 40px #71717117;
    border-radius: 20px;
    position: relative;
`
const CardBody = styled.div``
const RewardHeader = styled.header`
    display: flex;
    color: #fff;
    background-color: #072a48;
    padding: 20px;
    gap: 100px;
    align-items: center;
    @media screen and (max-width: 768px) {
        display: block;
    }
`
const RewardHeaderText = styled.div`
    h5 {
        font-size: 25px;
        padding: 0;
        margin: 0;
    }
`
const RewardHeaderPrice = styled.div`
    span {
        display: block;
        &:last-child {
            font-size: 14px;

            @media screen and (max-width: 768px) {
                text-align: start;
            }
        }
        &:first-child {
            font-size: 30px;
        }
    }
`
const CardContent = styled.div`
    padding: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: block;
        button {
            margin-top: 30px;
        }
    }
`

const ContentFloat = styled.div``

const CheckList = styled.ul`
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
    li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }
    span {
        color: #072a48;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(7, 42, 72, 0.1);
        margin-right: 20px;
    }
`

export default RewardCardCmp
