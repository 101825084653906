import {useState, useEffect} from 'react';

import styled from 'styled-components'
import ImageWrapper from 'components/image-wrapper'

import HeroStats from 'assets/image/kghype_afilliate.png'

import Feature1Image from 'assets/image/kghype_afilliate_f1.png'
import Feature2Image from 'assets/image/kghype_afilliate_f2.png'
import Feature3Image from 'assets/image/kghype_afilliate_f3.png'

import Info1Image from 'assets/image/kghype_afilliate_i1.png'
import Info1Stats from 'assets/image/kghype_afilliate_i1s.png'

import Info2Image from 'assets/image/kghype_afilliate_i2.png'
import Info2Stats from 'assets/image/kghype_afilliate_i2s.png'

export default function Affiliate() {
    return (
        <div style={{ display: 'block', width: '100%' }}>
            <HeroWrapper>
                <HeroContainer>
                    <HeroText>
                        <HeroHeaderText>
                            JOIN OUR ADVANCED
                            <br />
                            AFFILIATE PROGRAM
                        </HeroHeaderText>
                        <HeroHeaderSubtext>
                            Who are KGHYPE Affiliates?
                            <br /> Individuals and business customers who
                            <br />
                            promote through the KGHYPE Affiliates
                            <br /> marketing system.
                        </HeroHeaderSubtext>

                        <HeroButtons>
                            <HeroPrimaryButton onClick={() => window.location.href = "/affiliate"}>JOIN NOW</HeroPrimaryButton>
                            <HeroSecondaryButton>Explore</HeroSecondaryButton>
                        </HeroButtons>
                    </HeroText>
                    <HeroImage>
                        <ImageWrapper src={HeroStats} alt="hero-stats" />
                    </HeroImage>
                </HeroContainer>
            </HeroWrapper>

            <FeaturesWrapper>
                <FeaturesHeadText>Our Affiliate Program</FeaturesHeadText>
                <FeaturesHeader>
                    Features we offer to our affiliates
                </FeaturesHeader>

                <Features>
                    <Feature>
                        <FeatureImage>
                            <ImageWrapper src={Feature1Image} />
                        </FeatureImage>
                        <FeatureTitle>Good Payouts</FeatureTitle>
                        <FeatureDesc>
                            We pride ourselves in having <br /> the best
                            commission rates <br /> and fast payouts
                        </FeatureDesc>
                        <FeatureButton>Explore</FeatureButton>
                    </Feature>
                    <Feature>
                        <FeatureImage>
                            <ImageWrapper src={Feature2Image} />
                        </FeatureImage>
                        <FeatureTitle>Instant Withdrawals</FeatureTitle>
                        <FeatureDesc>
                            We allow our users to withdraw <br /> instantly no
                            longer will you
                            <br /> have to wait 30 days <br /> like other
                            affiliate programs
                        </FeatureDesc>
                        <FeatureButton>Explore</FeatureButton>
                    </Feature>
                    <Feature>
                        <FeatureImage>
                            <ImageWrapper src={Feature3Image} />
                        </FeatureImage>
                        <FeatureTitle>Advanced Statistics</FeatureTitle>
                        <FeatureDesc>
                            We offer detailed stats <br /> on how you are you
                            are performing
                        </FeatureDesc>
                        <FeatureButton>Explore</FeatureButton>
                    </Feature>
                </Features>
            </FeaturesWrapper>

            <InfosWrapper>
                <InfoWrapper>
                    <InfoImage>
                        <ImageWrapper src={Info1Image} />
                        <InfoStatsImage src={Info1Stats} />
                    </InfoImage>

                    <InfoText>
                        <InfoHeader>
                            We Offer A Premium Affiliate Program With High
                            Commissions
                        </InfoHeader>

                        <InfoList>
                            <InfoListItem>Promotion Link</InfoListItem>
                            <InfoListItem>Compensation Guaranteed</InfoListItem>
                            <InfoListItem>5% Commission Rate</InfoListItem>
                        </InfoList>

                        <FeatureButton>Start Now</FeatureButton>
                    </InfoText>
                </InfoWrapper>

                <InfoWrapper>
                    <InfoText>
                        <InfoHeader>
                            Many Ways To Promote to Earn high amount of money.
                        </InfoHeader>

                        <InfoListBox>1. Discord Servers</InfoListBox>
                        <InfoListBox>2. YouTube/TikTok Videos</InfoListBox>
                        <InfoListBox>3. Reddit</InfoListBox>
                    </InfoText>

                    <InfoImage>
                        <ImageWrapper src={Info2Image} />
                        <InfoStatsImage src={Info2Stats} />
                    </InfoImage>
                </InfoWrapper>
            </InfosWrapper>
        </div>
    )
}

const HeroWrapper = styled.div`
    width: 100%;
    height: 872px;
    background: #0a2640;
    display: flex;
    justify-content: center;
`

const HeroContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    margin: 0 4rem;
    align-items: center;

    @media (max-width: 860px) {
        padding: 30vh 0;
        flex-direction: column-reverse;
    }

    @media (max-width: 600px) {
    }
`

const HeroText = styled.div`
    color: white;

    @media (max-width: 860px) {
        margin-top: 2rem;
        text-align: center;
    }
`

const HeroHeaderText = styled.div`
    font-weight: 400;
    font-size: 58px;
    color: #ffffff;
    margin-bottom: 16px;

    @media (max-width: 460px) {
        font-size: 46px;
    }
`

const HeroHeaderSubtext = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 20px;
    color: #f1f1f1;
    margin-bottom: 32px;

    @media (max-width: 460px) {
        font-size: 16px;
    }
`

const HeroButtons = styled.div`
    display: flex;

    @media (max-width: 860px) {
        justify-content: center;
    }
`

const HeroPrimaryButton = styled.button`
    background: #65e4a3;
    border: 2px solid #69e6a6;
    border-radius: 36px;
    padding: 12px 48px;
    color: #0a2640;
    cursor: pointer;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
    margin-right: 24px;

    @media (max-width: 460px) {
        margin-right: 12px;
        font-size: 16px;
    }
`

const HeroSecondaryButton = styled.button`
    border: 2px solid white;
    border-radius: 36px;
    padding: 12px 48px;
    color: white;
    cursor: pointer;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
    background: #0a2640;

    @media (max-width: 460px) {
        font-size: 16px;
    }
`

const HeroImage = styled.div`

@media (max-width: 460px) {
    width: 80%;
`

const FeaturesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
`

const FeaturesHeadText = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    color: #777777;
`

const FeaturesHeader = styled.div`
    font-weight: 400;
    font-size: 48px;
    color: #000000;
    margin-top: 24px;
    text-align: center;

    @media (max-width: 460px) {
        font-size: 36px;
    }
`

const Features = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;

    @media (max-width: 800px) {
        flex-direction: column;
        text-align: center;
    }
`

const Feature = styled.div`
    margin: 1rem 3rem;
`

const FeatureImage = styled.div``

const FeatureTitle = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    margin: 12px 0;
`

const FeatureDesc = styled.div`
    font-family: 'Open Sans';
    font-size: 20px;
    color: #777777;
    margin: 12px 0;
`

const FeatureButton = styled.button`
    border-radius: 16px;
    padding: 16px 36px;
    margin-top: 6px;
    background: #0a2640;
    color: white;
    border: none;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
`

const InfosWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 5rem 1rem;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`

const InfoImage = styled.div`
    position: relative;
`

const InfoStatsImage = styled.img`
    position: absolute;
    right: 50px;
    width: 50%;
    bottom: -6rem;
`

const InfoText = styled.div`
    padding: 3rem;

    @media (max-width: 400px) {
        padding: 3rem 1rem;
    }
`

const InfoHeader = styled.div`
    font-weight: 400;
    font-size: 36px;
    color: #000000;

    @media (max-width: 800px) {
        margin-top: 1rem;
    }
`

const InfoList = styled.ul`
    padding: 0;
    list-style-type: none;
`

const InfoListItem = styled.li`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    margin-top: 12px;
`

const InfoListBox = styled.div`
    background: #ffffff;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-top: 2rem;
    padding: 1.5rem;
`
