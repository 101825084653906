import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

export default function AddressEdit({addressData, country, setCountry, editAddress, setAddressEditPopup}) {
    return (
        <div id="address-edit-popup" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.45)"
        }}  onClick={(event) => {if (event.target == event.currentTarget) setAddressEditPopup(false) }}>
            <div style={{
                padding: "15px",
                backgroundColor: "white",
                borderRadius: "10px",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                flexDirection: "column",
                width: "70%"
            }}>
                <div style={{display: "flex", justifyContent: "center", marginTop: 10, flexWrap: "wrap", maxWidth: "100%", position: "relative"}}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            flexWrap: "wrap",
                            marginBottom: "20px"
                        }}
                    >
                        <TextField
                            id="name"
                            label="Consignee Name"
                            variant="outlined"
                            defaultValue={addressData.name}
                        />
                        <FormControl fullWidth>
                            <InputLabel>Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="country"
                                label="Age"
                                defaultValue={country || addressData.country}
                                onChange={(event) => setCountry(event.target.value)}
                            >
                                <MenuItem value={"Afghanistan"}>Afghanistan</MenuItem>
                                    <MenuItem value={"Albania"}>Albania</MenuItem>
                                    <MenuItem value={"Algeria"}>Algeria</MenuItem>
                                    <MenuItem value={"American Samoa"}>American Samoa</MenuItem>
                                    <MenuItem value={"Andorra"}>Andorra</MenuItem>
                                    <MenuItem value={"Angola"}>Angola</MenuItem>
                                    <MenuItem value={"Anguilla"}>Anguilla</MenuItem>
                                    <MenuItem value={"Antarctica"}>Antarctica</MenuItem>
                                    <MenuItem value={"Antigua and Barbuda"}>Antigua and Barbuda</MenuItem>
                                    <MenuItem value={"Argentina"}>Argentina</MenuItem>
                                    <MenuItem value={"Armenia"}>Armenia</MenuItem>
                                    <MenuItem value={"Aruba"}>Aruba</MenuItem>
                                    <MenuItem value={"Australia"}>Australia</MenuItem>
                                    <MenuItem value={"Austria"}>Austria</MenuItem>
                                    <MenuItem value={"Azerbaijan"}>Azerbaijan</MenuItem>
                                    <MenuItem value={"Bahamas"}>Bahamas</MenuItem>
                                    <MenuItem value={"Bahrain"}>Bahrain</MenuItem>
                                    <MenuItem value={"Bangladesh"}>Bangladesh</MenuItem>
                                    <MenuItem value={"Barbados"}>Barbados</MenuItem>
                                    <MenuItem value={"Belarus"}>Belarus</MenuItem>
                                    <MenuItem value={"Belgium"}>Belgium</MenuItem>
                                    <MenuItem value={"Belize"}>Belize</MenuItem>
                                    <MenuItem value={"Benin"}>Benin</MenuItem>
                                    <MenuItem value={"Bermuda"}>Bermuda</MenuItem>
                                    <MenuItem value={"Bhutan"}>Bhutan</MenuItem>
                                    <MenuItem value={"Bolivia (Plurinational State of)"}>Bolivia (Plurinational State of)</MenuItem>
                                    <MenuItem value={"Bonaire, Sint Eustatius and Saba"}>Bonaire, Sint Eustatius and Saba</MenuItem>
                                    <MenuItem value={"Bosnia and Herzegovina"}>Bosnia and Herzegovina</MenuItem>
                                    <MenuItem value={"Botswana"}>Botswana</MenuItem>
                                    <MenuItem value={"Bouvet Island"}>Bouvet Island</MenuItem>
                                    <MenuItem value={"Brazil"}>Brazil</MenuItem>
                                    <MenuItem value={"British Indian Ocean Territory"}>British Indian Ocean Territory</MenuItem>
                                    <MenuItem value={"Brunei Darussalam"}>Brunei Darussalam</MenuItem>
                                    <MenuItem value={"Bulgaria"}>Bulgaria</MenuItem>
                                    <MenuItem value={"Burkina Faso"}>Burkina Faso</MenuItem>
                                    <MenuItem value={"Burundi"}>Burundi</MenuItem>
                                    <MenuItem value={"Cabo Verde"}>Cabo Verde</MenuItem>
                                    <MenuItem value={"Cambodia"}>Cambodia</MenuItem>
                                    <MenuItem value={"Cameroon"}>Cameroon</MenuItem>
                                    <MenuItem value={"Canada"}>Canada</MenuItem>
                                    <MenuItem value={"Cayman Islands"}>Cayman Islands</MenuItem>
                                    <MenuItem value={"Central African Republic"}>Central African Republic</MenuItem>
                                    <MenuItem value={"Chad"}>Chad</MenuItem>
                                    <MenuItem value={"Chile"}>Chile</MenuItem>
                                    <MenuItem value={"China"}>China</MenuItem>
                                    <MenuItem value={"Christmas Island"}>Christmas Island</MenuItem>
                                    <MenuItem value={"Cocos (Keeling) Islands"}>Cocos (Keeling) Islands</MenuItem>
                                    <MenuItem value={"Colombia"}>Colombia</MenuItem>
                                    <MenuItem value={"Comoros"}>Comoros</MenuItem>
                                    <MenuItem value={"Congo (the Democratic Republic of the)"}>Congo (the Democratic Republic of the)</MenuItem>
                                    <MenuItem value={"Congo"}>Congo</MenuItem>
                                    <MenuItem value={"Cook Islands"}>Cook Islands</MenuItem>
                                    <MenuItem value={"Costa Rica"}>Costa Rica</MenuItem>
                                    <MenuItem value={"Croatia"}>Croatia</MenuItem>
                                    <MenuItem value={"Cuba"}>Cuba</MenuItem>
                                    <MenuItem value={"Curaçao"}>Curaçao</MenuItem>
                                    <MenuItem value={"Cyprus"}>Cyprus</MenuItem>
                                    <MenuItem value={"Czechia"}>Czechia</MenuItem>
                                    <MenuItem value={"Côte d'Ivoire"}>Côte d'Ivoire</MenuItem>
                                    <MenuItem value={"Denmark"}>Denmark</MenuItem>
                                    <MenuItem value={"Djibouti"}>Djibouti</MenuItem>
                                    <MenuItem value={"Dominica"}>Dominica</MenuItem>
                                    <MenuItem value={"Dominican Republic"}>Dominican Republic</MenuItem>
                                    <MenuItem value={"Ecuador"}>Ecuador</MenuItem>
                                    <MenuItem value={"Egypt"}>Egypt</MenuItem>
                                    <MenuItem value={"El Salvador"}>El Salvador</MenuItem>
                                    <MenuItem value={"Equatorial Guinea"}>Equatorial Guinea</MenuItem>
                                    <MenuItem value={"Eritrea"}>Eritrea</MenuItem>
                                    <MenuItem value={"Estonia"}>Estonia</MenuItem>
                                    <MenuItem value={"Eswatini"}>Eswatini</MenuItem>
                                    <MenuItem value={"Ethiopia"}>Ethiopia</MenuItem>
                                    <MenuItem value={"Falkland Islands [Malvinas]"}>Falkland Islands [Malvinas]</MenuItem>
                                    <MenuItem value={"Faroe Islands"}>Faroe Islands</MenuItem>
                                    <MenuItem value={"Fiji"}>Fiji</MenuItem>
                                    <MenuItem value={"Finland"}>Finland</MenuItem>
                                    <MenuItem value={"France"}>France</MenuItem>
                                    <MenuItem value={"French Guiana"}>French Guiana</MenuItem>
                                    <MenuItem value={"French Polynesia"}>French Polynesia</MenuItem>
                                    <MenuItem value={"French Southern Territories"}>French Southern Territories</MenuItem>
                                    <MenuItem value={"Gabon"}>Gabon</MenuItem>
                                    <MenuItem value={"Gambia"}>Gambia</MenuItem>
                                    <MenuItem value={"Georgia"}>Georgia</MenuItem>
                                    <MenuItem value={"Germany"}>Germany</MenuItem>
                                    <MenuItem value={"Ghana"}>Ghana</MenuItem>
                                    <MenuItem value={"Gibraltar"}>Gibraltar</MenuItem>
                                    <MenuItem value={"Greece"}>Greece</MenuItem>
                                    <MenuItem value={"Greenland"}>Greenland</MenuItem>
                                    <MenuItem value={"Grenada"}>Grenada</MenuItem>
                                    <MenuItem value={"Guadeloupe"}>Guadeloupe</MenuItem>
                                    <MenuItem value={"Guam"}>Guam</MenuItem>
                                    <MenuItem value={"Guatemala"}>Guatemala</MenuItem>
                                    <MenuItem value={"Guernsey"}>Guernsey</MenuItem>
                                    <MenuItem value={"Guinea"}>Guinea</MenuItem>
                                    <MenuItem value={"Guinea-Bissau"}>Guinea-Bissau</MenuItem>
                                    <MenuItem value={"Guyana"}>Guyana</MenuItem>
                                    <MenuItem value={"Haiti"}>Haiti</MenuItem>
                                    <MenuItem value={"Heard Island and McDonald Islands"}>Heard Island and McDonald Islands</MenuItem>
                                    <MenuItem value={"Holy See"}>Holy See</MenuItem>
                                    <MenuItem value={"Honduras"}>Honduras</MenuItem>
                                    <MenuItem value={"Hong Kong"}>Hong Kong</MenuItem>
                                    <MenuItem value={"Hungary"}>Hungary</MenuItem>
                                    <MenuItem value={"Iceland"}>Iceland</MenuItem>
                                    <MenuItem value={"India"}>India</MenuItem>
                                    <MenuItem value={"Indonesia"}>Indonesia</MenuItem>
                                    <MenuItem value={"Iran (Islamic Republic of)"}>Iran (Islamic Republic of)</MenuItem>
                                    <MenuItem value={"Iraq"}>Iraq</MenuItem>
                                    <MenuItem value={"Ireland"}>Ireland</MenuItem>
                                    <MenuItem value={"Isle of Man"}>Isle of Man</MenuItem>
                                    <MenuItem value={"Israel"}>Israel</MenuItem>
                                    <MenuItem value={"Italy"}>Italy</MenuItem>
                                    <MenuItem value={"Jamaica"}>Jamaica</MenuItem>
                                    <MenuItem value={"Japan"}>Japan</MenuItem>
                                    <MenuItem value={"Jersey"}>Jersey</MenuItem>
                                    <MenuItem value={"Jordan"}>Jordan</MenuItem>
                                    <MenuItem value={"Kazakhstan"}>Kazakhstan</MenuItem>
                                    <MenuItem value={"Kenya"}>Kenya</MenuItem>
                                    <MenuItem value={"Kiribati"}>Kiribati</MenuItem>
                                    <MenuItem value={"Korea (the Democratic People's Republic of)"}>Korea (the Democratic People's Republic of)</MenuItem>
                                    <MenuItem value={"Korea (the Republic of)"}>Korea (the Republic of)</MenuItem>
                                    <MenuItem value={"Kuwait"}>Kuwait</MenuItem>
                                    <MenuItem value={"Kyrgyzstan"}>Kyrgyzstan</MenuItem>
                                    <MenuItem value={"Lao People's Democratic Republic"}>Lao People's Democratic Republic</MenuItem>
                                    <MenuItem value={"Latvia"}>Latvia</MenuItem>
                                    <MenuItem value={"Lebanon"}>Lebanon</MenuItem>
                                    <MenuItem value={"Lesotho"}>Lesotho</MenuItem>
                                    <MenuItem value={"Liberia"}>Liberia</MenuItem>
                                    <MenuItem value={"Libya"}>Libya</MenuItem>
                                    <MenuItem value={"Liechtenstein"}>Liechtenstein</MenuItem>
                                    <MenuItem value={"Lithuania"}>Lithuania</MenuItem>
                                    <MenuItem value={"Luxembourg"}>Luxembourg</MenuItem>
                                    <MenuItem value={"Macao"}>Macao</MenuItem>
                                    <MenuItem value={"Madagascar"}>Madagascar</MenuItem>
                                    <MenuItem value={"Malawi"}>Malawi</MenuItem>
                                    <MenuItem value={"Malaysia"}>Malaysia</MenuItem>
                                    <MenuItem value={"Maldives"}>Maldives</MenuItem>
                                    <MenuItem value={"Mali"}>Mali</MenuItem>
                                    <MenuItem value={"Malta"}>Malta</MenuItem>
                                    <MenuItem value={"Marshall Islands"}>Marshall Islands</MenuItem>
                                    <MenuItem value={"Martinique"}>Martinique</MenuItem>
                                    <MenuItem value={"Mauritania"}>Mauritania</MenuItem>
                                    <MenuItem value={"Mauritius"}>Mauritius</MenuItem>
                                    <MenuItem value={"Mayotte"}>Mayotte</MenuItem>
                                    <MenuItem value={"Mexico"}>Mexico</MenuItem>
                                    <MenuItem value={"Micronesia (Federated States of)"}>Micronesia (Federated States of)</MenuItem>
                                    <MenuItem value={"Moldova (the Republic of)"}>Moldova (the Republic of)</MenuItem>
                                    <MenuItem value={"Monaco"}>Monaco</MenuItem>
                                    <MenuItem value={"Mongolia"}>Mongolia</MenuItem>
                                    <MenuItem value={"Montenegro"}>Montenegro</MenuItem>
                                    <MenuItem value={"Montserrat"}>Montserrat</MenuItem>
                                    <MenuItem value={"Morocco"}>Morocco</MenuItem>
                                    <MenuItem value={"Mozambique"}>Mozambique</MenuItem>
                                    <MenuItem value={"Myanmar"}>Myanmar</MenuItem>
                                    <MenuItem value={"Namibia"}>Namibia</MenuItem>
                                    <MenuItem value={"Nauru"}>Nauru</MenuItem>
                                    <MenuItem value={"Nepal"}>Nepal</MenuItem>
                                    <MenuItem value={"Netherlands"}>Netherlands</MenuItem>
                                    <MenuItem value={"New Caledonia"}>New Caledonia</MenuItem>
                                    <MenuItem value={"New Zealand"}>New Zealand</MenuItem>
                                    <MenuItem value={"Nicaragua"}>Nicaragua</MenuItem>
                                    <MenuItem value={"Niger"}>Niger</MenuItem>
                                    <MenuItem value={"Nigeria"}>Nigeria</MenuItem>
                                    <MenuItem value={"Niue"}>Niue</MenuItem>
                                    <MenuItem value={"Norfolk Island"}>Norfolk Island</MenuItem>
                                    <MenuItem value={"Northern Mariana Islands"}>Northern Mariana Islands</MenuItem>
                                    <MenuItem value={"Norway"}>Norway</MenuItem>
                                    <MenuItem value={"Oman"}>Oman</MenuItem>
                                    <MenuItem value={"Pakistan"}>Pakistan</MenuItem>
                                    <MenuItem value={"Palau"}>Palau</MenuItem>
                                    <MenuItem value={"Palestine, State of"}>Palestine, State of</MenuItem>
                                    <MenuItem value={"Panama"}>Panama</MenuItem>
                                    <MenuItem value={"Papua New Guinea"}>Papua New Guinea</MenuItem>
                                    <MenuItem value={"Paraguay"}>Paraguay</MenuItem>
                                    <MenuItem value={"Peru"}>Peru</MenuItem>
                                    <MenuItem value={"Philippines"}>Philippines</MenuItem>
                                    <MenuItem value={"Pitcairn"}>Pitcairn</MenuItem>
                                    <MenuItem value={"Poland"}>Poland</MenuItem>
                                    <MenuItem value={"Portugal"}>Portugal</MenuItem>
                                    <MenuItem value={"Puerto Rico"}>Puerto Rico</MenuItem>
                                    <MenuItem value={"Qatar"}>Qatar</MenuItem>
                                    <MenuItem value={"Republic of North Macedonia"}>Republic of North Macedonia</MenuItem>
                                    <MenuItem value={"Romania"}>Romania</MenuItem>
                                    <MenuItem value={"Russian Federation"}>Russian Federation</MenuItem>
                                    <MenuItem value={"Rwanda"}>Rwanda</MenuItem>
                                    <MenuItem value={"Réunion"}>Réunion</MenuItem>
                                    <MenuItem value={"Saint Barthélemy"}>Saint Barthélemy</MenuItem>
                                    <MenuItem value={"Saint Helena, Ascension and Tristan da Cunha"}>Saint Helena, Ascension and Tristan da Cunha</MenuItem>
                                    <MenuItem value={"Saint Kitts and Nevis"}>Saint Kitts and Nevis</MenuItem>
                                    <MenuItem value={"Saint Lucia"}>Saint Lucia</MenuItem>
                                    <MenuItem value={"Saint Martin (French part)"}>Saint Martin (French part)</MenuItem>
                                    <MenuItem value={"Saint Pierre and Miquelon"}>Saint Pierre and Miquelon</MenuItem>
                                    <MenuItem value={"Saint Vincent and the Grenadines"}>Saint Vincent and the Grenadines</MenuItem>
                                    <MenuItem value={"Samoa"}>Samoa</MenuItem>
                                    <MenuItem value={"San Marino"}>San Marino</MenuItem>
                                    <MenuItem value={"Sao Tome and Principe"}>Sao Tome and Principe</MenuItem>
                                    <MenuItem value={"Saudi Arabia"}>Saudi Arabia</MenuItem>
                                    <MenuItem value={"Senegal"}>Senegal</MenuItem>
                                    <MenuItem value={"Serbia"}>Serbia</MenuItem>
                                    <MenuItem value={"Seychelles"}>Seychelles</MenuItem>
                                    <MenuItem value={"Sierra Leone"}>Sierra Leone</MenuItem>
                                    <MenuItem value={"Singapore"}>Singapore</MenuItem>
                                    <MenuItem value={"Sint Maarten (Dutch part)"}>Sint Maarten (Dutch part)</MenuItem>
                                    <MenuItem value={"Slovakia"}>Slovakia</MenuItem>
                                    <MenuItem value={"Slovenia"}>Slovenia</MenuItem>
                                    <MenuItem value={"Solomon Islands"}>Solomon Islands</MenuItem>
                                    <MenuItem value={"Somalia"}>Somalia</MenuItem>
                                    <MenuItem value={"South Africa"}>South Africa</MenuItem>
                                    <MenuItem value={"South Georgia and the South Sandwich Islands"}>South Georgia and the South Sandwich Islands</MenuItem>
                                    <MenuItem value={"South Sudan"}>South Sudan</MenuItem>
                                    <MenuItem value={"Spain"}>Spain</MenuItem>
                                    <MenuItem value={"Sri Lanka"}>Sri Lanka</MenuItem>
                                    <MenuItem value={"Sudan"}>Sudan</MenuItem>
                                    <MenuItem value={"Suriname"}>Suriname</MenuItem>
                                    <MenuItem value={"Svalbard and Jan Mayen"}>Svalbard and Jan Mayen</MenuItem>
                                    <MenuItem value={"Sweden"}>Sweden</MenuItem>
                                    <MenuItem value={"Switzerland"}>Switzerland</MenuItem>
                                    <MenuItem value={"Syrian Arab Republic"}>Syrian Arab Republic</MenuItem>
                                    <MenuItem value={"Taiwan"}>Taiwan</MenuItem>
                                    <MenuItem value={"Tajikistan"}>Tajikistan</MenuItem>
                                    <MenuItem value={"Tanzania, United Republic of"}>Tanzania, United Republic of</MenuItem>
                                    <MenuItem value={"Thailand"}>Thailand</MenuItem>
                                    <MenuItem value={"Timor-Leste"}>Timor-Leste</MenuItem>
                                    <MenuItem value={"Togo"}>Togo</MenuItem>
                                    <MenuItem value={"Tokelau"}>Tokelau</MenuItem>
                                    <MenuItem value={"Tonga"}>Tonga</MenuItem>
                                    <MenuItem value={"Trinidad and Tobago"}>Trinidad and Tobago</MenuItem>
                                    <MenuItem value={"Tunisia"}>Tunisia</MenuItem>
                                    <MenuItem value={"Turkey"}>Turkey</MenuItem>
                                    <MenuItem value={"Turkmenistan"}>Turkmenistan</MenuItem>
                                    <MenuItem value={"Turks and Caicos Islands"}>Turks and Caicos Islands</MenuItem>
                                    <MenuItem value={"Tuvalu"}>Tuvalu</MenuItem>
                                    <MenuItem value={"Uganda"}>Uganda</MenuItem>
                                    <MenuItem value={"Ukraine"}>Ukraine</MenuItem>
                                    <MenuItem value={"United Arab Emirates"}>United Arab Emirates</MenuItem>
                                    <MenuItem value={"United Kingdom of Great Britain and Northern Ireland"}>United Kingdom of Great Britain and Northern Ireland</MenuItem>
                                    <MenuItem value={"United States Minor Outlying Islands"}>United States Minor Outlying Islands</MenuItem>
                                    <MenuItem value={"United States of America"}>United States of America</MenuItem>
                                    <MenuItem value={"Uruguay"}>Uruguay</MenuItem>
                                    <MenuItem value={"Uzbekistan"}>Uzbekistan</MenuItem>
                                    <MenuItem value={"Vanuatu"}>Vanuatu</MenuItem>
                                    <MenuItem value={"Venezuela"}>Venezuela</MenuItem>
                                    <MenuItem value={"Viet Nam"}>Viet Nam</MenuItem>
                                    <MenuItem value={"Virgin Islands (British)"}>Virgin Islands (British)</MenuItem>
                                    <MenuItem value={"Virgin Islands (U.S.)"}>Virgin Islands (U.S.)</MenuItem>
                                    <MenuItem value={"Wallis and Futuna"}>Wallis and Futuna</MenuItem>
                                    <MenuItem value={"Western Sahara"}>Western Sahara</MenuItem>
                                    <MenuItem value={"Yemen"}>Yemen</MenuItem>
                                    <MenuItem value={"Zambia"}>Zambia</MenuItem>
                                    <MenuItem value={"Zimbabw"}>Zimbabw</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            id="state"
                            label="State/Province"
                            variant="outlined"
                            defaultValue={addressData.state}
                        />
                        <TextField
                            id="city"
                            label="Consignee City"
                            variant="outlined"
                            defaultValue={addressData.city}
                        />
                        <TextField
                            id="address"
                            label="Address Details"
                            variant="outlined"
                            defaultValue={addressData.address}
                        />
                        <TextField
                            id="postcode"
                            label="Consignee Postcode"
                            variant="outlined"
                            defaultValue={addressData.postcode}
                        />
                        <TextField
                            id="phone"
                            label="Consignee Phone"
                            variant="outlined"
                            defaultValue={addressData.phone}
                        />
                        <TextField
                            id="email"
                            label="Consignee Email"
                            variant="outlined"
                            defaultValue={addressData.email}
                        />
                    </Box>
                    <button onClick={() => {setAddressEditPopup(false)}} style={{margin: "0 5px", backgroundColor: "red", color: "white", padding: "4px 12px", borderRadius:"6px"}}>Cancel</button>
                    <button onClick={editAddress} style={{margin: "0 5px", backgroundColor: "#0fb200", color: "white", padding: "4px 12px", borderRadius:"6px"}}>Confirm</button>
                </div>
            </div>
        </div>
    )
}