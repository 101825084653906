import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FormGroup, FormControl, Input, InputLabel, FormHelperText } from '@mui/material';
import { TextField } from '@mui/material';
import {MenuItem, Select} from '@mui/material';
// import ImagesGallery from '../../components/image_gallery';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };


export default function ReturnsDialog({ open, onClose, onSubmit }) {
    const [isOpen, setOpen] = React.useState(open);
    // const [description, setDescription] = React.useState('')
    const [reason, setReason] = React.useState('')

    React.useEffect(() => {
      setOpen(open)
    }, [open])

    const handleClose = (e, reason) => {
        if(reason !== 'backdropClick')
        setOpen(false);
        if(onClose && reason !== 'backdropClick') {
            onClose()
        }
      };

      function handleReasonChange(e) {
        setReason(e.target.value)
      }

    
    const handleSubmit = () => {
        if(onSubmit) {
            onSubmit({ reason })
        }
    }

    const headingStyle = {
        fontWeight: 600,
        marginRight: '10px'
    }

    const textStyle = {
        fontWeight: 400
    }

    const pStyle = {
        margin: "2px 0px"
    }

    return (
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            disableEscapeKeyDown = {true}
          >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <h3 style={{padding:"0 90px 0 0"}}>Return Details</h3>
                </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>    
                            {/* <FormGroup style = {{margin: '20px', width: '500px'}}>
                                <br />
                                <TextField placeholder="Reason for return" onChange = {(e) => setDescription(e.target.value)}/>
                            </FormGroup> */}

                            <FormControl fullWidth>
                            <Select
                            labelId="demo-simple-select-label"
                            id="country"
                            value={reason}
                            label="Country"
                            onChange={handleReasonChange}
                            >
                            <MenuItem value={"No Longer Needed"} selected>No Longer Needed</MenuItem>
                            <MenuItem value={"Wrong Product"}>Wrong Product</MenuItem>
                            <MenuItem value={"Wrong Size"}>Wrong Size</MenuItem>
                            <MenuItem value={"Quality Issues"}>Quality Issues</MenuItem>
                            <MenuItem value={"Exchange"}>Exchange</MenuItem>
                            
                            </Select>
                        </FormControl>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button variant='outlined' autoFocus onClick={() => handleSubmit()}>
                          Send Request
                      </Button>
                    </DialogActions>
            </BootstrapDialog>
        </div>
      );
}