import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FormGroup, FormControl, Input, InputLabel, FormHelperText } from '@mui/material';
import { TextField } from '@mui/material';
import './topup.scss'
// import ImagesGallery from '../../components/image_gallery';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


export default function TopUpDialog({ open, onClose, onSubmit }) {
  const [isOpen, setOpen] = React.useState(open);
  const [amount, setAmount] = React.useState(0)

  React.useEffect(() => {
    setOpen(open)
  }, [open])

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick')
      setOpen(false);
    if (onClose && reason !== 'backdropClick') {
      onClose()
    }
  };


  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit({ amount })
    }
  }

  function clickTopup(e) {
    let topupVal = e.currentTarget.getAttribute("topup-val");
    document.getElementById("topup-input").value = topupVal;
    setAmount(topupVal);
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableEscapeKeyDown={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h3>Top Up Balance</h3>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="premade-topups">
            <div onClick={event => { clickTopup(event) }} topup-val="20" className="premade-topup-box">20$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="50" className="premade-topup-box">50$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="100" className="premade-topup-box">100$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="150" className="premade-topup-box">150$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="200" className="premade-topup-box">200$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="250" className="premade-topup-box">250$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="300" className="premade-topup-box">300$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="400" className="premade-topup-box">400$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="500" className="premade-topup-box">500$</div>
            <div onClick={event => { clickTopup(event) }} topup-val="1000" className="premade-topup-box">1000$</div>
          </div>
          <Typography gutterBottom>
            <FormGroup style={{ margin: '20px', width: '500px' }}>
              <TextField variant="outlined" type="number" placeholder="Amount" id="topup-input" onChange={(e) => setAmount(e.target.value)} />
            </FormGroup>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' autoFocus onClick={() => handleSubmit()}>
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}