import {useEffect, useState} from "react";
import moment from "moment";
import { db } from 'firebase.js'

const CouponCard = ({ coupon, style, onClick, active=false }) => {

  useEffect(() => {
    let expDate = coupon.expiry_date;
    expDate = new Date(expDate).getTime();
    let now = new Date().getTime();

    if (expDate <= now) {
      //Coupon expired
      db.ref(`coupon_list/`).once("value", snap => {
        let coupons = snap.val();
        for (let i = 0; i < Object.keys(coupons).length; i++) {
          if (Object.values(coupons)[i]["code"] == coupon.code && !Object.values(coupons)[i]?.["expired"]) {
            let key_ = Object.keys(coupons)[i];
            coupons[key_]["expired"] = true;
            db.ref(`coupon_list/`).update(coupons);
          }
        }
      });
    }
  }, []);

  return (
    <div style={{...style, boxShadow: active && "0px 0px 22px 0px rgba(0,173,40,1)"}} onClick={onClick} className="availableCoupon__card">
      <div className="availableCoupon__card--top">
        <h2 className="availableCoupon__price">¥{coupon.amount}</h2>
        {/*<span className="availableCoupon__reach">Reach ¥ 200 Available</span>*/}
        <span className="availableCoupon__Shipping">
          {coupon.code}
        </span>
      </div>
      <div className="availableCoupon__card--bottom">
        <div className="coupon-min-vals">
          <p>
            Minimum Weight: <span>{coupon.minimum_weight || 0}</span>
          </p>
          <p>
            Minimum Price: <span>¥{coupon?.minimum_price || 0}</span>
          </p>
        </div>
        <p className="availableCoupon__pera">
        </p>
        <p className="availableCoupon__pera">
          {moment(coupon?.created_at).format("YYYY-MM-DD")} ——{" "}
          {moment(coupon?.expiry_date).format("YYYY-MM-DD")}
        </p>
      </div>
    </div>
  );
};

export default CouponCard;
