import { useContext, useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Currency } from 'utilities'
import exchange from '../assets/icons/currency-exchange.png'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { InputLabel } from '@mui/material'
import UserCurrencyContext from 'contexts/userCurrencyContext'

export default function SelectCurrency({ updateUserCurrency }) {
    let [rates, setRates] = useState({})
    let [toggleHidden, setToggleHidden] = useState(false)

    const selectedCurrency = useContext(UserCurrencyContext)

    const onChange = (e) => {
        updateUserCurrency(e.target.value)
    }

    const handleClose = () => {
        setToggleHidden(false)
    }

    useEffect(async () => {
        setRates(await Currency.GetRates())
    }, [])

    return (
        <>
            <Wrapper>
                <Button
                    onClick={() => {
                        setToggleHidden(true)
                    }}
                >
                    <img src={exchange} />
                    <p className="convert-button">Convert</p>
                </Button>
                <Dialog open={toggleHidden} onClose={handleClose}>
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                        <Box
                            component="form"
                            sx={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel htmlFor="dialog-native">
                                    Currency
                                </InputLabel>
                                <Select
                                    labelId="dialog-select-label"
                                    name="selected-currency"
                                    value={selectedCurrency}
                                    onChange={onChange}
                                    label="Currency"
                                >
                                    {Object.keys(rates).map((country) => (
                                        <MenuItem
                                            key={country}
                                            value={country}
                                            id={country}
                                        >
                                            {country}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

const ConvertWrapper = styled.div`
    display: flex;
    p {
        font-size: 1.6rem;
    }
`
const ConvertButton = styled.div`
    margin-left: 1rem;
    display: flex;
`
