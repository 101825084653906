import { Box, Center, Flex, Heading, Image } from "@chakra-ui/react"
import IntroCard from "../../business-tab/intro/IntroCard"
import chooseData from "./ChooseData"

function WhyChooseSuperBuy() {
  return (
    <Box w="full" pb="20">
        <Center>
            <Flex direction="column" alignItems="center" maxW="80rem">
                <Heading color="black" fontSize="3xl" pt="40" pb="20" textAlign="center">Why Choose Superbuy</Heading>
                <Flex gap={20} direction={["column", "column", "column", "row", "row"]} wrap="wrap">
                  {chooseData.slice(0, 3).map(({image, name}) => (
                    <Flex
                        direction="column"
                        alignItems="center"
                        key={name}
                    >
                        <Image
                        src={image}
                        cursor="pointer"
                        />
                        <Box textAlign="center" mt="5" cursor="pointer" fontSize="lg">
                        {name}
                        </Box>
                    </Flex>
                  ))}
                </Flex>
                <Flex gap={20} mt="20" direction={["column", "column", "column", "row", "row"]} wrap="wrap">
                  {chooseData.slice(3, 7).map(({image, name}) => (
                    <Flex
                        direction="column"
                        alignItems="center"
                        key={name}
                    >
                        <Image
                        src={image}
                        cursor="pointer"
                        />
                        <Box textAlign="center" mt="5" cursor="pointer" fontSize="lg">
                        {name}
                        </Box>
                    </Flex>
                  ))}
                </Flex>
            </Flex>
        </Center>
    </Box>
  )
}

export default WhyChooseSuperBuy