import React from "react";

const TabAfterSale = () => {
  return (
    <div className="TabAfterSale">
      <p className="TabAfterSale__pera">
        The item you buy will be sent to the KgHype warehouse. After it arrives
        at the warehouse, we will check and take pictures of it for you. You can
        check and manage the items in Warehouse
      </p>
      <p className="TabAfterSale__pera">
        If you are dissatisfied with the items after they arrived at the KgHype
        warehouse, you can apply for return within 5 days. Our agents will
        negotiate with the sellers to deal with the after-sale issues on your
        behalf.
      </p>
    </div>
  );
};

export default TabAfterSale;
