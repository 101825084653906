import Logo from 'assets/image/KGHYPE_logo.png'
import UserIcon from 'assets/image/KGHYPE_user.png'
import CartIcon from 'assets/image/KGHYPE_cart.png'
import SearchIcon from 'assets/image/KGHYPE_search.png'
import Coupon from 'assets/image/coupon.png'
import Notfound from 'assets/image/404.jpg'
import Profile from 'assets/image/kghype_profile.png'
import Product from 'assets/image/kghype_product.png'
import SearchIconGray from 'assets/image/kghype_search_gray.png'
import Cloth from 'assets/image/kghype_cloth_icon.png'
import Foot from 'assets/image/kghype_foot_icon.png'
import Handbag from 'assets/image/kghype_handbag_icon.png'
import Watch from 'assets/image/kghype_watch_icon.png'
import Jewelry from 'assets/image/kghype_jewelry_icon.png'
import Baby from 'assets/image/kghype_baby_icon.png'
import Phone from 'assets/image/kghype_mobile_icon.png'
import Desktop from 'assets/image/kghype_desktop_icon.png'
import Home from 'assets/image/kghype_home_icon.png'
import Food from 'assets/image/kghype_food_icon.png'
import Step1 from 'assets/image/kghype_step_1.png'
import Step2 from 'assets/image/kghype_step_2.png'
import Step3 from 'assets/image/kghype_step_3.png'
import Step4 from 'assets/image/kghype_step_4.png'
import Step5 from 'assets/image/kghype_step_5.png'
import Step6 from 'assets/image/kghype_step_6.png'
import Bg from 'assets/image/kghype_bg.png'
import Avatar from 'assets/image/kghype_avatar.png'
import Bitcoin from 'assets/image/kghype_bitcoin.png'
import Airpal from 'assets/image/kghype_airpal.png'
import Paypal from 'assets/image/kghype_paypal.png'
import Venmo from 'assets/image/kghype_paypal.png'
import CahsApp from 'assets/image/kghype_paypal.png'
import CashAppQR from 'assets/image/cashapp-qr.png'
import VenmoQR from 'assets/image/venmo-qr.png'
import Transfer from 'assets/image/kghype_transfer.png'
import BankTransfer from 'assets/image/kghype_bank_transfer.png'
import SearchProduct from 'assets/image/kghype_product_search.png'
import Landing from 'assets/image/kghype_landing.png'
import AccountImage from 'assets/image/account.jpg'
import Bell from 'assets/image/bell.svg';
import TrashCan from 'assets/image/TrashCan.svg';
import UpArrow from 'assets/image/up-arrow.svg';
import DownArrow from 'assets/image/down-arrow.svg';
import CreditCard from 'assets/image/credit-card.png'
import CategoryClothing from 'assets/image/clothing-category.png'
import CategoryFootwear from 'assets/image/footwear-category.png'
import CategoryHandbag from 'assets/image/handbag-category.png'
import CategoryAccessories from 'assets/image/accessories-category.png'
import CategoryKids from 'assets/image/kids-category.png'
import CategoryHome from 'assets/image/home-category.png'
import CategoryComputers from 'assets/image/computer-category.png'
import CategoryFood from 'assets/image/food-category.png'
import CategoryElectronics from 'assets/image/electronics-category.png'
import CategoryJewelry from 'assets/image/jewelry-category.png'
import Wallet from 'assets/image/wallet.png'

export const SharedImage = {
    Logo: Logo,
    UserIcon: UserIcon,
    SearchIcon: SearchIcon,
    CartIcon: CartIcon,
    Coupon: Coupon,
    Notfound: Notfound,
    Profile: Profile,
    Product: Product,
    SearchIconGray: SearchIconGray,
    Bg: Bg,
    Avatar: Avatar,
    Landing: Landing,
    Bell: Bell,
    TrashCan: TrashCan,
    UpArrow: UpArrow,
    DownArrow: DownArrow,
    AccountImage,
}

export const CategoryImage = {
    Cloth: Cloth,
    Foot: Foot,
    Handbag: Handbag,
    Watch: Watch,
    Jewelry: Jewelry,
    Baby: Baby,
    Phone: Phone,
    Desktop: Desktop,
    Home: Home,
    Food: Food,
    Clothing: CategoryClothing,
    Footwear: CategoryFootwear,
    Handbag: CategoryHandbag,
    Accessories: CategoryAccessories,
    Jewelry: CategoryJewelry,
    Kids: CategoryKids,
    Computers: CategoryComputers,
    FoodDrinks: CategoryFood,
    HomeCat: CategoryHome,
    Electronics: CategoryElectronics,
}

export const StepImage = {
    Step1: Step1,
    Step2: Step2,
    Step3: Step3,
    Step4: Step4,
    Step5: Step5,
    Step6: Step6,
}

export const IconImage = {
    Bitcoin: Bitcoin,
    Paypal: Paypal,
    Airpal: Airpal,
    Transfer: Transfer,
    BankTransfer: BankTransfer,
    SearchProduct: SearchProduct,
    CashAppQR: CashAppQR,
    VenmoQR: VenmoQR,
    CreditCard: CreditCard,
    Wallet: Wallet
}
