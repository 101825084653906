import { Flex } from '@chakra-ui/react'

export const Container = ({ children }) => {
    return (
        <Flex
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            bg="gray.50"
            color="black"
            _dark={{
                bg: 'gray.900',
                color: 'white',
            }}
            transition="all 0.15s ease-out"
            width="100%"
        >
            {children}
        </Flex>
    )
}
