import {
    ABOUT_PREFIX, ADDRESS_PREFIX, AFFILIATE_PREFIX, AFILLIATE_PREFIX, B2B_PREFIX, BANK_PREFIX, BUSINESS_PREFIX, CALCULATOR_PREFIX, CART_PREFIX, CHECKOUT_PREFIX, CONFIRM_PREFIX, CONTACT_PREFIX, ERROR_PREFIX, FAQ_PREFIX, FAVORITE_PREFIX, FEE_PREFIX, FORWARDED_PREFIX, FORWARDING_PREFIX, HISTORYCART_PREFIX, HISTORY_PREFIX, INTERNATIONAL_PREFIX, LOGIN_PREFIX, MANUAL_PREFIX, MANUAL_PRODUCT_DETAIL_PREFIX, PACKAGING_PREFIX, PARCEL_PREFIX, PAYPAL_PREFIX, PRIVACY_PREFIX, PRODUCT_DETAIL_PREFIX, PROFILE_DETAIL_PREFIX, PROFILE_PREFIX, PROMO_PREFIX, PUBLIC_PREFIX, REFUND_PREFIX, REVIEW_PREFIX, SEARCH_PREFIX, SHIPPINGSTATUS_PREFIX, SHIPPING_PREFIX, SIGNUP_PREFIX, STATUS_PREFIX, STORAGE_PREFIX, TERMS_PREFIX, WALLET_PREFIX, WAREHOUSE_PREFIX
} from 'configs/app-config'
import { Route, Switch } from 'react-router-dom'
import NowFoundPage from './404'
import LoginPage from './login'
import ProductDetailPage from './product-detail'
import SignUpPage from './signup'
// import ProductDetailPage from "./ProductDetails";
import { B2BServices } from 'components/business/b2b-services/B2BServices'
import ProfileLayout from 'layouts/profile-layout'
import PaymentSuccessLayout from 'views/public/payment-success'
import About from './about'
import AfilliatePage from './afilliate'
import BankPage from './bank'
import BusinessPage from './business'
import Cart from './cart'
import CheckoutPage from './checkout'
import ConfirmCart from './confirm'
import Contact from './contact'
import Faq from './faq'
import Fee from './fee'
import ForwardingPage from './forwarding'
import InternationalShippingPage from './international-shipping'
import LandingPage from './landing-page'
import ManualProductDetailsPage from './manual-product-detail'
import ManualProductSubmissionPage from './manual-product-submission'
import Packaging from './packaging'
import PaypalInfoPage from './paypal-info'
import Privacy from './privacy'
import ProductSearch from './product-search'
import Promo from './promo'
import Refund from './refunds'
import ReviewPage from './review'
import Shipping from './shipping'
import ShippingCalculator from './shipping-calculator'
import ShippingStatus from './shippingstatus'
import Status from './status'
import Storage from './storage'
import Terms from './terms'

export default function PublicPage() {
    return (
        <Switch>
            <Route
                exact path="/" component={LandingPage} 
            />
            <Route
                exact
                path={PUBLIC_PREFIX + LOGIN_PREFIX}
                component={LoginPage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + SIGNUP_PREFIX}
                component={SignUpPage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + PRIVACY_PREFIX}
                component={Privacy}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + PACKAGING_PREFIX}
                component={Packaging}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + STORAGE_PREFIX}
                component={Storage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + BUSINESS_PREFIX}
                component={BusinessPage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + B2B_PREFIX}
                component={B2BServices}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + FORWARDING_PREFIX}
                component={ForwardingPage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + SHIPPINGSTATUS_PREFIX}
                component={ShippingStatus}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + AFILLIATE_PREFIX}
                component={AfilliatePage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + PROFILE_PREFIX}
                component={ProfileLayout}
            />
            <Route
                path={PUBLIC_PREFIX + PROFILE_DETAIL_PREFIX}
                component={ProfileLayout}
            />
            <Route
                path={PUBLIC_PREFIX + PRODUCT_DETAIL_PREFIX}
                component={ProductDetailPage}
            />
            <Route
                path={PUBLIC_PREFIX + MANUAL_PRODUCT_DETAIL_PREFIX}
                component={ManualProductDetailsPage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + SHIPPING_PREFIX}
                component={Shipping}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + AFFILIATE_PREFIX}
                component={ProfileLayout}
            />
            <Route exact path={PUBLIC_PREFIX + FAQ_PREFIX} component={Faq} />
            <Route
                exact
                path={PUBLIC_PREFIX + PROMO_PREFIX}
                component={Promo}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + REFUND_PREFIX}
                component={Refund}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + ABOUT_PREFIX}
                component={About}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + TERMS_PREFIX}
                component={Terms}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + STATUS_PREFIX}
                component={Status}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + CONTACT_PREFIX}
                component={Contact}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + REVIEW_PREFIX}
                component={ReviewPage}
            />
            <Route exact path={PUBLIC_PREFIX + FEE_PREFIX} component={Fee} />
            <Route
                exact
                path={PUBLIC_PREFIX + CONFIRM_PREFIX}
                component={ConfirmCart}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + WAREHOUSE_PREFIX}
                // component={WareHousePage}
                component={ProfileLayout}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + HISTORY_PREFIX}
                // component={HistoryPage}
                component={ProfileLayout}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + HISTORYCART_PREFIX}
                // component={HistoryCart}
                component={ProfileLayout}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + PARCEL_PREFIX}
                // component={ParcelPage}
                component={ProfileLayout}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + BANK_PREFIX}
                component={BankPage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + SHIPPING_PREFIX + INTERNATIONAL_PREFIX}
                component={InternationalShippingPage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + PROFILE_PREFIX + AFILLIATE_PREFIX}
                component={AfilliatePage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + CHECKOUT_PREFIX}
                component={CheckoutPage}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + CALCULATOR_PREFIX}
                component={ShippingCalculator}
            />
            <Route
                exact
                path={PUBLIC_PREFIX + SEARCH_PREFIX}
                component={ProductSearch}
            />
            <Route
                path={PUBLIC_PREFIX + MANUAL_PREFIX}
                component={ManualProductSubmissionPage}
            />
            <Route path={PUBLIC_PREFIX + CART_PREFIX} component={Cart} />
            <Route
                path={PUBLIC_PREFIX + PAYPAL_PREFIX}
                component={PaypalInfoPage}
            />
            <Route
                path={PUBLIC_PREFIX + WALLET_PREFIX}
                component={ProfileLayout}
            />
            <Route
                path={PUBLIC_PREFIX + ADDRESS_PREFIX}
                component={ProfileLayout}
            />
            <Route
                path={PUBLIC_PREFIX + FAVORITE_PREFIX}
                component={ProfileLayout}
            />
            <Route
                path={PUBLIC_PREFIX + PAYPAL_PREFIX}
                component={ProfileLayout}
            />
            <Route
                path={PUBLIC_PREFIX + ADDRESS_PREFIX}
                component={ProfileLayout}
            />
            <Route
              exact
              path={PUBLIC_PREFIX + FORWARDED_PREFIX}
              component={ProfileLayout}
            />
            <Route
              exact
              path={PUBLIC_PREFIX + "/payment-success"}
              component={PaymentSuccessLayout}
            />
            <Route
                path={PUBLIC_PREFIX + ERROR_PREFIX}
                component={NowFoundPage}
            />
        </Switch>
    )
}
