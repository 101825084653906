import Notfound from 'assets/image/404.jpg'
import axios from 'axios'
import Spinner from 'components/Spinner'
import {
    MANUAL_PREFIX, PUBLIC_PREFIX
} from 'configs/app-config'
import { db } from 'firebase.js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import s1688 from '../../../assets/logos/1688.png'
import jd from '../../../assets/logos/jd.png'
import micro from '../../../assets/logos/micro.png'
import taobao from '../../../assets/logos/taobao.png'
import ProductGallery from '../../../components/ProductGallery/ProductGallery'
import ProductInfo from '../../../components/ProductInfo/ProductInfo'
import Tabs from '../ProductDetails/Tabs'

const ProductDetailsPage = () => {
    const location = useLocation()
    const search = location.search
    const [item, setItem] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [shopLogo, setShopLogo] = useState(taobao)
    const [currImage, setCurrImage] = useState(null)
    const [retryDataFetching, setRetry] = useState(0);

    let user = useSelector(state => state.user);
    
    useEffect(() => {
        setLoading(true)
        let shop = 'taobao'
        shop = new URLSearchParams(search).get('store')

        if (shop.includes('weidian')) {
            shop = 'micro'
            setShopLogo(micro)
        } else if (shop.includes('jd')) {
            setShopLogo(jd)
        } else if (shop.includes('1688')) {
            shop = '1688'
            setShopLogo(s1688)
        }

        let query = new URLSearchParams(search).get('id')

        if (retryDataFetching < 2) {
            axios.get(
                `https://api-gw.onebound.cn/${shop}/item_get/?key=t8607980302&num_iid=${query}&is_promotion=1&lang=en&secret=20220515`
            )
            .then(async (res) => {
                setItem(res.data.item)
                setLoading(false)
                if (!item.price) setRetry(retryDataFetching + 1);
                await db.ref("stats/api").once("value", async snapshot => {
                    let vals = snapshot.val();
                    let curDate = new Date().toLocaleString('tr-TR').split(" ")[0].split(".");
                    curDate[1] = new Date().toLocaleString('en-EN', { month: 'long' });
                    curDate = curDate.join(" ");
                    if (!vals) vals = {};
                    if (!vals[curDate]) vals[curDate] = 1;
                    else vals[curDate] += 1;
        
                    return db.ref("stats/api").update(vals);
                });
            })
            .catch((err) => {
                console.log(err)
                setError('something went wrong')
                setLoading(false)
            });
        }
    }, [search, retryDataFetching])

    let url_string = window.location.href; 
    let url = new URL(url_string);
    let refID = url.searchParams.get("ref");
    let itemID = url.searchParams.get("id");

    useEffect(() => {
        (async() => {
            let userKey = user?.key;

            if (refID && user) {
                db.ref('affiliates').once("value", async snapshot => {
                    let affiliateList = snapshot.val();
                    if (affiliateList) {
                        if (affiliateList[refID]) {
                            if (userKey) {
                                if (affiliateList[refID]["user_id"] != userKey) {
                                    let obj = affiliateList[refID];
                                    obj["clicks"] = affiliateList[refID].clicks + 1

                                    //Add click history
                                    let curDateNum = new Date().getTime();
                                    let historyClickArr = []
                                    if (obj["history"]["clicks"] == "") {
                                        historyClickArr.push(curDateNum)
                                        obj["history"]["clicks"] = historyClickArr;
                                    }
                                    else {
                                        historyClickArr = obj["history"]["clicks"];
                                        historyClickArr.push(curDateNum);
                                        obj["history"]["clicks"] = historyClickArr;
                                    }

                                    affiliateList[refID] = obj;
                                    db.ref('affiliates').update(affiliateList);
                                }
                            }
                            else {
                                let obj = affiliateList[refID];
                                obj["clicks"] = affiliateList[refID].clicks + 1

                                //Add click history
                                let curDateNum = new Date().getTime();
                                let historyClickArr = []
                                if (obj["history"]["clicks"] == "") {
                                    historyClickArr.push(curDateNum)
                                    obj["history"]["clicks"] = historyClickArr;
                                }
                                else {
                                    historyClickArr = obj["history"]["clicks"];
                                    historyClickArr.push(curDateNum);
                                    obj["history"]["clicks"] = historyClickArr;
                                }

                                affiliateList[refID] = obj;
                                db.ref('affiliates').update(affiliateList);
                            }
                        }
                    }
                });
            }
        })()
    }, [user]);

    if (loading) return <Spinner bg="dark" />
    if (error) return (
        <div className="product-page container">
            <div style={{ textAlign: 'center', margin: '2rem 0' }}>
                <div>
                    <img
                        style={{ maxWidth: '100%' }}
                        src={Notfound}
                        alt=""
                    />
                </div>
                <h1 style={{ fontSize: '4rem', margin: '1rem' }}>
                    Oops, not found!
                </h1>
                <p>
                    This product is not available right now. You can
                    manually submit through {''}
                    <Link to={(itemID) ? PUBLIC_PREFIX + MANUAL_PREFIX + "?id=" + itemID : PUBLIC_PREFIX + MANUAL_PREFIX}>here</Link>
                </p>
            </div>
        </div>
    )

    return (
        <div className="product-page container">
            {(item.price && (
                <div>
                    <div className="row">
                        <div className="col-md-4">
                            <ProductGallery
                                currImage={currImage}
                                setCurrImage={setCurrImage}
                                item={item}
                            />
                        </div>
                        <div className="col-md-8">
                            <ProductInfo
                                currImage={currImage}
                                setCurrImage={setCurrImage}
                                shopLogo={shopLogo}
                                item={item}
                                currentUser={user}
                            />
                        </div>
                    </div>
                    <Tabs item={item} />
                </div>
            )) || (
                <div style={{ textAlign: 'center', margin: '2rem 0' }}>
                    <div>
                        <img
                            style={{ maxWidth: '100%' }}
                            src={Notfound}
                            alt=""
                        />
                    </div>
                    <h1 style={{ fontSize: '4rem', margin: '1rem' }}>
                        Oops, not found!
                    </h1>
                    <p>
                        This product is not available right now. You can
                        manually submit through {''}
                        <Link to={(itemID) ? PUBLIC_PREFIX + MANUAL_PREFIX + "?id=" + itemID : PUBLIC_PREFIX + MANUAL_PREFIX}>here</Link>
                    </p>
                </div>
            )}
        </div>
    )
}

export default ProductDetailsPage
