import React, { useEffect, useState } from "react";
import CouponCard from "components/coupon-card";

import { db } from "firebase.js";
import { ref, onValue, update } from "firebase/database";

const AvailableCoupon = ({currentUser, checkout=false}) => {
  const [coupons, setCoupons] = useState([]);
  const [clickedCoupon, setClickedCoupon] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser?.dbUser) {
		/* Get available coupons from user database */
		let available_coupons_raw = currentUser.dbUser["available_coupons"] || [];
		let coupons_ = [];
		let couponNames = [];
		let couponsMatched = true;

		//Check if they in list
		onValue(ref(db, "coupon_list"), async snapshot => {
			let vals = snapshot.val();
			for (let i = 0; i < available_coupons_raw.length; i++) {
				let c = available_coupons_raw[i];
				if (Object.keys(vals).includes(c)) {
					if (!vals[c]?.expired && !currentUser.dbUser?.used_coupons?.includes(c)) {
						coupons_.push(vals[c]);
						couponNames.push(c);
					} else couponsMatched = false;
				} else couponsMatched = false;
			}
			
			if (!couponsMatched) {
				//Update user's available coupons
				onValue(ref(db, "users"), userSnap => {
					let userVals = userSnap.val();
					const obj = {};
					userVals[currentUser.key]["available_coupons"] = couponNames;
					obj["/users/" + currentUser.key] = {...userVals[currentUser.key]};
					update(ref(db), obj).then(() => { setCoupons(coupons_); });
				})
			}
			else setCoupons(coupons_);
		}, {onlyOnce: true})

    }
  }, [currentUser]);

  function clickOnCoupon(key) {
    document.querySelector("#couponthing").value = key;
    setClickedCoupon(key);
  }

  return (
    <div className="availableCoupon">
      {coupons.map((coupon, i) => {
        return (<CouponCard key={i} coupon={coupon} onClick={() => checkout && clickOnCoupon(coupon.code)} style={checkout && {cursor: "pointer"}} active={clickedCoupon === coupon.code} />)
      })}
    </div>
  );
};

export default AvailableCoupon;
