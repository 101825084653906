import { CALCULATOR_PREFIX, PUBLIC_PREFIX } from 'configs/app-config'
import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { FaChevronDown, FaMapMarkerAlt } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Currency } from 'utilities'

import './ShippingInfo.scss';

const ShippingInfo = ({ shipping }) => {
    const history = useHistory()
    let [rates, setRates] = useState({})

    useEffect(async () => {
        setRates(await Currency.GetRates())
        //console.log("rates fetched")
    }, [])

    function shippingDropdown() {
        setTimeout(() => $(".shipping-dropdown").show(), 1);
        $(".shipping-dropdown li").on("click", function(event) {
            $("#shipping-info-button span:first-child").text($(event.currentTarget).text());
            $(".shipping-dropdown").hide();
        })
    }

    $(window).on("click", function(e) {
        let target = $(e.target);
        if (!target.closest(".shipping-dropdown").length && $('.shipping-dropdown').is(":visible")) {
            $(".shipping-dropdown").hide();
        }
    });

    return (
        <div className="ShippingInfo">
            <ShippingInfoWrapper className="ShippingInfo__domestic">
                <span className="ShippingInfo__number">1</span>
                <span className="ShippingInfo__text">Domestic Shipping</span>

                <span className="ShippingInfo__text ShippingInfo__text--currency">
                    {Currency.GetPrice(rates, shipping)}
                </span>
                <span className="ShippingInfo__text ShippingInfo__text--currency">
                    Send to
                </span>
                <div style={shippingButtonContainer}>
                    <button id="shipping-info-button" className="ShippingInfo__button" onClick={shippingDropdown}>
                        <span>Shenzhen</span>
                        <span className="ShippingInfo__buttonIcon">
                            <FaChevronDown />
                        </span>
                    </button>
                    <ul className="shipping-dropdown">
                        <li>Shenzhen</li>
                    </ul>
                </div>
                <FaMapMarkerAlt />
            </ShippingInfoWrapper>
            <ShippingInfoWrapper className="ShippingInfo__international">
                <span className="ShippingInfo__number">2</span>
                <span className="ShippingInfo__text">
                    Exclude International Delivery,
                </span>
                <a
                    style={{ cursor: 'pointer' }}
                    className="ShippingInfo__text ShippingInfo__text--link"
                    onClick={() => {
                        history.push({
                            pathname: PUBLIC_PREFIX + CALCULATOR_PREFIX,
                        })
                    }}
                >
                    Check Int'l Shipping Fee and Transit Time Here
                </a>
            </ShippingInfoWrapper>
        </div>
    )
}

const ShippingInfoWrapper = styled.div`
    @media (max-width: 460px) {
        flex-wrap: wrap;
    }
`

const shippingButtonContainer = {
    position:"relative",
}

export default ShippingInfo
