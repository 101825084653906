import styled from 'styled-components';
import { Container } from "components/container";
import { Heading } from "components/heading";
import { SharedImage } from 'constants/image-constant';
import ImageWrapper from 'components/image-wrapper';
import Input from 'components/input';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, div, CardActions } from '@mui/material';
import * as React from 'react';
//Firebase 

import { auth } from 'firebase.js';
import { db } from 'firebase.js';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"

const HeadingStyle = {
    textAlign: "left",
    margin: "0px"
}

const HeadingStyle2 = {
    fontSize: "18px",
    fontWeight: "400"
}

const ContainerStyle = {

    padding: "50px 20px",
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap"
}

const ButtonStyle = {
    marginTop: "50px"
}

const { Profile } = SharedImage;

export default function Terms() {


    return (
    <div style={{ display:'flex', justifyContent:'center' }}>
        <Container style={ContainerStyle}>
    <Card sx={{ maxWidth: 1000 }}>
      <div>
        <h1>TOS</h1>
        <CardContent>

          <Typography  variant="h5" component="div">
          <b>1. Service Terms Confirmation and Acceptance</b>
          </Typography>
          <br>
          </br>
          <Typography variant="h5" component="div">

          Service Terms Confirmation and Acceptance registered users’ ownership and operation right are owned by KgHype. The services provided must be in accordance with its published service agreement, service terms, and operating rules strictly enforced. Also says that users and "KgHype" have reached an agreement and accepted all of its terms of service.          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          <b>2. The Provision of Service information</b>
          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          ‘KgHype’ provides global users with international shopping agent service via the internet by using its own information and service platform. Receivers' information provided by users needs to be detailed, accurate, truthful, and valid, the same as the user's personal email address. Once the information has changed, the updated information needs to be submitted to ‘KgHype’ customer service. If the information provided by the users is inaccurate, untruthful, valid service cannot be provided. In addition, the platform has the unilateral right to terminate users’ service rights.          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          <b>3. Users’ information Protection Rules </b>
          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          Respecting user privacy is one of the ‘KgHype’ fundamental rules. Therefore, we will not publish, modify or share users’ personal information with a third party and will save it in ‘KgHype’ registered users' service files. Unless requested with legal permission or users themselves, then we can share with the third party.          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          <b>4. Service Terms Rights</b>
          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          ‘KgHype’ reserved the right to modify or discontinue the service without notifying the user, it is not necessary for the rights of the user or any third party.          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          <b>6. Users Management</b>
          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          Users will take the responsibility solely in posting information. Users’ service usage is in accordance with all applicable local laws, state laws, and international legal standards. Users must follow:
          <br></br>
⑴ Published information must be consistent with China's relevant laws and regulations.
<br></br>
⑵ Do not use network registered users engaged in illegal activities.
<br></br>
⑶ Do not interfere with or confuse network services.
<br></br>
⑷ Comply with all using network registered users service network agreement, rules and regulations, and procedures. Users have the responsibility of registering service activities in ‘KgHype’. If users spread or disseminate reactionary, pornographic or other information in violation of State law in "KgHype" registered user services, we will mark the system record as evidence in possible violation of the law and submit to relevant departments. Meanwhile, simultaneously and immediately delete these messages without informing the users.
          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          <b>7. Limited liability</b>
          </Typography>
          <br></br>

          <Typography  variant="h5" component="div">

          ‘KgHype’ for any direct, indirect, incidental, unique usage of network member services or any illegal usage of network member in terms of harming or causing ‘KgHype’ image and reputation damaged, ‘KgHype’ presents here to reserve rights to pursue compensation for the reputational damage.
          </Typography>
          <br></br>

          <Typography  variant="h5" component="div">

          <b>8. Refused to provide guarantees</b>
          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          Users agree the usage of email services is users’ responsible for any risks. ‘KgHype’ clearly states that it will not provide any kind of explicit or inexplicit guarantees. Users understand and accept all kinds of information, which depends on their own, and users are responsible for all risks and liabilities of system damage or information lost.          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          <b>9. ‘KgHype’ registered user's services information storage and Restrictions</b>
          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          ‘KgHype’ does not set the maximum limits for uploading, but the site has the right to judge whether users' behavior is followed ‘KgHype’ registered users' services terms and spirit. If users violated services terms, termination of account will be acted. ‘KgHype’ has all copyrights of all content for the site. If anyone wants to reprint website content, it has to be authorized by ‘KgHype’, otherwise, it is considered copyright infringement.          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          <b>10. Exclusion Clause</b>
          </Typography>
          <br></br>
          <Typography  variant="h5" component="div">

          (1) Items information the site provided, we will try our best to match the items description and patterns between the sales items and the one posted online. However, during the photo-taking process, lighting, color background, equipment differences, computer color differences, computer image resolution differences, etc. might affect the outcome. We cannot guarantee that item's description will exactly match the real products, especially in color. All items' actual status is the presented outcome.
<br></br>
(2) This site will base on users' instructions to shop for specific products from the particular website. Generally speaking, it takes 6 -15 working days to complete the shopping agent services (this is also based on the ‘international shipping methods’ chosen by users), but this is not a final commitment. If the website users provided says out-of-stock, price changes, not deliver on time, any other transporting issues or any irresistible factor, and it has affected us to complete the service, we will immediately communicate with users to solve the issues. For any relevant loss caused to the users, ‘KgHype’ is not responsible for it.
<br></br>
(3) If you need after-sales services (such as items missed, damaged, flawed, etc.), please see Help Center ‘Returns/ Refunds Policy and Process’ for more details. Please note, that after-sales service is only applied for 72 after items delivered. If you request it after that period of time, your request will be no longer accepted.
<br></br>
(4) Due to users' personal reasons caused to parcels' unsuccessful delivery, this site is not responsible for it.
<br></br>
(5) During shopping agent process, in this site will not accept any agreed conditions between users and sellers. Members of the site will only enjoy sales discounts published by ‘KgHype’ official website, and cannot enjoy any other discounts from other sellers’ websites.
<br></br>
(6) This site cannot accept to shop via shopping agent services for the following items: counterfeit products, prescription medicine, tobacco, lottery and adult magazine & DVD.

          </Typography>
          <br></br>

        </CardContent>

      </div>
      <CardActions>

      </CardActions>
    </Card>
        </Container>
        
        </div>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width:800px){
        display:none;
    }
`
const SideMenu = styled.ul`
    list-style:none;
    display:flex;
    flex-direction:column;
    gap:20px;
    font-family:Open Sans;
    padding:0px;
    margin:0px;
    padding-top:50px;
`

const Content = styled.section`
    padding-left:100px;
    max-width:100%;
    flex:1;
    @media screen and (max-width:800px){
        padding-left:0px;
    }
`

const ProfileWrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-flow:wrap;
    padding:50px 0px;
    align-items:center;
    @media screen and (max-width:800px) {
        flex-direction:column;
        align-items:center; 
    }
`

const TextWrapper = styled.div`
    padding-left:50px;
    display:flex;
    flex-direction:column;
    gap:20px;
    @media screen and (max-width:800px){
        padding-left:0px;
        padding-top:30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding:0px;
    margin:0px;
    padding:0px;
    @media screen and (max-width:800px){
        text-align:center;
    }
`