import { Flex } from '@chakra-ui/react'
import AvailableApi from './available-api/AvailableApi'
import WhyChooseSuperBuy from './choose-superbuy/WhyChooseSuperBuy'
import ChannelCooperationIntro from './intro/ChannelCooperationIntro'
import LikeToWorkSection from './like-to-work/LikeToWorkSection'
import SuccessfulCases from './successful-cases/SuccessfulCases'

function ChannelCooperation() {
  return (
    <Flex direction="column">
        <ChannelCooperationIntro />
        <LikeToWorkSection />
        <WhyChooseSuperBuy />
        <SuccessfulCases />
        <AvailableApi />
    </Flex>
  )
}

export default ChannelCooperation