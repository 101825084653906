import React from 'react'
import styled from 'styled-components'
import { FaClipboardCheck } from 'react-icons/fa'

import { Heading } from '../../../components/heading'
import reviewbg from '../../../assets/image/reviewbg.jpg'
import serviceImg from '../../../assets/image/kghype_step_2.png'
import RewardCardCmp from 'components/reward-card'

const headingStylePromotion = {
    padding: 0,
    margin: 0,
    textAlign: 'left',
    marginBottom: '30px',
    fontSize: '30px',
}

const ReviewPage = () => {
    return (
        <div>
            <ReviewHero>
                <TextBox>
                    <Heading>Review Rewards</Heading>
                    <Pera style={{ fontWeight: 'bold' }}>
                        If you enjoy the service that we provide reviewing us on
                        social media goes a long way plus you get rewarded for
                        it
                    </Pera>
                </TextBox>
            </ReviewHero>
            <Section>
                <Container>
                    <PromotionImgBox>
                        <img src={serviceImg} alt="service" />
                    </PromotionImgBox>
                    <PromotionContent>
                        <Heading style={headingStylePromotion}>
                            KgHype Service Review Promotion
                        </Heading>
                        <Pera>
                            Make, post, upload, share, publish, vlog, or any
                            creative way you can to share your experience using
                            our service. The more the impressions and referrals
                            you make, the more credit we can give you. Link it
                            to us and we'll determine how much credit we'll give
                            to your account! You can also refer your friends and
                            get commission from what they bought! What are you
                            waiting for? Review now!
                        </Pera>
                        <CheckList>
                            <li>
                                <span>
                                    <FaClipboardCheck />
                                </span>
                                This promotion begins from April in 2020.
                            </li>
                            <li>
                                <span>
                                    <FaClipboardCheck />
                                </span>
                                We check data two weeks after the posting date.
                            </li>
                            <li>
                                <span>
                                    <FaClipboardCheck />
                                </span>
                                You cannot withdraw rewards made from reviews.
                            </li>
                            <li>
                                <span>
                                    <FaClipboardCheck />
                                </span>
                                You are subject to a “one month one review
                                policy”.
                            </li>
                            <li>
                                <span>
                                    <FaClipboardCheck />
                                </span>
                                This promotion begins from April in 2020.
                            </li>
                        </CheckList>
                        <Button
                            href="https://forms.gle/zKrijQAVuxPa1i3j7"
                            target="_blank"
                            filled
                        >
                            Submit Link
                        </Button>
                    </PromotionContent>
                </Container>
            </Section>
            <Heading style={{ fontSize: '50px' }}>Reward Rules</Heading>

            <Section>
                <Container>
                    <RewardCardCmp />
                    <RewardCardCmp />
                    <RewardCardCmp>
                        <ContentFloat>
                            <h3>Comming soon</h3>
                        </ContentFloat>
                    </RewardCardCmp>
                    <RewardCardCmp>
                        <ContentFloat>
                            <h3>Comming soon</h3>
                        </ContentFloat>
                    </RewardCardCmp>
                </Container>
            </Section>
            <Section bg>
                <Container className="affContainer">
                    <div className="contentBox">
                        <Heading
                            style={{
                                textAlign: 'left',
                                fontSize: '30px',
                                padding: '1rem 0',
                            }}
                        >
                            Affiliate Program
                        </Heading>
                        <Pera>
                            Invite new KgHype members, The more you invite, the
                            more money you make. No limits!Each time your
                            invitees complete an international shipment order,
                            the amount of the order will turn into your
                            experience value. The actual bonus ratio an
                            affiliate enjoys is closely related to the shipment
                            discounts its linked user receives.
                        </Pera>
                    </div>
                    <Button ghost>Check More</Button>
                </Container>
            </Section>
        </div>
    )
}

const ReviewHero = styled.div`
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)
        ),
        url(${reviewbg});
    background-size: cover;
    background-position: 20% 25%;
    background-repeat: no-repeat;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const TextBox = styled.div`
    color: #fff;
    text-align: center;
`

const Pera = styled.p`
    padding: 1rem 0;
    font-size: 20px;
    line-height: 140%;
    text-align: ${(props) => (props.center ? 'center' : '')};
`

const Section = styled.section`
    ${(props) =>
        props.bg
            ? {
                  backgroundColor: '#072a48',
                  marginBottom: '2rem',
                  color: '#fff',
              }
            : null};
    & > div {
        /* padding: 3rem 0; */
        display: flex;
        flex-wrap: wrap;
        gap: 50px;
    }
`
const Container = styled.div`
    max-width: 1680px;
    padding: 3rem 2rem;
    &.affContainer {
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
    .contentBox {
        flex: 0 0 60%;
    }
`
const PromotionImgBox = styled.div`
    flex: 0 0 40%;
    & img {
        width: 100%;
    }
    @media screen and (max-width: 890px) {
        display: none;
    }
`
const PromotionContent = styled.div`
    /* margin-left: 2rem; */
    flex: 1;
    font-size: 18px;
    font-weight: 500;
`
const CheckList = styled.ul`
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }
    span {
        color: #072a48;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(7, 42, 72, 0.1);
        margin-right: 20px;
    }
`

const Button = styled.a`
    margin: 1rem 0;
    text-decoration: none;
    border: none;
    border-radius: 10px;
    padding: 20px 30px;
    outline: none;
    font-size: 20px;
    color: #fff;
    background: #072a48;
    cursor: pointer;
    ${(props) =>
        props.ghost
            ? {
                  background: '#fff',
                  border: '1px solid #072a48',
                  color: '#072a48',
              }
            : ''}
`

const ContentFloat = styled.div`
    position: absolute;
    width: 100%;
    height: 90%;
    z-index: 2;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(3px);
    p {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
    }
    h3 {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -60%);
        font-size: 40px;
        font-weight: normal;
    }
`

export default ReviewPage
