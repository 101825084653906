import TextField from '@mui/material/TextField'
import DefaultImage from 'assets/image/default.webp'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import ImageInput from 'components/image-input'
import { CART_PREFIX, PUBLIC_PREFIX } from 'configs/app-config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { NotifyFail } from 'utilities'
import { addToCartButton } from 'utilities/cart'
import { db } from 'firebase.js'

const HeadingStyle = {
    textAlign: 'left',
    margin: '1rem 0',
}
const ContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
    paddingTop: '100px',
    paddingBottom: '100px',
}

const WrapperStyle = {
    marginTop: '50px',
}

const TextStyle = {
    paddingLeft: '30px',
}

const MetaRow = {
    gap: '30px',
}

export default function ManualProductSubmissionPage() {
    
    let user = useSelector(state => state.user);

    const history = useHistory()
    const [total, setTotal] = useState(0)
    const [markup, setMarkup] = useState(20);
    const [data, setData] = useState({
        title: '',
        link: '',
        remark: '',
        price: 0,
        warehouse: 0,
        pic_url: '',
        total_price: 0,
    })

    const dataValidated = () => data.title && data.price && data.link && data.warehouse;
    const handleSubmit = (e) => {
        e.preventDefault()
        const newData = { ...data }
        newData.total_price = parseFloat(newData.price) + parseFloat(newData.warehouse || 0);
        newData.price = parseFloat(newData.price);
        newData.warehouse = parseFloat(newData.warehouse || 0);
        newData[e.target.id] = e.target.value;
        setTotal(parseFloat(newData.price) + parseFloat(newData.warehouse || 0));
        setData(newData);
    }

    const [url_, setURL] = useState("");
    const [currentUser, setUser] = useState(false);

    useEffect(async () => {
        db.ref("config").once("value", async snap => {
            let markup_ = snap.val()?.markup || 20;
            setMarkup(markup_);
        });

        if (user) {
            let url_string = window.location.href; 
            let url = new URL(url_string);
            let itemID = url.searchParams.get("id");
            if (itemID) setURL("https://item.taobao.com/item.htm?id="+itemID);
            setUser(user);
        }
    }, [user]);

    return (
        <Container style={ContainerStyle}>
            <ProductForm>
                <FieldsWrapper>
                    <Row>
                        <ReviewForm>
                            <Heading style={HeadingStyle}>
                                Product Information
                            </Heading>
                            <FormControl>
                                <TextField
                                    defaultValue={url_}
                                    id="link"
                                    type="text"
                                    onChange={(e) => handleSubmit(e)}
                                    label="Link"
                                />
                                <TextField
                                    defaultValue={data.title}
                                    id="title"
                                    type="text"
                                    onChange={(e) => handleSubmit(e)}
                                    label="Name"
                                />
                                <TextField
                                    defaultValue={data.remark}
                                    id="remark"
                                    type="text"
                                    onChange={(e) => handleSubmit(e)}
                                    label="Remark"
                                />
                            </FormControl>
                        </ReviewForm>
                    </Row>
                    <Row>
                        <ReviewForm>
                            <Heading style={HeadingStyle}>Fees</Heading>
                            <FormControl>
                                <TextField
                                    defaultValue={data.price}
                                    id="price"
                                    type="text"
                                    onChange={(e) => {
                                        let re = /^[0-9\b]+$/

                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            handleSubmit(e)
                                        }
                                    }}
                                    label="Product Price (¥)"
                                />
                                <TextField
                                    defaultValue={data.warehouse}
                                    id="warehouse"
                                    type="text"
                                    onChange={(e) => {
                                        let re = /^[0-9\b]+$/

                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            handleSubmit(e)
                                        }
                                    }}
                                    label="Domestic Shipping Cost (¥)"
                                />
                            </FormControl>
                            <TextWrapper style={WrapperStyle}>
                                <Heading style={HeadingStyle}>
                                    Total: {total || 0}¥
                                </Heading>
                            </TextWrapper>
                        </ReviewForm>
                    </Row>
                </FieldsWrapper>
                <TextWrapper>
                    <Heading style={HeadingStyle}>Others</Heading>
                    {
                        /*
                        <Button text="Upload File" />
                        <p style={TextStyle}>Size limit for single picture is 1M - 2M</p>
                        */
                        <ImageInput
                            value={data.pic_url}
                            id="pic_url"
                            type="text"
                            onChange={(e) => handleSubmit(e)}
                            label="Image Link"
                        />
                    }
                </TextWrapper>
                <Row style={MetaRow}>
                    <button
                        className="btn btn__primary btn__primary--lg"
                        onClick={async () => {
                            if (!dataValidated()) {
                                NotifyFail('Some fields are required')
                                return
                            }
                            if (!data.pic_url) {
                                data.pic_url = DefaultImage
                            }
                            
                            //Apply markup
                            data["price"] += (markup / 100) * data["price"];
                            data["total_price"] = data["price"] + (data["warehouse"] || 0);

                            await addToCartButton(data, false, currentUser)
                            history.push(PUBLIC_PREFIX + CART_PREFIX)
                        }}
                    >
                        Buy now
                    </button>
                    <button
                        style={{ marginTop: '1rem' }}
                        className="btn btn__ghost btn__ghost--lg"
                        onClick={async () => {
                            if (!dataValidated()) {
                                NotifyFail('Some fields are required')
                                return
                            }
                            if (!data.pic_url) {
                                data.pic_url = DefaultImage
                            }

                            //Apply markup
                            data["price"] += (markup / 100) * data["price"];
                            data["total_price"] = data["price"] + data["warehouse"];

                            await addToCartButton(data, true, currentUser)
                        }}
                    >
                        Add to cart
                    </button>
                    {/* <Button onClick={submit} type="submit" text="Buy Now" />
                    <Button onClick={submit} type="submit" text="Add to Cart" /> */}
                </Row>
            </ProductForm>
            <div>
                <TextWrapper>
                    <Heading style={HeadingStyle}>Disclaimer</Heading>
                    <p>
                        These items are sold by third parties. KGHYPE does not
                        sell products directly and serves as a chineese shopping
                        agent.
                    </p>
                </TextWrapper>
                <TextWrapper>
                    <Heading style={HeadingStyle}>Fee Standards</Heading>
                    <p>
                        These items are sold by third parties. KGHYPE does not
                        sell products directly and serves as a chineese shopping
                        agent.
                    </p>
                </TextWrapper>
            </div>
        </Container>
    )
}

const ProductForm = styled.div`
    margin: auto;
`

const ReviewForm = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 880px;
    width: 100%;
    padding-right: 50px;
`
const TextWrapper = styled.div`
    padding: 15px 0;
    #price-board {
        margin-top: 30px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
`
const FieldsWrapper = styled.div`
    label {
        font-size: 1.4rem;
    }
`
const FormControl = styled.div`
    display: flex;
    flex-direction column;

    & > div{
        margin: 1rem 0;
    }
    
`
