import styled from 'styled-components'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import { SharedImage } from 'constants/image-constant'
import ImageWrapper from 'components/image-wrapper'
import Input from 'components/input'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, div, CardActions } from '@mui/material'
import * as React from 'react'
//Firebase

import { auth } from 'firebase.js'
import { db } from 'firebase.js'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { SetMeal } from '@mui/icons-material'

const HeadingStyle = {
    textAlign: 'left',
    margin: '0px',
}

const HeadingStyle2 = {
    fontSize: '18px',
    fontWeight: '400',
}

const ContainerStyle = {
    padding: '50px 20px',
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
}

const ButtonStyle = {
    marginTop: '50px',
}

const { Profile } = SharedImage

export default function PaypalInfoPage() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Container style={ContainerStyle}>
                <Card sx={{ maxWidth: 1000 }}>
                    <div>
                        <h1
                            style={{
                                margin: '1rem 0',
                                fontSize: '3.4rem',
                                padding: '16px',
                            }}
                        >
                            How to Use paypal on KgHype
                        </h1>
                        <CardContent>
                            <p style={{ padding: '0.4rem 0' }}>
                                PayPal accepts VISA, Mastercard, American
                                Express, and JCB Cards in addition to various
                                national banks throughout the world.
                            </p>
                            <p style={{ padding: '0.4rem 0' }}>
                                When you pay using PayPal, your funds will be
                                converted to US dollars and will arrive at
                                KgHype with a delay of up to an hour, regardless
                                of your original currency or account.
                            </p>
                            <p style={{ padding: '0.4rem 0' }}>
                                In order to keep up with the most recent rate
                                released by the Bank of China, we will make
                                periodic adjustments.
                            </p>
                            <p style={{ padding: '0.4rem 0' }}>
                                Currently, PayPal adds a 3.7% processing fee
                                plus $0.3 US.
                            </p>
                            <p style={{ padding: '0.4rem 0' }}>
                                To access your KgHype Wallet, log in and then
                                select the Wallet tab.
                            </p>
                            <p style={{ padding: '0.4rem 0' }}>
                                In order to keep up with the most recent rate
                                released by the Bank of China, we will make
                                periodic adjustments.
                            </p>
                        </CardContent>
                    </div>
                    <CardActions></CardActions>
                </Card>
            </Container>
            <Container style={ContainerStyle}>
                <Card sx={{ maxWidth: 1000 }}>
                    <div>
                        <h1
                            style={{
                                margin: '1rem 0',
                                fontSize: '3.4rem',
                                padding: '16px',
                            }}
                        >
                            Top Up
                        </h1>
                        <CardContent>
                            <p style={{ padding: '0.4rem 0' }}>
                                To add funds to your Kghype account, go to your
                                account page and follow the prompts to do so.
                            </p>
                            <p style={{ padding: '0.4rem 0' }}>
                                Pick your payment option, then hit "Pay Now"
                            </p>
                            <p style={{ padding: '0.4rem 0' }}>
                                You may choose to add funds using either your
                                PayPal balance or a credit/debit card.
                            </p>
                        </CardContent>
                    </div>
                    <CardActions></CardActions>
                </Card>
            </Container>
        </div>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const SideMenu = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: Open Sans;
    padding: 0px;
    margin: 0px;
    padding-top: 50px;
`

const Content = styled.section`
    padding-left: 100px;
    max-width: 100%;
    flex: 1;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
    }
`

const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 50px 0px;
    align-items: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

const TextWrapper = styled.div`
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
        padding-top: 30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding: 0px;
    margin: 0px;
    padding: 0px;
    @media screen and (max-width: 800px) {
        text-align: center;
    }
`
