import React from 'react'
import { Box, Heading, Center, Flex, Button, Image } from '@chakra-ui/react'
import CustomBulletList from '../../common/CustomBulletList'
import { expandingData, marketibngData } from './ExpandingProductsData'

function GetFromSBS() {
    return (
        <Flex direction="column" alignItems="center">
            <Heading color="black" fontSize="3xl" py="16" textAlign="center">
                What can you get from SBS?
            </Heading>
            <Center>
                <Flex
                    direction={['column', 'column', 'column', 'row', 'row']}
                    alignItems="center"
                    maxW="70rem"
                    gap={10}
                >
                    <Flex
                        direction="column"
                        position="absolute"
                        bg="white"
                        alignItems="center"
                        p="10"
                        left="48%"
                        transform={[
                            'translateY(50%)',
                            'translateY(50%)',
                            'translateY(50%)',
                            'translateY(0%)',
                            'translateY(0%)',
                        ]}
                        borderRadius="lg"
                        boxShadow="lg"
                    >
                        <Image src="/inset_0.png" />
                        <Box color="rgba(0, 0, 0, .5)">Sourcing</Box>
                    </Flex>
                    <Box>
                        <Image src="/service_0.png" w="900px" alt="" />
                    </Box>
                    <Box>
                        <CustomBulletList
                            title="Expanding your products"
                            bulletColor="blue"
                            box={true}
                            listData={expandingData}
                        />
                    </Box>
                </Flex>
            </Center>
            <Center>
                <Flex
                    direction={['column', 'column', 'column', 'row', 'row']}
                    alignItems="center"
                    maxW="70rem"
                    gap={10}
                    mt="10"
                >
                    <Box>
                        <CustomBulletList
                            title="Marketing support"
                            bulletColor="blue"
                            box={true}
                            listData={marketibngData}
                        />
                    </Box>
                    <Flex
                        direction="column"
                        position="absolute"
                        bg="white"
                        alignItems="center"
                        p="10"
                        left={['50%', '50%', '50%', '40%', '40%']}
                        transform={[
                            'translateY(100%)',
                            'translateY(100%)',
                            'translateY(100%)',
                            'translateY(0%)',
                            'translateY(0%)',
                        ]}
                        borderRadius="lg"
                        boxShadow="lg"
                    >
                        <Image src="/inset_1.png" />
                        <Box color="rgba(0, 0, 0, .5)">Marketing</Box>
                    </Flex>
                    <Box w={['60%', '60%']}>
                        <Image src="/service_1.png" w="900px" alt="" />
                    </Box>
                </Flex>
            </Center>
        </Flex>
    )
}

export default GetFromSBS
