import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import AvailableCoupon from './AvailableCoupon'
import CouponExpired from './CouponExpired'
import CouponUsed from './CouponUsed'
import couponsPng from '../../../assets/icons/coupons.png'
import { db } from 'firebase.js'
import {
    ref,
    update,
    onValue,
} from 'firebase/database'
import styled from 'styled-components'
import { useSelector } from 'react-redux';

const EmptyState = () => (
    <div className="couponPage__empty">
        <img src={couponsPng} alt="coupons" />
        <p>You don't have a coupon yet.</p>
    </div>
)

const CouponPage = () => {
    const isEmpty = false
    const [couponTab, setCouponTab] = useState('available')
    const [codeTxt, setCodeTxt] = useState('')
    const [currCouponsTab, setCurrCouponsTab] = useState(<></>);

    let user = useSelector(state => state.user);

    // Redeem coupon
    const redeemHandler = async (e) => {
        e.preventDefault();
        if (!codeTxt.trim()) return toast.error('Invalid input');

        if (user) {
            toast.loading('Please wait...');

            onValue(ref(db, `users/${user.key}`), snapshot => {
                let vals = snapshot.val();
                onValue(ref(db, 'coupon_list'), couponSnapshot => {
                    let coupons = couponSnapshot.val();
                    if (Object.keys(coupons).includes(codeTxt)) {
                        if (vals?.available_coupons?.includes(codeTxt)) {
                            toast.error("Code has been already redeemed");
                        } else if (vals?.used_coupons?.includes(codeTxt)) {
                            toast.error("This coupon has already been used");
                        } else {
                            if (new Date(coupons[codeTxt]?.expiry_date).getTime() <= new Date().getTime()) {
                                toast.error("Code has been expired");
                            } else {
                                if (vals?.available_coupons) vals["available_coupons"].push(codeTxt);
                                else vals["available_coupons"] = [codeTxt];
                                update(ref(db, `users/${user.key}`), vals).then(() => {
                                    toast.success("Code has been redeemed successfully");
                                });
                            }
                        }
                    } else toast.error("Code is invalid");
                }, {onlyOnce: true})
            }, {onlyOnce: true});

        } else { toast.error("Please try again."); }
    }

    useEffect(() => {
        if (user) {
            setCurrCouponsTab(<AvailableCoupon currentUser={user} />);

            switch (couponTab) {
                case 'used':
                    setCurrCouponsTab(<CouponUsed currentUser={user} />)
                    break
                case 'expired':
                    setCurrCouponsTab(<CouponExpired currentUser={user} />)
                    break
                default:
                    setCurrCouponsTab(<AvailableCoupon currentUser={user} />)
            }
        }
    }, [couponTab, user]);

    return (
        <div className="couponPage">
            <div className="couponPage__redeem">
                <form className="couponPage__redeemForm">
                    <input
                        className="couponPage__redeemInput"
                        type="text"
                        placeholder="Please, enter redeem code"
                        value={codeTxt}
                        onChange={(e) => setCodeTxt(e.target.value)}
                    />
                    <button
                        className="couponPage__redeemBtn"
                        onClick={(event) => redeemHandler(event)}
                    >
                        Convert
                    </button>
                </form>
            </div>
            <CouponButtonsList className="couponPage__couponTab">
                <li
                    onClick={() => setCouponTab('available')}
                    className={`couponPage__couponTabItem ${
                        couponTab === 'available'
                            ? 'couponPage__couponTabItem--active'
                            : ''
                    }`}
                >
                    Available Coupon
                </li>
                <li
                    onClick={() => setCouponTab('used')}
                    className={`couponPage__couponTabItem ${
                        couponTab === 'used'
                            ? 'couponPage__couponTabItem--active'
                            : ''
                    }`}
                >
                    Coupon Used
                </li>
                <li
                    onClick={() => setCouponTab('expired')}
                    className={`couponPage__couponTabItem ${
                        couponTab === 'expired'
                            ? 'couponPage__couponTabItem--active'
                            : ''
                    }`}
                >
                    Coupon Expired
                </li>
            </CouponButtonsList>

            {isEmpty ? <EmptyState /> : currCouponsTab}
        </div>
    )
}

const CouponButtonsList = styled.ul`
    flex-wrap: wrap;
`

export default CouponPage
