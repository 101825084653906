import styled from 'styled-components'
// import { Container } from "components/container";
import { Container } from '@mui/material'
import AddressEdit from "components/address-edit"
import Button from 'components/button'
import { Heading } from 'components/heading'
import Spinner from "components/Spinner"
import { Text } from 'components/text'
import { CHECKOUT_PREFIX, PUBLIC_PREFIX } from 'configs/app-config'
import { db } from 'firebase.js'
import { useEffect, useState } from 'react'
import { ArrowRightCircleFill, PencilSquare } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getShippingMethodList, shippingCalculator } from 'utilities/shipping'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import AddressDialog from '../address/add_address_dialog'
import { ShippingMethodItem } from './shipping_components'

function Products({ next, cartItems = [], setActions, setPackingDetails, setNote }) {
    const [cart, setCart] = useState([])
    const [total, setTotal] = useState(0)

    const { height, width } = useWindowDimensions()
    const [tax, setTax] = useState(0.0)
    const [packagingFee, setPackagingFee] = useState(0.0)

    function firstStageNext() {
        //next();

        let pd = Array.from(document.querySelectorAll(".packing-details input"));
        let pdLen = pd.length;
        let pdArr = [];
        for (let i = 0; i < pdLen; i++) if (pd[i].checked) pdArr.push(pd[i].parentElement.querySelector("span").innerText);
        setPackingDetails(pdArr);

        let ac = Array.from(document.querySelectorAll(".actions-container input"));
        let acLen = ac.length;
        let acArr = [];
        for (let i = 0; i < acLen; i++) if (ac[i].checked) acArr.push(ac[i].parentElement.querySelector("span").innerText);
        setActions(acArr);

        setNote(document.getElementById("note").value);
        next();
    }

    return (
        <Container maxWidth="lg">
            <Heading style={{ fontSize: '36px' }}>Product List</Heading>

            <div>
                <Heading style={{ textAlign: 'left' }}>Products</Heading>

                <div>
                    <div>{cartItems?.selectedRows?.length || 0} item(s)</div>
                    <br />
                    <div>
                        {cartItems?.selectedRows?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection:
                                            width <= 1000 ? 'column' : 'row',
                                        width: '100%',
                                        marginBottom: '5%',
                                        boxShadow: '#80808061 2px 2px 5px 0px',
                                        borderRadius: '12px',
                                        padding: "10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width:
                                                width <= 1000 ? '100%' : '40%',
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: '100%',
                                                maxWidth:
                                                    width <= 1000
                                                        ? '80px'
                                                        : '180px',
                                                objectFit: 'contain',
                                                borderRadius: '12px',
                                            }}
                                            src={item?.images?.[0] || (item?.product_image ? item?.product_image : "/case_2.png")}
                                            alt="item"
                                        />

                                        <h3
                                            style={{
                                                fontSize: '16px',
                                                marginLeft: '2%',
                                                color: '#072a48',
                                            }}
                                        >
                                            {item?.title}
                                        </h3>
                                    </div>

                                    <InfoWrapper
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            width:
                                                width <= 1000 ? '100%' : '60%',
                                            marginTop:
                                                width <= 1000 ? '2%' : '0px',
                                            marginBottom:
                                                width <= 1000 ? '2%' : '0px',
                                        }}
                                    >
                                        <div>
                                            <h3 style={{ color: '#072a48' }}>
                                                Qty
                                            </h3>

                                            <span>1</span>
                                        </div>

                                        <div>
                                            <h3 style={{ color: '#072a48' }}>
                                                Weight
                                            </h3>

                                            <span>{item?.weight}g</span>
                                        </div>

                                        <div className="actions-container">
                                            <h3 style={{ color: '#072a48' }}>
                                                Actions
                                            </h3>
                                            <ActionsWrapper>
                                                <span
                                                    style={{ color: '#072a48', marginRight: "10px" }}
                                                >
                                                    Remove Tags
                                                </span>
                                                <Checkbox
                                                    type="checkbox"
                                                    id="vehicle1"
                                                    name="vehicle1"
                                                    value="Bike"
                                                />
                                            </ActionsWrapper>
                                            <ActionsWrapper>
                                                <span
                                                    style={{ color: '#072a48', marginRight: "10px" }}
                                                >
                                                    Remove Packaging
                                                </span>
                                                <Checkbox
                                                    type="checkbox"
                                                    id="vehicle1"
                                                    name="vehicle1"
                                                    value="Bike"
                                                />
                                            </ActionsWrapper>
                                        </div>

                                        <div>
                                            {/* <NumberBox /> */}

                                            <br />

                                            <Heading
                                                style={{ color: '#072a48' }}
                                            >
                                                {parseFloat(item?.price || 0).toFixed(2) || 0} ¥
                                            </Heading>
                                        </div>
                                    </InfoWrapper>
                                </div>
                            )
                        })}
                    </div>

                    <hr />

                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '12px',
                            }}
                        >
                            <div>Subtotal</div>
                            <div>{total} ¥</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '12px',
                            }}
                        >
                            <div>Tax</div>
                            <div>{tax} ¥</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '12px',
                            }}
                        >
                            <div>Packaging etc.</div>
                            <div>{packagingFee} ¥</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '12px',
                            }}
                        >
                            <div style={{ fontWeight: 'bold' }}>Total</div>
                            <div style={{ fontWeight: 'bold' }}>
                                {total + tax + packagingFee} ¥
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: "10px"
                        }}
                    >
                        Packing Details
                        <div className="packing-details">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{paddingRight:"5px"}}>Strech Film</span>
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{paddingRight:"5px"}}>Moisture Bag</span>

                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{paddingRight:"5px"}}>Vaccuum Packing</span>
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{paddingRight:"5px"}}>Simple Packing</span>
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>
                        </div>
                    </div>

                    <textarea
                        rows="8"
                        id="note"
                        placeholder="Please leave a note here if you have other requirements"
                        style={{
                            width: '100%',
                            marginTop: '24px',
                            resize: 'none',
                            padding:"10px",
                            fontSize:"17px"
                        }}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        style={{
                            borderRadius: '10px',
                            fontSize: '18px',
                            padding: '16px 8px',
                            margin: '24px 0',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        text="Next"
                        onClick={firstStageNext}
                    />
                </div>
            </div>
        </Container>
    )
}

function ShippingMethod({ next, totalWeight = 0, onSelect, country }) {
    const [selected, setSelected] = useState(null)
    const [shippingInfo, setShippingInfo] = useState([])
    const [netWeight, setNetWeight] = useState(0)

    useEffect(() => {
        setNetWeight(totalWeight)
        getShippingMethodList().then((shippingList) => {
            setShippingInfo(shippingList)
        })
    }, [])

    function handleSelection(data) {
        setSelected(data)
        if (onSelect) {
            onSelect(data)
        }
    }

    return (
        <Container maxWidth="lg">
            <Heading style={{ fontSize: '36px' }}>Shipping Method</Heading>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Heading style={{ textAlign: 'left' }}>
                    Select your shipping method
                </Heading>

                {shippingInfo?.map((info) => (
                    <ShippingMethodItem
                        isSelected={
                            selected && selected?.name === info?.name
                                ? true
                                : false
                        }
                        shippingInfo={info}
                        totalWeight={netWeight}
                        onClick={handleSelection}
                        showAll={true}
                        country={country}
                    />
                ))}
                {shippingInfo?.length === 0 &&
                    <p>A shipping method that fits with current requirements has not found</p>
                }

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        style={{
                            borderRadius: '10px',
                            fontSize: '18px',
                            padding: '16px 8px',
                            margin: '24px 0',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        text="Next"
                        disabled={selected ? false : true}
                        onClick={next}
                    />
                </div>
            </div>
        </Container>
    )
}

const FontStyle = {
    fontSize: '14px',
    color: '#72727E',
    textAlign: 'left',
}

function ParcelDeclare({ next, shippingMethod, totalWeight }) {
    function showShipment() {
        if (shippingMethod) {
            return (
                <Heading style={{ textAlign: 'left' }}>
                    You selected {shippingMethod.name} as your shipment method
                </Heading>
            )
        } else {
            return (
                <Heading style={{ textAlign: 'left' }}>
                    No shipment method selected
                </Heading>
            )
        }
    }

    return (
        <>
            <Heading style={{ fontSize: '36px' }}>Parcel Declare</Heading>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {showShipment()}
                <ShippingMethodItem
                    shippingInfo={shippingMethod}
                    totalWeight={totalWeight}
                    showSelectButton={false}
                    showAll={true}
                />
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        maxWidth: '900px',
                        marginTop: '32px',
                    }}
                >
                    <div>
                        <label>
                            <Heading style={FontStyle}>
                                Tax Payment Method
                            </Heading>
                        </label>
                        <SelectDropdown name="warehouses" id="warehouses">
                            <option value="h-1">Recipiant</option>
                        </SelectDropdown>
                    </div>

                    <Text style={{ marginTop: '16px' }}>
                        When package arrives at destination, you need to pay
                        taxes; allow the local authorities and co-operate with
                        them.
                    </Text>

                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '49%', marginRight: '2%' }}>
                            <label>
                                <Heading style={FontStyle}>
                                    Declare Method
                                </Heading>
                            </label>
                            <SelectDropdown name="warehouses" id="warehouses">
                                <option value="h-1">
                                    Personalized Declare
                                </option>
                            </SelectDropdown>
                        </div>
                        <div style={{ width: '49%' }}>
                            <label>
                                <Heading style={FontStyle}>
                                    Declared Currency
                                </Heading>
                            </label>
                            <SelectDropdown name="warehouses" id="warehouses">
                                <option value="h-1">USD</option>
                            </SelectDropdown>
                        </div>
                    </div>

                    <WarningBox>
                        Logicstics disclaimer - reminder When package are
                        procceding customes clearance of the destination
                        country, i If you recieved a tax notice please actively
                        assist in customs clearance When package are procceding
                        customes clearance of the destination country, i If you
                        recieved a tax notice please actively assist in customs
                        clearance
                    </WarningBox>

                    <Heading style={{ fontSize: '36px' }}>
                        Value Added Services
                    </Heading>
                    <span style={{ fontWeight: 'bold' }}>
                        Parcel Declarement
                    </span>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        <span style={{ marginRight: '2%' }}>
                            Custom Declare
                        </span>{' '}
                        <Checkbox
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                        />
                    </div>

                    <WarningBox>
                        Declarment Tips When package are procceding customes
                        clearance of the destination country, i If you recieved
                        a tax notice please actively assist in customs clearance
                        When package are procceding customes clearance of the
                        destination country, i If you recieved a tax notice
                        please actively assist in customs clearance
                    </WarningBox>

                    <TableHeading>
                        <TableHeadingColumn>
                            Platform Service Fee
                        </TableHeadingColumn>
                        <TableHeadingColumn>Freight</TableHeadingColumn>
                        <TableHeadingColumn>
                            Freight Discount
                        </TableHeadingColumn>
                        <TableHeadingColumn>Packaging Fee</TableHeadingColumn>
                        <TableHeadingColumn>
                            Vaccuum Packaging
                        </TableHeadingColumn>
                        <TableHeadingColumn>Reinforced Box</TableHeadingColumn>
                        <TableHeadingColumn>
                            Shockproof Packaging
                        </TableHeadingColumn>
                        <TableHeadingColumn>Parcel Photo</TableHeadingColumn>
                        <TableHeadingColumn>Shoebox Removal</TableHeadingColumn>
                        <TableHeadingColumn>Total</TableHeadingColumn>
                    </TableHeading>

                    <TableRow>
                        <TableHeadingColumn>0.00Y</TableHeadingColumn>
                        <TableHeadingColumn>240.00Y</TableHeadingColumn>
                        <TableHeadingColumn>0.00Y</TableHeadingColumn>
                        <TableHeadingColumn>0.00Y</TableHeadingColumn>
                        <TableHeadingColumn>0.00Y</TableHeadingColumn>
                        <TableHeadingColumn>0.00Y</TableHeadingColumn>
                        <TableHeadingColumn>0.00Y</TableHeadingColumn>
                        <TableHeadingColumn>0.00Y</TableHeadingColumn>
                        <TableHeadingColumn>0.00Y</TableHeadingColumn>
                        <TableHeadingColumn>240.00Y</TableHeadingColumn>
                    </TableRow>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <span>Packaging Fee (Fee 8Y)</span>{' '}
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>

                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {' '}
                                <span>Vaccuum Packaging (Fee 26Y)</span>{' '}
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>

                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {' '}
                                <span>Reinforcment for Box (Fee 20Y)</span>{' '}
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>

                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <span>Shockproof Packaging (Fee 20Y)</span>{' '}
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>

                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {' '}
                                <span>Waterproof Packaging (Fee 10Y)</span>{' '}
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>

                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <span>Palet Photo (Fee 0Y)</span>{' '}
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>

                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {' '}
                                <span>Shoebox Removal (Fee 0Y)</span>{' '}
                                <Checkbox
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                />
                            </div>
                        </div>
                    </div>

                    <ConfirmBox>
                        <ConfirmDetails>
                            Estimated parcel Weight : {totalWeight}g
                            {/*<br />
                            Parcel estimated Volume : 5338.67g*/}
                        </ConfirmDetails>
                        <Button
                            onClick={next}
                            style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                borderRadius: '10px',
                            }}
                            text="Submit Parcel"
                        />
                    </ConfirmBox>
                </div>
            </div>
        </>
    )
}

function ShippingAddress({ next, onSelect }) {
    let user = useSelector(state => state.user);
    const [addressList, setAddressList] = useState(null)
    const [currentAddress, setCurrentAddress] = useState('')

    useEffect(async () => {
        if (user) {
            user.addressBookRef().on('value', (snapshot) => {
                let list = snapshot.val()
                if (list) {
                    setAddressList(list)
                } else setAddressList([])
            })
        }
    }, [user])

    const [addressData, setAddressData] = useState({});

    function handleSelect(a) {
        setCurrentAddress(a)
        if(onSelect) {
            onSelect(a)
        }
    }

    const [addressEditPopup, setAddressEditPopup] = useState(false);
    function showAddressEditPopup(data) {
        setAddressEditPopup(true);
        setAddressData(data);
    }

    const [country, setCountry] = useState("");
    function editAddress() {
        let changeValues = {
            name: document.querySelector('#address-edit-popup #name').value,
            country: country,
            state: document.querySelector('#address-edit-popup #state').value,
            address: document.querySelector('#address-edit-popup #address').value,
            city: document.querySelector('#address-edit-popup #city').value,
            postcode: document.querySelector('#address-edit-popup #postcode').value,
            phone: document.querySelector('#address-edit-popup #phone').value,
            email: document.querySelector('#address-edit-popup #email').value,
        }
        
        let data = addressData;
        setAddressEditPopup(false);

        try {
            let datas_ = addressList;
            datas_[addressList.indexOf(data)] = changeValues;
            db.ref(`users/${user.key}/addressbook`).set(datas_).then(() => {
                toast.success("Address successfully edited");
            });
        } catch(e) {}
    }


    if (!addressList) return <Spinner />
    return (
        <div style={{width: "80%", margin: "10px auto"}}>
            <h3>Choose Shipping Address</h3>
            <AddressDialog />
            <ul style={{width: "500px", padding: "10px"}}>
            {
                addressList.map((a) =>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div style = {{cursor:"pointer", border: currentAddress === a ? "solid 2px green" : null, padding: "10px"}} onClick={() => handleSelect(a)}>
                        <li style={{display: "flex", alignItems: "center", height:"30px"}}>
                            <ArrowRightCircleFill width="20px" height="20px"/>
                            <span style={{marginLeft:"10px", marginTop: "-5px", fontSize: "20px"}}>{a.address}, {a.city}, {a.country}</span>
                        </li>
                    </div>
                    <PencilSquare color="green" width="20px" height="20px" style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => {showAddressEditPopup(a)}} />
                </div>
                )
            }
            { addressEditPopup && 
                <AddressEdit 
                    addressData={addressData}
                    country={country}
                    setCountry={setCountry}
                    editAddress={editAddress}
                    setAddressEditPopup={setAddressEditPopup}
                />
            }
        </ul>

        <Button
            onClick={next}
            style={{
                fontSize: '18px',
                fontWeight: 'bold',
                borderRadius: '10px',
            }}
            text="Next"
        />
        </div>
    );
}

export default function Shipping() {
    const location = useLocation()
    const history = useHistory()
    const [stage, setStage] = useState(1)
    const [cartItems, setCartItems] = useState({
        selectedRows: [],
        totalWeight: 0,
    });

    const [shipment, setShipment] = useState(null)
    const [shipmentFee, setShipmentFee] = useState(0)
    const [shippingAddress, setShippingAddress] = useState(false);
    const [shippingCountry, setShippingCountry] = useState(false);
    const [actions, setActions] = useState([]);
    const [packingDetails, setPackingDetails] = useState([]);
    const [note, setNote] = useState("");

    useEffect(() => {
        if (location?.state?.selectedRows) {
            setCartItems(location.state)
        }
    }, [location])

    function handleSubmit() {
        history.push(PUBLIC_PREFIX + CHECKOUT_PREFIX, {
            type: 'SHIPPING',
            items: cartItems.selectedRows,
            shippingInfo: shipment,
            shippingFee: shipmentFee
        })
    }

    function handleShippingAddressSelect(a) {
        setShippingAddress(a)
        setShippingCountry(a.country);
    }

    async function handleShipmentSelect(shippingInfo) {
        //console.log("Shipping info:", shippingInfo)

        //SHIPPING INFO IS HERE
        let initialFee = shippingInfo.country_prices ? shippingInfo.country_prices[0].initialWeightFee : 500;
        let continuedFee = shippingInfo.country_prices ? shippingInfo.country_prices[0].feePerGram : 50;
        
        if (shippingInfo.country_prices) {
            if (shippingInfo?.country_prices[0]?.initialWeightFee) initialFee = shippingInfo?.country_prices[0]?.initialWeightFee;
            else initialFee = 500;
            if (shippingInfo?.country_prices[0]?.feePerGram) continuedFee = shippingInfo?.country_prices[0]?.feePerGram;
            else continuedFee = 50;
        }
        
        let shippingFee = shippingCalculator({
            weight: cartItems.totalWeight, 
            feePerGram: parseFloat(continuedFee), 
            initialWeight: parseFloat(shippingInfo.initialWeight) || 500,
            initialWeightFee: parseFloat(initialFee)
        });

        shippingInfo.shippingAddress = shippingAddress;
        shippingInfo.actions = actions;
        shippingInfo.packingDetails = packingDetails;
        shippingInfo.note = note;

        setShipment(shippingInfo)
        setShipmentFee(shippingFee)
    }

    return (
        <Container>
            <StageStatus>
                <StageStatusNumber completed>1</StageStatusNumber>
                <StageStatusLine completed={stage >= 2}></StageStatusLine>
                <StageStatusNumber completed={stage >= 2}>2</StageStatusNumber>
                <StageStatusLine completed={stage >= 3}></StageStatusLine>
                <StageStatusNumber completed={stage >= 3}>3</StageStatusNumber>
                <StageStatusLine completed={stage >= 4}></StageStatusLine>
                <StageStatusNumber completed={stage >= 4}>4</StageStatusNumber>
                <StageStatusLine></StageStatusLine>
            </StageStatus>

            {stage === 1 && (
                <Products
                    cartItems={cartItems}
                    next={() => setStage(stage + 1)}
                    setActions={setActions}
                    setPackingDetails={setPackingDetails}
                    setNote={setNote}
                />
            )}
            {stage === 2 && (
                <ShippingAddress
                    next={() => setStage(stage + 1)}
                    onSelect={handleShippingAddressSelect}
                />
            )}
            {stage === 3 && (
                <ShippingMethod
                    totalWeight={cartItems.totalWeight}
                    next={() => {
                        if(shipment)
                            setStage(stage + 1)
                    }}
                    onSelect={handleShipmentSelect}
                    country={shippingCountry}
                />
            )}
            {/* {stage === 3 && (
                <ShippingAddress
                    next={() => setStage(stage + 1)}
                    onSelect={handleShipmentSelect}
                />
            )} */}
            {stage === 4 && (
                <ParcelDeclare
                    next={handleSubmit}
                    shippingMethod={shipment}
                    totalWeight={cartItems.totalWeight}
                />
            )}
        </Container>
    )
}

const StageStatus = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const StageStatusNumber = styled.div`
    height: 36px;
    width: 36px;
    background: ${(props) => (props.completed ? '#072a48' : 'white')};
    border: ${(props) => (!props.completed ? '2px solid #D1D5DB' : 'none')};
    color: ${(props) => (props.completed ? 'white' : 'black')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
`

const StageStatusLine = styled.div`
    width: 37px;
    height: 2px;

    background: ${(props) => (props.completed ? '#072a48' : '#D1D5DB')};
    transition: all 0.3s;
`

const Product = styled.div`
    display: flex;
    justify-content: space-between;
`

const Checkbox = styled.input`
    margin: 0;
    accent-color: #00ff29;
    border-radius: 4px;
`

const ResultBox = styled.div`
    display: flex;
    width: 100%;
    max-width: 800px;
    background-color: white;
    padding: 32px 56px;
    border: 1px solid rgb(218, 217, 217);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 32px;

    div {
        flex-grow: 1;
        margin-right: 12px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

const SelectDropdown = styled.select`
    border: none;
    background-color: #eef2f5;
    height: 50px;
    width: 100%;
    outline: none;
    text-indent: 12px;
    font-family: Open Sans;
`

const WarningBox = styled.div`
    background: #f3efd3;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;

    color: #af0404;
    padding: 8px;
    width: 98%;
    margin-top: 12px;
`

const TableHeading = styled.div`
    background: #f1f1f1;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    padding: 6px;
    margin-top: 16px;
`

const TableRow = styled.div`
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    padding: 6px;
    margin-top: 8px;
`

const TableHeadingColumn = styled.div`
    padding: 4px;
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`

const ConfirmBox = styled.div`
    background: #f7f7f7;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin: 24px 0px;
`

const ConfirmDetails = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
`

const InfoWrapper = styled.div`
    @media (max-width: 420px) {
        flex-direction: column;
        text-align: center;
    }
`

const ActionsWrapper = styled.div`
    display: flex;
    justifycontent: space-between;
    alignitems: flex-start;
    @media (max-width: 420px) {
        flex-direction: column;
        align-items: center;
    }
`
