import { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js'
import { Line, getElementsAtEvent } from 'react-chartjs-2'
import styled from 'styled-components'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
)

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            align: 'end',
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        myScale: {
            position: 'right',
            beginAtZero: true,
            ticks: {
                stepSize: 1
            },
            max: 0
        },
        /* grayscale / gray */
    },
    beginAtZero: true,
}

function getPastMonths() {
    const date = new Date();
    let month = date.toLocaleString('en-US', {month: 'long'});
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let arr = [];
    for (let i = 0; i < 12; i++) {
        arr.push(months[i]);
        if (months[i] == month) {
            return arr;
        }
    }
}

export default function AffiliateChart(props) {
    const [datasCalculated, setDatasCalculated] = useState(false);
    const [datas, setDatas] = useState(false);
    const [historyData, setHistory] = useState({});
    const [labels, setLabels] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
    const [update_, setUpdate_] = useState(false);
    
    useEffect(() => {
        let history_ = props.history;
        let historyObj = {};
        let counter = 0;

        Object.keys(history_).forEach(h => { //Clicks
            counter++;
            if (history_[h] != "") {
                historyObj[h] = [];
                let daysArr = [];
                let monthsArr = [];
                history_[h].forEach(date => {
                    if (props.duration != "30-days") {
                        let month = new Date(+date).toLocaleString('en-US', {month: 'long'});
                        let year = new Date(+date).getFullYear();
                        let curYear = new Date().getFullYear();

                        if (year == curYear) monthsArr.push(month);
                        if (date == history_[h][history_[h].length - 1]) {
                            let arr_ = [];

                            let originalLabel = getPastMonths();
                            originalLabel.forEach(label => {
                                arr_.push(monthsArr.filter(x => x === label).length);

                                if (label == originalLabel[originalLabel.length - 1]) {
                                    switch(props.duration) {
                                        case "1-year":
                                            if (originalLabel.length >= 6) {
                                                let labelsArr = [...originalLabel];
                                                setLabels(labelsArr);
                                            }
                                            break;
                                        case "6-months":
                                            if (originalLabel.length >= 6) {
                                                arr_.splice(0, arr_.length - 6);
                                                let labelsArr = [...originalLabel];
                                                labelsArr.splice(0, originalLabel.length - 6);
                                                setLabels(labelsArr);
                                            }
                                            break;
                                        case "3-months":
                                            if (originalLabel.length >= 3) {
                                                arr_.splice(0, arr_.length - 3);
                                                let labelsArr = [...originalLabel];
                                                labelsArr.splice(0, originalLabel.length - 3);
                                                setLabels(labelsArr);
                                            }
                                            break;
                                    }
                                    historyObj[h] = arr_;
                                    setHistory(historyObj);
                                    setDatasCalculated(true);
                                    setUpdate_(!update_);
                                }
                            });
                        }
                    } else {
                        //30 Days selected
                        const getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){a.push(new Date(d).toLocaleDateString("en-EN"));}return a;};

                        let date_ = new Date(+date),
                            cur = new Date();
                            
                        let monthAgo = new Date();
                            monthAgo.setDate(monthAgo.getDate() - 30);
                            monthAgo = monthAgo.toISOString().split('T')[0];

                        let daysArr_, dateAsStr;
                        daysArr_ = getDaysArray(monthAgo, cur);

                        dateAsStr = date_.toLocaleDateString("en-EN");
                        daysArr.push(dateAsStr);

                        if (date == history_[h][history_[h].length - 1]) {
                            let arr_ = [];
                            daysArr_.forEach(day => {
                                if (daysArr.filter(x => x === day).length != 0) arr_.push(daysArr.filter(x => x === day).length);
                                else arr_.push(0);

                                if (day == daysArr[daysArr.length - 1]) {
                                    historyObj[h] = arr_;
                                    setHistory(historyObj);
                                    setLabels(daysArr_);
                                    setUpdate_(!update_);
                                    setDatasCalculated(true);
                                }
                            });
                        }
                    }
                });

            } else {
                historyObj[h] = [0];

                if (Object.keys(history_).length == counter) {
                    setHistory(historyObj);
                    setDatasCalculated(true);
                }
            }
        });
    }, [props.duration]);

    useEffect(() => {
        if (datasCalculated) {
            setDatas({
                labels,
                datasets: [
                    {
                        label: 'Clicks',
                        data: Object.values(historyData["clicks"]),
                        borderColor: '#3751FF',
                        fill: false,
                        hidden: props.chartDataType[0],
                        pointRadius: 7,
                        pointBorderColor: '#3751FF',
                        pointBackgroundColor: 'white',
                        backgroundColor: (context) => {
                            const ctx = context.chart.ctx
                            const gradient = ctx.createLinearGradient(0, 0, 600, 0)
                            gradient.addColorStop(1, '#3751FF')
                            gradient.addColorStop(1, '#FFFFFF')
                            return gradient
                        },
                        lineTension: 0.6,
                    },
                    {
                        label: 'Signups',
                        data: Object.values(historyData["signups"]),
                        hidden: props.chartDataType[1],
                        borderColor: '#f04516',
                        fill: false,
                        pointRadius: 7,
                        pointBorderColor: '#f04516',
                        pointBackgroundColor: 'white',
                        backgroundColor: (context) => {
                            const ctx = context.chart.ctx
                            const gradient = ctx.createLinearGradient(0, 0, 600, 0)
                            gradient.addColorStop(1, '#f04516')
                            gradient.addColorStop(1, '#FFFFFF')
                            return gradient
                        },
                        lineTension: 0.6,
                    },
                    {
                        label: 'Sales Amount',
                        data: Object.values(historyData["sales"]),
                        hidden: props.chartDataType[2],
                        borderColor: '#38c300',
                        fill: false,
                        pointRadius: 7,
                        pointBorderColor: '#38c300',
                        pointBackgroundColor: 'white',
                        backgroundColor: (context) => {
                            const ctx = context.chart.ctx
                            const gradient = ctx.createLinearGradient(0, 0, 600, 0)
                            gradient.addColorStop(1, '#38c300')
                            gradient.addColorStop(1, '#FFFFFF')
                            return gradient
                        },
                        lineTension: 0.6,
                    },
                    {
                        label: 'Earnings',
                        data: Object.values(historyData["earnings"]),
                        hidden: props.chartDataType[3],
                        borderColor: '#8800c3',
                        fill: false,
                        pointRadius: 7,
                        pointBorderColor: '#8800c3',
                        pointBackgroundColor: 'white',
                        backgroundColor: (context) => {
                            const ctx = context.chart.ctx
                            const gradient = ctx.createLinearGradient(0, 0, 600, 0)
                            gradient.addColorStop(1, '#8800c3')
                            gradient.addColorStop(1, '#FFFFFF')
                            return gradient
                        },
                        lineTension: 0.6,
                    },
                    {
                        label: 'Payments',
                        data: Object.values(historyData["payments"]),
                        hidden: props.chartDataType[4],
                        borderColor: '#c30054',
                        fill: false,
                        pointRadius: 7,
                        pointBorderColor: '#c30054',
                        pointBackgroundColor: 'white',
                        backgroundColor: (context) => {
                            const ctx = context.chart.ctx
                            const gradient = ctx.createLinearGradient(0, 0, 600, 0)
                            gradient.addColorStop(1, '#c30054')
                            gradient.addColorStop(1, '#FFFFFF')
                            return gradient
                        },
                        lineTension: 0.6,
                    },
                ],
            });

            //Find visible datas
            let max = 0;
            let all = ["clicks", "signups", "sales", "earnings", "payments"];
            for (let i = 0; i < all.length; i++) {
                if (!props.chartDataType[i]) {
                    let len = Object.values(historyData[all[i]].filter(x => x > 0));
                    let largest = len[0];
                    for (let j = 0; j < len.length; j++) {
                        if (largest < len[j] ) {
                            largest = len[j];
                        }
                    }
                    if (largest > max) max = largest;
                }
            }

            options.scales.myScale.max = max + 4;
        }
    }, [datasCalculated, update_, props]);
    
    if (!datas) return <></>;
    return (
        <ChartContainer>
            <Line options={options} data={datas} />
        </ChartContainer>
    )
}

const ChartContainer = styled.div`
    padding: 1rem 4rem;

    @media (max-width: 800px) {
        padding: 1rem 0;
    }
`
