import Spinner from "components/Spinner";
import React, { useState, useEffect } from 'react';

function SpinnerWrapper() {
    return <Spinner bg="dark" />;
}

function ChildrenWrapper({ children }) {
    return children
}

export default function useLoader() {
    const [loading, setLoading] = useState(true)

    function LoaderWrapper() {
        if (loading) return SpinnerWrapper;

        return ChildrenWrapper
    }

    function dataLoader(callbackPromise) {
        return new Promise(async (resolve, reject) => {
            try {
                await callbackPromise()
                setLoading(false)
                resolve()
            } catch(e) {
                reject(e)
            }
        })
    }

    return [LoaderWrapper(), dataLoader]
}