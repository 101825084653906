import axios from 'axios'
import Footer from 'components/footer'
import Header from 'components/header'
import StatusBar from 'components/status-bar'
import { UserCurrencyProvider } from 'contexts/userCurrencyContext'
import { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import PublicPage from 'views/public'

export default function PublicLayout() {
    const [userCurrency, setUserCurrency] = useState(
        localStorage.getItem('currency') || 'USD'
    )

    const updateUserCurrency = (currency) => {
        localStorage.setItem('currency', currency)
        setUserCurrency(currency)
    }

    useEffect(() => {
        if (!localStorage.getItem("currency")) {
            axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                localStorage.setItem('currency', data.currency);
                setUserCurrency(data.currency);
            });
        }
    }, []);

    return (
        <>
            <UserCurrencyProvider value={userCurrency}>
                <StatusBar />
                <Header updateUserCurrency={updateUserCurrency} />
                <Content>
                    <Switch>
                        <Route path="/">
                            <PublicPage />
                        </Route>
                    </Switch>
                    <ToastContainer />
                </Content>
            </UserCurrencyProvider>
            <Footer />
        </>
    )
}

const Content = styled.section`
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
