import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import { onTopupRequestChanged } from 'utilities/wallet';
import useLoader from 'hooks/useLoader';

const HeadingStyle = {
    textAlign: "left",
    marginTop: "20px",
    marginBottom: "-20px"
}

const ContainerStyle = {
    paddingBottom: "10px",
    width: '100%',
    marginLeft: "20px"
}

const columns = [
    {
        name: 'Amount',
        width: "250px",
        cell: (d) => {
            return <p>{d.amount}</p>
        }
    },
    {
        name: 'Status',
        width: "300px",
        cell: (d) => {
            return <p>Pending</p>
        }
    },
    {
        name: 'Date',
        selector: (row) => row?.date,
        width: "300px",
        cell: (d) => {
            return <p>{new Date(parseInt(d?.date)).toLocaleString("en-EN")}</p>
        }
    },
    {
        name: 'Description',
        width: "300px",
        cell: (d) => {
            return <p>{d?.desc || "-"}</p>
        }
    },
]

export default function TopupRequestPage({ userKey }) {
    const [datas, setData] = useState([]);
    const [LoaderWrapper, dataLoader] = useLoader()
    const [selectedRows, setSelectedRows] = useState([])

    function handleRowSelect(select_data) {
        setSelectedRows((prev) => {
            prev.splice(0, prev.length, ...select_data.selectedRows)
            return prev
        })
    }


    useEffect(() => {
        if (userKey) {
            const fetchData = async () => {
                try {
                    let list_ = []
                    onTopupRequestChanged((orderList) => {
                        let cpy = { ...orderList }
                        orderList = Object.values(orderList);
                        for (let i = 0; i < orderList.length; i++) {
                            let order = orderList[i];
                            if (order.id == userKey) list_.push({ ...order, ...{ date: Object.keys(cpy)[i] } });
                        }
                        setData(list_);
                    })
                } catch (e) {
                    console.error(e)
                }
            };
            dataLoader(fetchData);
        }
    }, [userKey])

    const tableData = {
        columns,
        data: datas
    };

    return (
        <div>
            <LoaderWrapper>
                <div style={ContainerStyle}>
                    <div style={HeadingStyle}>
                        <strong>Topup Requests</strong>
                    </div>
                    <br />
                    <DataTable
                        title="Topup Requests"
                        columns={columns}
                        data={tableData.data}
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        striped
                        selectableRows
                        onSelectedRowsChange={handleRowSelect}
                        customStyles={
                            {
                                table: {
                                    style: {
                                        fontFamily: "Montserrat",
                                    }
                                },
                                headCells: {
                                    style: {
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        fontWeight: "bold",
                                        color: "#504D4D"
                                    },
                                    draggingStyle: {
                                        cursor: 'move',
                                    },
                                },

                                headRow: {
                                    style: {
                                        minHeight: '52px',
                                        borderBottomWidth: '1px',
                                        borderBottomStyle: 'solid',
                                        // backgroundColor: "#F0EFEF",
                                    },
                                    denseStyle: {
                                        minHeight: '32px',
                                    },
                                },
                                rows: {
                                    style: {
                                        border: 'solid 0px blue',
                                        backgroundColor: '#F4F4F5'

                                    }
                                }
                            }
                        }
                    />
                </div>

            </LoaderWrapper>
        </div>
    )
}