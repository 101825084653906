export const expandingData = [
    "High quality valued products directly from China",
    "Warehousing in Mainland China and Hong Kong(3-6 months free storage)",
    "Mixed bulk purchasing, available to mix various items",
    "Extremely low MOQ",
    "Drop-ship",
    "Product & packaging customization",
    "Cardboard display stand and packaging materials purchasing",
    "Door-to-door delivery",
    "Top performing and discounted products recommendation",
    "Group buys",
    "Quality inspection report",
]

export const marketibngData = [
    "Digital documents",
    "Market potential research of products",
    "Product photography and videography",
    "Brand and packaging",
    "Brochures and flyers print",
]

