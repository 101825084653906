import { Box, Center, Flex, Heading, AspectRatio, Button } from "@chakra-ui/react"
import CustomBulletList from "../../common/CustomBulletList"
import mostPopularData from "./MostPopularData"

function MostPopular() {
  return (
    <Box py="20">
        <Center>
            <Flex direction={["column", "column", "column", "row", "row"]} alignItems="center" maxW="70rem" gap={10}>
                <Box w={["full", "full", "full", "60%", "60%"]}>
                    <video width="800px" height="700px" controls>
                        <source src="https://superbuy-daigou.oss-us-west-1.aliyuncs.com/video/2017/11/24/BuckyDrop-English-version.mp4" type="video/mp4" />
                    </video>
                </Box>
                <Box w={["full", "full", "full", "40%", "40%"]}>
                    <CustomBulletList title="Most Popular Project" bulletColor="red" listData={mostPopularData} />
                    <Button rounded="full" bg="black" color="white" zIndex={2} w="min-content" px={8} py={2} fontWeight="normal" fontSize="lg">Learn More</Button>
                </Box>
            </Flex>
        </Center>
    </Box>
  )
}

export default MostPopular