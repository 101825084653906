import React, { Component } from 'react';
import { Button } from '@mui/material';
import { Plus } from 'react-bootstrap-icons'

import {
    Row,
    Column,
    InputSelection,
} from '../../../components/ui-components'


export function ImageItem({url, onDelete}) {
    return (
        <span style={{margin: '5px 10px'}}>
            <img src={url} alt="..." style = {{width: '180px', height: '180px'}}/>
        </span>
    );
}

export default class ProductImageUpload extends Component {
    fileObj = [];
    fileArray = [];
    fileArray2 = [];
    onFilesSelect;

    constructor({ onFileSelect }) {
        super({ onFileSelect })
        this.state = {
            files: [null],
            files2: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
        this.handleDelete = this.handleDelete.bind(this)

        this.onFileSelect = onFileSelect
    }

    uploadMultipleFiles(e) {
        this.fileObj.push(e.target.files)
        this.fileArray = []
        this.fileArray2 = []
        for(let j = 0; j < this.fileObj.length; j++) {
            for (let i = 0; i < this.fileObj[j].length; i++) {
                this.fileArray.push(URL.createObjectURL(this.fileObj[j][i]))
                this.fileArray2.push(this.fileObj[j][i])
            }
        }
        
        this.setState({ files: this.fileArray, files2: this.fileArray2 })
        if(this.onFileSelect) {
            this.onFileSelect(this.fileArray2[0])
        }
    }

    handleDelete(url) {
        this.setState((prevState) => {
            let files = prevState.files
            let files2 = prevState.files2
            //console.log(prevState)
            for(let i = 0; i < files.length; i++) {
                if(files[i] === url) {
                    files.splice(i, 1)
                    files2.splice(i, 1)
                    break
                }
            }
            prevState.files = files
            prevState.files2 = files2

            return prevState
        })
    }

    uploadFiles(e) {
        e.preventDefault()
        //console.log(this.state.files)
    }

    // Programatically click the hidden file input element
  // when the Button component is clicked
    handleAutoClick (event) {
        event.preventDefault()
        document.getElementById('fileInput').click()
    };

    render() {
        return (
            <div>
                <Row>
                    
                    <div className="form-group">
                        <input type='file' style = {{ display: 'none'}}  id ='fileInput' onChange={this.uploadMultipleFiles}/>
                    </div>
                    
                    <Button onClick = {this.handleAutoClick}>
                        <div style={{width: '180px', height: '180px', border: "dashed 1px grey", alignContent: 'center', alignItems: 'center', textAlign: 'center', verticalAlign: 'center'}}>
                            <Plus style={{
                                        width: '20%',
                                        height: '20%',
                                        alignSelf: 'center',
                                        // fontSize: '10px',
                                    }}/>
                        </div>
                    </Button>

                    <div className="form-group multi-preview">
                        {
                                (this.fileArray || []).map(url => (
                                    ImageItem({url, onDelete: (link) => this.handleDelete(link)})
                                ))
                        }
                    </div>
                </Row>
            </div>
        )
    }
}