// import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField'
import Spinner from 'components/Spinner.js'
import CheckBox from 'components/checkbox'
import { auth, db } from 'firebase.js'
import {
    createUserWithEmailAndPassword
} from 'firebase/auth'
import SignPageLayout from 'layouts/sign-page-layout'
import { useEffect, useMemo, useState } from 'react'
import { isValidEmail } from 'utilities/validator.js'
import Cookie from 'helpers/cookie'
import './index.css'

function makeID(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

let cookie = new Cookie();

const SignUpPage = () => {
    const [data, setData] = useState({
        mail: '',
        password: '',
        fName: '',
        lName: '',
        phoneNum: '',
        country: '',
        username: '',
        agreement: false,
    })

    const [refID, setRefID] = useState(null);
    const [loading, setLoading] = useState(false);

    const validationEmail = useMemo(() => {
        return data.mail === '' || isValidEmail(data.mail)
    }, [data.mail])

    const validationPassword = useMemo(() => {
        return data.password === '' || data.password.length > 6
    }, [data.password])

    const usersRef = db.ref('users');
    const affiliatesRef = db.ref('affiliates');
    const newUserRef = usersRef.push();

    const register = async () => {
        try {
            setLoading(true);
            await createUserWithEmailAndPassword(auth, data.mail, data.password)

            function createUserWithoutRef() {
                    newUserRef
                    .child(btoa(data.mail))
                    .set({
                        mail: data.mail,
                        password: data.password,
                        fName: data.fName,
                        lName: data.lName,
                        phoneNum: data.phoneNum,
                        country: data.country,
                        balance: 0
                    })
                    .then(async () => {
                        let obj = {};
                        let secret = makeID(8);
                        obj[newUserRef.key()] = secret;
                        cookie.setCookie("s", btoa(secret), 90);
                        await db.ref("secrets").update(obj);

                        //Update stat
                        db.ref("stats/signup").once("value", async snapshot => {
                            let vals = snapshot.val();
                            let curDate = new Date().toLocaleString('tr-TR').split(" ")[0].split(".");
                            curDate[1] = new Date().toLocaleString('en-EN', { month: 'long' });
                            curDate = curDate.join(" ");
                            if (!vals) vals = {};
                            if (!vals[curDate]) vals[curDate] = 1;
                            else vals[curDate] += 1;

                            await db.ref("stats/signup").update(vals);
                            window.location = '/profile';
                        });
                    });
            }

            function getDate() {
                var currentDate = new Date();
                var month = currentDate.getMonth();
                if (month < 10) month = "0" + month;
                var dateOfMonth = currentDate.getDate();
                if (dateOfMonth < 10) dateOfMonth = "0" + dateOfMonth;
                var year = currentDate.getFullYear();
                var formattedDate = dateOfMonth + "/" + month + "/" + year;
                return formattedDate;
            }

            if (refID) {
                //Check if redID valid
                affiliatesRef.once("value", async snapshot => {
                    let affiliateList = snapshot.val();
                    if (affiliateList) {
                        if (affiliateList[refID]) {
                            //Valid
                            newUserRef
                                .set({
                                    mail: data.mail,
                                    password: data.password,
                                    fName: data.fName,
                                    lName: data.lName,
                                    phoneNum: data.phoneNum,
                                    country: data.country,
                                    ref: refID,
                                    balance: 0
                                })
                                .then(() => {
                                    let obj = affiliateList[refID];
                                    let users = obj["users"];
                                    if (users == "") users = [{
                                        date: getDate(),
                                        orders: 0,
                                        affiliate_contribution: 0
                                    }];
                                    else {
                                        users.push({
                                            date: getDate(),
                                            orders: 0,
                                            affiliate_contribution: 0
                                        });
                                    }

                                    obj["users"] = users;
                                    obj["signups"] = obj["signups"] + 1;

                                    //Add click history
                                    let curDateNum = new Date().getTime();
                                    let historySignupArr = []
                                    if (obj["history"]["signups"] == "") {
                                        historySignupArr.push(curDateNum)
                                        obj["history"]["signups"] = historySignupArr;
                                    }
                                    else {
                                        historySignupArr = obj["history"]["signups"];
                                        historySignupArr.push(curDateNum);
                                        obj["history"]["signups"] = historySignupArr;
                                    }

                                    affiliateList[refID] = obj;
                                    affiliatesRef.update(affiliateList).then(() => {
                                        //Update stat
                                        db.ref("stats/signup").once("value", async snapshot => {
                                            let vals = snapshot.val();
                                            let curDate = new Date().toLocaleString('tr-TR').split(" ")[0].split(".");
                                            curDate[1] = new Date().toLocaleString('en-EN', { month: 'long' });
                                            curDate = curDate.join(" ");
                                            if (!vals) vals = {};
                                            if (!vals[curDate]) vals[curDate] = 1;
                                            else vals[curDate] += 1;

                                            await db.ref("stats/signup").update(vals);
                                            window.location = '/profile';
                                        });
                                    });
                                });
                        } else {
                            //Not Valid
                            createUserWithoutRef()
                        }
                    }
                });
            } else createUserWithoutRef()
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    useEffect(() => {
        //Get ref ID from URI
        let url_string = window.location.href;
        let url = new URL(url_string);
        let refID = url.searchParams.get("ref");
        if (refID) {
            setRefID(refID);

            affiliatesRef.once("value", async snapshot => {
                let affiliateList = snapshot.val();
                if (affiliateList) {
                    if (affiliateList[refID]) {
                        let obj = affiliateList[refID];
                        obj["clicks"] = affiliateList[refID].clicks + 1;

                        //Add click history
                        let curDateNum = new Date().getTime();
                        let historyClickArr = []
                        if (obj["history"]["clicks"] == "") {
                            historyClickArr.push(curDateNum)
                            obj["history"]["clicks"] = historyClickArr;
                        }
                        else {
                            historyClickArr = obj["history"]["clicks"];
                            historyClickArr.push(curDateNum);
                            obj["history"]["clicks"] = historyClickArr;
                        }

                        affiliateList[refID] = obj;
                        affiliatesRef.update(affiliateList);
                    }
                }
            });
        }
    }, []);

    //Values
    const handleSubmit = (e) => {
        e.preventDefault()
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        if (e.target.id === 'agreement') {
            newData[e.target.id] = e.target.checked
        }
        setData(newData)
    }

    return (
        <SignPageLayout
            rightIcon={'/image/3595867.png'}
            placeholderText="If you don't have an account, please register here. By registering you agree to our terms of services."
            label="Register on our Service!"
        >
            <h1>Register here</h1>
            <h5>
                If you dont have account on our service,please register here. By
                registering you agree to our terms of services.
            </h5>
            <div className="space-2"></div>

            <div className="form">
                <TextField
                    label="First Name"
                    name="firstname"
                    value={data.fName}
                    id="fName"
                    onChange={handleSubmit}
                />
                <TextField
                    label="Last Name"
                    name="lastname"
                    value={data.lName}
                    id="lName"
                    onChange={handleSubmit}
                />
                <TextField
                    label="Email"
                    name="email"
                    value={data.mail}
                    id="mail"
                    error={!validationEmail}
                    placeholder="Email"
                    onChange={handleSubmit}
                />

                <TextField
                    label="Password"
                    name="password"
                    value={data.password}
                    id="password"
                    placeholder="Password"
                    type="password"
                    onChange={handleSubmit}
                    error={!validationPassword}
                />

                <CheckBox
                    name="agreement"
                    label="By register you agree on our Terms of Service and Privacy Policy"
                    value={data.agreement}
                    onChange={handleSubmit}
                    id="agreement"
                />

                {loading ? <Spinner /> : <button className="submit-button" onClick={register}>SUBMIT</button>}
                <br />
            </div>
        </SignPageLayout>
    )
}

export default SignUpPage
