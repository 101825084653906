const modalStyle = {
    zIndex:"10000",
    position:"fixed",
    width:"100%",
    height:"100%",
    top:"0",
    left:"0",
    backgroundColor:"rgba(0,0,0,0.4)",
    alignItems:"center",
    justifyContent:"center"
}
const modalContent = {
    width:"512px",
    height:"512px"
}

export default function Modal(props) {
    return (
        <div className="modal" style={{...modalStyle, display: props.display.display}}>
            <div className="modal-content" style={props?.style ? props.style : modalContent}>
                {props.children}
            </div>
        </div>
    )
}