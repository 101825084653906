import { CardActions } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Container } from 'components/container'
import styled from 'styled-components'

const ContainerStyle = {
    padding: '50px 20px',
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
}

export default function Storage() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Container style={ContainerStyle}>
                <Card sx={{ maxWidth: 1000 }}>
                    <div>
                        <h1
                            style={{
                                margin: '1rem 0',
                                fontSize: '3.4rem',
                                padding: '16px',
                            }}
                        >
                            Storage
                        </h1>
                        <CardContent>
                            <PragraphText>
                                With a "stored in warehouse" order status,
                                products can be kept for up to 180 days (about 6
                                months). Items can be stored for free for a
                                total of 90 days. If you need more time to store
                                your items after the initial free period is
                                over, KgHype will charge CN0.1 per DI every day.
                                After 150 days, we will send you a reminder
                                email asking why you haven't submitted your
                                parcel delivery order. After 180 days, your
                                parcels will be considered abandoned and treated
                                as late whether or not you have placed a
                                delivery request or provided feedback (in this
                                case, KgHype will have ownership of it).
                            </PragraphText>

                            <PragraphText>
                                If a package is signed in first but the order
                                isn't submitted or the logistics information
                                isn't filled out, the warehouse personnel has 60
                                days to store the package before it is
                                considered abandoned. We are unable to send a
                                reminder for this type of package as we are
                                unable to link orders with users. All overdue
                                items will be considered abandoned and KgHype
                                will take possession of them.
                            </PragraphText>

                            <h1>Liability</h1>
                            <PragraphText>
                                KgHype shall be liable for the storage of the
                                items and all related responsibilities
                                throughout the storage period, with the
                                exception of the items' shelf life (for example,
                                the shelf life of perishable items is only one
                                month).
                            </PragraphText>
                            <PragraphText>
                                No more notices or reminders will be sent out
                                for late returns.
                            </PragraphText>
                            <PragraphText>
                                Please submit the parcel delivery order within
                                the storage time to avoid having your products
                                marked as overdue. Your help and patience are
                                much appreciated.
                            </PragraphText>
                        </CardContent>
                    </div>
                    <CardActions></CardActions>
                </Card>
            </Container>
        </div>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const SideMenu = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: Open Sans;
    padding: 0px;
    margin: 0px;
    padding-top: 50px;
`

const Content = styled.section`
    padding-left: 100px;
    max-width: 100%;
    flex: 1;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
    }
`

const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 50px 0px;
    align-items: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

const TextWrapper = styled.div`
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
        padding-top: 30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding: 0px;
    margin: 0px;
    padding: 0px;
    @media screen and (max-width: 800px) {
        text-align: center;
    }
`
const PragraphText = styled.p`
    padding: 1rem 0;

    h2 {
        padding: 0.4rem 0;
    }
`
