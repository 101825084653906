import $ from 'jquery';
import * as React from 'react';
import { useState } from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import { FormGroup } from '@mui/material';
// import { TextField } from '@mui/material';
import { SharedImage } from 'constants/image-constant';
import './topup.scss';

const { Logo } = SharedImage;
// import ImagesGallery from '../../components/image_gallery';


export default function WithdrawalDialog({ open, onClose, onSubmit, balance }) {

  balance = parseFloat(balance);

  $(".modal").on("click", function (e) {
    let target = $(e.target);
    if (!target.closest(".modal-content").length) {
      handleClose();
    }
  });

  const [isOpen, setOpen] = React.useState(open);
  const [amount, setAmount] = React.useState(0)

  React.useEffect(() => {
    setOpen(open)
  }, [open])

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick')
      setOpen(false);
    if (onClose && reason !== 'backdropClick') {
      onClose()
    }
  };

  const [withdrawErrorMessage, setWithdrawErrorMessage] = useState("");

  const handleSubmit = () => {
    let desc = "";
    //let amount = $("#amount-to-withdraw").val();
    switch (paymentMethod) {
      case "paypal":
        desc = $("#paypal-method-entry").attr("prefix") + ": " + $("#paypal-method-entry").val();
        break;
      case "cashapp":
        desc = $("#cashapp-method-entry").attr("prefix") + ": " + $("#cashapp-method-entry").val();
        break;
      case "bitcoin":
        desc = $("#bitcoin-method-entry").attr("prefix") + ": " + $("#bitcoin-method-entry").val();
        break;
      case "venmo":
        desc = $("#venmo-method-entry").attr("prefix") + ": " + $("#venmo-method-entry").val() + ", "
          + $("#venmo-method-phone-entry").attr("prefix") + ": " + $("#venmo-method-phone-entry").val();
        break;
    }
    if (onSubmit) {
      if (balance >= amount && amount >= 10) {
        onSubmit({ amount, description: desc });
      }
      else if (amount < 10) setWithdrawErrorMessage("Minimum withdraw amount is 10$")
      else setWithdrawErrorMessage("Insufficient balance");
    }
  }

  const [paymentInfo, setPaymentInfo] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("paypal");
  function paymentDropdown(t) {
    setPaymentMethod(t);
    switch (t) {
      case "paypal":
        setPaymentInfo(<input id="paypal-method-entry" prefix="paypal-email" style={withdrawInput} type="email" placeholder="Your Paypal E-Mail" />);
        break;
      case "cashapp":
        setPaymentInfo(<input id="cashapp-method-entry" prefix="cashtag" style={withdrawInput} type="text" placeholder="Your $Cashtag" />);
        break;
      case "bitcoin":
        setPaymentInfo(<input id="bitcoin-method-entry" prefix="btc-address" style={withdrawInput} type="text" placeholder="Your Bitcoin Address" />);
        break;
      case "venmo":
        setPaymentInfo(<>
          <input id="venmo-method-entry" prefix="venmo-username" style={withdrawInput} type="text" placeholder="Venmo Username" />
          <input id="venmo-method-phone-entry" prefix="last-4-digit-of-phone-number" style={withdrawInput} type="text" placeholder="Last 4 digits of your phone number" />
        </>);
        break;
    }
  }

  return (
    <div className="modal" style={{
      zIndex: "10000",
      position: "fixed",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      backgroundColor: "rgba(0,0,0,0.4)",
      alignItems: "center",
      justifyContent: "center",
      display: isOpen ? "flex" : "none"
    }}>
      <div className="modal-content" style={{
        width: "512px",
        height: "512px",
        backgroundColor: "white",
        position: "relative"
      }}>
        <img style={withdrawLogo} src={Logo} alt="Logo" />
        <h1 style={withdrawText}>Withdraw</h1>
        <div style={{ display: "flex", height: "100%", padding: "0 90px", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <p style={withdrawAvailableBalance}>Your available balance: ${balance.toFixed(2)}</p>
          <input id="amount-to-withdraw" style={withdrawInput} type="number" placeholder="Amount to Withdraw ($)" onChange={event => setAmount(event.currentTarget.value)} />
          <small style={{ fontSize: "13px" }}>Minimum withdraw amount is <span style={{ color: "blue" }}>10$</span></small>
          <select style={withdrawPaymentMethods} onChange={(event) => { paymentDropdown(event.target.value) }}>
            <option value="cashapp">CashApp</option>
            <option selected value="paypal">Paypal</option>
            <option value="bitcoin">Bitcoin</option>
            <option value="venmo">Venmo</option>
          </select>
          {paymentInfo ? paymentInfo : <input id="paypal-method-entry" prefix="paypal-email" style={withdrawInput} type="email" placeholder="Your Paypal E-Mail" />}
          <p style={{ color: "red", fontSize: "15px", margin: "10px 0 -10px 0" }}>{withdrawErrorMessage}</p>
          <button style={withdrawButton} onClick={handleSubmit}>Withdraw</button>
        </div>
      </div>
    </div>
  );
}

const withdrawButton = {
  backgroundColor: "#40bd00",
  color: "white",
  fontSize: "25px",
  fontFamily: "Raleway",
  padding: "5px 20px",
  border: "none",
  marginTop: "30px",
  borderRadius: "10px"
};

const withdrawInput = {
  fontSize: "18px",
  padding: "10px 20px",
  marginTop: "10px",
  border: "1px solid #e7e7e7",
  borderRadius: "9px",
  boxShadow: "0px 1px 11px 2px #f3f3f3",
  outline: "none",
  width: "330px"
};

const withdrawAvailableBalance = {
  fontSize: "20px",
  margin: "20px",
  textAlign: "center",
};

const withdrawText = {
  fontSize: "20px",
  position: "absolute",
  right: "20px",
  top: "20px"
};

const withdrawLogo = {
  height: "40px",
  position: "absolute",
  left: "10px",
  top: "10px"
};

const withdrawPaymentMethods = {
  marginTop: "35px",
  fontFamily: "Raleway",
  fontSize: "18px",
  padding: "3px 10px",
  borderRadius: "8px",
  border: "1px solid #cbcbcb61",
  boxShadow: "rgb(90 133 43 / 10%) 0px 0px 8px 3px",
  outline: "none",
  marginRight: "auto"
}