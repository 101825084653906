import { getSeller, searchProduct } from 'actions/product'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import ImageWrapper from 'components/image-wrapper'
import SearchBox from 'components/search-box'
import {
    PRODUCT_DETAIL_PREFIX, PUBLIC_PREFIX, MANUAL_PREFIX
} from 'configs/app-config'
import { db } from 'firebase.js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Currency, NotifyFail } from 'utilities'

const HeadingStyle = {
    textAlign: 'left',
}

const ContainerStyle = {
    padding: 0,
}

function ListCard({ title, href, description, price, img }) {
    const [t, setT] = useState(title);
    const [d, setD] = useState(description);

    const [rates, setRates] = useState({})
    const [rand, setRand] = useState(0)

    const [priceState, setPrice] = useState(price);

    useEffect(async () => {
        var min = 1
        var max = 100
        setRand(min + Math.random() * (max - min))
        
        db.ref("config").once("value", async snap => {
            let markup = snap.val()?.markup || 20;
            let price_ = parseFloat(price);
            price_ = price_ + price_ * (markup/100);
            setPrice(price_);
        });

        setRates(await Currency.GetRates());

        let title_ = title;

        let english = /^[A-Za-z0-9]*$/;
        if (!english.test(title.replaceAll(" ", ""))) {
            await fetch("https://api.kghype.com:5000/api/translate", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    words: {
                        title: t,
                        description: d
                    }
                }),
            })
            .then(data => {return data.json()})
            .then(data => {
                title_ = data.title;
                setD(data.description);
            });
        }

        let formattedTitle = title_
            .split(' ')
            .slice(0, Math.min(title_.split(' ').length), 20)
            .join(' ');
        formattedTitle = formattedTitle.substring(0, 65);
        if (title_.length >= 62) formattedTitle = formattedTitle + "...";
        setT(formattedTitle);
    }, [])

    return (
        <li>
            <Link to={href} target="_blank" style={{color: "black", textDecoration: "none"}}>
                <Card
                    style={{ height: '500px', cursor: 'pointer', display: 'grid' }}
                >
                    <ImageWrapper
                        src={img}
                        alt="product-image"
                        className="product-image"
                        style={{
                            maxHeight: "300px",
                            display: "block",
                            margin: "0 auto"
                        }}
                    />
                    <p title={d}>{d}</p>
                    <h3 title={t}>{t}</h3>
                    <br />
                    <InfoWrapper>
                        <Heading>{Currency.GetPrice(rates, priceState)}</Heading>
                        <span>{parseInt(rand)} sold</span>
                    </InfoWrapper>
                </Card>
            </Link>
        </li>
    )
}

export default function ProductSearch() {
    const [query, setQuery] = useState('')
    const [formData, setFormData] = useState({})
    const [productData, setProductData] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchError, setSearchError] = useState(false)
    const [widgetVisibilty, setWidgetVisibilty] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState('')

    const location = useLocation()
    const history = useHistory()

    let user = useSelector(state => state.user);

    useEffect(async () => {
        if (!location.state || !location?.state?.item?.items?.item) {
            setProductData([])

            const searchqs = location.search
                .split('&')
                .find((s) => s.includes('search'))

            const searchqsValue = searchqs ? searchqs.split('=')[1] : '';

            const page = location.search
                .split('&')
                .find((s) => s.includes('page'))
            const pageValue = page ? page.split('=')[1] : ''

            if (searchqsValue) {
                setQuery(searchqsValue)
                gotoPage(pageValue, searchqsValue)
            }
        } else {
            setProductData([...location.state.item.items.item])
            setQuery(location.state.item.call_args.q)
            setWidgetVisibilty(true)
        }
    }, []);

    const [ref, setRef] = useState(false);
    
    useEffect(async () => {
        if (user) {
            let userKey = user.key;

            const searchqs = location.search
                .split('&')
                .find((s) => s.includes('search'))

            const searchqsValue = searchqs ? searchqs?.split('=')[1] : '';

            if (searchqsValue) {
                await user.searchHistoryRef().once("value", user_snap => {
                    let datas = Object.values(user_snap.val());
                    if (!datas) datas = [
                        {
                            term: searchqsValue,
                            date: new Date().getTime()
                        }
                    ]
                    else datas.push({
                        term: searchqsValue,
                        date: new Date().getTime()
                    })
    
                    user.searchHistoryRef().update(datas);
                })
            }

            db.ref('affiliates').once("value", async snapshot => {
                let affiliateList = snapshot.val();
                if (affiliateList) {
                    let affiliateListVals = Object.values(affiliateList);
                    for (let i = 0; i < affiliateListVals.length; i++) {
                        if (affiliateListVals[i]["user_id"] == userKey) {
                            setRef(affiliateListVals[i]["id"]);
                            break;
                        }
                    }
                }
            });
        }
    }, [user]);

    const toDetail = (item) => {
        return ref ? PUBLIC_PREFIX + PRODUCT_DETAIL_PREFIX + `?store=${getSeller(item.detail_url)}&id=${item.num_iid}&ref=${ref}` : PUBLIC_PREFIX + PRODUCT_DETAIL_PREFIX + `?store=${getSeller(item.detail_url)}&id=${item.num_iid}`;
    }

    const toSearchProduct = async (e) => {
        if (!formData.search) {
            NotifyFail('Search field cant be empty')
            setLoading(false)
            return
        }
        e.preventDefault()
        setLoading(true)
        setPage(1)
        setQuery(formData.search)

        history.push({
            pathname: '/search',
            search: '?page=' + 1 + '&search=' + formData.search,
        })
        const response = await searchProduct(formData)

        if (response) {
            setLoading(false);

            if (user) {
                await user.searchHistoryRef().once("value", user_snap => {
                    let datas = Object.values(user_snap.val());
                    if (!datas) datas = [
                        {
                            term: formData.search,
                            date: new Date().getTime()
                        }
                    ]
                    else datas.push({
                        term: formData.search,
                        date: new Date().getTime()
                    })
    
                    user.searchHistoryRef().update(datas);
                })
            }

            if (response.flag === 0) {
                history.push({
                    pathname: PUBLIC_PREFIX + MANUAL_PREFIX,
                })
                return;
            }
            if (response.flag === 2) {

                setQuery(response.data.call_args.q)
                setProductData(response.data.items.item)

                if (!response?.data?.items?.item?.length) {
                    setSearchError(true)
                    setWidgetVisibilty(false)
                } else {
                    setSearchError(false)
                    setWidgetVisibilty(true)
                }
            } else {
                window.location.href = toDetail(response.data.item);
            }
        }
    }

    const setData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const gotoPage = async (page, _query = '') => {
        setLoading(true)
        setPage(parseInt(page))

        const response = await searchProduct(
            { search: _query || query, sort },
            page
        )

        if (response && response.flag === 2) {
            setQuery(response.data.call_args.q)
            setProductData(response.data.items.item)
        }

        setLoading(false)
        history.push({
            pathname: '/search',
            search: '?page=' + page + '&search=' + _query || query,
        })
        if (_query || query) setWidgetVisibilty(true)
    }

    const onSortDropdownChange = async (event) => {
        const value = event.target.value
        setSort(value)
        setLoading(true)

        const response = await searchProduct(
            { search: query, sort: value },
            page
        )

        if (response && response.flag === 2) {
            setQuery(response.data.call_args.q)
            setProductData(response.data.items.item)
        }

        setLoading(false)
    }

    const previousPage = () => {
        if (page - 1 > 0) {
            gotoPage(page - 1, query)
        }
    }

    const nextPage = () => {
        if (1) {
            gotoPage(page + 1, query)
        }
    }

    return (
        <Container style={ContainerStyle}>
            <div style={{ padding: '20px' }}>
                <SearchBoxWrapper>
                    <div
                        style={{
                            borderRadius: '12px',
                            width: '100%',
                            maxWidth: '1200px',
                            display:"flex",
                            justifyContent:"center"
                        }}
                    >
                        <SearchBox
                            defaultValue={query}
                            loading={loading}
                            name="search"
                            onChange={(e) => setData(e)}
                            value={formData.search}
                            setLoading={setLoading}
                            onClick={toSearchProduct}
                        />
                    </div>
                </SearchBoxWrapper>

                {window.location.href.includes("&search") &&
                    <Heading style={HeadingStyle}>Search Results: {query}</Heading>
                }

                {/* <ToolWrapper>
                <Input placeholder='US Dollar' />
                <Input placeholder='US Dollar' />
            </ToolWrapper> */}

                { (!loading && widgetVisibilty) &&
                    <SortOptions>
                        <SortDropdown onChange={onSortDropdownChange}>
                            <option disabled selected>
                                Sort By
                            </option>
                            <option value="_bid2">Price: Low to High</option>
                            <option value="bid2">Price: High to Low</option>
                            <option value="_sale">Sales: Low to High</option>
                            <option value="sale">Sales: High to Low</option>
                            <option value="_credit">
                                Credits: Low to High
                            </option>
                            <option value="credit">Credits: High to Low</option>
                        </SortDropdown>
                    </SortOptions>
                }
                <br />
                {loading && (
                    <LoaderWrapper>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-loader rotate"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke="#408abf"
                            strokeWidth="1.5"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                            ></path>
                            <line x1="12" y1="6" x2="12" y2="3"></line>
                            <line
                                x1="16.25"
                                y1="7.75"
                                x2="18.4"
                                y2="5.6"
                            ></line>
                            <line x1="18" y1="12" x2="21" y2="12"></line>
                            <line
                                x1="16.25"
                                y1="16.25"
                                x2="18.4"
                                y2="18.4"
                            ></line>
                            <line x1="12" y1="18" x2="12" y2="21"></line>
                            <line
                                x1="7.75"
                                y1="16.25"
                                x2="5.6"
                                y2="18.4"
                            ></line>
                            <line x1="6" y1="12" x2="3" y2="12"></line>
                            <line x1="7.75" y1="7.75" x2="5.6" y2="5.6"></line>
                        </svg>
                    </LoaderWrapper>
                )}

                <CardWrapper>
                    {
                        productData?.map((item, index) => {
                            return (
                                <ListCard
                                    title={item.title}
                                    description={item.description}
                                    price={item.price}
                                    //fee={parseFloat(item?.post_fee ||0 + item?.express_fee ||0 + item?.ems_fee ||0)}
                                    fee={0}
                                    onChange={(e) => setData(e)}
                                    href={toDetail(item)}
                                    img={item.pic_url}
                                    key={index}
                                />
                            )
                        })
                    }
                </CardWrapper>

                { (!loading && searchError) &&
                    <p style={{ textAlign: 'center', marginTop: '2rem' }}>
                        Search query not found. Try searching different keywords
                    </p>
                }

                { (!loading && widgetVisibilty && !searchError && productData?.length) ?
                    <Pages>
                        <PageWrapper onClick={() => previousPage()}>
                            &lt;
                        </PageWrapper>
                        <PageWrapper active>{page}</PageWrapper>
                        <PageWrapper onClick={() => gotoPage(page + 1, query)}>
                            {page + 1}
                        </PageWrapper>
                        <PageWrapper onClick={() => gotoPage(page + 2, query)}>
                            {page + 2}
                        </PageWrapper>
                        <PageWrapper onClick={() => nextPage()}>
                            &gt;
                        </PageWrapper>
                    </Pages>  : <></>
                }
            </div>
        </Container>
    )
}

const SortOptions = styled.div``

const SortDropdown = styled.select`
    height: 32px;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #eee;
`

const Pages = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 60px;
`

const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid #eee;
    margin-right: 6px;
    border-radius: 6px;
    font-weight: ${(props) => (props.active ? 'bold' : 'light')};
    background: ${(props) => (props.active ? '#2c3e50' : 'auto')};
    color: ${(props) => (props.active ? 'white' : 'black')};
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        background: #2c3e50;
        color: white;
    }
`

const Card = styled.div`
    box-shadow: 0px 41px 27px -27px rgba(116, 155, 169, 0.59);
    max-width: 320px;
    padding: 20px;
    border: 1px solid #d6d5d5;
`

const CardWrapper = styled.ul`
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;

    @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 920px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 680px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const SearchBoxWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 64px;
`
