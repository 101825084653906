import { useEffect, useState } from "react";
import { db } from "firebase.js";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { createOrder } from "utilities/order";
import { topUp } from 'utilities/wallet';
import { addShippingOrder } from 'utilities/shipping';
import Cookie from "helpers/cookie";
import Spinner from "components/Spinner";
import {
    FaCheckCircle,
} from "react-icons/fa";

const cookie = new Cookie();

const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export default function PaymentSuccessLayout() {

    function productCheckout(userInfo, desc) {
        return new Promise((resolve, reject) => {
            try {
                if (!userInfo) return reject(new Error('User Info Object required'))
    
                let selectedCartRef = userInfo.selectedCartRef();
        
                selectedCartRef.once('value', async (cartSnapshot) => {
                    let cart = cartSnapshot.val()
                    if (!cart) return resolve()
        
                    let orderHistory = userInfo.dbUser.orderhistory;
                    let usercart = userInfo.dbUser.cart || [];
                    if (orderHistory === undefined) orderHistory = [];
                    let newArr = [];
        
                    let ids = [];
                    for (let j = 0; j < Object.values(cart).length; j++) {
                        ids.push(Object.values(cart)[j].cid);
                    }
        
                    for (let i = 0; i < usercart.length; i++) {
                        if (!ids.includes(usercart[i].cid)) newArr.push(usercart[i]);
                    }
        
                    let order = await createOrder([...cart], userInfo.key, desc);
                    orderHistory.push(order.order_number)
        
                    await userInfo.ref().update({
                        orderhistory: orderHistory,
                        selectedCart: [],
                        cart: newArr,
                    });
        
                    resolve();
                })
            } catch(e) { reject() }
        })
    }
    
    function topUpCheckout(checkoutInfo, desc="", user) {
        return new Promise((resolve, reject) => {
            topUp({ amount: parseFloat(checkoutInfo.amount), desc: desc, userInfo: user })
            .then(() => {
                toast.success("TOPUP Request has been received");
                resolve();
            })
            .catch(e => reject());
        });
    }
    
    function shippingCheckout(userInfo, checkoutInfo, desc) {
        return new Promise(async (resolve, reject) => {
            let overallInfo = {
                items: checkoutInfo.items,
                shippingInfo: checkoutInfo.shippingInfo,
                user_id: userInfo.key,
                user: {
                    name: `${userInfo.dbUser.fName} ${userInfo.dbUser.lName}`,
                    email: userInfo.dbUser.mail,
                },
                shippingFee: checkoutInfo.shippingFee,
                description: desc
            };
            if (checkoutInfo?.couponUsed) {
                overallInfo["couponUsed"] = true;
                overallInfo["couponName"] = checkoutInfo.couponName;
            }
            await addShippingOrder(overallInfo)
            .then(() => resolve())
            .catch(e => reject());
        })
    }

    let user = useSelector(state => state.user);

    const [confirmed, setConfirmed] = useState(false);
    const [success, setSuccess] = useState(null);

    function done(method, type, amount = 0) {
        db.ref("stats/order/" + type).once("value", async snapshot => {
            let vals = snapshot.val();
            let curDate = new Date().toLocaleString('tr-TR').split(" ")[0].split(".");
            curDate[1] = new Date().toLocaleString('en-EN', { month: 'long' });
            curDate = curDate.join(" ");

            if (type != "topup") {
                if (!vals) vals = {};
                if (!vals[curDate]) vals[curDate] = 1;
                else vals[curDate] += 1;
            } else {
                if (!vals) vals = {"count": {}, "balance": {}};
                if (!vals["count"]?.[curDate]) {
                    vals["count"][curDate] = 1;
                    vals["balance"][curDate] = parseFloat(amount);
                }
                else {
                    vals["count"][curDate] += 1;
                    vals["balance"][curDate] += parseFloat(amount);
                }
            }

            await db.ref("stats/order/" + type).update(vals);

            db.ref("transaction").once("value", async snap => {
                let transactions = snap.val();
                transactions.push({
                    method: method,
                    type: type.toUpperCase(),
                    amount: amount,
                    date: new Date().getTime()
                });

                await db.ref("transaction").update(transactions);
                
                setConfirmed(true);
                cookie.eraseCookie("sid");
                if (localStorage.getItem("stripe_item_data")) localStorage.removeItem("stripe_item_data");
            });
        });
    }

    useEffect(() => {
        const url = new URL(window.location.href);
        
        if (url.searchParams.get("n") == 1) setConfirmed(true);
        else if (user) {
            let h = url.searchParams.get("h"),
                u = url.searchParams.get("u"),
                t = url.searchParams.get("t"),
                m = url.searchParams.get("m"),
                d = url.searchParams.get("d");

            if (u === user.key && cyrb53(u + cookie.getCookie("sid"), 2) == h) {
                switch (t.toUpperCase()) {
                    case "SHIPPING":
                        if (d || localStorage.getItem("stripe_item_data")) {
                            d = JSON.parse(atob(d || localStorage.getItem("stripe_item_data")));
                            shippingCheckout(user, d, "Paid with " + m).then(() => done(m, t.toLowerCase()))
                            .catch(e => {
                                console.error(e);
                                setSuccess(false);
                            });
                        } else setSuccess(false);
                        break;
                    case "TOPUP":
                        if (d || localStorage.getItem("stripe_item_data")) {
                            d = JSON.parse(atob(d || localStorage.getItem("stripe_item_data")));
                            topUpCheckout(d, "Paid with " + m, user).then(() => done(m, t.toLowerCase(), d.amount))
                            .catch(e => {
                                console.error(e);
                                setSuccess(false);
                            });
                        } else setSuccess(false);
                        break;
                    default:
                        //Payment confirmed
                        if (d) d = JSON.parse(atob(d));
                        productCheckout(user, "Paid with " + m).then(() => done(m, "product", (d?.amount || 0)))
                        .catch(e => {
                            console.error(e);
                            setSuccess(false);
                        });
                }
            } else setSuccess(false);
        }
    }, [user]);

    //Redirect countdown
    const [counter, setCounter] = useState(5);
    useEffect(() => {
        if (confirmed) {
            setTimeout(() => {
                window.location.href = "/history";
            }, 5000);
        }
    }, [confirmed]);
    useEffect(() => {
        if (confirmed) {
            setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        }
    }, [counter, confirmed]);

    if (success === false) return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <h2 style={{color: "red"}}>Payment cannot be confirmed</h2>
        </div>
    )

    if (!confirmed) return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <h2>Payment is being confirmed...</h2>
            <p>Please don't close the page</p>
            <Spinner bg="dark" />
        </div>
    )

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <FaCheckCircle color="rgb(31,196,93)" fontSize="140px" />
            <h1 style={{ fontSize: "30px", marginTop: "30px", fontFamily: "Montserrat" }}>We got your payment</h1>
            <h2>Continue on <Link style={{ color: "rgb(31,196,93)" }} to="/">KgHype.com</Link></h2>
            <br />
            <p>You'll be redirected after {counter} seconds</p>
        </div>
    );
}