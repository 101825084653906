import styled from 'styled-components'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import { SharedImage } from 'constants/image-constant'
import ImageWrapper from 'components/image-wrapper'
import Input from 'components/input'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, div, CardActions } from '@mui/material'
import * as React from 'react'
//Firebase

import { auth } from 'firebase.js'
import { db } from 'firebase.js'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'

const HeadingStyle = {
    textAlign: 'left',
    margin: '0px',
}

const HeadingStyle2 = {
    fontSize: '18px',
    fontWeight: '400',
}

const ContainerStyle = {
    padding: '50px 20px',
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
}

const ButtonStyle = {
    marginTop: '50px',
}

const { Profile } = SharedImage

export default function Privacy() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Container style={ContainerStyle}>
                <Card sx={{ maxWidth: 1000 }}>
                    <div>
                        <h1
                            style={{
                                margin: '1rem 0',
                                fontSize: '3.4rem',
                                padding: '16px',
                            }}
                        >
                            Privacy Policy
                        </h1>
                        <CardContent>
                            <h1>Privacy Policy for Shiftery LLC</h1>

                            <PragraphText>
                                At sales@kghype.com, accessible from kghype.com,
                                one of our main priorities is the privacy of our
                                visitors. This Privacy Policy document contains
                                types of information that is collected and
                                recorded by sales@kghype.com and how we use it.
                            </PragraphText>

                            <PragraphText>
                                If you have additional questions or require more
                                information about our Privacy Policy, do not
                                hesitate to contact us.
                            </PragraphText>

                            <PragraphText>
                                This Privacy Policy applies only to our online
                                activities and is valid for visitors to our
                                website with regards to the information that
                                they shared and/or collect in sales@kghype.com.
                                This policy is not applicable to any information
                                collected offline or via channels other than
                                this website. Our Privacy Policy was created
                                with the help of the{' '}
                                <a href="https://www.termsfeed.com/privacy-policy-generator/">
                                    TermsFeed Free Privacy Policy Generator
                                </a>
                                .
                            </PragraphText>

                            <PragraphText>
                                <h2>Consent</h2>
                                By using our website, you hereby consent to our
                                Privacy Policy and agree to its terms.
                            </PragraphText>

                            <PragraphText>
                                <h2>Information we collect</h2>
                                The personal information that you are asked to
                                provide, and the reasons why you are asked to
                                provide it, will be made clear to you at the
                                point we ask you to provide your personal
                                information.
                            </PragraphText>
                            <PragraphText>
                                If you contact us directly, we may receive
                                additional information about you such as your
                                name, email address, phone number, the contents
                                of the message and/or attachments you may send
                                us, and any other information you may choose to
                                provide.
                            </PragraphText>
                            <PragraphText>
                                When you register for an Account, we may ask for
                                your contact information, including items such
                                as name, company name, address, email address,
                                and telephone number.
                            </PragraphText>

                            <PragraphText>
                                <h2>How we use your information</h2>
                                We use the information we collect in various
                                ways, including to:
                            </PragraphText>

                            <ul>
                                <li>
                                    Provide, operate, and maintain our website
                                </li>
                                <li>
                                    Improve, personalize, and expand our website
                                </li>
                                <li>
                                    Understand and analyze how you use our
                                    website
                                </li>
                                <li>
                                    Develop new products, services, features,
                                    and functionality
                                </li>
                                <li>
                                    Communicate with you, either directly or
                                    through one of our partners, including for
                                    customer service, to provide you with
                                    updates and other information relating to
                                    the website, and for marketing and
                                    promotional purposes
                                </li>
                                <li>Send you emails</li>
                                <li>Find and prevent fraud</li>
                            </ul>

                            <PragraphText>
                                <h2>Log Files</h2>
                                sales@kghype.com follows a standard procedure of
                                using log files. These files log visitors when
                                they visit websites. All hosting companies do
                                this and a part of hosting services' analytics.
                                The information collected by log files include
                                internet protocol (IP) addresses, browser type,
                                Internet Service Provider (ISP), date and time
                                stamp, referring/exit pages, and possibly the
                                number of clicks. These are not linked to any
                                information that is personally identifiable. The
                                purpose of the information is for analyzing
                                trends, administering the site, tracking users'
                                movement on the website, and gathering
                                demographic information.
                            </PragraphText>

                            <PragraphText>
                                <h2>Cookies and Web Beacons</h2>
                                Like any other website, sales@kghype.com uses
                                'cookies'. These cookies are used to store
                                information including visitors' preferences, and
                                the pages on the website that the visitor
                                accessed or visited. The information is used to
                                optimize the users' experience by customizing
                                our web page content based on visitors' browser
                                type and/or other information.
                            </PragraphText>

                            <PragraphText>
                                <h2>Advertising Partners Privacy Policies</h2>
                                You may consult this list to find the Privacy
                                Policy for each of the advertising partners of
                                sales@kghype.com.
                            </PragraphText>

                            <PragraphText>
                                Third-party ad servers or ad networks uses
                                technologies like cookies, JavaScript, or Web
                                Beacons that are used in their respective
                                advertisements and links that appear on
                                sales@kghype.com, which are sent directly to
                                users' browser. They automatically receive your
                                IP address when this occurs. These technologies
                                are used to measure the effectiveness of their
                                advertising campaigns and/or to personalize the
                                advertising content that you see on websites
                                that you visit.
                            </PragraphText>

                            <PragraphText>
                                Note that sales@kghype.com has no access to or
                                control over these cookies that are used by
                                third-party advertisers.
                            </PragraphText>

                            <PragraphText>
                                <h2>Third Party Privacy Policies</h2>
                                sales@kghype.com's Privacy Policy does not apply
                                to other advertisers or websites. Thus, we are
                                advising you to consult the respective Privacy
                                Policies of these third-party ad servers for
                                more detailed information. It may include their
                                practices and instructions about how to opt-out
                                of certain options.{' '}
                            </PragraphText>

                            <PragraphText>
                                You can choose to disable cookies through your
                                individual browser options. To know more
                                detailed information about cookie management
                                with specific web browsers, it can be found at
                                the browsers' respective websites.
                            </PragraphText>

                            <PragraphText>
                                <h2>
                                    CCPA Privacy Rights (Do Not Sell My Personal
                                    Information)
                                </h2>
                                Under the CCPA, among other rights, California
                                consumers have the right to:
                            </PragraphText>
                            <PragraphText>
                                Request that a business that collects a
                                consumer's personal data disclose the categories
                                and specific pieces of personal data that a
                                business has collected about consumers.
                            </PragraphText>
                            <PragraphText>
                                Request that a business delete any personal data
                                about the consumer that a business has
                                collected.
                            </PragraphText>
                            <PragraphText>
                                Request that a business that sells a consumer's
                                personal data, not sell the consumer's personal
                                data.
                            </PragraphText>
                            <PragraphText>
                                If you make a request, we have one month to
                                respond to you. If you would like to exercise
                                any of these rights, please contact us.
                            </PragraphText>

                            <PragraphText>
                                <h2>GDPR Data Protection Rights</h2>
                                We would like to make sure you are fully aware
                                of all of your data protection rights. Every
                                user is entitled to the following:
                            </PragraphText>
                            <PragraphText>
                                The right to access – You have the right to
                                request copies of your personal data. We may
                                charge you a small fee for this service.
                            </PragraphText>
                            <PragraphText>
                                The right to rectification – You have the right
                                to request that we correct any information you
                                believe is inaccurate. You also have the right
                                to request that we complete the information you
                                believe is incomplete.
                            </PragraphText>
                            <PragraphText>
                                The right to erasure – You have the right to
                                request that we erase your personal data, under
                                certain conditions.
                            </PragraphText>
                            <PragraphText>
                                The right to restrict processing – You have the
                                right to request that we restrict the processing
                                of your personal data, under certain conditions.
                            </PragraphText>
                            <PragraphText>
                                The right to object to processing – You have the
                                right to object to our processing of your
                                personal data, under certain conditions.
                            </PragraphText>
                            <PragraphText>
                                The right to data portability – You have the
                                right to request that we transfer the data that
                                we have collected to another organization, or
                                directly to you, under certain conditions.
                            </PragraphText>
                            <PragraphText>
                                If you make a request, we have one month to
                                respond to you. If you would like to exercise
                                any of these rights, please contact us.
                            </PragraphText>

                            <PragraphText>
                                <h2>Children's Information</h2>
                                Another part of our priority is adding
                                protection for children while using the
                                internet. We encourage parents and guardians to
                                observe, participate in, and/or monitor and
                                guide their online activity.
                            </PragraphText>

                            <PragraphText>
                                sales@kghype.com does not knowingly collect any
                                Personal Identifiable Information from children
                                under the age of 13. If you think that your
                                child provided this kind of information on our
                                website, we strongly encourage you to contact us
                                immediately and we will do our best efforts to
                                promptly remove such information from our
                                records.
                            </PragraphText>
                        </CardContent>
                    </div>
                    <CardActions></CardActions>
                </Card>
            </Container>
        </div>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const SideMenu = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: Open Sans;
    padding: 0px;
    margin: 0px;
    padding-top: 50px;
`

const Content = styled.section`
    padding-left: 100px;
    max-width: 100%;
    flex: 1;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
    }
`

const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 50px 0px;
    align-items: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

const TextWrapper = styled.div`
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
        padding-top: 30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding: 0px;
    margin: 0px;
    padding: 0px;
    @media screen and (max-width: 800px) {
        text-align: center;
    }
`
const PragraphText = styled.p`
    padding: 1rem 0;

    h2 {
        padding: 0.4rem 0;
    }
`
