const partnerData: { image: string, name: string }[] = [
    {
        image: "/platform_0.png",
        name: "Taobao",
    },
    {
        image: "/platform_1.png",
        name: "Tmall",
    },
    {
        image: "/platform_2.png",
        name: "1688",
    },
    {
        image: "/platform_3.png",
        name: "Xianyu",
    },
]

export default partnerData