import AddressEdit from "components/address-edit";
import Spinner from "components/Spinner";
import { db } from "firebase.js";
import useLoader from 'hooks/useLoader';
import { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

const ContainerStyle = {
    paddingBottom: "200px",
    width: '100%'
}

export default function AddressBook() {

    let user = useSelector(state => state.user);
    
    const [datas, setData] = useState(null);
    const [currentUser, setUser] = useState('')
    const [LoaderWrapper, dataLoader] = useLoader()
    const [selectedRows, setSelectedRows] = useState([])

    const columns = [
        {
            name: 'Address',
            width: "270px",
            cell: (d) => {
                let style = {overflowWrap: 'break-word', padding: "10px 0px", color: "#625E5E", fontWeight: "normal" }
                return <p style={style}>{d.address}</p>
            }
        },
        {
            name: "City",
            width: "120px",
            cell: (d) => {
                return <div style = {{margin: '20px 0px'}}>
                    {d.city}
                    </div>
            },
          },
    
          {
            name: 'State',
            width: '100px',
            cell: (d) => {
                let style = {overflowWrap: 'break-word', padding: "10px 0px", color: "#625E5E", fontWeight: "normal" }
                return <div>
                    <p style = {style}>{d.state}</p>
                </div>
            }
          },
          {
            name: "Country",
            sortable: true,
            width: "150px",
    
            cell: (d) => <><span>{d.country}</span></>
          },
          {
            name: "Email",
            sortable: true,
            width: "200px",
    
            cell: (d) => <><span>{d.email}</span></>
          },
    
          {
            name: "Name",
            sortable: true,
            width: "200px",
    
            cell: (d) => <><span>{d.name}</span></>
          },
    
          {
            name: "Postal Code",
            sortable: true,
            width: "100px",
    
            cell: (d) => <><span>{d.postcode}</span></>
          },

          {
            name: "Edit",
            // selector: "id",
            sortable: false,
            cell: (d) => {
                let btnStyle2 = {padding: "3px 4px", margin: "2px 0px", fontSize: "11px", backgroundColor: "rgb(31,196,93)", border:"solid 1px rgb(31,196,93)", color: 'white'};
                return <div>
                    <button type = "button" onClick={() => { showAddressEditPopup(d); }} style={btnStyle2}> Edit </button>
                </div>
            },
            width: "130px",
          },
          
          {
            name: "Delete",
            // selector: "id",
            sortable: false,
            cell: (d) => {
                let btnStyle2 = {padding: "3px 4px", margin: "2px 0px", fontSize: "11px", backgroundColor: "#e74c3c", border:"solid 1px #e74c3c", color: 'white'};
                return <div>
                    <button type = "button" onClick={() => { showAddressDeletePopup(d); }} style={btnStyle2}> X Delete </button>
                </div>
            },
            width: "140px",
          },
    ]

    function handleRowSelect(select_data) {
        setSelectedRows((prev) => {
            prev.splice(0, prev.length, ...select_data.selectedRows)
            return prev
        })
    }

    const [addressData, setAddressData] = useState({});

    const [addressDeletePopup, setAddressDeletePopup] = useState(false);
    function showAddressDeletePopup(data) {
        setAddressDeletePopup(true);
        setAddressData(data);
    }

    const [addressEditPopup, setAddressEditPopup] = useState(false);
    function showAddressEditPopup(data) {
        setAddressEditPopup(true);
        setAddressData(data);
    }

    function deleteAddress() {
        let data = addressData;
        setAddressDeletePopup(false);
        try {
            let datas_ = datas;
            datas_.splice(datas.indexOf(data), 1);
            db.ref(`users/${currentUser.key}/addressbook`).set(datas_).then(() => {
                toast.success("Address successfully removed");
            });
        } catch(e) {
            toast.error("An error has been occured");
        }
    }

    const [country, setCountry] = useState("");
    function editAddress() {
        let changeValues = {
            name: document.querySelector('#address-edit-popup #name').value,
            country: country,
            state: document.querySelector('#address-edit-popup #state').value,
            address: document.querySelector('#address-edit-popup #address').value,
            city: document.querySelector('#address-edit-popup #city').value,
            postcode: document.querySelector('#address-edit-popup #postcode').value,
            phone: document.querySelector('#address-edit-popup #phone').value,
            email: document.querySelector('#address-edit-popup #email').value,
        }
        
        let data = addressData;
        setAddressEditPopup(false);

        try {
            let datas_ = datas;
            datas_[datas.indexOf(data)] = changeValues;
            db.ref(`users/${currentUser.key}/addressbook`).set(datas_).then(() => {
                toast.success("Address successfully edited");
            });
        } catch(e) {
            toast.error("An error has been occured");
        }
    }
    
    useEffect(async () => {
        setUser(user);
        const fetchData = async () => {
            try {
                user.addressBookRef().on('value', (snapshot) => {
                    let list = snapshot.val();
                    let dummy = []
                    setData(list || dummy);
                })
            } catch(e) {}
        };
            
        dataLoader(fetchData);
    }, [])

    return (
        <div>
            {(datas === null) &&
                <Spinner />
            }
            {datas &&
            <LoaderWrapper>
                <div style={ContainerStyle}>
                    <br />
                    <DataTable
                    title = "Address Book"
                    columns={columns}
                    data={datas}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    striped
                    //selectableRows
                    //onSelectedRowsChange={handleRowSelect}
                    customStyles = {
                            {
                                table: {
                                    style: {
                                        fontFamily: "Montserrat",
                                    }
                                },
                                headCells: {
                                    style: {
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        fontWeight: "bold",
                                        color: "#504D4D"
                                    },
                                    draggingStyle: {
                                        cursor: 'move',
                                    },
                                },

                                headRow: {
                                    style: {
                                        minHeight: '52px',
                                        borderBottomWidth: '1px',
                                        borderBottomStyle: 'solid',
                                        // backgroundColor: "#F0EFEF",
                                    },
                                    denseStyle: {
                                        minHeight: '32px',
                                    },
                                },
                                rows: {
                                    style: {
                                        border: 'solid 0px blue',
                                        backgroundColor: '#F4F4F5'

                                    }
                                }
                            }
                        }
                    />
                </div>
            </LoaderWrapper>
            }
            {
                addressDeletePopup &&
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    left: "0",
                    top: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.45)"
                }} onClick={(event) => {if (event.target == event.currentTarget) setAddressDeletePopup(false) }}>
                    <div style={{
                        padding: "15px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        flexDirection: "column"
                    }}>
                        <p style={{fontFamily: "Inter", fontSize:"14px"}}>Are you sure that you want to delete the address?</p>
                        <div style={{display: "flex", justifyContent: "center", marginTop: 10}}>
                            <button onClick={deleteAddress} style={{margin: "0 5px", backgroundColor: "#0fb200", color: "white", padding: "4px 12px", borderRadius:"6px"}}>Confirm</button>
                            <button onClick={() => {setAddressDeletePopup(false)}} style={{margin: "0 5px", backgroundColor: "red", color: "white", padding: "4px 12px", borderRadius:"6px"}}>Cancel</button>
                        </div>
                    </div>
                </div>
            }

            { addressEditPopup && 
                <AddressEdit 
                    addressData={addressData}
                    country={country}
                    setCountry={setCountry}
                    editAddress={editAddress}
                    setAddressEditPopup={setAddressEditPopup}
                />
            }
        </div>
    )
}