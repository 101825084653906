import UserCurrencyContext from 'contexts/userCurrencyContext'
import moment from 'moment-timezone'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container } from './container'
import { Text } from './status-text'

const ContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 20px',
}

const getCurrentBeijingDateTime = () => {
    let date = moment.tz('Asia/Shanghai').format('YYYY/MM/DD')
    let time = moment.tz('Asia/Shanghai').format('h:mma')

    return {
        date: date,
        time: time,
    }
}

export default function StatusBar() {
    const [dateTime, setDateTime] = useState(getCurrentBeijingDateTime)
    const selectedCurrency = useContext(UserCurrencyContext)
    useEffect(() => {
        setInterval(() => {
            setDateTime(getCurrentBeijingDateTime)
        }, 60000)
    }, [])
    return (
        <Wrapper>
            <Container style={ContainerStyle}>
                <Text>
                    Beijing Time {dateTime.date} | {dateTime.time}{' '}
                </Text>
                <Text>English / {selectedCurrency}</Text>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #072a48;
    color: white;
    font-size: 12px;
`
