import styled from 'styled-components'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import { SharedImage } from 'constants/image-constant'
import ImageWrapper from 'components/image-wrapper'
import Input from 'components/input'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, div, CardActions } from '@mui/material'
import * as React from 'react'
//Firebase

import { auth } from 'firebase.js'
import { db } from 'firebase.js'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'

const HeadingStyle = {
    textAlign: 'left',
    margin: '0px',
}

const HeadingStyle2 = {
    fontSize: '18px',
    fontWeight: '400',
}

const ContainerStyle = {
    padding: '50px 20px',
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
}

const ButtonStyle = {
    marginTop: '50px',
}

const { Profile } = SharedImage

export default function Refund() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Container style={ContainerStyle}>
                <Card sx={{ maxWidth: 1000 }}>
                    <div>
                        <h1
                            style={{
                                margin: '1rem 0',
                                fontSize: '3.4rem',
                                padding: '16px',
                            }}
                        >
                            Returns and Refunds
                        </h1>
                        <CardContent>
                            <h2>
                                What exactly is the 5-Day Return/Exchange
                                Guarantee?
                            </h2>
                            <PragraphText>
                                5-Day Return/Exchange Guarantee is a particular
                                after-sales service provided to clients by
                                sellers on third-party platforms (both the
                                service itself and tech support). Based on this
                                regulation and similar rules provided by
                                third-party platforms, the buyer may
                                return/exchange the bought goods within 7 days
                                after the product is signed (i.e. within 5 days
                                after the product is placed in the warehouse).
                            </PragraphText>
                            <h2>Questions you may Have</h2>

                            <PragraphText>
                                <h4>
                                    1. What should I do if there is a problem
                                    with my item after purchase, but it doesn't
                                    qualify for my "return/exchange guarantee"?
                                </h4>
                                Whenever there is a problem with the product's
                                quality, KgHype will double-check with the
                                vendor to make sure everything is as it should
                                be. We will find out the ultimate solution and
                                tell the user. The return/exchange policy will
                                be inaccessible if there is no quality concern
                                with the goods, as per the guidelines of the
                                third-party site. KgHype will attempt to contact
                                the vendor in an effort to resolve the issue and
                                safeguard the user's interests.
                            </PragraphText>

                            <PragraphText>
                                <h4>
                                    2. Who will be paying for the delivery price
                                    when utilizing the “ return/exchange
                                    guarantee”?
                                </h4>
                                The merchandise must be new and fulfill the
                                resale criterion before seeking the
                                return/exchange service. User pays delivery
                                costs in the absence of a quality fault with the
                                goods. The user will not be charged for shipping
                                costs if the problem is deemed to be of poor
                                quality by the vendor.
                            </PragraphText>

                            <PragraphText>
                                <h4>
                                    3. When an order is marked as "not sent,"
                                    can I still request the return service, and
                                    if so, what will be the cost?
                                </h4>
                                Don’t worry, in this instance, there will be no
                                price. Only when all three of the following
                                criteria are met will you be charged for a
                                return or exchange: There are no problems with
                                the product's quality, to begin with. Second,
                                the goods is already stocked in the warehouse.
                                Third, the month's allotted number of returns
                                and exchanges have already been utilized.
                                Therefore, we will not charge you for any
                                products that have not yet been dispatched.
                            </PragraphText>

                            <PragraphText>
                                <h4>
                                    4.Where can I verify whether I still have
                                    free returns/exchanges remaining for this
                                    month?
                                </h4>
                                You may check your free returns/exchanges of the
                                month on the return/exchange service request
                                page. Follow these steps to seek free
                                return/exchange services: Navigate to [Orders]
                                {'>'} Locate the Order {'>'} Click [Aftersales]
                            </PragraphText>
                        </CardContent>
                    </div>
                    <CardActions></CardActions>
                </Card>
            </Container>
        </div>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const SideMenu = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: Open Sans;
    padding: 0px;
    margin: 0px;
    padding-top: 50px;
`

const Content = styled.section`
    padding-left: 100px;
    max-width: 100%;
    flex: 1;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
    }
`

const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 50px 0px;
    align-items: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

const TextWrapper = styled.div`
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
        padding-top: 30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding: 0px;
    margin: 0px;
    padding: 0px;
    @media screen and (max-width: 800px) {
        text-align: center;
    }
`
const PragraphText = styled.p`
    padding: 1rem 0;

    h4 {
        font-size: 1.8rem;
        padding: 0.4rem 0;

        @media (max-width: 400px) {
            padding: 0.8rem 0;
        }
    }
`
