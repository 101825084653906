import { db } from "firebase.js";
import { onValue, ref, update, query, endAt, orderByChild, equalTo,} from "firebase/database";
import { mapObjectToArray } from "utilities";

const forwardingRootUrl = 'forwarding/';


async function onForwardingChanged(handler) {
    try {
        onValue(ref(db, forwardingRootUrl), (snapshot) => {
            let list = snapshot.val()
            return handler(mapObjectToArray(list))
        } )
    } catch(e) {
        console.error(e)
    }
 }

function getWarehouseProductByUser(userId) {
    return new Promise((resolve, reject) => {
        try {
            if (!userId) return reject()
            let r = query(
                ref(db, `warehouselist`),
                orderByChild('user_id'),
                equalTo(userId)
            )
            onValue(r, (snapshot) => {
                let warehouse = snapshot.val()
                if (!warehouse) {
                    resolve([])
                    return
                }
                let keys = Object.keys(warehouse)
                let mappedEntries = [];
                keys.forEach(k => {
                    if (!warehouse[k]?.invisibility) mappedEntries.push(warehouse[k]);
                })
                resolve(mappedEntries)
            })
        } catch (e) {
            reject(e)
        }
    })
}

export {
    getWarehouseProductByUser,
    onForwardingChanged
}
