import { db } from "firebase.js";
import { onValue, ref } from "firebase/database";
import { generateOrderId, mapObjectToArray } from "utilities";

const withdrawalRootUrl = 'withdrawal-requests/'

function topUp({ amount, desc, userInfo }) {
    return new Promise(async (resolve, reject) => {
        if(!amount) return reject(new Error("Amount must be provided"))
        else if (!userInfo) reject();

        let userID = userInfo.key;
        db.ref("topup-request").once("value", async snapshot => {
            let topupSnapshot = snapshot.val();
            if (!topupSnapshot) topupSnapshot = {}
            topupSnapshot[(new Date().getTime()).toString()] = {
                id: userID,
                amount: parseFloat(amount),
                desc: desc
            }
            db.ref("topup-request").update(topupSnapshot).then(() => {
                resolve();
            });
        });
    })
}

function sendWithdrawalRequest({ amount, description, affiliate=false, userInfo }) {
    return new Promise(async (resolve, reject) => {
        try {

            if (!userInfo) reject();

            const requestUrl = withdrawalRootUrl
            const requestRef = db.ref(requestUrl)
            let requestId = generateOrderId()
            let requestKey = `${requestId}__${userInfo.key}`

            const newRequest = {
                id: requestId,
                amount: parseFloat(amount),
                description,
                status: "Pending",
                date: new Date().toISOString(),
                user: {
                    email: userInfo.dbUser.mail,
                    name: `${userInfo.dbUser.fName} ${userInfo.dbUser.lName}`,
                    userId: userInfo.key
                }
            }

            if (affiliate) {
                let afRequestID = affiliate["id"];
                let requestKey = `${requestId}-${afRequestID}`
                if (amount < 10) reject("Minimum withdraw amount is 10$");
                else {
                    db.ref("affiliates/" + afRequestID).once("value", async (snapshot_) => {
                        let userDatas = snapshot_.val();
                        if (userDatas.balance >= amount) {
                            db.ref("affiliate-withdraw-requests").once("value", async (snapshot) => {
                                let requests = snapshot.val()
                                if(!requests) {
                                    let newList = {}
                                    newList[requestKey] = newRequest
                                    await db.ref("affiliate-withdraw-requests").update(newList)
                                } else {
                                    requests[requestKey] = newRequest
                                    await db.ref("affiliate-withdraw-requests").update(requests)
                                }
    
                                userDatas.balance -= amount;
                                db.ref("affiliates/" + afRequestID).update(userDatas).then(() => {
                                    resolve();
                                });
                            })
                        } else reject("Not enough balance");
                    });
                }
            } else {
                db.ref("users/" + userInfo.key).once("value", async (snapshot_) => {
                    let userDatas = snapshot_.val();

                    if (userDatas.balance >= amount) {
                        requestRef.once("value", async (snapshot) => {
                            let requests = snapshot.val()
                            if(!requests) {
                                let newList = {}
                                newList[requestKey] = newRequest
                                await requestRef.update(newList)
                            } else {
                                requests[requestKey] = newRequest
                                await requestRef.update(requests)
                            }
    
                            userDatas.balance -= amount;
                            db.ref("users/" + userInfo.key).update(userDatas).then(() => {
                                resolve();
                            });
                        });
                    } else reject("Not enough balance");
                });
            }
        } catch(e) {
            reject(e)
        }
    })
}

async function onWithdrawalRequestChanged(handler) {
    try {
        onValue(ref(db, withdrawalRootUrl), (snapshot) => {
            let obj = snapshot.val()
            let arr = mapObjectToArray(obj);
            for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < arr.length; j++) {
                    if (new Date(arr[i]["date"]).getTime() > new Date(arr[j]["date"]).getTime()) {
                        let clone = {...arr[i]};
                        arr[i] = arr[j];
                        arr[j] = clone;
                    }
                }
            }
            return handler(arr);
        })
    } catch(e) {
        console.error(e)
    }
}

async function onRefundRequestChanged(handler) {
    try {
        onValue(ref(db, "returns/"), (snapshot) => {
            let obj = snapshot.val()
            return handler(mapObjectToArray(obj))
        })
    } catch(e) {
        console.error(e)
    }
}

async function onTopupRequestChanged(handler) {
    try {
        onValue(ref(db, "topup-request/"), (snapshot) => {
            let obj = snapshot.val()
            return handler(obj)
        })
    } catch(e) {
        console.error(e)
    }
}

export {
    topUp,
    sendWithdrawalRequest,
    onWithdrawalRequestChanged,
    onRefundRequestChanged,
    onTopupRequestChanged
};
