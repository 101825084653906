export const PUBLIC_PREFIX = ''
export const LOGIN_PREFIX = '/login'
export const SIGNUP_PREFIX = '/signup'
export const PROFILE_PREFIX = '/profile'
// export const PRODUCT_DETAIL_PREFIX = "/product/*";
// export const CHAT_PREFIX = "/chat";
export const FORWARDED_PREFIX = "/forwarded";
export const PRODUCT_DETAIL_PREFIX = '/product-detail'
export const ERROR_PREFIX = '/*'
export const ADMIN_PREFIX = '/cp'
export const CUSTOMER_PREFIX = '/customer'
export const ADD_PREFIX = '/add'
export const PRODUCT_PREFIX = '/product'
export const ORDER_PREFIX = '/order'
export const SHIPPING_PREFIX = '/shipping'
export const WAREHOUSE_PREFIX = '/warehouse'
export const ABOUT_PREFIX = '/about'
export const PRIVACY_PREFIX = '/privacy'
export const PAYPAL_PREFIX = '/paypal'
export const FEE_PREFIX = '/fee'
export const TERMS_PREFIX = '/terms'
export const PARCEL_PREFIX = '/parcel'
export const INTERNATIONAL_PREFIX = '/international'
export const AFILLIATE_PREFIX = '/afilliate'
export const AFFILIATE_PREFIX = '/affiliate'
export const CHECKOUT_PREFIX = '/checkout'
export const CALCULATOR_PREFIX = '/calc'
export const SEARCH_PREFIX = '/search'
export const MANUAL_PREFIX = '/manual'
export const USER_PREFIX = '/user'
export const GET_PREFIX = '/get'
export const DELETE_PREFIX = '/delete'
export const DETAIL_PREFIX = '/detail'
export const UPDATE_PREFIX = '/update'
export const BANK_PREFIX = '/bank'
export const MANUAL_PRODUCT_DETAIL_PREFIX = '/manual-product'
export const HISTORY_PREFIX = '/history'
export const HISTORYCART_PREFIX = '/historycart'
export const CONFIRM_PREFIX = '/confirm'
export const REFUND_PREFIX = '/refund'
export const STATUS_PREFIX = '/status'
export const ADDRESS_PREFIX = '/address'
export const SHIPPINGSTATUS_PREFIX = '/shippingstatus'
export const FAQ_PREFIX = '/faq'
export const PROMO_PREFIX = '/promo'
export const CONTACT_PREFIX = '/contact'
export const REVIEW_PREFIX = '/review'
export const BUSINESS_PREFIX = '/business'
export const FORGOT_PASSWORD_PREFIX = '/forgot-password'
export const RESET_PASSWORD_PREFIX = '/reset-password'
export const PROFILE_DETAIL_PREFIX = '/profile/*'
export const B2B_PREFIX = '/b2b'
export const FORWARDING_PREFIX = '/forwarding'
export const FAVORITE_PREFIX = '/favorite'
export const WALLET_PREFIX = '/wallet'
export const CART_PREFIX = '/cart'
export const STORAGE_PREFIX = '/storage'
export const PACKAGING_PREFIX = '/packaging'

export const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1740,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 1260,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 770,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
}
