import React, { useState } from "react";
import TabAfterSale from "./TabAfterSale";
import TabAgent from "./TabAgent";
import TabProductDetails from "./TabProductDetails";
import TabSpecification from "./TabSpecification";

const Tabs = ({ item }) => {
  const [currTab, setCurrTab] = useState("details");
  let TabItem;
  switch (currTab) {
    case "specification":
      TabItem = <TabSpecification item={item} />;
      break;
    case "agent":
      TabItem = <TabAgent />;
      break;
    case "after-sale":
      TabItem = <TabAfterSale />;
      break;
    default:
      TabItem = <TabProductDetails item={item} />;
  }
  return (
    <>
      <ul className="product-page__tabs">
        <li
          onClick={() => setCurrTab("details")}
          className={`product-page__tab ${
            currTab === "details" ? "product-page__tab--active" : ""
          }`}
        >
          Product details
        </li>
        <li
          onClick={() => setCurrTab("specification")}
          className={`product-page__tab ${
            currTab === "specification" ? "product-page__tab--active" : ""
          }`}
        >
          Specification parameter
        </li>
        <li
          onClick={() => setCurrTab("agent")}
          className={`product-page__tab ${
            currTab === "agent" ? "product-page__tab--active" : ""
          }`}
        >
          Shopping Agent Guidance
        </li>
        <li
          onClick={() => setCurrTab("after-sale")}
          className={`product-page__tab ${
            currTab === "after-sale" ? "product-page__tab--active" : ""
          }`}
        >
          After Sales Service
        </li>
      </ul>
      {TabItem}
    </>
  );
};

export default Tabs;
