import { db } from "firebase.js";
import axios from 'axios'
import {
    ADD_PREFIX,
    ADMIN_PREFIX,
    CUSTOMER_PREFIX,
    DELETE_PREFIX,
    GET_PREFIX,
    PRODUCT_PREFIX,
    UPDATE_PREFIX
} from 'configs/app-config'
import { ChooseURL, DetectLink, getId } from 'utilities'

export const addProduct = async (params) => {
    const result = await axios.post(
        ADMIN_PREFIX + PRODUCT_PREFIX + ADD_PREFIX,
        params
    )
    if (result.status === 200) {
        return 1
    }
}

export const getSeller = (url) => {
    let shop = 'taobao'

    let decodeUrl = decodeURIComponent(url)
    if (decodeUrl.includes('taobao')) {
        shop = 'taobao'
    } else if (decodeUrl.includes('weidian')) {
        shop = 'weidian'
    } else if (decodeUrl.includes('jd')) {
        shop = 'jd'
    } else if (decodeUrl.includes('1688')) {
        shop = '1688'
    }
    

    return shop
}

export const searchProduct = async (params) => {
    const url = DetectLink(params.search)
    let platfo = ChooseURL(params.search)

    let flag = 0
    let options = {}

    if (url) {
        let id = getId(params.search)

        if (!platfo || !id) {
            //console.log('url not found')
            return {
                flag: flag,
                data: {},
            }
        }

        options = {
            method: 'GET',
            url: `https://api-gw.onebound.cn/${platfo}/item_get/?key=t8607980302&&num_iid=${id}&is_promotion=1&&lang=en&secret=20220515`,
        }
        flag = 1
    } else {
        options = {
            method: 'GET',
            url: `https://api-gw.onebound.cn/taobao/item_search/?key=t8607980302&&q=${
                params.search
            }&start_price=0&end_price=0&page=${Math.floor(
                Math.random() * 5
            )}&cat=0&discount_only=&sort=${
                params.sort ? params.sort : ""
            }&page_size=&seller_info=&nick=&ppath=&imgid=&filter=&&lang=en&secret=20220515`,
        }
        flag = 2
    }

    let counter = 0;
    while (counter < 3) {
        const result = await axios.request(options);

        if (result.status === 200) {
            await db.ref("stats/api").once("value", async snapshot => {
                let vals = snapshot.val();
                let curDate = new Date().toLocaleString('tr-TR').split(" ")[0].split(".");
                curDate[1] = new Date().toLocaleString('en-EN', { month: 'long' });
                curDate = curDate.join(" ");
                if (!vals) vals = {};
                if (!vals[curDate]) vals[curDate] = 1;
                else vals[curDate] += 1;
    
                return db.ref("stats/api").update(vals);
            });

            if (flag != 2) {
                if (result.data.error || !result.data.item.price) {
                    flag = 0
                }
            }
    
            return {
                flag: flag,
                data: result.data,
            }
        } else {
            if (counter === 2) {
                window.location.reload();
                break;
            }

            counter++
        }
    }
}

export const addCustomer = async (params) => {
    const result = await axios.post(
        ADMIN_PREFIX + CUSTOMER_PREFIX + ADD_PREFIX,
        params
    )
    if (result.status === 200) {
        return 1
    }
}

export const getProducts = async () => {
    const result = await axios.post(ADMIN_PREFIX + PRODUCT_PREFIX + GET_PREFIX)
    if (result.status === 200) {
        return result.data
    }
}

export const getCustmers = async () => {
    const result = await axios.post(ADMIN_PREFIX + CUSTOMER_PREFIX + GET_PREFIX)
    if (result.status === 200) {
        return result.data
    }
}

export const updateProduct = async (params) => {
    const result = await axios.post(
        ADMIN_PREFIX + PRODUCT_PREFIX + UPDATE_PREFIX,
        params
    )
    if (result.status === 200) {
        return 1
    }
}

export const updateCustomer = async (params) => {
    const result = await axios.post(
        ADMIN_PREFIX + CUSTOMER_PREFIX + UPDATE_PREFIX,
        params
    )
    if (result.status === 200) {
        return 1
    }
}

export const deleteProduct = async (params) => {
    const result = await axios.post(
        ADMIN_PREFIX + PRODUCT_PREFIX + DELETE_PREFIX,
        params
    )
    if (result.status === 200) {
        return 1
    }
}

export const deleteCustomer = async (params) => {
    const result = await axios.post(
        ADMIN_PREFIX + CUSTOMER_PREFIX + DELETE_PREFIX,
        params
    )
    if (result.status === 200) {
        return 1
    }
}
