import React from "react";

const TabProductDetails = ({ item }) => {
  return (
    <div className="TabProductDetails">
      <div dangerouslySetInnerHTML={{ __html: item?.desc }} />
    </div>
  );
};

export default TabProductDetails;
