import styled from 'styled-components'
import { Container } from 'components/container'
import { Heading } from 'components/heading'
import { SharedImage } from 'constants/image-constant'
import ImageWrapper from 'components/image-wrapper'
import Input from 'components/input'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, div, CardActions } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import * as React from 'react'
//Firebase

import { auth } from 'firebase.js'
import { db } from 'firebase.js'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'

const HeadingStyle = {
    textAlign: 'left',
    margin: '0px',
}

const HeadingStyle2 = {
    fontSize: '18px',
    fontWeight: '400',
}

const ContainerStyle = {
    padding: '50px 20px',
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
}

const ButtonStyle = {
    marginTop: '50px',
}

const { Profile } = SharedImage

export default function Faq() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Container style={ContainerStyle}>
                <Card sx={{ maxWidth: 1000 }}>
                    <div>
                        <h1>FAQ</h1>

                        <CardContent>
                            <div>
                                <h2 style={{ margin: '1rem 0' }}>Delivery</h2>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        Why Should you combine your items into
                                        one Package?
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        When it comes to shipping, we provide
                                        expert packing assistance, which
                                        includes consolidating individual
                                        product parts. It is less expensive than
                                        sending items individually, especially
                                        when shipping internationally. 1. Why
                                        you should use EMS to go to Singapore as
                                        an example One parcel's customs
                                        clearance costs 8 RMB, the first unit is
                                        500g and costs 99 RMB, and each further
                                        unit costs 28 RMB. So, if you have three
                                        products, each of which weighs 700
                                        grams, 1100 grams, and 1600 grams. There
                                        will be a shipping cost of 489RMB if you
                                        choose the standard delivery option.
                                        (99+28+8) + (99+28*2+8) + (99+28*3+8)
                                        =489 RMB It will only cost you 275RMB to
                                        send everything together in one package.
                                        Adding the weights of the individual
                                        components brings the total to 3400g
                                        (700g + 1100g + 1600g). Cost to ship:
                                        275 RMB (99+28*6+8) There was a direct
                                        cost reduction of 214 RMB. 489-275=214
                                        RMB
                                    </AccordionDetails>
                                </Accordion>
                                <h2 style={{ margin: '1rem 0' }}>
                                    Risks With Shipping
                                </h2>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Initial Risk
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        When an order is placed and confirmed,
                                        the system will alert the user to any
                                        possible dangers associated with
                                        shopping agents or package forwarding
                                        services. Once the goods arrive at our
                                        warehouse, we will evaluate their
                                        characteristics and the shipping
                                        environment to determine the safest
                                        shipping option.
                                        <br></br>A friendly reminder that there
                                        is always the possibility that your
                                        shipment may be delayed, taxed, or even
                                        confiscated due to customs issues.
                                        Please be aware that you will be held
                                        financially accountable for any such
                                        dangers.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Secondly, the Customs Declaration
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        We shall declare a fair value for
                                        shopping agency and parcel forwarding
                                        items taking into account the customs
                                        tax threshold in the destination country
                                        and our knowledge of international
                                        shipping practices. When sending in the
                                        package, you may either write a message
                                        or specify the value yourself.
                                        <br></br>
                                        It's worth noting that customs policies
                                        and the regularity with which they're
                                        enforced may significantly increase or
                                        decrease the likelihood that a package
                                        would incur taxes. For further
                                        information, see Taxable Income Limits
                                        Worldwide. Please be aware that any
                                        potential problems that may arise from
                                        customs clearance are your
                                        responsibility (return, confiscation,
                                        taxation).
                                        <br></br>
                                        Additionally, if your goods is
                                        excessively heavy or expensive, customs
                                        officials are more likely to open your
                                        package for further inspection. Avoid
                                        packing too many of the same items into
                                        your shipment, and try to limit the
                                        overall weight to a manageable level.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Shopping Agent Disclaimers
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        KgHype commits to eliminating any of
                                        your worries during international online
                                        shopping. In order to ensure your items
                                        can be received smoothly, we will have
                                        corresponding processing standards to
                                        deal with the items that arrived.
                                        E-commerce platforms, such as
                                        Taobao,1688, weidian may have illegal
                                        commercial phenomena, please be aware of
                                        shopping on it. Following special
                                        circumstances will be seen as you have
                                        agreed and prepared the subsequent
                                        operations. If you have any questions
                                        when submitting orders, please contact
                                        customer service.
                                        <br></br>
                                        <h5>1. Sensitive products</h5>
                                        Contained essential oil, care solution,
                                        lubricants, batteries, glue and other
                                        sensitive items, in order to guarantee
                                        your delivery, we will take them out for
                                        you.
                                        <br></br>
                                        <h5>2. The customs policy</h5>
                                        Items containing a brand, a large volume
                                        of liquid or powders, electrical
                                        products, etc. may be restricted by the
                                        customs policy, where there are certain
                                        risks to　sending them. If the
                                        consequences are caused by the above
                                        risks, KgHype is not responsible for
                                        customs clearance and related legal
                                        responsibility.
                                        <br></br>
                                        <h5>
                                            3. Can't be professionally
                                            inspected.
                                        </h5>
                                        If you buy electric equipment, brand
                                        items, tickets, cards, etc., we cannot
                                        inspect the items professionally (i.e.
                                        quality, authenticity, completeness,
                                        etc.). Inspector can only check whether
                                        the items' appearance is in sound
                                        condition or accessories complete, but
                                        can't turn on the equipment to check the
                                        quality.
                                        <br></br>
                                        <h5>4. Fragile items</h5>
                                        Contained ceramics & glass products,
                                        irregular shape items (wipers, automatic
                                        damper, etc.), items during delivery may
                                        cause abrasion, broken, please be
                                        cautious to purchase. We guarantee that
                                        the completeness of the items before
                                        delivery, but if such items are damaged
                                        during delivery, and logistics have
                                        categorized them as fragile, you need to
                                        bear the resulting loss caused by above
                                        situation.
                                        <br></br>
                                        <h5>5. Customized products</h5>
                                        If you submit orders as customized
                                        items, we suggest you negotiate with the
                                        seller directly so that your
                                        requirements would be met. After the
                                        successful negotiations, you need to
                                        provide us with the number, information,
                                        or signs of Wangwang chatting with the
                                        seller. We cannot do a professional
                                        inspection for these items, so you need
                                        to bear the resulting loss caused by the
                                        above situation.
                                        <br></br>
                                        <h5>6. Deposit order</h5>
                                        Due to the regulation of Taobao sellers,
                                        deposit orders cannot be canceled once
                                        the order has been placed, and
                                        meanwhile, you have to follow up on the
                                        seller’s issued information and clear
                                        the outstanding balance. Then after
                                        that, the items could be delivered. If
                                        the outstanding balance cannot be
                                        cleared within a certain time, the
                                        deposit will not be returned.
                                        <br></br>
                                        <h5>7. Sellers with bad reviews</h5>
                                        We suggest you choose the well-reputed
                                        store which has more than three hearts
                                        and has paid for the Taobao guarantee.
                                        If the seller's credit is low, and there
                                        is no guarantee paid, there might be
                                        bothersome situations, such as false
                                        delivery, no delivery, no after-sale
                                        service, and no rights protection. You
                                        need to bear the resulting loss caused
                                        by above risks.
                                        <br></br>
                                        If your orders have the risks mentioned
                                        above, we will acknowledge that you have
                                        read and accepted the above terms and
                                        conditions, please be sure to read them
                                        carefully. Interpretation right is
                                        reserved by KgHype.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Do You Provide Quality Inspections
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Quality inspection is provided for free
                                        when the product arrives in the Kghype
                                        warehouse. These will be free quality
                                        inspection pictures if you need more
                                        pictures or videos there will be a small
                                        fee for requesting them. For example,
                                        the quality inspection for clothing
                                        would be a picture of it folded, the
                                        size tag and it unfolded. This will help
                                        you make an informed decision on whether
                                        to keep the item or return it.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Do I need an account on Taobao/Weidian?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        No, you only need an account on
                                        KgHype.com and paste the item link you
                                        get from other platforms such as discord
                                        or Reddit.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Are Clothing and Shoes true to Size?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Most of the time clothing is true to
                                        size. Some brands have an oversized fit
                                        so please be careful when purchasing
                                        certain shirts. Most of the time pants
                                        and jeans are true to size. For shoes
                                        please get your actual size it will save
                                        you a lot of time and money. Shoes most
                                        of the time are meant to be true to
                                        size.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        How long does shipping take to our
                                        Warehouse
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Once the payment has been made we will
                                        purchase the item within 48 hours and
                                        once the item has been shipped from the
                                        seller it will arrive at our warehouse
                                        in 3-5 days.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Do you accept Returns?
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Returns are on a case-by-case basis but
                                        most of the time items can be returned
                                        to Taobao and other Chinese
                                        applications. We will try our best to
                                        exchange or return the item. We can
                                        return or exchange for a different size
                                        or color.
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </CardContent>
                    </div>
                    <CardActions></CardActions>
                </Card>
            </Container>
        </div>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width: 800px) {
        display: none;
    }
`
const SideMenu = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: Open Sans;
    padding: 0px;
    margin: 0px;
    padding-top: 50px;
`

const Content = styled.section`
    padding-left: 100px;
    max-width: 100%;
    flex: 1;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
    }
`

const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 50px 0px;
    align-items: center;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

const TextWrapper = styled.div`
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 800px) {
        padding-left: 0px;
        padding-top: 30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding: 0px;
    margin: 0px;
    padding: 0px;
    @media screen and (max-width: 800px) {
        text-align: center;
    }
`
