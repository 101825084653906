import styled from 'styled-components';
import { Container } from "components/container";
import { Heading } from "components/heading";
import ImageWrapper from 'components/image-wrapper';
import Input from 'components/input';
import Button from 'components/button';
import { SharedImage } from "constants/image-constant";
import { ClientTable, ClientTd, ClientTh, ClientTr } from "components/client-table";
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

//Firebase 
import { auth } from 'firebase.js';
import { db } from 'firebase.js';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"

const HeadingStyle = {
    textAlign: "left",
    margin: "0px"
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


  
const { Product } = SharedImage;



const MetaStyle = {
    paddingLeft: "10px"
}


const HeadingStyle2 = {
    fontSize: "18px",
    fontWeight: "400"
}

const ContainerStyle = {
    padding: "50px 20px",
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap"
}

const ButtonStyle = {
    borderRadius: 5,
    marginTop: "50px"
}

const { Profile } = SharedImage;

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Something', 'something', 'something', 'something', 'something'),

  ];

export default function Shipping() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    const [nameText, setNameText] = useState([]);
    const [mailText, setMailText] = useState([]);

    onAuthStateChanged(auth, async (currentUser) => {
        if (!currentUser) {
            window.location = "/login";
        }
        else {
            const usersRef = await db.ref("users");
            usersRef.once('value', function (snapshot) {
                snapshot.forEach(function (usersSnapshot) {
                    var usersData = usersSnapshot.val();


                    if (usersData.mail == currentUser.email) {

                        setNameText(`${usersData.fName} ${usersData.lName} `);
                        setMailText(usersData.mail);
                    }

                });
            });
        }
    });

    return (
        <Container style={ContainerStyle}>

            <SideBar>
                <Heading style={HeadingStyle}>
                    Menu
                </Heading>
                <SideMenu>
                    <li>My Orders</li>
                    <li>My Warehouse</li>
                    <li>International Shipping</li>
                    <li>Address Book</li>
                    <li>Profile</li>

                </SideMenu>
            </SideBar>
            <Content>

            <Heading style={HeadingStyle}>
                Shipping status
            </Heading>
            <ClientTable>
                <ClientTr>
                    <ClientTh>Item Name</ClientTh>
                    <ClientTh>Price</ClientTh>
                    <ClientTh>Status</ClientTh>
                    <ClientTh>Shipping Method</ClientTh>
                    <ClientTh>Tracking Number</ClientTh>
                </ClientTr>
                <ClientTr >
                    <ClientTd>
                        <Wrapper>
                            <ProductWrapper>
                                <ImageWrapper src={Product} alt="product" />
                            </ProductWrapper>
                            Cowhide SportsShoe 2023 new edition
                        </Wrapper>
                    </ClientTd>
                    <ClientTd>
                        1021
                    </ClientTd>
                    <ClientTd>
                        Shipping
                    </ClientTd>
                    <ClientTd>
                        Something
                    </ClientTd>
                    <ClientTd>
                        Something
                    </ClientTd>
                    <ClientTd>
                    <div>
      <Button aria-describedby={id} text="More" variant="contained" onClick={handleClick}>
        
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Item </TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Shipping Method</TableCell>
            <TableCell align="right">Tracking Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.fat}
              <Avatar
        alt="Remy Sharp"
        src="https://mui.com/static/images/avatar/1.jpg"
        sx={{ width: 56, height: 56 }}
      />
              </TableCell>
              <TableCell align="right"> {row.fat}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Popover>
    </div>
                    </ClientTd>
                </ClientTr>
 
                <ClientTr >
                    <ClientTd>
                        <Wrapper>
                            <ProductWrapper>
                                <ImageWrapper src={Product} alt="product" />
                            </ProductWrapper>
                            Cowhide SportsShoe 2023 new edition
                        </Wrapper>
                    </ClientTd>
                    <ClientTd>
                        1021
                    </ClientTd>
                    <ClientTd>
                        Shipping
                    </ClientTd>
                    <ClientTd>
                    Something
                    </ClientTd>
                    <ClientTd>
                        Something
                    </ClientTd>
                    <ClientTd>
                        <Button text="More" />
                    </ClientTd>
                </ClientTr>
            </ClientTable>
            <Wrapper>

            </Wrapper>
            </Content>

        </Container>
    )
}

const SideBar = styled.aside`
    @media screen and (max-width:800px){
        display:none;
    }
`
const SideMenu = styled.ul`
    list-style:none;
    display:flex;
    flex-direction:column;
    gap:20px;
    font-family:Open Sans;
    padding:0px;
    margin:0px;
    margin-left:50px;
    padding-top:50px;
`

const Content = styled.section`
    padding-left:100px;
    max-width:100%;
    flex:1;
    @media screen and (max-width:800px){
        padding-left:0px;
    }
`

const ProfileWrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-flow:wrap;
    padding:50px 0px;
    align-items:center;
    @media screen and (max-width:800px) {
        flex-direction:column;
        align-items:center; 
    }
`

const TextWrapper = styled.div`
    padding-left:50px;
    display:flex;
    flex-direction:column;
    gap:20px;
    @media screen and (max-width:800px){
        padding-left:0px;
        padding-top:30px;
    }
`

const UserInfoText = styled.h3`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    text-align: left;
    padding:0px;
    margin:0px;
    padding:0px;
    @media screen and (max-width:800px){
        text-align:center;
    }
`


const ProductWrapper = styled.div`
    max-width:150px;
    padding-right:20px;
`

const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
`

const MetaWrapper = styled.div`
    display:flex;
    flex-direction:row;
    gap:30px;
    padding-left:20px;
`

const ButtonWrapper = styled.div`
borderRadius: 5;
    display:flex;
    flex-direction:column;
    gap:10px;
`