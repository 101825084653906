import { CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Container } from "components/container";

const ContainerStyle = {
    padding: "50px 20px",
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap"
}

export default function About() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Container style={ContainerStyle}>
                <Card sx={{ maxWidth: 1000 }}>
                    <div>
                        <h1>ABOUT US</h1>
                        <CardMedia
                            component="img"
                            image="https://cdn.discordapp.com/attachments/905521023363997766/992518362842271874/unknown.png"
                        />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                KgHype is a startup company started in 2023, with the main goal of providing a trustworthy solution for buying products from China. Taobao is one of the biggest Chinese e-commerce platforms but unfortunately, it is not available worldwide leaving billions of products locked away from the rest of the world. KgHype was created to fix that problem created for small business owners and everyday consumers, this platform allows anyone to buy from places such as Taobao, 1688, Tmall, Weidian, Jd, and even yupoo sellers.
                            </Typography>
                            <br>
                            </br>
                            <Typography variant="h5" component="div">

                                The CEO’s main goal when creating this platform was to create a simple and easy-to-use platform for users to purchase items from China. We offer many ways to contact us through email, Live chat, and even our public discord server. Our mission is to make the most enjoyable shopping agent experience for anyone regardless of order quantity being 1 item or 100’s of items we are here to help
                            </Typography>
                            <br></br>
                            <Typography variant="h5" component="div">

                                Thank you for your continued support
                            </Typography>
                            <br></br>
                            <Typography variant="h5" component="div">

                                King Regards
                            </Typography>

                        </CardContent>
                        <CardMedia
                            component="img"
                            image="https://cdn.discordapp.com/attachments/905521023363997766/992518397155881010/unknown.png"
                        />
                    </div>
                    <CardActions>

                    </CardActions>
                </Card>
            </Container>

        </div>
    )
}