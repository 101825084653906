import Spinner from 'components/Spinner'
import { useEffect, useState } from 'react'
//icons
import { FaStar } from 'react-icons/fa'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import { useSelector } from 'react-redux'
import { NotifyFail, NotifySuccess } from 'utilities'
import { addToFavoriteButton } from 'utilities/favorite'

const ProductGallery = ({ item, setCurrImage, currImage }) => {
    let user = useSelector(state => state.user);
    const [images, setImages] = useState([])
    // const [currImage, setCurrImage] = useState(images[0]);

    useEffect(() => {
        if (item?.item_imgs) {
            setImages(
                item.item_imgs.map((img) =>
                    img.url.startsWith('https:') ? img.url : `https:${img.url}`
                )
            )
            setCurrImage(
                item?.item_imgs[0].url.startsWith('https:')
                    ? item?.item_imgs[0].url
                    : 'https:' + item?.item_imgs[0].url
            )
        }
    }, [item, setCurrImage])

    const changeCurrImage = (imgSrc) => {
        setCurrImage(imgSrc)
    }

    if (!user) return <Spinner />
    return (
        <div className="productGallery">
            <div className="productGallery__currImgbox">
                {/* <img
          className="productGallery__currImg"
          // src={currImage}
          alt="product img 1"
        /> */}
                {currImage && (
                    <InnerImageZoom
                        zoomPreload={true}
                        zoomType="hover"
                        src={currImage}
                    />
                )}
            </div>
            <ul className="productGallery__allImages">
                {images.map((imgSrc, index) => (
                    <li
                        key={index}
                        onClick={changeCurrImage.bind(null, imgSrc)}
                        className={`productGallery__imgBox ${
                            currImage === imgSrc ? 'productGallery__active' : ''
                        }`}
                    >
                        <img
                            src={imgSrc}
                            alt="product img 1"
                            className="productGallery__img"
                        />
                    </li>
                ))}
            </ul>
            <div className="productGallery__btns">
                <button
                    onClick={async () => {
                        try {
                            await addToFavoriteButton(item, user)
                        } catch (e) {
                            NotifyFail('Product was not favorited')
                        }
                    }}
                    className="btn btn__text btn__text--fav"
                >
                    <FaStar /> Favorite
                </button>
            </div>
            <p className="productGallery__sale">Sales: {item?.total_sold}</p>
        </div>
    )
}

export default ProductGallery
