const chooseData = [
  {
    image: "/reason-1.png",
    name: "Over 1.49 million registered users",
  },
  {
    image: "/reason-2.png",
    name: "Footprint in over 185 countries",
  },
  {
    image: "/reason-3.png",
    name: "Offers over 300 million SKU",
  },
  {
    image: "/reason-4.png",
    name: "Over 8 million SKU shipped",
  },
  {
    image: "/reason-5.png",
    name: "Ships to over 180 countries and regions",
  },
  {
    image: "/reason-6.png",
    name: "80% repurchase rate",
  },
];

export default chooseData;
