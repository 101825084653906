import { Box, Center, Heading, Image } from '@chakra-ui/react'

function IntroSection() {
    return (
        <Box position="relative" w="full">
            <Image
                src="/guide-banner.png"
                position="absolute"
                zIndex={1}
                w="full"
                mt="100"
                minH={['7.5rem', '7.5rem', '7.5rem', '8.3rem', '8.3rem']}
                alt=""
            />
            <Center>
                <Box>
                    <Heading
                        position="relative"
                        color="white"
                        textAlign="center"
                        fontSize="3xl"
                        mt={['110', '110', '110', '130', '130']}
                        zIndex={2}
                    >
                        BUSINESS
                    </Heading>
                    <Heading
                        position="relative"
                        color="white"
                        textAlign="center"
                        fontSize="xl"
                        fontWeight="normal"
                        zIndex={2}
                    >
                        One-Stop Business Solutions Platform.
                    </Heading>
                </Box>
            </Center>
        </Box>
    )
}

export default IntroSection
